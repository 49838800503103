import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = {};

const acceptedSchema = {
    "id": true,
    "timeDeparture": true,
    "airportArrival": true,
    "airportDeparture": true,
    "reservationNumber": true,
    "comment": true,
    "representativeAgreementRequired": true,
    "problemClaimFlight": true,
    "details": true,
    "flights": true,
    "passengers": true,
    "partnerId": true,
    "user": true,
    "documents": true,
    "metadata": true,
    "medium": true,
    "alternativeFlights": true,
    "alternativeTransport": true,
    "alternativeVehicle": true
}

const userSchema = {
    name: "",
    surname: "",
    birthdate: "",
    email: "",
    address: "",
    city: "",
    country: "",
    phoneCode: "",
    phone: "",
}

export default function (state = INITIAL_STATE, action) {
    const { type, payload } = action;

    switch (type) {
        case V2_ACTIONS.SET_V2_CLAIM: 
            const newPayload = { ...state, ...payload };
            Object.keys(payload).map((key) => {
                if (!acceptedSchema[key]) {
                    delete newPayload[key];
                }
                return key;
            });
            return newPayload;
        case V2_ACTIONS.RESET_V2_CLAIM:
            return INITIAL_STATE;
        case V2_ACTIONS.RESET_V2_CLAIM_USER:
            return {
                ...state,
                user: userSchema,
            };
        default:
    }
    return state;
}