import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { CheckBox } from '../../../shared/components/Form'
import t from '../../../shared/translations'

const CookieItem = styled.div`
    width: 100%;
    margin-bottom: 16px;
    padding: 20px 40px;
    background: #fafafa;
    border-radius: 16px;
`

const CategoryTitle = styled.div`
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 600;
`

const CategoryDescription = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #717171;
`

const MoreInfoLink = styled.div`
    color: ${({ theme }) => theme.colors.primaryColor};
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;

    cursor: pointer;
    padding-bottom: 10px;
`

const TextContainer = styled.div`
    width: 90%;
    display: inline-block;
`

const ActionContainer = styled.div`
    width: 20%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
`

const MoreInfoBox = styled.table`
    width: 100%;
    transition: all 0.5s ease;

    td,
    th {
        text-align: left;
        padding: 6px;
        font-size: 12px;
    }

    th {
        border-bottom: 2px solid #dddddd;
    }
    tbody {
        tr:nth-child(odd) {
            background-color: #f1f7f7;
        }
    }
`

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const TableContainer = styled.div`
    max-height: ${({ visible }) => (visible ? '999px' : '0px')};
    overflow: hidden;
    transition-timing-function: ease;
    transition: max-height 0.8s;
`

class AdvancedSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            visible1: false,
            visible2: false,
            visible3: false,
        }
    }

    _viewMore = moreId => {
        this.setState({ [`visible${moreId}`]: !this.state[`visible${moreId}`] })
    }

    render() {
        const { cookies, onChange } = this.props
        return (
            <div>
                {cookies.map(
                    ({
                        title,
                        description,
                        id,
                        advancedState,
                        name,
                        details,
                    }) => (
                        <CookieItem key={title}>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'column',
                                }}
                            >
                                <HeaderWrapper>
                                    <CategoryTitle>{title}</CategoryTitle>

                                    <ActionContainer>
                                        <CheckBox
                                            checked={advancedState}
                                            disabled={id === 1}
                                            name={name}
                                            onChange={onChange}
                                        />
                                    </ActionContainer>
                                </HeaderWrapper>
                                <TextContainer>
                                    <CategoryDescription>
                                        {description}
                                    </CategoryDescription>
                                    <MoreInfoLink
                                        visible={this.state[`visible${id}`]}
                                        onClick={() => this._viewMore(id)}
                                    >
                                        {t`cookies.shared.label.view_cookies`}
                                    </MoreInfoLink>
                                </TextContainer>
                            </div>
                            <TableContainer
                                visible={this.state[`visible${id}`]}
                            >
                                <MoreInfoBox
                                    visible={this.state[`visible${id}`]}
                                >
                                    <tr>
                                        <th>{t`cookies.shared.label.cookie`}</th>
                                        <th>{t`cookies.shared.label.purpose`}</th>
                                    </tr>
                                    <tbody>
                                        {details.map(({ title, info }) => (
                                            <tr key={title}>
                                                <td>{title}</td>
                                                <td>{info}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </MoreInfoBox>
                            </TableContainer>
                        </CookieItem>
                    )
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

export default connect(mapStateToProps)(AdvancedSettings)
