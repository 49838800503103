import React from 'react'

export const DotsIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="14"
        viewBox="0 0 4 14"
        fill="none"
    >
        <path
            d="M3.33333 2.00065C3.33333 2.92113 2.58714 3.66732 1.66667 3.66732C0.746192 3.66732 0 2.92113 0 2.00065C0 1.08018 0.746192 0.333984 1.66667 0.333984C2.58714 0.333984 3.33333 1.08018 3.33333 2.00065ZM3.33333 7.00065C3.33333 7.92113 2.58714 8.66732 1.66667 8.66732C0.746192 8.66732 0 7.92113 0 7.00065C0 6.08018 0.746192 5.33398 1.66667 5.33398C2.58714 5.33398 3.33333 6.08018 3.33333 7.00065ZM1.66667 13.6673C2.58714 13.6673 3.33333 12.9211 3.33333 12.0007C3.33333 11.0802 2.58714 10.334 1.66667 10.334C0.746192 10.334 0 11.0802 0 12.0007C0 12.9211 0.746192 13.6673 1.66667 13.6673Z"
            fill="#525F7F"
        />
        <path
            d="M3.33333 2.00065C3.33333 2.92113 2.58714 3.66732 1.66667 3.66732C0.746192 3.66732 0 2.92113 0 2.00065C0 1.08018 0.746192 0.333984 1.66667 0.333984C2.58714 0.333984 3.33333 1.08018 3.33333 2.00065ZM3.33333 7.00065C3.33333 7.92113 2.58714 8.66732 1.66667 8.66732C0.746192 8.66732 0 7.92113 0 7.00065C0 6.08018 0.746192 5.33398 1.66667 5.33398C2.58714 5.33398 3.33333 6.08018 3.33333 7.00065ZM1.66667 13.6673C2.58714 13.6673 3.33333 12.9211 3.33333 12.0007C3.33333 11.0802 2.58714 10.334 1.66667 10.334C0.746192 10.334 0 11.0802 0 12.0007C0 12.9211 0.746192 13.6673 1.66667 13.6673Z"
            fill="black"
            fillOpacity="0.2"
        />
    </svg>
)

export const ActiveIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
    >
        <path
            d="M12.15 6.867C10.989 7.488 9.927 8.28 9 9.234C8.073 8.271 7.011 7.479 5.85 6.867C6.066 4.374 7.128 1.926 9.054 0C10.962 1.908 11.979 4.356 12.15 6.867ZM9 12.105C10.935 9.153 14.238 7.2 18 7.2C18 16.2 9.612 17.892 9 18C8.388 17.901 0 16.2 0 7.2C3.762 7.2 7.065 9.153 9 12.105ZM9.045 2.871C8.451 3.807 8.037 4.842 7.812 5.922L9 6.795L10.215 5.913C10.008 4.833 9.612 3.798 9.045 2.871ZM9 16.173C9 16.173 14.4 15.3 15.966 9.225C10.8 10.8 9 15.39 9 15.39C9 15.39 6.3 9.9 2.034 9.234C3.6 15.3 9 16.173 9 16.173Z"
            fill="#219653"
        />
    </svg>
)

export const ActiveFamilyIcon = ({ color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
    >
        <path
            d="M9.99627 0.870575C10.568 0.62614 11.1816 0.5 11.8019 0.5C12.4222 0.5 13.0358 0.62614 13.6076 0.870575C14.1791 1.11494 14.6968 1.47242 15.1318 1.92142C15.567 2.37029 15.9111 2.90196 16.1454 3.48549C16.3797 4.06898 16.5 4.69361 16.5 5.3239C16.5 5.95419 16.3797 6.57882 16.1454 7.16232C15.9111 7.74579 15.5671 8.27742 15.1319 8.72625C15.1319 8.72629 15.132 8.72621 15.1319 8.72625L8.78839 15.2719C8.64712 15.4177 8.4528 15.5 8.24981 15.5C8.04682 15.5 7.8525 15.4177 7.71123 15.2719L1.36768 8.72625C0.489403 7.81999 0 6.59571 0 5.3239C0 4.05209 0.489403 2.82781 1.36768 1.92155C2.24672 1.01449 3.44407 0.500396 4.69771 0.500396C5.95134 0.500396 7.14869 1.01449 8.02774 1.92155L8.24981 2.1507L8.47176 1.92168C8.47172 1.92172 8.4718 1.92163 8.47176 1.92168C8.90678 1.47262 9.42467 1.11496 9.99627 0.870575ZM14.0547 2.96532C13.7567 2.65769 13.4041 2.41496 13.0179 2.2498C12.6316 2.08467 12.2185 2 11.8019 2C11.3853 2 10.9722 2.08467 10.586 2.2498C10.1997 2.41496 9.84715 2.65769 9.54916 2.96532L8.78839 3.75034C8.64712 3.89611 8.4528 3.97839 8.24981 3.97839C8.04682 3.97839 7.8525 3.89611 7.71123 3.75034L6.95057 2.96545C6.34894 2.34465 5.53806 2.0004 4.69771 2.0004C3.85735 2.0004 3.04647 2.34465 2.44484 2.96545C1.84243 3.58705 1.5 4.43499 1.5 5.3239C1.5 6.21281 1.84243 7.06075 2.44484 7.68235L8.24981 13.6723L14.0548 7.68235C14.353 7.37483 14.5908 7.00829 14.7534 6.60336C14.916 6.1984 15 5.76356 15 5.3239C15 4.88424 14.916 4.4494 14.7534 4.04444C14.5908 3.63951 14.3528 3.27285 14.0547 2.96532Z"
            fill={color}
        />
    </svg>
)

export const ArchivedIcon = () => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_374_1709)">
            <path
                d="M2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75C5.27208 15.75 2.25 12.7279 2.25 9ZM9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75ZM7.28033 6.21967C6.98744 5.92678 6.51256 5.92678 6.21967 6.21967C5.92678 6.51256 5.92678 6.98744 6.21967 7.28033L7.93934 9L6.21967 10.7197C5.92678 11.0126 5.92678 11.4874 6.21967 11.7803C6.51256 12.0732 6.98744 12.0732 7.28033 11.7803L9 10.0607L10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.0607 9L11.7803 7.28033C12.0732 6.98744 12.0732 6.51256 11.7803 6.21967C11.4874 5.92678 11.0126 5.92678 10.7197 6.21967L9 7.93934L7.28033 6.21967Z"
                fill="#525F7F"
            />
            <path
                d="M2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75C5.27208 15.75 2.25 12.7279 2.25 9ZM9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75ZM7.28033 6.21967C6.98744 5.92678 6.51256 5.92678 6.21967 6.21967C5.92678 6.51256 5.92678 6.98744 6.21967 7.28033L7.93934 9L6.21967 10.7197C5.92678 11.0126 5.92678 11.4874 6.21967 11.7803C6.51256 12.0732 6.98744 12.0732 7.28033 11.7803L9 10.0607L10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.0607 9L11.7803 7.28033C12.0732 6.98744 12.0732 6.51256 11.7803 6.21967C11.4874 5.92678 11.0126 5.92678 10.7197 6.21967L9 7.93934L7.28033 6.21967Z"
                fill="black"
                fillOpacity="0.2"
            />
        </g>
        <defs>
            <clipPath id="clip0_374_1709">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const FamilyIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
    >
        <g opacity="0.6">
            <path
                d="M4.77273 7.02499C3.18409 7.02499 0 7.99999 0 9.94166V11.1917C0 11.65 0.306818 12.025 0.681818 12.025H8.86364C9.23864 12.025 9.54545 11.65 9.54545 11.1917V9.94166C9.54545 7.99999 6.36136 7.02499 4.77273 7.02499ZM10.2273 7.02499C10.0295 7.02499 9.80455 7.04166 9.56591 7.06666C9.57955 7.07499 9.58636 7.09166 9.59318 7.09999C10.3705 7.79166 10.9091 8.71666 10.9091 9.94166V11.1917C10.9091 11.4833 10.8614 11.7667 10.7864 12.025H14.3182C14.6932 12.025 15 11.65 15 11.1917V9.94166C15 7.99999 11.8159 7.02499 10.2273 7.02499Z"
                fill="#525F7F"
            />
            <path
                d="M4.77273 7.02499C3.18409 7.02499 0 7.99999 0 9.94166V11.1917C0 11.65 0.306818 12.025 0.681818 12.025H8.86364C9.23864 12.025 9.54545 11.65 9.54545 11.1917V9.94166C9.54545 7.99999 6.36136 7.02499 4.77273 7.02499ZM10.2273 7.02499C10.0295 7.02499 9.80455 7.04166 9.56591 7.06666C9.57955 7.07499 9.58636 7.09166 9.59318 7.09999C10.3705 7.79166 10.9091 8.71666 10.9091 9.94166V11.1917C10.9091 11.4833 10.8614 11.7667 10.7864 12.025H14.3182C14.6932 12.025 15 11.65 15 11.1917V9.94166C15 7.99999 11.8159 7.02499 10.2273 7.02499Z"
                fill="black"
                fillOpacity="0.2"
            />
            <path
                d="M14 2.52499C14 3.90833 12.8592 5.02499 11.4353 5.02499C10.0114 5.02499 8.86204 3.90833 8.86204 2.52499C8.86204 1.14166 10.0114 0.0249939 11.4353 0.0249939C12.8592 0.0249939 14 1.14166 14 2.52499Z"
                fill="#525F7F"
            />
            <path
                d="M14 2.52499C14 3.90833 12.8592 5.02499 11.4353 5.02499C10.0114 5.02499 8.86204 3.90833 8.86204 2.52499C8.86204 1.14166 10.0114 0.0249939 11.4353 0.0249939C12.8592 0.0249939 14 1.14166 14 2.52499Z"
                fill="black"
                fillOpacity="0.2"
            />
            <path
                d="M7.13796 2.52499C7.13796 3.90833 5.99714 5.02499 4.57327 5.02499C3.14939 5.02499 2 3.90833 2 2.52499C2 1.14166 3.14939 0.0249939 4.57327 0.0249939C5.99714 0.0249939 7.13796 1.14166 7.13796 2.52499Z"
                fill="#525F7F"
            />
            <path
                d="M7.13796 2.52499C7.13796 3.90833 5.99714 5.02499 4.57327 5.02499C3.14939 5.02499 2 3.90833 2 2.52499C2 1.14166 3.14939 0.0249939 4.57327 0.0249939C5.99714 0.0249939 7.13796 1.14166 7.13796 2.52499Z"
                fill="black"
                fillOpacity="0.2"
            />
        </g>
    </svg>
)
