import React from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import moment from 'moment'
import styled from 'styled-components'
import t from '../../../../shared/translations'
import { Col, Row } from '../../../../shared/components/V2/Layout'
import { isMobileDevice, isProduction } from '../../../../main'
import V2FlightNumber from '../../../../shared/components/V2/V2FlightNumber'
import AirlineInput from '../../components/AirlineInput'
import {
    Reupload,
    PlaneIconV2,
    WarnIcon,
    Timer,
} from '../../../../shared/components/svgIcons'
import FlightInput, { flightTypes } from '../../components/FlightInput'
import {
    VerticalRadio,
    ImageRadio,
} from '../../../../shared/components/V2/RadioButtons'
import {
    desktop,
    mobile,
    tablet,
} from '../../../../shared/components/helpers/styled_queries'
import {
    FlightAlternative,
    Bus,
    Train,
    DontRemember,
} from '../../../../shared/components/V2/SkycopPay/icons'
import {
    clearAllSuggestedFlights,
    getAirportById,
    getSuggestedFlights,
    setSelectedAirports,
    setUsedAirports,
    showModal,
} from '../../../../shared/actions/v2_actions'
import { CloseCircle } from './index'
import { SideWaysRadio } from '../../../../shared/components/V2/RadioButtons'
import { Document } from '../../../../shared/components/V2/V2Icons'
import {
    DocumentWrapper,
    IconWrapper,
    DocumentStatusWrapper,
    ClearIconWrapper,
    DocumentTitle,
    DocumentStatus,
    ReuploadWrapper,
    ReuploadTitle,
} from './flightDocumentsStyles'
import { bindActionCreators } from 'redux'
import { HeaderContainer } from '../FlightDetails'
import Datepicker from '../../../../shared/components/V2/DatePicker'
import { modalTypes } from '../../../../shared/constants'
import FlightSuggestModalCodeshares from '../../components/FlightSuggestModalCodeshares'
import { alternativeTransports } from '../FlightDetails'

const WrapperFields = styled.div`
    display: flex;
    align-items: end;
    padding-left: 0px;
`

const TimeIconWrapper = styled.div`
    position: absolute;
    top: 18px;
    right: 18px;
`

const CalendarWrapper = styled.div`
    margin-right: 8px;
`

const ColNoPadding = styled(Col)`
    padding: unset;
    position: relative;
`

const LeftSideCol = styled(Col)`
    ${desktop`
        padding-right: 0px;
    `}
`

const Input = styled.input`
    width: 100%;
    display: relative;
    border-radius: 12px;
    height: 56px;
    font-size: 16px;
    background: #fafafa !important;
    font-family: 'Noto Sans';
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    line-height: 1.71;
    letter-spacing: normal;
    color: #333333;
    padding: 12px 16px;
    border: 1px solid #717171;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:hover,
    &:active,
    &:focus {
        box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
    }

    &:disabled,
    &.disabled {
        background: #f2f5f9;
        color: #7f8fa4;
        font-weight: 600;
        opacity: 1;
        -webkit-text-fill-color: #7f8fa4;

        &:hover {
            box-shadow: none;
            cursor: not-allowed;
        }
    }

    &::placeholder {
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 400;

        color: #717171;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
    }
`

const PresuggestLink = styled.div`
    opacity: 0.9;
    font-family: 'Noto Sans';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    background-color: #149954;
    color: #fff;
    display: flex;
    align-self: flex-end;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52px;
`

const FlightAirportIataTitleArrival = styled.div`
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    color: #717171;
    margin-left: 43px;
    width: 100px;
`

const FlightRow = styled(Row)`
    flex-direction: column;

    .simple-select {
        padding: 0px 12px;
        flex-wrap: wrap;

        .simple-item {
            width: fit-content;
            flex: unset;
            border-radius: 32px;
            border: solid 1px #717171;
            background-color: #f7f7f8;
            margin: 0px 8px 8px 0px;
            font-size: 16px;
            color: #728096;
            font-weight: 600;
            padding: 5px 12px;

            &:hover {
                opacity: 0.9;
                cursor: pointer;
                border-color: #43c67f;
            }

            span:first-child {
                color: #3a3f56;
            }
        }

        .simple-selected {
            border-color: #43c67f;
            background-color: rgba(50, 184, 106, 0.05);
        }
    }

    .simple-label {
        font-family: 'Noto sans';
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        color: #7f8fa4;
        letter-spacing: normal;
        margin-bottom: 8px;

        ${Col} {
            padding-bottom: 0px;
        }
    }

    ${tablet`
        .simple-select {
            flex-direction: row;
            width: 66.666666666666%;

            .simple-item {
                margin: 0px 8px 8px 0px;

            }
        }
    `}

    ${mobile`
        .simple-select {
            .simple-item {
                margin: 8px 0px;
            }
        }
    `}
`

const StyledFieldsRow = styled(Row)`
    // margin-bottom: 16px;

    ${tablet`
        margin-bottom: 0px;
        `}

    ${mobile`
        margin-bottom: 0px;
        `}
`

const IataIconWrapper = styled.div`
    margin-right: 8px;
    margin-top: 6px;
    ${mobile`
        margin-left: 6pxpx;
    `}
`

export const PlaneIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
`

const FlightAirportIataTitle = styled.div`
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    color: #717171;
    margin-left: 33px;
    width: 100px;

    ${mobile`
         margin-left: 38px;
    `}
`

const FlightAirportIata = styled.div`
    font-family: Noto Sans;
    font-size: 40px;
    font-weight: 600;
    color: #000000;
`

const GroupInputsTitle = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #717171;

    span {
        color: #fe6050;
        margin-left: 4px;
    }
`

const IataTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const FlightCounterDesktop = styled.div`
    margin-left: auto;
    color: #717171;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;

    ${mobile`
        text-align: center;
        align-items: center;
        justify-content: center;
        display: none;
    `}

    ${tablet`
        text-align: center;
        align-items: center;
        justify-content: center;
    `}
`

const Title = styled.div`
    color: #717171;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    text-align: left;
    margin-top: 10px;
`

const Description = styled.div`
    line-height: 22.4px;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 4px;
    color: #717171;
`

const WrapperTitles = styled.div`
    display: flex;
    flex-direction: column;
`

const FLightCounterMobile = styled(FlightCounterDesktop)`
    display: none;
    ${mobile`
        display: unset;
        margin-bottom: 12px;
        margin-left: 16px;
        width: max-content;
    `}
`

const StyledRow = styled(Row)`
    margin-bottom: 24px;
    ${mobile`
        padding-right: 16px;
        `}
`

const StyledCol = styled(Col)`
    // padding: 0px 16px 13px 0px;
`

class AlternativeTransportation extends React.Component {
    state = {
        preselectLoadingInternal: false,
    }

    componentDidMount() {
        const {
            alternativeTransEnabled,
            setUsedAirports,
            setSelectedAirports,
        } = this.props

        if (alternativeTransEnabled) {
            setUsedAirports([], true)
            setSelectedAirports([])
            clearAllSuggestedFlights()
        }
    }

    componentWillUnmount() {
        const { alternativeTransEnabled } = this.props

        if (alternativeTransEnabled) {
            clearAllSuggestedFlights()
        }
    }

    getCustomReasons = () => {
        const {
            hasAlternativeTransport,
            handleHasAlternativeTransportChange,
        } = this.props

        return [
            {
                name: 'has_alternative_reason',
                value: hasAlternativeTransport,
                onChange: handleHasAlternativeTransportChange,
                checked: hasAlternativeTransport === 'has_alternative_reason',
                label: t`yes`,
                id: 'yes-provided',
            },
            {
                name: 'has_no_alternative_reason',
                value: hasAlternativeTransport,
                onChange: handleHasAlternativeTransportChange,
                checked:
                    hasAlternativeTransport === 'has_no_alternative_reason',
                label: t`v2_common.label.no`,
                id: 'no-provided',
            },
        ]
    }

    getImageReasons = () => {
        const {
            alternativeTransportType,
            handleAlternativeTransportTypeChange,
            primaryColor,
        } = this.props

        return [
            {
                name: alternativeTransports.ALTERNATIVE_TRANSPORT_FLIGHT,
                checked:
                    alternativeTransportType ===
                    alternativeTransports.ALTERNATIVE_TRANSPORT_FLIGHT,
                value: alternativeTransportType,
                onChange: handleAlternativeTransportTypeChange,
                label: t`alternative.trans.page.what.alternative.transport.flight`,
                id: '1',
                icon: (
                    <FlightAlternative
                        stroke={
                            alternativeTransportType ===
                                alternativeTransports.ALTERNATIVE_TRANSPORT_FLIGHT &&
                            primaryColor
                        }
                    />
                ),
            },
            {
                name: alternativeTransports.ALTERNATIVE_TRANSPORT_TRAIN,
                checked:
                    alternativeTransportType ===
                    alternativeTransports.ALTERNATIVE_TRANSPORT_TRAIN,
                value: alternativeTransportType,
                onChange: handleAlternativeTransportTypeChange,
                label: t`alternative.trans.page.what.alternative.transport.train`,
                id: '2',
                icon: (
                    <Train
                        stroke={
                            alternativeTransportType ===
                                alternativeTransports.ALTERNATIVE_TRANSPORT_TRAIN &&
                            primaryColor
                        }
                    />
                ),
            },
            {
                name: alternativeTransports.ALTERNATIVE_TRANSPORT_BUS_CAR,
                checked:
                    alternativeTransportType ===
                    alternativeTransports.ALTERNATIVE_TRANSPORT_BUS_CAR,
                value: alternativeTransportType,
                onChange: handleAlternativeTransportTypeChange,
                label: t`alternative.trans.page.what.alternative.transport.bus.car`,
                id: '3',
                icon: (
                    <Bus
                        stroke={
                            alternativeTransportType ===
                                alternativeTransports.ALTERNATIVE_TRANSPORT_BUS_CAR &&
                            primaryColor
                        }
                    />
                ),
            },
            {
                name: alternativeTransports.ALTERNATIVE_TRANSPORT_DR,
                checked:
                    alternativeTransportType ===
                    alternativeTransports.ALTERNATIVE_TRANSPORT_DR,
                value: alternativeTransportType,
                onChange: handleAlternativeTransportTypeChange,
                label: t`alternative.trans.page.what.alternative.transport.dont.remember`,
                id: '4',
                icon: (
                    <DontRemember
                        stroke={
                            alternativeTransportType ===
                                alternativeTransports.ALTERNATIVE_TRANSPORT_DR &&
                            primaryColor
                        }
                    />
                ),
            },
        ]
    }

    handleReupload = file => {
        const { claimId, uploadDocument } = this.props
        // add action
        uploadDocument(file, claimId)
    }

    renderDocumentsListSeparate = (documents, failedDocuments) => {
        const {
            removeUserDocument,
            handleReupload,
            clearAlternativeUploadErrors,
            primaryColor,
        } = this.props
        if (
            (documents && !!documents.length) ||
            (failedDocuments && !!failedDocuments.length)
        ) {
            const docs = documents.map((document, index) => {
                const { file, progress } = document
                const { name } = file
                const isSuccess = progress === 100
                const isLoading = progress < 100

                return (
                    <DocumentWrapper>
                        <IconWrapper>
                            <Document />
                        </IconWrapper>
                        <DocumentStatusWrapper>
                            <DocumentTitle>{name}</DocumentTitle>
                            <DocumentStatus
                                isLoading={isLoading}
                                failed={false}
                            >
                                {isSuccess
                                    ? t`upload_document_success`
                                    : t`upload_document_uploading`}
                            </DocumentStatus>
                        </DocumentStatusWrapper>
                        <ClearIconWrapper
                            onClick={() => {
                                removeUserDocument(document.id)
                            }}
                        >
                            <CloseCircle />
                        </ClearIconWrapper>
                    </DocumentWrapper>
                )
            })

            const failedDocs = failedDocuments.map((document, index) => {
                const { file, progress } = document
                const name = file && file.name
                const isSuccess = progress === 100

                return (
                    <DocumentWrapper>
                        <IconWrapper>
                            <Document />
                        </IconWrapper>
                        <DocumentStatusWrapper>
                            <DocumentTitle>{name}</DocumentTitle>
                            <DocumentStatus
                                isLoading={false}
                                failed={!isSuccess}
                            >
                                {isSuccess
                                    ? t`upload_document_uploading`
                                    : t`upload_document_failed`}
                            </DocumentStatus>
                            {!isSuccess && (
                                <ReuploadWrapper
                                    onClick={() => handleReupload(file)}
                                >
                                    <ReuploadTitle>{t`reupload_document`}</ReuploadTitle>
                                    <Reupload color={primaryColor} />
                                </ReuploadWrapper>
                            )}
                        </DocumentStatusWrapper>
                        <ClearIconWrapper
                            onClick={() => clearAlternativeUploadErrors()}
                        >
                            <CloseCircle />
                        </ClearIconWrapper>
                    </DocumentWrapper>
                )
            })

            return (
                <div>
                    {docs}
                    {failedDocs}
                </div>
            )
        }
    }

    _renderStops = () => {
        const {
            isDirect,
            stopsCount,
            stopsState,
        } = this.props.componentState.alternativeFlightComponentState
        const { _handleStopChange, clearAirport } = this.props
        let allStops = []
        const stopNumeration = {
            0: t`v2_claim.flight_itinerary.first_stop`,
            1: t`v2_claim.flight_itinerary.second_stop`,
        }

        if (isDirect) {
            return null
        }

        for (let index = 0; index < stopsCount; index++) {
            const currStop = stopsState.find(s => s.id === index)
            const currVal = _get(currStop, 'value', '')
            const name = `stop_${index + 1}`

            allStops.push(
                <StyledFieldsRow key={index}>
                    <Col xs={12} sm={8} md={6} lg={6}>
                        <FlightInput
                            setRef={this.setRef}
                            hasIcon
                            required
                            clear={() => clearAirport(true, '', currVal)}
                            autoload={!!currVal}
                            type={flightTypes.stop}
                            label={stopNumeration[index]}
                            name={name}
                            value={currVal}
                            defaultValue={currVal}
                            onChange={_handleStopChange}
                            callback={() => index}
                            id={`airport-stop-${index + 1}`}
                        />
                    </Col>
                </StyledFieldsRow>
            )
        }

        return <React.Fragment>{allStops}</React.Fragment>
    }

    validateFlightDate = current => {
        const today = new Date()
        let now = new Date()
        now.setDate(today.getDate() + 14)

        let last = new Date()
        last.setDate(today.getDate() - 3660)
        return current.isBefore(now) && current.isAfter(last)
    }

    getAirlineSearchPromptText = () => {
        const { componentState } = this.props
        const { alternativeFlightComponentState } = componentState
        const { airlinesInputValue } = alternativeFlightComponentState

        return airlinesInputValue.length < 2 ? '' : t`v2_common.no_results`
    }

    renderFlightDetails = () => {
        const {
            suggestedFlights,
            handleAirlineChange,
            showModal,
            handlePreselect,
            clearAirline,
            handleAirlineInputChange,
            errors,
            modal,
            preselectLoading,
            hasAlternativeTransport,
            primaryColor,
        } = this.props
        const { componentState } = this.props
        const { alternativeFlightComponentState } = componentState
        const {
            altFlights,
            airportDeparture,
            airportArrival,
        } = alternativeFlightComponentState

        if (
            hasAlternativeTransport ===
            alternativeTransports.HAS_NO_ALTERNATIVE_REASON
        ) {
            return null
        }
        if (!airportDeparture || !airportArrival) return null

        const orderedFlights = altFlights.sort(
            (a, b) => a.flightOrder - b.flightOrder
        )

        return orderedFlights.map((flight, index) => {
            let depAirport = getAirportById(flight.airportDeparture)
            let arrAirport = getAirportById(flight.airportArrival)
            let prevFlightDate = ''

            if (
                altFlights.some(
                    val => !val.airportArrival || !val.airportDeparture
                )
            )
                return null

            if (index !== 0) {
                let prevFlight = altFlights[index - 1]
                if (prevFlight && prevFlight.timeDeparture) {
                    prevFlightDate = prevFlight.timeDeparture
                }
            }

            if (!depAirport || !arrAirport) {
                if (isProduction()) {
                    return null
                } else {
                    depAirport = {}
                    arrAirport = {}
                }
            }

            const suggestCount = Object.keys(
                suggestedFlights[index] || []
            ).reduce((prev, acc) => {
                const dataArrLength = (suggestedFlights[index][acc] || [])
                    .length
                return prev + dataArrLength
            }, 0)

            return (
                <FlightRow className="mb-16" key={index}>
                    {index !== 0 && (
                        <Col
                            xs={12}
                            md={12}
                            lg={12}
                            style={{
                                padding: 0,
                                margin: '0px 16px',
                                width: '95%',
                                alignSelf: 'center',
                            }}
                        />
                    )}
                    {orderedFlights.length > 1 && (
                        <FLightCounterMobile>
                            {`${index + 1} ${t`v2.flight_details.out_of`} ${
                                orderedFlights.length
                            }`}
                        </FLightCounterMobile>
                    )}
                    <HeaderContainer>
                        <WrapperTitles>
                            <IataTitleWrapper>
                                <IataIconWrapper>
                                    <WarnIcon />
                                </IataIconWrapper>
                                <FlightAirportIata>
                                    {depAirport.iata}
                                </FlightAirportIata>
                            </IataTitleWrapper>
                            <FlightAirportIataTitle>
                                {depAirport.title}
                            </FlightAirportIataTitle>
                        </WrapperTitles>

                        <WrapperTitles>
                            <IataTitleWrapper>
                                <PlaneIconContainer>
                                    <PlaneIconV2 color={primaryColor} />
                                </PlaneIconContainer>
                                <FlightAirportIata>
                                    {arrAirport.iata}
                                </FlightAirportIata>
                            </IataTitleWrapper>
                            <FlightAirportIataTitleArrival>
                                {arrAirport.title}
                            </FlightAirportIataTitleArrival>
                        </WrapperTitles>
                        {orderedFlights.length > 1 && (
                            <FlightCounterDesktop>
                                {`${index + 1} ${t`v2.flight_details.out_of`} ${
                                    orderedFlights.length
                                }`}
                            </FlightCounterDesktop>
                        )}
                    </HeaderContainer>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Datepicker
                                suggestDate={
                                    index !== 0 && !flight.timeDeparture
                                        ? prevFlightDate
                                        : ''
                                }
                                required
                                type="text"
                                name="timeDeparture"
                                id={`flight-${index + 1}-date`}
                                label={t`v2_common.label.date`}
                                errorText={flight.error && flight.error}
                                placeholder={t`v2_common.placeholder.departure_time`}
                                value={
                                    !flight.timeDeparture
                                        ? ''
                                        : moment.utc(flight.timeDeparture)
                                }
                                defaultValue={
                                    !flight.timeDeparture
                                        ? ''
                                        : moment.utc(flight.timeDeparture)
                                }
                                handleChange={e =>
                                    handleAirlineChange(e, index)
                                }
                                initialViewDate={
                                    alternativeFlightComponentState.viewDate
                                }
                                startDate={new Date().getFullYear()}
                                endDate={10}
                                dateFormat="YYYY-MM-DD"
                                isValidDate={this.validateFlightDate}
                            />
                        </Col>
                    </Row>
                    {suggestedFlights[index] &&
                        Object.keys(suggestedFlights[index] || []).length > 0 &&
                        suggestCount > 3 && (
                            <Row>
                                <Col
                                    style={{ display: 'flex' }}
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                >
                                    {_get(modal, 'modalType', '') ===
                                        modalTypes.flightSuggestModalCodeshares && (
                                        <FlightSuggestModalCodeshares
                                            callBack={(item, preselectId) => {
                                                handlePreselect(
                                                    index,
                                                    item,
                                                    preselectId
                                                )
                                            }}
                                        />
                                    )}
                                    <PresuggestLink
                                        className="flight-suggest-btn"
                                        onClick={() => {
                                            showModal(
                                                modalTypes.flightSuggestModalCodeshares,
                                                {
                                                    callBack: (
                                                        item,
                                                        preselectId
                                                    ) => {
                                                        handlePreselect(
                                                            index,
                                                            item,
                                                            preselectId
                                                        )
                                                    },
                                                    data:
                                                        suggestedFlights[index],
                                                    selected:
                                                        flight.preselectId,
                                                    route: {
                                                        depIata:
                                                            depAirport.iata,
                                                        arrIata:
                                                            arrAirport.iata,
                                                    },
                                                }
                                            )
                                        }}
                                    >
                                        <span>
                                            {t`flight_suggest.pick_scheduled_time`}
                                        </span>
                                    </PresuggestLink>
                                </Col>
                            </Row>
                        )}
                    {suggestedFlights[index] &&
                        Object.keys(suggestedFlights[index] || []).length > 0 &&
                        suggestCount < 4 && (
                            <React.Fragment>
                                <Row className="simple-label">
                                    <Col>
                                        {t`flight_suggest.one_of_these_flights`}
                                    </Col>
                                </Row>
                                <Row className="simple-select">
                                    {Object.keys(suggestedFlights[index]).map(
                                        key => {
                                            return suggestedFlights[index][
                                                key
                                            ].map(item => {
                                                const isSelected =
                                                    flight.number ===
                                                        item.flight_number &&
                                                    flight.prefillLetters &&
                                                    flight.prefillLetters.toLowerCase() ===
                                                        item.airline_iata.toLowerCase()
                                                return (
                                                    <Col
                                                        key={`pre-index-${index}`}
                                                        className={`simple-item ${
                                                            isSelected
                                                                ? 'simple-selected'
                                                                : ''
                                                        }`}
                                                        onClick={() => {
                                                            handlePreselect(
                                                                index,
                                                                item,
                                                                `pre-index-${index}`
                                                            )
                                                        }}
                                                    >
                                                        <span>
                                                            {item.flight_time} -{' '}
                                                            {
                                                                item.flight_arrive_time
                                                            }
                                                        </span>
                                                        <span
                                                            style={{
                                                                margin:
                                                                    '0px 6px',
                                                                lineHeight:
                                                                    '19px',
                                                                verticalAlign:
                                                                    'top',
                                                            }}
                                                        >
                                                            <img
                                                                src="/icons/oval.svg"
                                                                width="3"
                                                                height="3"
                                                                alt="oval"
                                                            />
                                                        </span>
                                                        <span>
                                                            {item.airline_title}
                                                        </span>
                                                        <span
                                                            style={{
                                                                margin:
                                                                    '0px 6px',
                                                                lineHeight:
                                                                    '19px',
                                                                verticalAlign:
                                                                    'top',
                                                            }}
                                                        >
                                                            <img
                                                                src="/icons/oval.svg"
                                                                width="3"
                                                                height="3"
                                                                alt="oval"
                                                            />
                                                        </span>
                                                        <span>
                                                            {item.airline_iata}{' '}
                                                            {item.flight_number}
                                                        </span>
                                                    </Col>
                                                )
                                            })
                                        }
                                    )}
                                </Row>
                            </React.Fragment>
                        )}
                    {flight.timeDeparture && (
                        <Row className="mb-10">
                            <Col xs={12} md={6} lg={6}>
                                {!preselectLoading && (
                                    <AirlineInput
                                        stickyOptionsList={true}
                                        stickyOptionsListTopPadding="1px"
                                        isFocusHighligh={true}
                                        borderRadius="12px"
                                        setRef={this.setRef}
                                        required
                                        refIndex={index}
                                        name="airline"
                                        id={`flight-${index + 1}-airline`}
                                        label={t`v2_common.label.airline`}
                                        errorText={
                                            errors &&
                                            errors[index] &&
                                            errors[index]['airline']
                                        }
                                        autoload={!!flight.airline}
                                        clear={() => clearAirline(index)}
                                        value={flight.airline || ''}
                                        onChange={e =>
                                            handleAirlineChange(e, index)
                                        }
                                        onInputChange={inputValue =>
                                            handleAirlineInputChange(inputValue)
                                        }
                                        searchPromptText={this.getAirlineSearchPromptText()}
                                        onBlur={() =>
                                            handleAirlineInputChange('')
                                        }
                                    />
                                )}
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <V2FlightNumber
                                    required
                                    placeholder={t`v2_common.placeholder.flight_number`}
                                    name="number"
                                    id={`flight-${index + 1}-number`}
                                    label={t`v2_common.label.flight_number`}
                                    value={flight.number || ''}
                                    lettersName={'prefillLetters'}
                                    prefillLetters={flight.prefillLetters}
                                    errorText={
                                        errors &&
                                        errors[index] &&
                                        errors[index]['number']
                                    }
                                    onChange={e =>
                                        handleAirlineChange(e, index)
                                    }
                                />
                            </Col>
                        </Row>
                    )}
                </FlightRow>
            )
        })
    }

    setRef = (name, ref) => {
        if (this.references && !this.references[name]) {
            this.references[name] = ref
        }
    }

    render() {
        const {
            trans,
            hasAlternativeTransport,
            componentState,
            alternativeTransportType,
            handleRadioOnChange,
            setAirport,
            clearAirport,
            handleStopCountChange,
            primaryColor,
            handleTimeInputChange,
            handleTimeDatePickerChange,
        } = this.props

        if (!trans) return null

        const {
            alternativeFlightComponentState: {
                isDirect,
                stopsCount,
                airportDeparture,
                airportArrival,
                arrivalTransportDate,
                departureTransportTime,
                arrivalTransportTime,
                departureTransportDate,
            },
        } = componentState

        return (
            <div>
                <StyledRow>
                    <VerticalRadio
                        label={t`alternative.trans.page.privided.transportation.question`}
                        required
                        options={this.getCustomReasons()}
                    />
                </StyledRow>

                {hasAlternativeTransport != null && (
                    <div>
                        {hasAlternativeTransport ===
                            alternativeTransports.HAS_ALTERNATIVE_REASON && (
                            <Row>
                                <ImageRadio
                                    mobileDirection="column"
                                    required
                                    id="second-row-reasons"
                                    label={t`alternative.trans.page.what.alternative.transport.question`}
                                    options={this.getImageReasons()}
                                />
                            </Row>
                        )}
                        {(alternativeTransportType === 'att' ||
                            alternativeTransportType === 'atb') &&
                            hasAlternativeTransport ===
                                alternativeTransports.HAS_ALTERNATIVE_REASON && (
                                <div>
                                    <Row>
                                        <Col>
                                            <Title>
                                                {t`alternative.flight.transport.train.bus.title`}
                                            </Title>
                                            <Description>
                                                {t`alternative.flight.transport.train.bus.description`}
                                            </Description>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <LeftSideCol
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                        >
                                            <GroupInputsTitle>
                                                {t`alternative.flight.transport.departure.date.time`}
                                                <span>*</span>
                                            </GroupInputsTitle>
                                            <WrapperFields>
                                                <ColNoPadding>
                                                    <CalendarWrapper>
                                                        <Datepicker
                                                            smallInput
                                                            name="departureTransportDate"
                                                            placeholder={t`common.calendar.placeholder`}
                                                            value={
                                                                departureTransportDate
                                                            }
                                                            dateFormat="YYYY-MM-DD"
                                                            viewMode="years"
                                                            viewDate={
                                                                new Date('1980')
                                                            }
                                                            startDate={new Date().getFullYear()}
                                                            endDate={10}
                                                            handleChange={
                                                                handleTimeDatePickerChange
                                                            }
                                                            primaryColor={
                                                                primaryColor
                                                            }
                                                        />
                                                    </CalendarWrapper>
                                                </ColNoPadding>
                                                <ColNoPadding>
                                                    <Input
                                                        name="departureTransportTime"
                                                        placeholder="00:00"
                                                        value={
                                                            departureTransportTime
                                                        }
                                                        onChange={e =>
                                                            handleTimeInputChange(
                                                                'departureTransportTime',
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <TimeIconWrapper>
                                                        <Timer />
                                                    </TimeIconWrapper>
                                                </ColNoPadding>
                                            </WrapperFields>
                                        </LeftSideCol>
                                        <Col xs={12} sm={12} md={6} lg={6}>
                                            <GroupInputsTitle>
                                                {t`alternative.flight.transport.arrival.date.time`}
                                                <span>*</span>
                                            </GroupInputsTitle>
                                            <WrapperFields>
                                                <ColNoPadding>
                                                    <CalendarWrapper>
                                                        <Datepicker
                                                            smallInput
                                                            name="arrivalTransportDate"
                                                            placeholder={t`common.calendar.placeholder`}
                                                            value={
                                                                arrivalTransportDate
                                                            }
                                                            dateFormat="YYYY-MM-DD"
                                                            viewMode="years"
                                                            viewDate={
                                                                new Date('1980')
                                                            }
                                                            startDate={new Date().getFullYear()}
                                                            endDate={10}
                                                            handleChange={
                                                                handleTimeDatePickerChange
                                                            }
                                                            primaryColor={
                                                                primaryColor
                                                            }
                                                        />
                                                    </CalendarWrapper>
                                                </ColNoPadding>

                                                <ColNoPadding>
                                                    <Input
                                                        name="arrivalTransportTime"
                                                        placeholder="00:00"
                                                        value={
                                                            arrivalTransportTime
                                                        }
                                                        onChange={e =>
                                                            handleTimeInputChange(
                                                                'arrivalTransportTime',
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <TimeIconWrapper>
                                                        <Timer />
                                                    </TimeIconWrapper>
                                                </ColNoPadding>
                                            </WrapperFields>
                                        </Col>
                                    </Row>
                                </div>
                            )}

                        {alternativeTransportType === 'atf' &&
                            hasAlternativeTransport ===
                                alternativeTransports.HAS_ALTERNATIVE_REASON && (
                                <div>
                                    <StyledFieldsRow>
                                        <StyledCol sm={12} md={8} lg={6}>
                                            <FlightInput
                                                setRef={this.setRef}
                                                autofocus={!isMobileDevice()}
                                                hasIcon
                                                required
                                                clear={() =>
                                                    clearAirport(
                                                        false,
                                                        'airportDeparture',
                                                        airportDeparture,
                                                        setAirport
                                                    )
                                                }
                                                autoload={!!airportDeparture}
                                                type={flightTypes.departure}
                                                label={t`v2_common.label.departed_from`}
                                                name="airportDeparture"
                                                id="airport-departure"
                                                value={airportDeparture}
                                                defaultValue={airportDeparture}
                                                onChange={e => setAirport(e)}
                                            />
                                        </StyledCol>
                                        <StyledCol sm={12} md={8} lg={6}>
                                            <FlightInput
                                                setRef={this.setRef}
                                                hasIcon
                                                required
                                                clear={() =>
                                                    clearAirport(
                                                        false,
                                                        'airportArrival',
                                                        airportArrival,
                                                        setAirport
                                                    )
                                                }
                                                autoload={!!airportArrival}
                                                label={t`v2_common.label.arrived_to`}
                                                name="airportArrival"
                                                id="airport-arrival"
                                                value={airportArrival}
                                                defaultValue={airportArrival}
                                                onChange={e => setAirport(e)}
                                            />
                                        </StyledCol>
                                    </StyledFieldsRow>
                                    <Row>
                                        <SideWaysRadio
                                            label={t`v2_common.flight_itinerary.direct_flight`}
                                            required
                                            options={[
                                                {
                                                    onChange: () =>
                                                        handleRadioOnChange(
                                                            0,
                                                            true
                                                        ),
                                                    name: 'isDirect',
                                                    value: !!isDirect,
                                                    checked: !!isDirect,
                                                    textCenter: true,
                                                    label: t`v2_common.label.yes`,
                                                    id: 'yes-direct',
                                                },
                                                {
                                                    onChange: () =>
                                                        handleRadioOnChange(
                                                            1,
                                                            false
                                                        ),
                                                    name: 'isDirect',
                                                    value: !isDirect,
                                                    checked: !isDirect,
                                                    textCenter: true,
                                                    label: t`v2_common.label.no`,
                                                    id: 'no-direct',
                                                },
                                            ]}
                                        />
                                    </Row>
                                    {!isDirect && (
                                        <Row>
                                            <SideWaysRadio
                                                label={t`v2_common.flight_itinerary.steps`}
                                                required
                                                options={[1, 2].map(
                                                    (stop, i) => ({
                                                        name: 'stopsCount',
                                                        value: stop,
                                                        defaultChecked:
                                                            stop === stopsCount,
                                                        label: stop,
                                                        onChange: () =>
                                                            handleStopCountChange(
                                                                stop,
                                                                i
                                                            ),
                                                        id: `stops-${i + 1}`,
                                                    })
                                                )}
                                            />
                                        </Row>
                                    )}
                                    {this._renderStops()}
                                    {this.renderFlightDetails()}
                                </div>
                            )}
                    </div>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        flightDocsAlternative: state.v2_user_documents,
        primaryColor: state.propsTheme.colors.primaryColor,
        suggestedFlights: state.suggestedFlights,
        errors: state.v2_step_four_errors.errors,
        modal: state.toggle_modal,
        alternativeTransEnabled: state.v2_claimable.isTransportAvailable,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSuggestedFlights,
            showModal,
            setUsedAirports,
            setSelectedAirports,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(AlternativeTransportation)
