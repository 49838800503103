import React, { Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import latinize from 'latinize'
import debounce from 'lodash/debounce'
import _get from 'lodash/get'
import moment from 'moment'
import Helmet from 'react-helmet'
import t from '../../../shared/translations'
import {
    mapFormData,
    scrollTo,
    loadScreenOn,
    getStorage,
    scrollComponentToView,
    isFeatureEnabled,
} from '../../../main'

// Input fields
import InputField from '../../../shared/components/V2/InputField'
import DatePicker from '../../../shared/components/V2/DatePicker'
import CountryInput from '../components/CountryInput'
import LanguageInput from '../components/LanguageInput'
import V2PhoneField from '../../../shared/components/V2/V2PhoneField'
import V2CheckBox from '../../../shared/components/V2/V2CheckBox'
import V2PasswordField from '../../../shared/components/V2/V2PasswordField'

//Layout
import {
    desktop,
    tablet,
    mobile,
} from '../../../shared/components/helpers/styled_queries'
import { V2Button } from '../../../shared/components/V2/V2Buttons'
import {
    Col,
    Row,
    PageContent,
    InfoBlock,
    PageViewContainer,
} from '../../../shared/components/V2/Layout'
import ErrorBox from '../components/ErrorsBox'

//Actions
import {
    getUserClaims,
    updateUserInfo,
    clearErrors,
} from '../../../shared/actions'
import {
    getUserData,
    validateProfileView,
} from '../../../shared/actions/v2_actions'

import { paths, featureToggles } from '../../../shared/constants'
import { SPACE_KEY, ENTER_KEY } from '../../../shared/components/V2/Modal'
import store from '../../../shared/services/store'
import { V2_ACTIONS } from '../../../shared/actions/actionTypes'
import WhatsAppBox from '../components/WhatsAppBox'

const PasswordBtn = styled(V2Button)`
    margin-top: 8px;
`

export const ProfileContainer = styled(PageContent)`
    height: fit-content;
    padding: 16px;
    padding-left: 28px;
    padding-top: 40px;

    .btn-row {
        ${Col} {
            display: flex;
            justify-content: center;
            margin-top: 24px;

            button {
                ${desktop`
                    width: unset;
                `}
            }
        }
    }

    ${tablet`
        padding: 12px;
        padding-top: 32px;
    `}

    ${mobile`
        padding: 8px;
        padding-top: 24px;
    `}

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
`

const CheckBoxInner = styled.div`
    margin-left: 8px;

    h3 {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #353d51;
    }

    p {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #7f8fa4;
    }
`

const ErrorText = styled.p`
    padding: 0 0 0 16px;
    margin: 0;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.85;
    letter-spacing: normal;
    color: #ffffff;

    span {
        font-weight: bold;
    }
`

class MyProfile extends Component {
    state = {
        birthdate: '',
        name: '',
        surname: '',
        country: '',
        phoneCode: '',
        email: '',
        phone: '',
        locale: '',
        city: '',
        address: '',
        postCode: '',
        oldPass: '',
        newPass: '',
        newPassRepeat: '',
        subscribe: false,
        disableEdit: false,
        createNewPassword: false,
        contactViaWhatsapp: false,
        innerWidth: window.innerWidth,
    }

    componentWillUnmount() {
        this.props.clearErrors()
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize = debounce(() => {
        const innerWidth = window.innerWidth

        this.setState({ innerWidth })
    })

    componentDidMount() {
        const {
            isAuthenticated,
            userClaims,
            user,
            getUserData,
            location,
        } = this.props
        window.addEventListener('resize', this.handleResize)

        if (!isAuthenticated && location) {
            browserHistory.push(paths.V2.LOGIN)
        }

        if (userClaims && !!userClaims.length) {
            this.setState({ disableEdit: true })
        } else {
            const { getUserClaims } = this.props

            getUserClaims().then(res => {
                if (res && !!res.length) {
                    this.setState({ disableEdit: true })
                }
            })
        }

        if (isAuthenticated && user) {
            const {
                post_code,
                phone_code,
                country,
                contact_via_whatsapp,
            } = user

            this.setState({
                ...user,
                postCode: post_code || '',
                phoneCode: (phone_code && phone_code.id) || '',
                country: (country && country.id) || '',
                contactViaWhatsapp: contact_via_whatsapp || false,
            })
        }

        if (isAuthenticated && !user) {
            getUserData().then(res => {
                const {
                    post_code,
                    phone_code,
                    country,
                    contact_via_whatsapp,
                } = res
                this.setState({
                    ...res,
                    postCode: post_code || '',
                    phoneCode: (phone_code && phone_code.id) || '',
                    country: (country && country.id) || '',
                    contactViaWhatsapp: contact_via_whatsapp || false,
                })
            })
        }
    }

    recheckErrors = name => {
        const { profileValidation } = this.props
        const { errors } = profileValidation
        if (name && errors && errors[name]) {
            store.dispatch({
                type: V2_ACTIONS.SET_PROFILE_ERRORS,
                payload: this.state,
            })
        }
    }

    handleChange = e => {
        if (!e) {
            return
        }
        const target = e.target || e
        const { name, value } = target

        if (['name', 'surname', 'address', 'city'].includes(name)) {
            const newValue = latinize(value)

            this.setState({ [name]: newValue }, () => {
                this.recheckErrors(name)
            })
        } else if (name === 'contactViaWhatsapp') {
            this.setState({
                contactViaWhatsapp: !this.state.contactViaWhatsapp,
            })
        } else {
            this.setState({ [name]: value }, () => {
                this.recheckErrors(name)
            })
        }

        if ('country' === name) {
            const { phoneCode, phone } = this.state
            if (!phoneCode && !phone) {
                this.setState({ phoneCode: value })
            }
        }
    }

    handleCheckboxChange = e => {
        const { name } = e.target

        this.setState({ [name]: !this.state[name] })
    }

    showPasswordFields = () => {
        const { createNewPassword } = this.state

        if (createNewPassword) {
            this.setState({
                oldPass: '',
                newPass: '',
                newPassRepeat: '',
            })
        }

        this.setState({
            createNewPassword: !this.state.createNewPassword,
        })
    }

    validUserDate(current) {
        const daysBefore = 365 * 150
        const today = moment().subtract(18, 'years')
        const last = moment().subtract(daysBefore, 'days')
        return current.isBefore(today) && current.isAfter(last)
    }

    renderPhoneField = () => {
        const { phone, phoneCode } = this.state
        const { countries, user, profileValidation, primaryColor } = this.props
        const isOldPhoneField = user && user.phone && !user.phone_code

        if (isOldPhoneField) {
            return (
                <InputField
                    required
                    name="phone"
                    label={t`v2_common.label.phone`}
                    placeholder={t`v2_common.placeholder.phone`}
                    value={phone}
                    onChange={this.handleChange}
                    errorText={t`${_get(
                        profileValidation,
                        'errors.phone',
                        ''
                    )}`}
                />
            )
        } else {
            return (
                <Col xs={12} md={12} lg={6}>
                    <V2PhoneField
                        required
                        label={t`v2_common.label.phone`}
                        placeholder={t`v2_common.placeholder.phone`}
                        onChange={this.handleChange}
                        maxLength={16}
                        autoComplete="off"
                        phoneCode={phoneCode}
                        value={phone}
                        prefixInputName="phoneCode"
                        numberInputName="phone"
                        countries={countries}
                        onDrop={e => e.preventDefault()}
                        errorText={t`${_get(
                            profileValidation,
                            'errors.phone',
                            ''
                        )}`}
                        color={primaryColor}
                    />
                </Col>
            )
        }
    }

    _parseDataAndSubmit = (input, redirect) => {
        const {
            updateUserInfo,
            getUserData,
            countries,
            clearErrors,
        } = this.props
        const {
            newPass,
            newPassRepeat,
            oldPass,
            phone,
            phoneCode,
            subscribe,
            phone_code,
            honorific,
            name,
            surname,
            email,
            birthdate,
            address,
            city,
            locale,
            country,
            postCode,
            contactViaWhatsapp,
        } = input

        const countryPhoneCode = countries.payload.filter(
            country => country.id === phoneCode
        )[0]
        const countryCode = countryPhoneCode && countryPhoneCode.phone_code

        const userData = {
            honorific,
            name,
            surname,
            email,
            birthdate,
            address,
            city,
            locale,
            country,
            postCode,
            phoneCode,
            subscribe: subscribe || null,
            phone:
                (countryCode ? '' : '+') +
                phone
                    .replace('+', '')
                    .replace('(', '')
                    .replace(')', '')
                    .replace(/\s+/g, '')
                    .replace(/-/g, ''),
            oldPassword: newPass ? oldPass : '',
            plainPassword: newPass
                ? {
                      first: newPass,
                      second: newPassRepeat,
                  }
                : '',
            contactViaWhatsapp: contactViaWhatsapp || false,
        }

        if (typeof phoneCode === 'object' && phone_code) {
            delete userData.phone_code
            userData.phoneCode = phoneCode.id
        }

        const errors = _get(this.props.validateProfileView(input), 'errors', '')

        if (!!this.state.disableEdit) {
            delete (errors || {}).name
            delete (errors || {}).surname
            if (userData.birthdate) {
                delete (errors || {}).birthdate
            }
        }

        const fd = mapFormData(userData, 'user_edit')

        if (!Object.keys(errors).length) {
            loadScreenOn()
            clearErrors()
            updateUserInfo(
                fd,
                redirect,
                getStorage('lang') || 'en',
                !redirect
            ).then(res => {
                if (res) {
                    getUserData()
                    scrollComponentToView('pageTopNavbar', false, true)
                }
            })
        } else {
            const missingInput = document.getElementsByName(
                Object.keys(errors)[0]
            )[0]
            if (missingInput && missingInput.getBoundingClientRect()) {
                scrollTo(
                    document.body,
                    missingInput.getBoundingClientRect().top +
                        window.scrollY -
                        100,
                    1000
                )
            }
        }
    }

    updateProfile = () => {
        let redirect
        const { location, claim, clmAmt } = this.props
        if (location && location.query) {
            const defaultLocation = paths.V2.STEP_6_PASSENGER_DETAILS
            redirect = claim && clmAmt ? defaultLocation : location.query['p']
        }

        this._parseDataAndSubmit(this.state, redirect)
    }

    clearUserCountry = () => {
        this.setState({ country: '' })
    }

    handleCountryInput = e => {
        const { countries } = this.props
        const { phoneCode } = this.state
        const countrylist = _get(countries, 'payload', [])
        const filteredCountry = countrylist.find(country => country.title === e)

        if (filteredCountry) {
            const newState = {
                country: filteredCountry.id,
            }

            if (!phoneCode) {
                newState.phoneCode = filteredCountry.id
            }

            this.setState(prevState => ({
                ...prevState,
                ...newState,
            }))
        }
    }

    render() {
        const {
            name,
            surname,
            birthdate,
            email,
            address,
            city,
            postCode,
            country,
            oldPass,
            newPass,
            newPassRepeat,
            locale,
            disableEdit,
            subscribe,
            createNewPassword,
            contactViaWhatsapp,
        } = this.state
        const { trans, user, profileValidation, errorMessage } = this.props
        const firstError = _get(errorMessage, '[0]', '')
        const passError =
            firstError && typeof firstError === 'string' && firstError

        if (!trans) return null

        return (
            <PageViewContainer>
                <ProfileContainer>
                    <form
                        autoComplete="on"
                        onSubmit={e => e.preventDefault()}
                        onKeyDown={e => {
                            if (e.keyCode === ENTER_KEY) {
                                e.preventDefault()
                                return false
                            }
                        }}
                    >
                        <Helmet>
                            <title>{`Skycop - ${t`user.edit_profile.title`}`}</title>
                        </Helmet>
                        <InfoBlock title={t`common.subtitle.personal_info`}>
                            {user && !user.active && (
                                <Row>
                                    <Col lg={12}>
                                        <ErrorBox noTitle={true}>
                                            <ErrorText>
                                                {t`user.please_fill_info`}
                                            </ErrorText>
                                        </ErrorBox>
                                    </Col>
                                </Row>
                            )}
                            {/* {isErrors && user.active && (
                        <Row>
                            <Col lg={12}>
                                <ErrorBox noTitle={true}>
                                    <ErrorText>
                                        <span>All fields must be filled</span>{" "}
                                        in order to saves changes to your
                                        profile
                                    </ErrorText>
                                </ErrorBox>
                            </Col>
                        </Row>
                    )} */}
                            <Row>
                                <InputField
                                    required
                                    disabled={disableEdit}
                                    name="name"
                                    label={t`v2_common.label.name`}
                                    placeholder={t`v2_common.placeholder.name`}
                                    value={name}
                                    onChange={this.handleChange}
                                    errorText={t`${_get(
                                        profileValidation,
                                        'errors.name',
                                        ''
                                    )}`}
                                    autoComplete="name-first"
                                />
                                <InputField
                                    required
                                    disabled={disableEdit}
                                    label={t`v2_common.label.surname`}
                                    name="surname"
                                    placeholder={t`v2_common.placeholder.surname`}
                                    value={surname}
                                    onChange={this.handleChange}
                                    errorText={t`${_get(
                                        profileValidation,
                                        'errors.surname',
                                        ''
                                    )}`}
                                    autoComplete="name-last"
                                />
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <DatePicker
                                        required
                                        readOnly={
                                            user &&
                                            user.birthdate &&
                                            disableEdit
                                        }
                                        dateFormat="YYYY-MM-DD"
                                        label={t`v2_common.label.birthdate`}
                                        type="text"
                                        name="birthdate"
                                        initialViewDate={
                                            birthdate
                                                ? moment.utc(birthdate)
                                                : moment.utc('1990')
                                        }
                                        isValidDate={this.validUserDate}
                                        startDate={moment().diff('18', 'years')}
                                        endDate={150}
                                        initialViewMode="years"
                                        placeholder={t`v2_common.placeholder.birthdate`}
                                        value={
                                            !birthdate
                                                ? ''
                                                : moment.utc(birthdate)
                                        }
                                        handleChange={this.handleChange}
                                        errorText={t`${_get(
                                            profileValidation,
                                            'errors.birthdate',
                                            ''
                                        )}`}
                                    />
                                </Col>
                                {this.renderPhoneField()}
                            </Row>
                            {isFeatureEnabled(
                                featureToggles.featureIsWhatsAppBoxVisible
                            ) &&
                                // eslint-disable-next-line no-restricted-globals
                                innerWidth < 961 && (
                                    <Row>
                                        <Col
                                            xs={12}
                                            sm={8}
                                            lg={6}
                                            style={{
                                                float: 'right',
                                                'align-self': 'flex-end',
                                            }}
                                        >
                                            <WhatsAppBox
                                                name="contactViaWhatsapp"
                                                id="contact-via-whatsapp"
                                                checked={contactViaWhatsapp}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            <Row>
                                <InputField
                                    required
                                    sm={6}
                                    label={t`v2_common.label.email`}
                                    name="email"
                                    type="email"
                                    placeholder={t`v2_common.placeholder.email`}
                                    value={email}
                                    onChange={this.handleChange}
                                    errorText={
                                        _get(errorMessage, 'email[0]', '') ||
                                        t`${_get(
                                            profileValidation,
                                            'errors.email',
                                            ''
                                        )}`
                                    }
                                    autoComplete="do-not-autofill"
                                    onKeyDown={e => {
                                        if (e.keyCode === SPACE_KEY) {
                                            e.preventDefault()
                                            return false
                                        }
                                    }}
                                />
                                {isFeatureEnabled(
                                    featureToggles.featureIsWhatsAppBoxVisible
                                ) &&
                                    // eslint-disable-next-line no-restricted-globals
                                    innerWidth > 960 && (
                                        <Col
                                            xs={12}
                                            sm={8}
                                            lg={6}
                                            style={{
                                                float: 'right',
                                                'align-self': 'flex-end',
                                            }}
                                        >
                                            <WhatsAppBox
                                                name="contactViaWhatsapp"
                                                id="contact-via-whatsapp"
                                                checked={contactViaWhatsapp}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    )}
                            </Row>
                        </InfoBlock>
                        <InfoBlock
                            title={t`common.subtitle.contact_info`}
                            className="pdt-40"
                        >
                            <Row>
                                <InputField
                                    required
                                    label={t`v2_common.label.address`}
                                    name="address"
                                    placeholder={t`v2_common.placeholder.address`}
                                    value={address}
                                    onChange={this.handleChange}
                                    errorText={t`${_get(
                                        profileValidation,
                                        'errors.address',
                                        ''
                                    )}`}
                                    autoComplete="street-address"
                                />
                                <InputField
                                    required
                                    lg={3}
                                    label={t`v2_common.label.city`}
                                    name="city"
                                    placeholder={t`v2_common.placeholder.city`}
                                    value={city}
                                    onChange={this.handleChange}
                                    errorText={t`${_get(
                                        profileValidation,
                                        'errors.city',
                                        ''
                                    )}`}
                                    autoComplete="address-level2"
                                />
                                <InputField
                                    lg={3}
                                    label={t`v2_common.label.postcode`}
                                    name="postCode"
                                    maxLength={10}
                                    placeholder={t`v2_common.placeholder.postcode`}
                                    value={postCode}
                                    onChange={this.handleChange}
                                    autoComplete="postal-code"
                                />
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <CountryInput
                                        required
                                        value={country}
                                        defaultValue={country}
                                        onChange={this.handleChange}
                                        name="country"
                                        errorText={t`${_get(
                                            profileValidation,
                                            'errors.country',
                                            ''
                                        )}`}
                                        onInputChange={this.handleCountryInput}
                                        clear={this.clearUserCountry}
                                        autoComplete="country"
                                    />
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <LanguageInput
                                        required
                                        value={locale}
                                        defaultValue={locale}
                                        onChange={this.handleChange}
                                        name="locale"
                                        errorText={t`${_get(
                                            profileValidation,
                                            'errors.locale',
                                            ''
                                        )}`}
                                        autoComplete="locale"
                                    />
                                </Col>
                            </Row>
                        </InfoBlock>
                        <InfoBlock
                            title={t`my_profile.subtitle.${
                                (user || {}).password ? 'change' : 'create'
                            }_password`}
                            className="pdt-40"
                        >
                            {createNewPassword && (
                                <React.Fragment>
                                    {user && user.password && (
                                        <Row>
                                            <V2PasswordField
                                                sm={6}
                                                required
                                                label={t`my_profile.label.current_password`}
                                                name="oldPass"
                                                placeholder={t`my_profile.label.current_password`}
                                                value={oldPass}
                                                onChange={this.handleChange}
                                                errorText={
                                                    passError ||
                                                    t`${_get(
                                                        profileValidation,
                                                        'errors.oldPass',
                                                        ''
                                                    )}`
                                                }
                                                autoComplete="current-password"
                                            />
                                        </Row>
                                    )}
                                    <Row>
                                        <V2PasswordField
                                            required
                                            label={t`my_profile.label.new_password`}
                                            name="newPass"
                                            placeholder={t`my_profile.label.new_password`}
                                            value={newPass}
                                            onChange={this.handleChange}
                                            errorText={t`${_get(
                                                profileValidation,
                                                'errors.newPass',
                                                ''
                                            )}`}
                                            autoComplete="new-password"
                                        />
                                        <V2PasswordField
                                            required
                                            label={t`my_profile.label.repeat_new_password`}
                                            name="newPassRepeat"
                                            placeholder={t`my_profile.label.repeat_new_password`}
                                            value={newPassRepeat}
                                            onChange={this.handleChange}
                                            errorText={t`${_get(
                                                profileValidation,
                                                'errors.newPassRepeat',
                                                ''
                                            )}`}
                                            autoComplete="new-password"
                                        />
                                    </Row>
                                </React.Fragment>
                            )}
                            <Row>
                                <Col lg={4} md={4}>
                                    <PasswordBtn
                                        type="borderBtn"
                                        onClick={this.showPasswordFields}
                                    >
                                        {createNewPassword
                                            ? t`common.label.cancel`
                                            : t`my_profile.subtitle.${
                                                  (user || {}).password
                                                      ? 'change'
                                                      : 'create'
                                              }_password`}
                                    </PasswordBtn>
                                </Col>
                            </Row>
                        </InfoBlock>
                        <InfoBlock
                            title={t`subscription.subscribe`}
                            className="pdt-40"
                        >
                            <Row>
                                <Col>
                                    <V2CheckBox
                                        label={() => (
                                            <CheckBoxInner>
                                                <p>
                                                    {t`subscription.description.subscribe`}
                                                </p>
                                            </CheckBoxInner>
                                        )}
                                        type="checkbox"
                                        name="subscribe"
                                        checked={subscribe}
                                        onChange={this.handleCheckboxChange}
                                    />
                                </Col>
                            </Row>
                        </InfoBlock>
                        <Row className="btn-row">
                            <Col>
                                <V2Button onClick={this.updateProfile}>
                                    {t`common.button.save`}
                                </V2Button>
                            </Col>
                        </Row>
                    </form>
                </ProfileContainer>
            </PageViewContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        countries: state.countries,
        user: state.user,
        isAuthenticated: state.auth.authenticated,
        userClaims: state.user_claims,
        claim: state.claim,
        clmAmt: state.claimable_amt,
        profileValidation: state.profileViewErrors,
        errorMessage: state.error.errorMessage,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUserData,
            getUserClaims,
            updateUserInfo,
            validateProfileView,
            clearErrors,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)
