export const V2_ACTIONS = {
    SET_SKYCOPCARE_PAGE: 'SET_SKYCOPCARE_PAGE',
    SET_SKYCOPCARE_PRODUCTS: 'SET_SKYCOPCARE_PRODUCTS',
    SET_SKYCOPCARE_PRODUCTS_V2: 'SET_SKYCOPCARE_PRODUCTS_V2',
    SET_SKYCOPCARE_ERRORS: 'SET_SKYCOPCARE_ERRORS',
    SET_SKYCOPCARE_SUBS: 'SET_SKYCOPCARE_SUBS',
    SUGGESTED_FLIGHTS: 'SUGGESTED_FLIGHTS',
    CLEAR_SUGGESTED_FLIGHTS: 'CLEAR_SUGGESTED_FLIGHTS',
    CLEAR_SPECIFIC_SUGGESTIONS: 'CLEAR_SPECIFIC_SUGGESTIONS',
    SET_DRAFT_EMAIL: 'SET_DRAFT_EMAIL',
    DRAFTS_ALL: 'DRAFTS_ALL',
    SET_REGULATION: 'SET_REGULATION',
    PROGRESS_BAR: 'PROGRESS_BAR',
    HIDE_ELEMENTS: 'HIDE_ELEMENTS',
    SET_FAST_PAYOUT: 'SET_FAST_PAYOUT',
    SELECTED_PAYOUT: 'SELECTED_PAYOUT',
    IS_FAST_SELECTED: 'IS_FAST_SELECTED',
    RESET_PROGRESS_BAR: 'RESET_PROGRESS_BAR',
    SUPPORTED_V2_LANGUAGES: 'SUPPORTED_V2_LANGUAGES',
    HIDE_COOKIES: 'HIDE_COOKIES',
    INITIAL_V2: 'INITIAL_V2',
    REASONS_BY_AIRLINE: 'REASONS_BY_AIRLINE',
    SELECTED_AIRPORTS: 'SELECTED_AIRPORTS',
    USED_AIRPORTS: 'USED_AIRPORTS',
    USED_AIRLINES: 'USED_AIRLINES',
    POSTED_CLAIM: 'POSTED_CLAIM',
    RESET_POSTED_CLAIM: 'RESET_POSTED_CLAIM',
    SELECTED_AIRLINES: 'SELECTED_AIRLINES',
    V2_NEW_TRANSLATIONS: 'V2_NEW_TRANSLATIONS',
    CLAIM_UUID: 'CLAIM_UUID',
    RESET_CLAIM_UUID: 'RESET_CLAIM_UUID',
    SKIP_SIGNATURE: 'SKIP_SIGNATURE',
    SET_STEP_ONE: 'SET_STEP_ONE',
    RESET_STEP_ONE: 'RESET_STEP_ONE',
    SET_STEP_TWO: 'SET_STEP_TWO',
    RESET_STEP_TWO: 'RESET_STEP_TWO',
    SET_STEP_THREE: 'SET_STEP_THREE',
    RESET_STEP_THREE: 'RESET_STEP_THREE',
    SET_STEP_FOUR: 'SET_STEP_FOUR',
    RESET_STEP_FOUR: 'RESET_STEP_FOUR',
    SET_STEP_FIVE: 'SET_STEP_FIVE',
    SET_STEP_SIX: 'SET_STEP_SIX',
    SET_STEP_ONE_ERRORS: 'SET_STEP_ONE_ERRORS',
    SET_STEP_TWO_ERRORS: 'SET_STEP_TWO_ERRORS',
    SET_STEP_THREE_ERRORS: 'SET_STEP_THREE_ERRORS',
    SET_STEP_FOUR_ERRORS: 'SET_STEP_FOUR_ERRORS',
    SET_STEP_FIVE_ERRORS: 'SET_STEP_FIVE_ERRORS',
    SET_STEP_SIX_ERRORS: 'SET_STEP_SIX_ERRORS',
    STEP_STATE: 'STEP_STATE',
    STEP_RESET: 'STEP_RESET',
    TOP_BAR: 'TOP_BAR',
    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',
    SET_DEPARTURE_FROM: 'SET_DEPARTURE_FROM',
    SET_ARRIVED_TO: 'SET_ARRIVED_TO',
    SET_FLIGHTS_STOPS: 'SET_FLIGHTS_STOPS',
    SET_STOPS_AIRPORTS: 'SET_STOPS_AIRPORTS',
    SET_STOPS_DEPARTURE: 'SET_STOPS_DEPARTURE',
    CLEAR_DEPARTURE_FROM: 'CLEAR_DEPARTURE_FROM',
    CLEAR_ARRIVED_TO: 'CLEAR_ARRIVED_TO',
    CLEAR_STOPS: 'CLEAR_STOPS',
    CLEAR_FLIGHTS_STOPS: 'CLEAR_FLIGHTS_STOPS',
    CLEAR_STOPS_DEPARTURE: 'CLEAR_STOPS_DEPARTURE',
    CLEAR_STOPS_AIRPORTS: 'CLEAR_STOPS_AIRPORTS',
    SET_DISRUPTED_FLIGHTS: 'SET_DISRUPTED_FLIGHTS',
    SET_REASONS: 'SET_REASONS',
    SET_ALTERNATIVE_FLIGHT: 'SET_ALTERNATIVE_FLIGHT',
    SET_ACCEPTED_ALTERNATIVE: 'SET_ACCEPTED_ALTERNATIVE',
    CLEAR_DISRUPTED_FLIGHTS: 'CLEAR_DISRUPTED_FLIGHTS',
    CLEAR_REASONS: 'CLEAR_REASONS',
    CLEAR_ALTERNATIVE_FLIGHT: 'CLEAR_ALTERNATIVE_FLIGHT',
    CLEAR_ACCEPTED_ALTERNATIVE: 'CLEAR_ACCEPTED_ALTERNATIVE',
    SET_DISRUPTION_REASON: 'SET_DISRUPTION_REASON',
    SET_PROVIDED_REASON: 'SET_PROVIDED_REASON',
    SET_COMMENT: 'SET_COMMENT',
    CLEAR_DISRUPTION_REASON: 'CLEAR_DISRUPTION_REASON',
    CLEAR_PROVIDED_REASON: 'CLEAR_PROVIDED_REASON',
    CLEAR_COMMENT: 'CLEAR_COMMENT',
    SET_FLIGHT_DETAILS: 'SET_FLIGHT_DETAILS',
    CLEAR_FLIGHT_DETAILS: 'CLEAR_FLIGHT_DETAILS',
    DO_IT_LATER_BTN_CLICK: 'DO_IT_LATER_BTN_CLICK',
    SET_FLIGHT_DOCUMENTS: 'SET_FLIGHT_DOCUMENTS',
    SET_RESERVATION_NUMBER: 'SET_RESERVATION_NUMBER',
    CLEAR_FLIGHT_DOCUMENTS: 'CLEAR_FLIGHT_DOCUMENTS',
    CLEAR_RESERVATION_NUMBER: 'CLEAR_RESERVATION_NUMBER',
    SET_PASSENGER_DETAILS: 'SET_PASSENGER_DETAILS',
    UPDATE_PASSENGER_DETAILS: 'UPDATE_PASSENGER_DETAILS',
    REMOVE_PASSENGER_DETAILS: 'REMOVE_PASSENGER_DETAILS',
    CLEAR_PASSENGER_DETAILS: 'CLEAR_PASSENGER_DETAILS',
    SET_SIGNATURE: 'SET_SIGNATURE',
    GET_PAYOUT_PLANS: 'GET_PAYOUT_PLANS',
    TOGGLE_TERMS_CONDITIONS: 'TOGGLE_TERMS_CONDITIONS',
    CLEAR_SIGNATURE: 'CLEAR_SIGNATURE',
    CLEAR_PAYOUT_PLAN: 'CLEAR_PAYOUT_PLAN',
    SET_USER_DATA: 'SET_USER_DATA',
    SET_DETAILS: 'SET_DETAILS',
    ADD_DETAILS: 'ADD_DETAILS',
    SET_FLIGHTS: 'SET_FLIGHTS',
    SET_PASSENGERS: 'SET_PASSENGERS',
    SET_CLAIMABLE: 'SET_CLAIMABLE',
    SET_IS_TRANSPORT_AVAILABLE: 'SET_IS_TRANSPORT_AVAILABLE',
    SET_PROBLEM_FLIGHTS_COUNT: 'SET_PROBLEM_FLIGHTS_COUNT',
    SET_STOPS: 'SET_STOPS',
    SET_DEPARTURE_TIME: 'SET_DEPARTURE_TIME',
    SET_DEPARTURE_AIRPORT: 'SET_DEPARTURE_AIRPORT',
    SET_ARRIVAL_AIRPORT: 'SET_ARRIVAL_AIRPORT',
    SET_PARTER_ID: 'SET_PARTER_ID',
    SET_PRICE: 'SET_PRICE',
    SET_V2_CLAIM: 'SET_V2_CLAIM',
    RESET_V2_CLAIM: 'RESET_V2_CLAIM',
    RESET_V2_CLAIM_USER: 'RESET_V2_CLAIM_USER',
    ADD_USER_DOCUMENTS: 'ADD_USER_DOCUMENTS',
    ADD_USER_DRAFT_DOCUMENTS: 'ADD_USER_DRAFT_DOCUMENTS',
    UPDATE_USER_DOCUMENTS: 'UPDATE_USER_DOCUMENTS',
    REMOVE_USER_DOCUMENT: 'REMOVE_USER_DOCUMENT',
    DOCUMENTS_UPLOADING: 'DOCUMENTS_UPLOADING',
    DOCUMENTS_CLEAR: 'DOCUMENTS_CLEAR',
    REMOVE_USER_BY_NAME: 'REMOVE_USER_BY_NAME',
    SET_FLIGHT_DISTANCE: 'SET_FLIGHT_DISTANCE',
    SET_CLAIM_AMOUNT: 'SET_CLAIM_AMOUNT',
    SET_TOTAL_CLAIM_AMOUNT: 'SET_TOTAL_CLAIM_AMOUNT',
    LOGIN_LOADING: 'LOGIN_LOADING',
    SET_ONBOARDING_SLIDER_STEPS: 'SET_ONBOARDING_SLIDER_STEPS',
    UNAVAILABLE_SERVICE: 'UNAVAILABLE_SERVICE',
    SET_PAYOUT_DETAILS: 'SET_PAYOUT_DETAILS',
    CLEAR_PAYOUT_DETAILS: 'CLEAR_PAYOUT_DETAILS',
    SET_DRAFT_SAVE_ERROR: 'SET_DRAFT_SAVE_ERROR',
    SET_DRAFT_EMAIL_ERROR: 'SET_DRAFT_EMAIL_ERROR',
    CLEAR_DRAFT_ERRORS: 'CLEAR_DRAFT_ERRORS',
    DRAFT_SUCCESS: 'DRAFT_SUCCESS',
    GET_CLAIM_DRAFT: 'GET_CLAIM_DRAFT',
    RESET_CLAIM_DRAFT: 'RESET_CLAIM_DRAFT',
    SET_CLAIM_DRAFT_PROPS: 'SET_CLAIM_DRAFT_PROPS',
    RESET_CLAIM_DRAFT_PROPS: 'RESET_CLAIM_DRAFT_PROPS',
    ENABLED_POSTCODE: 'ENABLED_POSTCODE',
    SET_PROFILE_ERRORS: 'SET_PROFILE_ERRORS',
    SET_PAYMENT_ERRORS: 'SET_PAYMENT_ERRORS',
    CLAIM_DRAFT_ID: 'CLAIM_DRAFT_ID',
    CLAIM_DRAFT_PRELOAD_STEP: 'CLAIM_DRAFT_PRELOAD_STEP',
    SET_NOT_SUPPORTED_AIRLINES: 'SET_NOT_SUPPORTED_AIRLINES',
    CLEAR_NOT_SUPPORTED_AIRLINES: 'CLEAR_NOT_SUPPORTED_AIRLINES',
    LEAVING_MODAL: 'LEAVING_MODAL',
    SET_REFERRAL_SHARE_CODE: 'SET_REFERRAL_SHARE_CODE',
    SET_PROMO_CODE: 'SET_PROMO_CODE',
    SET_PROMO_CODE_INVALID: 'SET_PROMO_CODE_INVALID',
    SET_MODAL_PROMO_CODE: 'SET_MODAL_PROMO_CODE',
    SET_SMART_DELAY_FLIGHT_DETAILS: 'SET_SMART_DELAY_FLIGHT_DETAILS',
    SET_SMART_DELAY_PASSENGER_DETAILS: 'SET_SMART_DELAY_PASSENGER_DETAILS',
    SET_SMART_DELAY_FLIGHT_DETAILS_ERRORS:
        'SET_SMART_DELAY_FLIGHT_DETAILS_ERRORS',
    SET_SMART_DELAY_FLIGHT_DETAILS_REGISTERED_FLIGHT_IDS:
        'SET_SMART_DELAY_FLIGHT_DETAILS_REGISTERED_FLIGHT_IDS',
    SET_SIGNATURE_PAGE_CONFIG: 'SET_SIGNATURE_PAGE_CONFIG',
    RESET_SIGNATURE_PAGE_CONFIG: 'RESET_SIGNATURE_PAGE_CONFIG',
}

export const CLAIM = 'CLAIM'
export const CLAIMABLE_AMOUNT = 'CLAIMABLE_AMOUNT'
export const AUTH_ERROR = 'AUTH_ERROR'
export const SOCIAL_LOGIN_ERROR = 'SOCIAL_LOGIN_ERROR'
export const DATEFORMAT = 'YYYY-MM-DD'
export const REMOVE_ERROR = 'REMOVE_ERROR'
export const AUTH_USER = 'AUTH_USER'
export const UNAUTH_USER = 'UNAUTH_USER'
export const USER = 'USER'
export const COUNTRIES = 'COUNTRIES'
export const AIRPORTS = 'AIRPORTS'
export const AIRLINES = 'AIRLINES'
export const STOPS = 'STOPS'
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE'
export const RESET_MESSAGE = 'RESET_MESSAGE'
export const CLAIM_REASONS = 'CLAIM_REASONS'
export const CLAIM_HISTORY = 'CLAIM_HISTORY'
export const CLAIM_ERRORS = 'CLAIM_ERRORS'
export const CLAIM_ERRORS_CLEAR = 'CLAIM_ERRORS_CLEAR'
export const CLAIM_ERRORS_CLEAR_FLIGHTS = 'CLAIM_ERRORS_CLEAR_FLIGHTS'
export const CLAIM_ERRORS_CLEAR_USER = 'CLAIM_ERRORS_CLEAR_USER'
export const DELAY_REASONS = 'DELAY_REASONS'
export const SIGNATURE = 'SIGNATURE'
export const PAYMENT_PLAN = 'PAYMENT_PLAN'
export const AUTH_USER_EXTERNAL = 'AUTH_USER_EXTERNAL'
export const TRANSLATIONS = 'TRANSLATIONS'
export const DEFAULT_TRANSLATIONS = 'DEFAULT_TRANSLATIONS'
export const LANGUAGE = 'LANGUAGE'
export const USER_CLAIMS = 'USER_CLAIMS'
export const USER_RELATED_CLAIMS = 'USER_RELATED_CLAIMS'
export const SELECTED_CLAIM = 'SELECTED_CLAIM'
export const TOTAL_KM = 'TOTAL_KM'
export const PAYOUT_PLANS = 'PAYOUT_PLANS'
export const departedFrom_LOADING = 'departedFrom_LOADING'
export const arrivedTo_LOADING = 'arrivedTo_LOADING'
export const ABOUT_US = 'ABOUT_US'
export const PASSENGER_NAME = 'PASSENGER_NAME'
export const DUPLICATE_CLAIM = 'DUPLICATE_CLAIM'
export const DOMAIN_INFO = 'DOMAIN_INFO'
export const WEB_LANGUAGES = 'WEB_LANGUAGES'
export const USER_AGREEMENT = 'USER_AGREEMENT'
export const DEFAULT_WEB_LANGUAGE = 'en'
export const CONFIG = 'CONFIG'
export const API_CLIENT = 'API_CLIENT'
export const THEME_CONFIG = 'THEME_CONFIG'
export const SET_LOADING_TEXT = 'SET_LOADING_TEXT'
export const CP_ACTIONS = 'CP_ACTIONS'
export const SET_SUBMITED_CLAIM_DATA = 'SET_SUBMITED_CLAIM_DATA'
export const CLEAR_SUBMITED_CLAIM_DATA = 'CLEAR_SUBMITED_CLAIM_DATA'
export const SMTP_VALIDATION = 'SMTP_VALIDATION'
export const SMTP_PASSENGER_VALIDATION = 'SMTP_PASSENGER_VALIDATION'
export const REASON_COEFFICIENTS = 'REASON_COEFFICIENTS'
export const BRANDED_GDPR = 'BRANDED_GDPR'
export const CURRENCIES = 'CURRENCIES'
export const SUPPORTED_POSTCODES = 'SUPPORTED_POSTCODES'
export const SET_BUFFERED_CLAIMS = 'SET_BUFFERED_CLAIMS'
export const SET_ID_ASSETS = 'SET_ID_ASSETS'
export const SET_LANDING_PARAMETERS = 'SET_LANDING_PARAMETERS'
