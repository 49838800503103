import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import CountryInput from '../V2/components/CountryInput'
import DatePicker from '../../shared/components/V2/DatePicker'
import InputField from '../../shared/components/V2/InputField'
import { tablet, mobile } from '../../shared/components/helpers/styled_queries'
import { PageContent } from '../../shared/components/V2/Layout'
import t from '../../shared/translations'
import { desktop } from '../../shared/components/helpers/styled_queries'
import { uploadUserSignatures } from '../../shared/actions/v2_actions'
import { getUserAgreements } from '../../shared/actions'
import AgreementModal from '../V2/components/AgreementModal'
import AgreementModalSig from '../V2/components/AgreementModalSig'
import { AgreementContainer } from '../../shared/components/V2/V2Agreement'
import _get from 'lodash/get'
import { Row, Col } from '../../shared/components/V2/Layout'
import {
    applyTranslationAction,
    applyTranslationElement,
    isValidSpecialChars,
    V2handleSignatureLength,
} from '../../main'
import V2SignBox from '../../shared/components/V2/V2SignBox'
import { V2Button } from '../../shared/components/V2/V2Buttons'
import TermsAndConditions from '../V2/components/TermsAndConditions'
import { dataURItoBlob, loadScreenOff, loadScreenOn } from '../../main'
import { showModal } from '../../shared/actions/v2_actions'
import { getPassengerAgreements } from '../../shared/actions'
import { AGREEMENT_TYPES } from './MissingDetails'

let selectErrors = {}

const CountryInputCol = styled(Col)`
    div.form-group {
        margin-bottom: 0;
    }
`

const TermsContainer = styled.div`
    display: flex;
    p {
        font-weight: normal;
    }

    span {
        color: #cf2e2e;
        font-weight: 700;
    }
`

const StyledAgreementContainer = styled(AgreementContainer)`
    border: none;
    padding: unset;
`

const MainContainer = styled.div`
    flex-grow: 1;
    max-width: 992px;
    margin: 40px auto 80px;

    .h-mb-8 {
        margin-bottom: 8px;
    }

    .h-mt-4 {
        margin-top: 4px;
    }

    h3 {
        font-family: 'Open Sans', sans-serif;
    }

    ${tablet`
        margin-bottom: 100px;
    `}

    ${mobile`
        margin-bottom: 100px;
    `}

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
`

const StyledPageContent = styled(PageContent)`
    ${desktop`
      width: ${({ width }) => width}px;
  `}
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 20px 16px 20px;
`

const Title = styled.div`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 28px;
    color: #353d51;
    margin-bottom: 80px;
    text-align: center;
`

const StyledRow = styled(Row)`
    border-radius: 8px;
    background-color: #ffffff;
    margin-bottom: 24px;

    ${desktop`
        padding: 10px 10px 10px 10px;
        border: solid 1px #f1f2f5;
        margin-bottom: 16px;
        ${({ mobileOnly }) => mobileOnly && 'display: none;'}
    `}

    ${tablet`
        ${({ desktopOnly }) => desktopOnly && 'display: none;'}
    `}

    ${mobile`
        ${({ desktopOnly }) => desktopOnly && 'display: none;'}
    `}

    ${Col} {
        padding: 0 !important;
    }
`

const RevStyledRow = styled(Row)`
    width: 100%;
    flex: 1 1 auto;
    margin-bottom: 24px;

    ${Col} {
        padding: 0 !important;
    }

    ${mobile`
        flex-direction: column-reverse;
    `}

    ${tablet`
        flex-direction: column-reverse;
    `}
`

const StyledCol = styled(Col)`
    ${mobile`
        display: flex;
        justify-content: center;
    `}

    ${tablet`
        display: flex;
        justify-content: center;
    `}
`

const Subheader = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #7f8fa4;
    margin: 0;
    margin-bottom: 8px;
    text-align: center;

    span {
        font-weight: 600;
        color: #353d51;
    }

    &.ml-8 {
        margin-left: 8px;
    }

    ${mobile`
        &.mobile-text-left {
            text-align: left;
        }

        span {
            color: #36a56b;
            font-weight: bold;
        }

        span:hover {
            cursor: pointer;
            filter: brightness(105%);
        }
    `}

    ${tablet`
        span {
            color: #36a56b;
            font-weight: bold;
        }

        span:hover {
            color: #36a56b;
            cursor: pointer;
            filter: brightness(105%);
        }
    `}

    ${desktop`
        text-align: left;
        margin-bottom: 0px;
    `}
`

const DesktopBtn = styled.button`
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    border: 0;
    min-width: 104px;
    min-height: 48px;
    border-radius: 24px;
    text-align: center;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    ${({ theme }) =>
        `${
            theme.colors.actionButton
                ? `background-color: ${theme.colors.actionButton};`
                : `background-image: linear-gradient(to top, ${theme.colors.primaryColor}, ${theme.colors.primaryColor}15)};`
        }`}
    color: #ffffff;
    outline: 0 none;
    font-size: 14px;

    &:hover {
        filter: brightness(105%);
    }

    &:focus {
        outline: 0 none;
    }
`

const StyledV2Button = styled(V2Button)`
    max-width: 246px;
    margin-top: 30px;

    ${mobile`
max-width: unset;
`};
`

const DesktopBorderBtn = styled(DesktopBtn)`
    background: transparent;
    border: solid 1px ${({ theme }) => theme.colors.primaryColor};
    width: 100%;
    color: ${({ theme }) => theme.colors.primaryColor};
    margin-left: 5px;

    ${desktop`
        width: 100%;
    `}
`

const AirlineName = styled.div`
    display: inline;
    font-weight: 700;
`

const AgreementLabel = styled.span`
    font-weight: 700;
`

class MissingSignature extends Component {
    constructor(props) {
        super(props)
        this.state = {
            termsCheck: false,
            shouldValidateTerms: false,
            componentState: {
                signature: '',
            },
            birthdate: '',
            address: '',
            city: '',
            country: '',
        }
    }

    componentDidMount() {
        const {
            getUserAgreements,
            missingDetails,
            type,
            getPassengerAgreements,
        } = this.props

        const fullName = this.getUserName()
        const userAddress = this.getUserAddress()
        const userBirthdate = this.getUserBirthdate()

        if (missingDetails.id && fullName) {
            if (type === AGREEMENT_TYPES.PASSENGER) {
                getPassengerAgreements(
                    missingDetails.id,
                    missingDetails.passengerId,
                    fullName,
                    userBirthdate,
                    userAddress
                )
            } else {
                getUserAgreements(
                    missingDetails.id,
                    fullName,
                    userBirthdate,
                    userAddress
                )
            }
        }
    }

    getUserAddress = () => {
        const { missingDetails } = this.props
        const { address } = missingDetails
        if (address) {
            return address
        } else {
            return ''
        }
    }

    getUserName = () => {
        const { missingDetails } = this.props
        const { name, surname } = missingDetails

        if (name && surname) {
            return `${name} ${surname}`
        } else {
            return ''
        }
    }

    handleCountryInput = e => {
        const { countries } = this.props
        const countryList = _get(countries, 'payload', [])
        const filteredCountry = countryList.find(country => country.title === e)
        if (filteredCountry) {
            this.setState({ country: filteredCountry.id })
        }
    }

    getUserBirthdate = () => {
        const { missingDetails } = this.props

        const { birthdate } = missingDetails

        if (birthdate) {
            return moment(birthdate).format('YYYY-MM-DD')
        } else {
            return ''
        }
    }

    handleSignatureState = e => {
        this.setState(prevState => ({
            ...prevState,
            componentState: {
                ...prevState.componentState,
                signature: e,
            },
        }))
    }

    handleNextStep = () => {
        const { componentState, country, birthdate, address, city } = this.state

        const {
            handleNext,
            missingDetails,
            type,
            uploadUserSignatures,
        } = this.props

        const claimId = missingDetails.id

        const isAddressExist = !this.getUserAddress()
        const isBirthDateExist = !this.getUserBirthdate()

        if (claimId && componentState.signature) {
            var blob = dataURItoBlob(componentState.signature)
            var fd = new FormData()
            if (!missingDetails.signature) {
                fd.append('claim_agreements_signature[types][]', 'sig')
            }
            if (
                missingDetails.isLegalProcessApplicable &&
                !missingDetails.legalSignature
            ) {
                fd.append('claim_agreements_signature[types][]', 'sil')
            }
            if (
                missingDetails.isLegalProcessApplicable &&
                !missingDetails.legalServicesSignature
            ) {
                fd.append('claim_agreements_signature[types][]', 'sls')
            }
            if (
                type === AGREEMENT_TYPES.PASSENGER &&
                missingDetails.passengerId
            ) {
                fd.append(
                    'claim_agreements_signature[passenger]',
                    missingDetails.passengerId
                )
            }

            if (isBirthDateExist && birthdate) {
                fd.append(
                    'additional_parameters[birthDate]',
                    moment(birthdate).format('YYYY-MM-DD')
                )
            }

            if (isAddressExist && address) {
                fd.append('additional_parameters[address]', address)
            }

            if (isAddressExist && city) {
                fd.append('additional_parameters[city]', city)
            }

            if (isAddressExist && country) {
                fd.append('additional_parameters[country]', country)
            }

            fd.append('claim_agreements_signature[claim]', claimId)
            fd.append('claim_agreements_signature[file]', blob)

            loadScreenOn()
            uploadUserSignatures(fd, false).then(() => {
                loadScreenOff()
                handleNext()
            })
        }
    }

    clearCountry = () => {
        this.setState({ country: '' })
    }

    handleCheckboxChange = () => {
        this.setState({ termsCheck: !this.state.termsCheck })
        this.setState({ shouldValidateTerms: false })
    }

    validateDisable = () => {
        this.setState({ shouldValidateTerms: true })
    }

    isValidMissingDetails = () => {
        const { country, birthdate, address, city } = this.state
        let isValid = true

        const isAddressExist = !this.getUserAddress()
        const isBirthDateExist = !this.getUserBirthdate()

        if (isAddressExist) {
            isValid = !!country && !!address && !!city
        }
        if (isBirthDateExist) {
            isValid = !!birthdate
        }
        return !isValid
    }

    handleChangeEvent = e => {
        if (!e) return

        const target = e.target || e

        if (target.name === 'birthdate') {
            const val = target.value
            target.value = moment(val).format('YYYY-MM-DD')
        }

        if (['address', 'city'].includes(target.name)) {
            if (!isValidSpecialChars(target.value)) {
                selectErrors[
                    target.name
                ] = t`common.label.is_required.special_symbols`
                this.forceUpdate()
                return
            }
        }

        this.setState({ [target.name]: target.value }, () => {})
        selectErrors = {}
    }

    render() {
        const {
            trans,
            userAgreement,
            missingDetails,
            showModal,
            primaryColor,
        } = this.props
        const {
            componentState,
            termsCheck,
            shouldValidateTerms,
            birthdate,
            address,
            city,
            country,
        } = this.state

        if (!trans) {
            return null
        }
        const userName = missingDetails.name
        const legalGuardianFullName = missingDetails.legalGuardianFullName
        const userSurname = missingDetails.surname
        const airlineTitle = missingDetails.airlineTitle
        const claimId = missingDetails.id
        const isAddressExist = !this.getUserAddress()
        const isBirthDateExist = !this.getUserBirthdate()

        return (
            <MainContainer>
                <StyledPageContent width={window.innerWidth * 0.8}>
                    <ContentWrapper>
                        <Title>{t`v2_claim.missing_details.title`}</Title>
                    </ContentWrapper>

                    <AgreementModal title={t`sign_form.contract_signed`}>
                        {userAgreement && (
                            <StyledAgreementContainer
                                dangerouslySetInnerHTML={{
                                    __html: userAgreement.sig,
                                }}
                            />
                        )}
                    </AgreementModal>
                    <AgreementModalSig title={t`sign_form.contract_signed`}>
                        {userAgreement && (
                            <StyledAgreementContainer
                                dangerouslySetInnerHTML={{
                                    __html: userAgreement.sil,
                                }}
                            />
                        )}
                    </AgreementModalSig>
                    {/* MOBILE */}
                    <StyledRow mobileOnly>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <Subheader>
                                {userAgreement &&
                                userAgreement.sig &&
                                userAgreement.sil ? (
                                    <>
                                        {applyTranslationAction(
                                            t`v2_claim.claim_signature.please_sign1`,
                                            '<span>',
                                            '</span>',
                                            () => showModal('agreementModal')
                                        )}
                                        &nbsp;
                                        {applyTranslationAction(
                                            t`v2_claim.claim_signature.please_sign2`,
                                            '<span>',
                                            '</span>',
                                            () => showModal('agreementModalSig')
                                        )}
                                    </>
                                ) : userAgreement && userAgreement.sig ? (
                                    applyTranslationAction(
                                        t`v2_claim.claim_signature.please_sign_sig`,
                                        '<span>',
                                        '</span>',
                                        () => showModal('agreementModal')
                                    )
                                ) : (
                                    userAgreement &&
                                    userAgreement.sil &&
                                    applyTranslationAction(
                                        t`v2_claim.claim_signature.please_sign_sil_only`,
                                        '<span>',
                                        '</span>',
                                        () => showModal('agreementModalSig')
                                    )
                                )}
                            </Subheader>
                        </Col>
                    </StyledRow>
                    {userAgreement && userAgreement.sig && (
                        <StyledRow desktopOnly>
                            <Col xs={12} sm={12} md={8} lg={8}>
                                <Subheader
                                    dangerouslySetInnerHTML={{
                                        __html: t`v2_claim.claim_signature.please_sign`,
                                    }}
                                />
                            </Col>
                            <StyledCol xs={12} sm={12} md={4} lg={4}>
                                <DesktopBorderBtn
                                    onClick={() => {
                                        showModal('agreementModal')
                                    }}
                                >
                                    {t`v2_claim.claim_signature.read_agreement`}
                                </DesktopBorderBtn>
                            </StyledCol>
                        </StyledRow>
                    )}
                    {userAgreement && userAgreement.sil && (
                        <StyledRow desktopOnly>
                            <Col xs={12} sm={12} md={8} lg={8}>
                                <Subheader>
                                    <>
                                        {applyTranslationElement(
                                            t`v2_claim.claim_signature.please_sign_sil`,
                                            '%airlineName%',
                                            <AirlineName>
                                                {airlineTitle}
                                            </AirlineName>
                                        )}
                                        &nbsp;
                                        <AgreementLabel>{t`v2_claim.claim_signature.please_sign_agreement_on_legal`}</AgreementLabel>
                                    </>
                                </Subheader>
                            </Col>
                            <StyledCol xs={12} sm={12} md={4} lg={4}>
                                <DesktopBorderBtn
                                    onClick={() => {
                                        showModal('agreementModalSig')
                                    }}
                                >
                                    {t`v2_claim.claim_signature.read_agreement`}
                                </DesktopBorderBtn>
                            </StyledCol>
                        </StyledRow>
                    )}
                    {isAddressExist && (
                        <Row className="h-mb-8">
                            <InputField
                                name="address"
                                label={t`v2_common.label.address`}
                                required
                                placeholder={t`common.placeholder.address`}
                                value={address}
                                onChange={this.handleChangeEvent}
                                autoComplete="street-address"
                            />
                            <InputField
                                name="city"
                                label={t`v2_common.label.city`}
                                placeholder={t`common.placeholder.city`}
                                required
                                value={city}
                                onChange={this.handleChangeEvent}
                                autoComplete="address-level2"
                            />
                        </Row>
                    )}
                    <Row className="h-mb-8">
                        {isAddressExist && (
                            <CountryInputCol xs={12} md={12} lg={6}>
                                <CountryInput
                                    name="country"
                                    required
                                    value={country}
                                    defaultValue="lt"
                                    onChange={this.handleChangeEvent}
                                    clear={this.clearCountry}
                                    autoComplete="country"
                                    onInputChange={this.handleCountryInput}
                                />
                            </CountryInputCol>
                        )}
                        {isBirthDateExist && (
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <DatePicker
                                    dateFormat="YYYY-MM-DD"
                                    label={t`v2_common.label.birthdate`}
                                    required
                                    type="text"
                                    name="birthdate"
                                    isValidDate={() => true}
                                    initialViewDate={moment('1990')}
                                    startDate={2020}
                                    endDate={120}
                                    InitialViewMode="years"
                                    placeholder={t`v2_common.placeholder.birthdate`}
                                    value={birthdate}
                                    handleChange={this.handleChangeEvent}
                                />
                            </Col>
                        )}
                    </Row>
                    <RevStyledRow>
                        <Col md={12} lg={12}>
                            <V2SignBox
                                legalGuardianFullName={legalGuardianFullName}
                                name={userName}
                                surname={userSurname}
                                handleSignatureState={this.handleSignatureState}
                                clearLabel={t`v2_common.clear`}
                                signature={componentState.signature}
                                hasBox={true}
                                downloadEnabled={true}
                                userBirthdate={this.getUserBirthdate()}
                                userAddress={this.getUserAddress()}
                                userName={this.getUserName()}
                                countries={this.props.countries}
                                agreementType="sig"
                                claimId={claimId}
                                primaryColor={primaryColor}
                            />
                        </Col>
                    </RevStyledRow>
                    <ContentWrapper>
                        <TermsContainer>
                            <TermsAndConditions
                                text={t`v2_claim.missing_documents.terms_and_conditions`}
                                onChange={this.handleCheckboxChange}
                                checked={termsCheck}
                                alert={!termsCheck && shouldValidateTerms}
                            />
                        </TermsContainer>
                        <StyledV2Button
                            disabledClick={this.validateDisable}
                            disabled={
                                !termsCheck ||
                                !componentState.signature.length ||
                                V2handleSignatureLength(
                                    componentState.signature,
                                    5000
                                ) ||
                                this.isValidMissingDetails()
                            }
                            onClick={() => {
                                this.handleNextStep()
                            }}
                        >
                            {t`v2_claim.missing_documents.submit`}
                        </StyledV2Button>
                    </ContentWrapper>
                </StyledPageContent>
            </MainContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        userAgreement: state.userAgreement,
        trans: state.translations,
        countries: state.countries,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUserAgreements,
            uploadUserSignatures,
            showModal,
            getPassengerAgreements,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(MissingSignature)
