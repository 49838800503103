import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Container, ContentContainer } from '../V2'
import SuccessBox from '../../views/V2/components/SuccessBox'
import {
    PageViewContainer,
    PageContent,
    Row,
    Col,
} from '../../shared/components/V2/Layout'
import { PageTitle } from '../../shared/components/V2/TextElements'
import t from '../../shared/translations'
import { bindActionCreators } from 'redux'
import { desktop } from '../../shared/components/helpers/styled_queries'

const StyledPageContent = styled(PageContent)`
    ${desktop`
        padding: 20px 60px 30px;
        max-width: 900px;
    `}

    padding-bottom: 130px;

    .form-group {
        margin-bottom: 0;
    }

    .submit-button {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
            display: flex;
        }
    }
`

class PaymentSuccess extends Component {
    render() {
        const { primaryColor } = this.props

        return (
            <Container>
                <ContentContainer>
                    <PageViewContainer id="step-page-view">
                        <StyledPageContent paddingTop={'0px'}>
                            <PageTitle
                                title={t`payment.title.success_page_title`}
                            />
                            <Row>
                                <Col>
                                    <SuccessBox
                                        title={t`payment.success_text`}
                                        primaryColor={primaryColor}
                                    />
                                </Col>
                            </Row>
                        </StyledPageContent>
                    </PageViewContainer>
                </ContentContainer>
            </Container>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess)
