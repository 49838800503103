import React, { Component } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import _get from 'lodash/get'
import moment from 'moment'
import FileDragAndDrop from 'react-file-drag-and-drop'
import scrollIntoView from 'scroll-into-view'
import ClaimInnerCard from '../../components/ClaimInnerCard'
import {
    Col,
    Row,
    PageContent,
    PageViewContainer,
    SidebarContainer,
} from '../../../../shared/components/V2/Layout'
import {
    desktop,
    tablet,
    mobile,
} from '../../../../shared/components/helpers/styled_queries'
import { getSingleClaim, getUserDocument } from '../../../../shared/actions'
import {
    uploadUserDocument,
    getPayoutPlans,
    showModal,
    setClaimAmounts,
    postPayoutPlan,
} from '../../../../shared/actions/v2_actions'
import { paths, claimStatus, modalTypes } from '../../../../shared/constants'
import t from '../../../../shared/translations'
import ErrorBox from '../../components/ErrorsBox'
import FailedFlightInformation from './FailedFlightInformation'
import { InfoCol } from './ClaimSummary'
import DocumentItem from './DocumentItem'
import {
    PlusIcon,
    NewFlightIcon,
    PapersIcon,
    PaperIcon,
    UploadDocumentIcon,
    PassengerIcon,
    CompensationIcon,
    RenumerationIcon,
    PayoutIcon,
} from './icons'
import { loadScreenOn, loadScreenOff } from '../../../../main'
import { InfoIcon } from '../../../../shared/components/V2/V2Icons'
import { StatusBox } from '../../components/ClaimItemCard'
import { PriceListLink } from '../../../../shared/components/V2/V2PayoutPlans'
import ToolTip from '../../../../shared/components/V2/V2Tooltip'
import FastPayoutSideBox from '../../components/FastPayoutSideBox'
import PaymentPlanModal from '../../components/PaymentPlanModal'
import Sidebar from '../../../../shared/components/V2/Sidebar'
import ClaimTimeline from './ClaimTimeline'
import ClaimHorizontalTimeline from './ClaimHorizontalTimeline'
/* global Raven */

const StyledPageViewContainer = styled(PageViewContainer)`
    justify-content: center;
`
const UploadLink = styled.p`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primaryColor};
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
    align-self: center;
    margin: 0;
    margin-bottom: -5px;
    margin-right: 33px;
    cursor: pointer;

    svg {
        margin-right: 8px;
    }
`
const StyledErrorBox = styled(ErrorBox)`
    margin: 0px;
    display: flex;

    .list {
        list-style: none;
        margin: 0;
        padding-left: 16px;

        .list-item {
            font-family: 'Open Sans', sans-serif;
            font-size: 13px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.85;
            letter-spacing: normal;
            word-break: break-all;
            color: #ffffff;
        }
    }

    .missing-documents {
        padding-left: 16px;
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.85;
        letter-spacing: normal;
        color: #ffffff;

        span {
            font-weight: bold;
        }
    }
`
const Container = styled.div`
    width: 844px;
    overflow: hidden;
`

const H1 = styled.h1`
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.24;
    letter-spacing: normal;
    color: #353d51;
    text-align: center;

    ${desktop`
        text-align: left;
    `}
`

const FlightTitle = styled.div`
    display: flex;
    padding: 16px 8px;
    ${tablet`
        justify-content: center;
    `}

    ${mobile`
        justify-content: center;
    `}

    h3 {
        font-family: 'Open Sans', sans-serif;
        font-size: 28px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.86;
        letter-spacing: normal;
        color: #353d51;

        ${tablet`
            font-size: 25px;
            line-height: 1;
            text-align: center;
        `}

        ${mobile`
            font-size: 22px;
            line-height: 1;
            text-align: center;
            margin: auto;
        `}
    }

    span {
        margin: 0 16px;

        &.icon.icon--plane {
            display: flex;
            align-items: center;

            &:before {
                display: flex;
                font-size: 16px;
            }
        }
    }
`

export const ContentItem = styled.div`
    display: flex;
    flex-direction: column;

    h4,
    p {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        text-align: left;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #353d51;
    }

    h4 {
        margin-bottom: 8px;
    }

    p {
        font-weight: bold;
    }
`

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 8px 16px;

    ${ContentItem}:not (:last-child) {
        margin-right: ${({ itemMr }) => itemMr || '64px'};
        margin-bottom: 24px;

        @media screen and (max-width: 1200px) {
            margin-right: 32px;
        }

        ${desktop`
            margin-bottom: 0px;
        `}
    }

    ${desktop`
        flex-direction: row;
    `}
`

const Icon = styled.div`
    width: 64px;
    height: 64px;
`

const DownloadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;

    ${tablet`
        padding: 8px;
    `}

    ${mobile`
        padding: 0;
    `}

    p {
        font-family: 'Open Sans', sans-serif;
        max-width: 251px;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        color: #7f8fa4;

        &.mb-42 {
            margin-bottom: 42px;
        }
    }

    button {
        width: unset;
    }
`

const DocumentsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    .doc-item:first-child {
        padding-top: 0px;
    }
`

const MobileHorizontalTimelineRow = styled(Row)`
    display: none;

    ${mobile`
        display: block;
    `}

    ${tablet`
        display: block;
    `}
`

const InfoRow = styled(Row)`
    flex-direction: row;

    ${InfoCol}:first-child {
        padding-left: 0px;
    }

    ${InfoCol}:last-child {
        padding-right: 0px;
    }
`

const InfoBoxRow = styled(Row)`
    ::-webkit-scrollbar {
        display: none;
    }

    & .infot-box-item {
        padding: 0;
        padding-left: 12px;
    }

    ${tablet`
        overflow-x: scroll;
        flex-direction: row;

        .infot-box-item {
            min-width: 266px;
            min-height: 106px;
        }
    `}

    ${mobile`
        overflow-x: scroll;
        flex-direction: row;

        .infot-box-item {
            min-width: 266px;
            min-height: 106px;
        }
    `}
`

const InfoBoxContainer = styled(Row)`
    background: #f5fafa;
    padding: 24px;
    border-radius: 8px;
    height: 100%;
    flex-direction: row;

    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        border-radius: 100%;
        width: 42px;
        height: 42px;
        flex-shrink: 0;
    }

    & .content {
        padding-left: 12px;

        .box-title {
            color: #06322f;
            /* H2 */
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .box-text {
            color: #353d51;

            /* Body 1 */
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
        }
    }

    ${tablet`
        min-width: 250px;
        min-height: 106px;
    `}

    ${mobile`
       min-width: 250px;
       min-height: 106px;
    `}
`

const PriceList = styled(PriceListLink)`
    margin: 12px;
    align-self: flex-start;
`

function InfoBox({ amount, text, icon }) {
    return (
        <InfoBoxContainer>
            <div className="icon-container">{icon}</div>
            <div className="content">
                <Row className="box-title">{amount}</Row>
                <Row className="box-text">{text}</Row>
            </div>
        </InfoBoxContainer>
    )
}

class ClaimInner extends Component {
    state = {
        fileErrors: [],
        finishedScroll: false,
    }
    dragContainer = React.createRef()
    scrollTimeout = null

    scrollDocsIntoView = elem => {
        const scroll = _get(this.props.location, 'query.s', '')
        if (scroll === 'doc') {
            scrollIntoView(elem, {
                vertical: 0,
                center: false,
                align: { top: 0.1 },
            })
        }
        this.setState({
            finishedScroll: true,
        })
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout)
        }
    }

    componentDidMount() {
        const { user, location, getSingleClaim } = this.props

        if (user && !user.active) {
            browserHistory.push(paths.PROFILE)
        }

        const id = _get(location, 'query.id', '')
        const related = _get(location, 'query.related', '')
        loadScreenOn()
        window.scrollTo(0, 0)

        if (id) {
            if (related) {
                this.setState({ isRelated: true })
                getSingleClaim(id, !!related)
                    .then(() => {
                        this.fetchPayouts()
                        loadScreenOff()
                    })
                    .catch(() => loadScreenOff())
            } else {
                getSingleClaim(id)
                    .then(() => {
                        this.fetchPayouts()
                        loadScreenOff()
                    })
                    .catch(() => loadScreenOff())
            }
        } else {
            loadScreenOff()
            browserHistory.push(paths.V2.MY_CLAIMS)
        }

        this.scrollTimeout = setTimeout(() => {
            if (!this.state.finishedScroll) {
                const docElem = document.getElementById('doc-upload')
                if (docElem) {
                    this.scrollDocsIntoView(docElem)
                }
                this.setState({
                    finishedScroll: true,
                })
            }
        }, 1500)
    }

    componentDidUpdate(prevProps, prevState) {
        const { finishedScroll } = this.state
        if (prevState.finishedScroll !== finishedScroll && !finishedScroll) {
            const docElem = document.getElementById('doc-upload')
            if (docElem) {
                this.scrollDocsIntoView(docElem)
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.scrollTimeout)
    }

    fileOnDragOver = () => {
        const el = this.dragContainer && this.dragContainer.current

        if (el) {
            const dragContainer = el.querySelector('.dragDrop')

            dragContainer && dragContainer.classList.add('hover')
        }
    }

    fileOnDragLeave = () => {
        const el = this.dragContainer && this.dragContainer.current

        if (el) {
            const dragContainer = el.querySelector('.dragDrop')

            if (dragContainer.classList.contains('hover')) {
                dragContainer.classList.remove('hover')
            }
        }
    }

    handleDrop = dataTransfer => {
        let e = { target: { files: [] } }
        e.target.files = dataTransfer.files
        this.fileOnDragLeave()
        this.handleFileChange(e)
    }

    handleFileChange = e => {
        const { selectedClaim, uploadUserDocument, getSingleClaim } = this.props

        const claimId = _get(selectedClaim, 'id', '')
        const { files } = e.target
        let uploadFiles = []
        const fileTypeReg = new RegExp(
            '(.*?)[.](docx|doc|pdf|xml|bmp|jpg|png|jpeg|odt)$'
        )

        let fileErrors = this.state.fileErrors

        ;[...files].forEach(file => {
            if (file.name && !fileTypeReg.test(file.name.toLowerCase())) {
                fileErrors = [
                    ...this.state.fileErrors,
                    { name: file.name, error: t`file.type.not.supported` },
                ]
            } else {
                const fileSize = (file.size / 1024 / 1024).toFixed(4)
                if (fileSize < 5) {
                    uploadFiles = [...uploadFiles, file]
                } else {
                    fileErrors = [
                        ...this.state.fileErrors,
                        { name: file.name, error: t`file.size.limit.reached` },
                    ]
                }
            }
        })

        this.setState({ fileErrors })

        loadScreenOn()
        uploadUserDocument(uploadFiles, claimId)
            .then(async () => {
                await getSingleClaim(claimId)
            })
            .catch(error => {
                Raven.setUserContext({
                    fileType: uploadFiles.map(file => file.type).join(', '),
                    fileSizes: uploadFiles
                        .map(file => (file.size / 1024 / 1024).toFixed(4))
                        .join(', '),
                })
                Raven.captureException(
                    'UPLOAD USER INNER CLAIM DOCUMENT ERROR: ' +
                        JSON.stringify(error)
                )
            })
            .finally(() => {
                loadScreenOff()
            })
        this.fileOnDragLeave()
    }

    getClaimData = () => {
        const { selectedClaim } = this.props
        const arrivalAirport = _get(selectedClaim, 'airport_arrival', {
            city: '',
            iata: '',
        })
        const departureAirport = _get(selectedClaim, 'airport_departure', {
            city: '',
            iata: '',
        })
        const problemFlight = _get(selectedClaim, 'problem_claim_flight', {
            airline: { title: '', iata: '' },
            number: '',
        })

        const arrivalTitle = `${arrivalAirport.city} (${arrivalAirport.iata})`
        const departureTitle = `${departureAirport.city} (${departureAirport.iata})`
        const airlineTitle = problemFlight.airline.title
        const flightNumber = `${problemFlight.airline.iata}${problemFlight.number}`
        const bookingRef = _get(selectedClaim, 'reservation_number', '')
        const flightDate = moment
            .utc(_get(selectedClaim, 'time_departure', ''))
            .format('YYYY-MM-DD')
        const claimCode = _get(selectedClaim, 'code', '')
        const documents = _get(selectedClaim, 'documents', [])
        const typeOfIssue = _get(selectedClaim, 'details[0].value', null)
        const reason = _get(
            selectedClaim,
            'details[3].value',
            _get(selectedClaim, 'details[2].value', null)
        )
        const flightDelay = _get(selectedClaim, 'details[1].value', null)
        const passengers = _get(selectedClaim, 'passengers', [])

        return {
            arrivalTitle,
            departureTitle,
            airlineTitle,
            flightNumber,
            bookingRef,
            flightDate,
            claimCode,
            documents,
            typeOfIssue,
            reason,
            flightDelay,
            passengers,
        }
    }

    renderDocuments = () => {
        const { getUserDocument, primaryColor } = this.props
        const { fileErrors } = this.state
        const info = this.getClaimData()

        return (
            <DocumentsContainer>
                {info &&
                    !!info.documents.length &&
                    info.documents.map((doc, i) => (
                        <DocumentItem
                            key={i}
                            doc={doc}
                            getUserDocument={getUserDocument}
                            primaryColor={primaryColor}
                        />
                    ))}
                {fileErrors &&
                    !!fileErrors.length &&
                    fileErrors.map((items, i) => (
                        <DocumentItem
                            key={i}
                            err={items}
                            onClose={() => {
                                const newFileErrors = fileErrors
                                newFileErrors.splice(i, i + 1)
                                this.setState({ fileErrors: newFileErrors })
                            }}
                        />
                    ))}
            </DocumentsContainer>
        )
    }

    handleCanPercentage = (noAmount, isOldPayoutTitle) => {
        const trans = t`v2.price_list_amount_description_canada.renumeration`

        if (noAmount && isOldPayoutTitle) {
            return trans
                .replace('25', '30')
                .replace('36.3', '30')
                .replace('36,3', '30')
        } else {
            return trans
        }
    }

    renderMobileClaimSummary = () => {
        const { selectedClaim, primaryColor } = this.props
        const status = _get(selectedClaim, 'status', '')
        const transactionStatus = _get(selectedClaim, 'transaction_status', '')
        const payoutConfig = _get(
            selectedClaim,
            'transactions[0].payout_config',
            ''
        )
        const isFastPayoutDescription =
            _get(selectedClaim, 'transactions[0].is_fast_payout', false) &&
            transactionStatus === 'trans_ready_to_pay'
        const isFastPayout = _get(selectedClaim, 'payout.is_fast_payout', false)
        const isLegalPayout = _get(
            selectedClaim,
            'payout.is_legal_payout',
            false
        )
        const amount = _get(selectedClaim, 'amount', '')
        const payableAmount = _get(selectedClaim, 'amount_payable', '')
        const regulation = _get(selectedClaim, 'regulation', '')
        const noAmount =
            (amount === 0 || amount === '0') && regulation === 'reg.canada'
        const isOldPayoutTitle = _get(
            selectedClaim,
            'payout.title',
            ''
        ).includes('old_can')

        const transactionDescription = payoutConfig
            ? t`${transactionStatus}_description${
                  isFastPayoutDescription ? '.fast_payout' : ''
              }`.replace('%period%', payoutConfig)
            : t`${transactionStatus}_description`
        const description = t`${status}_description`
        const statusClaim = transactionStatus || status
        const fee = parseFloat(amount - payableAmount).toFixed(2)

        return (
            <ClaimInnerCard icon={InfoIcon} title={t`inner_claim.summary`}>
                <Row>
                    <StatusBox
                        className={`box-status--${
                            (
                                claimStatus({ primaryColor })[statusClaim] ||
                                claimStatus({ primaryColor }).default
                            ).statusColor
                        }`}
                    >
                        <div className="icon">
                            {(
                                claimStatus({ primaryColor })[statusClaim] ||
                                claimStatus({ primaryColor }).default
                            ).icon()}
                        </div>
                        <p className="title">{t`${statusClaim}`}</p>
                        <p>
                            {transactionStatus
                                ? transactionDescription
                                : description}
                        </p>
                    </StatusBox>
                </Row>
                {!noAmount ? (
                    <React.Fragment>
                        <InfoRow style={{ flexDirection: 'row' }}>
                            <InfoCol
                                xs={7}
                                sm={7}
                                md={7}
                                className="combined left bold"
                            >
                                {t`inner_claim.your_compensation`}
                            </InfoCol>
                            <InfoCol
                                xs={5}
                                sm={5}
                                md={5}
                                className="combined right bold"
                            >
                                €{parseFloat(amount).toFixed(2)}
                            </InfoCol>
                        </InfoRow>
                        <InfoRow style={{ flexDirection: 'row' }}>
                            <InfoCol
                                xs={7}
                                sm={7}
                                md={7}
                                className="combined left grey"
                            >
                                {t`v2.payout_rework.skycop_renumeration${
                                    !isFastPayout && !isLegalPayout
                                        ? '.VAT'
                                        : ''
                                }`}
                            </InfoCol>
                            <InfoCol
                                xs={5}
                                sm={5}
                                md={5}
                                className="combined right grey"
                            >
                                -€{fee}
                            </InfoCol>
                        </InfoRow>
                        <InfoRow style={{ flexDirection: 'row' }}>
                            <InfoCol
                                xs={7}
                                sm={7}
                                md={7}
                                className="combined left grey"
                            >
                                {t`inner_claim.flight_compensation`}
                            </InfoCol>
                            <InfoCol
                                xs={5}
                                sm={5}
                                md={5}
                                className="combined right grey"
                            >
                                €{parseFloat(payableAmount).toFixed(2)}
                            </InfoCol>
                        </InfoRow>
                        {!isFastPayout && (
                            <InfoRow
                                style={{
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <PriceListLink
                                    dangerouslySetInnerHTML={{
                                        __html: t`v2.price_list.see_full_info`,
                                    }}
                                />
                            </InfoRow>
                        )}
                    </React.Fragment>
                ) : (
                    <InfoRow style={{ flexDirection: 'row' }}>
                        <InfoCol
                            sm={7}
                            md={7}
                            lg={7}
                            className="combined left bold"
                        >
                            {t`inner_claim.your_compensation`}
                        </InfoCol>
                        <InfoCol
                            sm={5}
                            md={5}
                            lg={5}
                            className="combined right bold"
                        >
                            <span
                                data-tip="disabled"
                                data-for="compensation"
                                id="compensation"
                            >
                                <InfoIcon />
                            </span>
                            <ToolTip
                                dataTip="disabled"
                                dataFor="compensation"
                                place="left"
                                force="left"
                                id="compensation"
                                type="info"
                                className="sidebar-tooltip"
                            >
                                {t`v2.price_list_amount_description_canada.final_amount`}
                                &#32;
                                {this.handleCanPercentage(
                                    noAmount,
                                    isOldPayoutTitle
                                )}
                            </ToolTip>
                        </InfoCol>
                    </InfoRow>
                )}
            </ClaimInnerCard>
        )
    }

    fetchPayouts = () => {
        const { getPayoutPlans, selectedClaim, setClaimAmounts } = this.props

        if (!selectedClaim) {
            return
        }

        const claimId = _get(selectedClaim, 'id', null)
        const payoutId = _get(selectedClaim, 'payout.id', null)
        const amount = _get(selectedClaim, 'amount', null)
        const flights = _get(selectedClaim, 'flights', [])
        const passengers = _get(selectedClaim, 'passengers', [])

        if (amount) {
            setClaimAmounts(amount, passengers)

            const directFlight = _get(selectedClaim, 'flights', []).length === 1

            if (directFlight) {
                const flightNumber = _get(flights[0], 'number', null)
                const flightAirline = _get(flights[0], 'airline.id', null)
                const flightDate = _get(flights[0], 'time_departure', null)
                const airportDeparture = _get(
                    flights[0],
                    'airport_departure.id',
                    null
                )
                const airportArrival = _get(
                    flights[0],
                    'airport_arrival.id',
                    null
                )

                if (
                    flightNumber &&
                    flightAirline &&
                    airportDeparture &&
                    airportArrival &&
                    flightDate &&
                    payoutId &&
                    claimId
                ) {
                    getPayoutPlans(
                        amount,
                        [],
                        true,
                        flightNumber,
                        flightAirline,
                        flightDate,
                        airportDeparture,
                        airportArrival,
                        payoutId,
                        claimId
                    )
                }
            }
        }
    }

    updatePayout = () => {
        const {
            selectedClaim,
            amount,
            selectedPayout,
            postPayoutPlan,
        } = this.props

        const claimId = _get(selectedClaim, 'id', null)
        const currentPayoutId = _get(selectedClaim, 'payout.id', null)

        if (
            selectedPayout &&
            currentPayoutId &&
            selectedPayout !== currentPayoutId
        ) {
            if (claimId && typeof amount === 'number') {
                loadScreenOn()
                postPayoutPlan(amount, selectedPayout, claimId, true)
            }
        }
    }

    renderClaimInformation = () => {
        const { selectedClaim } = this.props
        const status = _get(selectedClaim, 'status', '')
        const isFastPayout = _get(selectedClaim, 'payout.is_fast_payout', false)
        const isLegalPayout = _get(
            selectedClaim,
            'payout.is_legal_payout',
            false
        )
        const amount = _get(selectedClaim, 'amount', '')
        const payableAmount = _get(selectedClaim, 'amount_payable', '')
        const fee = parseFloat(amount - payableAmount).toFixed(2)

        if (!status || status === 'reject') {
            return null
        }

        return (
            <>
                <Row>
                    <InfoBoxRow>
                        <Col className="infot-box-item">
                            <InfoBox
                                amount={`€${parseFloat(amount).toFixed(2)}`}
                                text={t`inner_claim.your_compensation`}
                                icon={<CompensationIcon />}
                            />
                        </Col>
                        <Col className="infot-box-item">
                            <InfoBox
                                amount={`-€${fee}`}
                                text={t`v2.payout_rework.skycop_renumeration${
                                    !isFastPayout && !isLegalPayout
                                        ? '.VAT'
                                        : ''
                                }`}
                                icon={<RenumerationIcon />}
                            />
                        </Col>
                        <Col className="infot-box-item">
                            <InfoBox
                                amount={`€${parseFloat(payableAmount).toFixed(
                                    2
                                )}`}
                                text={t`inner_claim.flight_compensation`}
                                icon={<PayoutIcon />}
                            />
                        </Col>
                    </InfoBoxRow>
                </Row>
                <Row>
                    {!isFastPayout && (
                        <PriceList
                            dangerouslySetInnerHTML={{
                                __html: t`v2.price_list.see_full_info`,
                            }}
                        />
                    )}
                </Row>
            </>
        )
    }

    render() {
        const { selectedClaim, trans, modal, primaryColor } = this.props
        const { fileErrors, isRelated } = this.state

        if (selectedClaim && trans) {
            const info = this.getClaimData()

            return (
                <>
                    {_get(modal, 'modalType', '') ===
                        modalTypes.paymentPlanModal && <PaymentPlanModal />}
                    <StyledPageViewContainer>
                        <Helmet>
                            <title>{`Skycop - ${t`inner_claim.title`} #${
                                selectedClaim.code
                            }`}</title>
                        </Helmet>
                        <SidebarContainer>
                            <Sidebar>
                                <ClaimTimeline
                                    statusHistory={selectedClaim.status_history}
                                    primaryColor={primaryColor}
                                />
                                <FastPayoutSideBox
                                    callBack={this.updatePayout}
                                />
                            </Sidebar>
                        </SidebarContainer>
                        <Container>
                            <PageContent style={{ height: 'fit-content' }}>
                                <Row>
                                    <Col>
                                        <H1>
                                            {t`inner_claim.title`} #
                                            {info.claimCode}
                                        </H1>
                                    </Col>
                                </Row>
                                <MobileHorizontalTimelineRow>
                                    <ClaimHorizontalTimeline
                                        statusHistory={
                                            selectedClaim.status_history
                                        }
                                        primaryColor={primaryColor}
                                    />
                                </MobileHorizontalTimelineRow>
                                {this.renderClaimInformation()}
                                <Row>
                                    <Col>
                                        <ClaimInnerCard
                                            icon={NewFlightIcon}
                                            title={t`claim_inner.label.flight_information`}
                                        >
                                            <FlightTitle>
                                                <h3>{info.departureTitle}</h3>
                                                &nbsp;
                                                <span className="icon icon--plane" />
                                                &nbsp;
                                                <h3>{info.arrivalTitle}</h3>
                                            </FlightTitle>
                                            <FailedFlightInformation
                                                selectedClaim={selectedClaim}
                                            />
                                            <CardContent>
                                                <ContentItem>
                                                    <h4>{t`claim_inner.label.airlines`}</h4>
                                                    <p>{info.airlineTitle}</p>
                                                </ContentItem>
                                                <ContentItem>
                                                    <h4>{t`claim_inner.label.flight_nr`}</h4>
                                                    <p>{info.flightNumber}</p>
                                                </ContentItem>
                                                <ContentItem>
                                                    <h4>{t`claim_inner.label.reservation_nr`}</h4>
                                                    <p>
                                                        {info.bookingRef ||
                                                            t`claim_inner.label.not_specified`}
                                                    </p>
                                                </ContentItem>
                                                <ContentItem>
                                                    <h4>{t`claim_inner.label.date`}</h4>
                                                    <p>{info.flightDate}</p>
                                                </ContentItem>
                                            </CardContent>
                                        </ClaimInnerCard>
                                    </Col>
                                </Row>
                                {!info.related && !!info.passengers.length && (
                                    <Row>
                                        <Col>
                                            <ClaimInnerCard
                                                icon={PassengerIcon}
                                                title={t`claim.traveller_info.passengers`}
                                            >
                                                <CardContent>
                                                    <ContentItem>
                                                        <h4>{t`v2_common.label.name`}</h4>
                                                        {info.passengers.map(
                                                            (passenger, i) => (
                                                                <p key={i}>
                                                                    {
                                                                        passenger.name
                                                                    }
                                                                </p>
                                                            )
                                                        )}
                                                    </ContentItem>
                                                    <ContentItem>
                                                        <h4>{t`v2_common.label.surname`}</h4>
                                                        {info.passengers.map(
                                                            (passenger, i) => (
                                                                <p key={i}>
                                                                    {
                                                                        passenger.surname
                                                                    }
                                                                </p>
                                                            )
                                                        )}
                                                    </ContentItem>
                                                </CardContent>
                                            </ClaimInnerCard>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col>
                                        <ClaimInnerCard
                                            icon={PaperIcon}
                                            title={t`claim_inner.label.issue_type`}
                                        >
                                            <CardContent>
                                                <ContentItem>
                                                    <h4>{t`claim_inner.label.type_of_issue`}</h4>
                                                    <p>{t`${info.typeOfIssue}`}</p>
                                                </ContentItem>
                                                <ContentItem>
                                                    <h4>{t`claim_inner.label.reason_of_issue`}</h4>
                                                    <p>{t`${info.reason}`}</p>
                                                </ContentItem>
                                                <ContentItem>
                                                    <h4>{t`claim_inner.label.delay`}</h4>
                                                    <p>{t`${info.flightDelay}`}</p>
                                                </ContentItem>
                                            </CardContent>
                                        </ClaimInnerCard>
                                    </Col>
                                </Row>
                                {!!fileErrors.length && (
                                    <Row>
                                        <Col>
                                            <StyledErrorBox>
                                                <ul className="list">
                                                    {fileErrors.map(
                                                        (file, i) => {
                                                            return (
                                                                <li
                                                                    key={i}
                                                                    className="list-item"
                                                                >
                                                                    {`${file.name} - ${file.error}`}
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            </StyledErrorBox>
                                        </Col>
                                    </Row>
                                )}
                                {!isRelated ? (
                                    <Row>
                                        <Col>
                                            <FileDragAndDrop
                                                onDrop={this.handleDrop}
                                                onDragOver={this.fileOnDragOver}
                                                onDragLeave={
                                                    this.fileOnDragLeave
                                                }
                                                onDragEnd={this.fileOnDragLeave}
                                            >
                                                <div ref={this.dragContainer}>
                                                    <ClaimInnerCard
                                                        className={`dragDrop ${!!fileErrors.length &&
                                                            'danger'}`}
                                                        icon={PapersIcon}
                                                        title={t`claim_inner.label.documents`}
                                                        id="doc-upload"
                                                        headerElement={
                                                            <UploadLink
                                                                onClick={() => {
                                                                    document
                                                                        .getElementById(
                                                                            'fileDoc'
                                                                        )
                                                                        .click()
                                                                }}
                                                            >
                                                                <PlusIcon
                                                                    color={
                                                                        primaryColor
                                                                    }
                                                                />
                                                                {t`claim_inner.button.upload_document`}
                                                            </UploadLink>
                                                        }
                                                    >
                                                        <DownloadContainer>
                                                            {!!info.documents
                                                                .length ||
                                                            !!fileErrors.length ? (
                                                                this.renderDocuments()
                                                            ) : (
                                                                <React.Fragment>
                                                                    <Icon>
                                                                        <UploadDocumentIcon />
                                                                    </Icon>
                                                                    <p className="mb-42">
                                                                        {t`v2_claim.flight_documents.drag_drop_info`}
                                                                    </p>
                                                                </React.Fragment>
                                                            )}
                                                            <input
                                                                type="file"
                                                                id="fileDoc"
                                                                name="fileselect[]"
                                                                className="custom-file-input"
                                                                multiple="multiple"
                                                                onChange={
                                                                    this
                                                                        .handleFileChange
                                                                }
                                                                accept="image/*,application/msword,application/vnd.oasis.opendocument.text,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            />
                                                        </DownloadContainer>
                                                    </ClaimInnerCard>
                                                </div>
                                            </FileDragAndDrop>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row>
                                        <Col>
                                            <ClaimInnerCard
                                                className={`dragDrop`}
                                                icon={PapersIcon}
                                                title={t`claim_inner.label.documents`}
                                                id="doc-upload"
                                            >
                                                <DownloadContainer>
                                                    {!!info.documents.length &&
                                                        this.renderDocuments()}
                                                </DownloadContainer>
                                            </ClaimInnerCard>
                                        </Col>
                                    </Row>
                                )}
                            </PageContent>
                        </Container>
                    </StyledPageViewContainer>
                </>
            )
        } else {
            return null
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        trans: state.translations,
        selectedClaim: state.selected_claim,
        claimUUID: state.claim_UUID,
        modal: state.toggle_modal,
        amount: state.v2_amount,
        selectedPayout: state.selectedPayout,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSingleClaim,
            uploadUserDocument,
            getUserDocument,
            getPayoutPlans,
            showModal,
            setClaimAmounts,
            postPayoutPlan,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimInner)
