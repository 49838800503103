export const defaultConfig = {
    colors: {
        primaryColor: '#149954',
        secondaryColor: '#36935d',
        tertiaryColor: '#4ccd82',
        quaternaryColor: '#3ddc7f',
        pageBackground: '#f5fafa',
        activeItemBackground: '#E8FCF4',
        lightHoverColor: '#C4E7E6',
        midHoverColor: '#92d0cd',
        primaryBorderColor: '#92d0ce',
        secondaryBorderColor: '#C4E7E6',
        sidebarBackground: '#5aaeaa',
        actionButton: '',
        errorColor: '#FE6050'
    },
    logo: {
        url: 'https://claim.skycop.com/images/logo_green_transparent.png',
        alt: 'Skycop logo',
    },
    flexboxgrid: {
        gridSize: 12, // columns
        gutterWidth: 1, // rem
        outerMargin: 2, // rem
        mediaQuery: 'only screen',
        container: {
            sm: 46, // rem
            md: 61, // rem
            lg: 76, // rem
        },
        breakpoints: {
            xs: 0, // em
            sm: 32,
            md: 48,
            lg: 72,
            xl: 96,
            xxl: 120,
        },
    },
}

export const v2DefaultConfig = {
    colors: {
        primaryColor: '#149954',
        primaryBorderColor: '#DFE3E9',
        darkText: '#353D51',
    },
}

const themeConfig = () => {
    return defaultConfig
}

export const getTheme = themeConfig
export default themeConfig()
