import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _get from 'lodash/get'
import t from '../../translations'
import {
    InputField,
    ErrorText,
    SelectField,
} from '../../../shared/components/Form'

const ErrorContainer = styled.span`
    padding: 0 10px;
    margin-top: -2.7rem;
`

const SearchIconWrapper = styled.div`
    padding: 0 15px;
    position: absolute;
    pointer-events: none;
    z-index: 99;
    line-height: 52px;
    img {
        width: 18px;
    }
`

const StyledRow = styled.div`
    display: flex;
    margin-right: -10px;
    margin-left: -10px;
    flex-wrap: wrap;
`

const SearchItemRow = styled(StyledRow)`
    width: 100%;
`

const SearchItemFlag = styled.div`
    padding-right: 15px;
`

const SearchItem = styled.li`
    position: relative;
    display: flex;
    padding: 0;
    text-align: left;
    cursor: pointer;
    align-items: center;
    ${SearchItemRow} {
        margin: 0px;
        justify-content: space-between;
        align-items: center;
        div {
            float: left;
        }
        > div {
            display: flex;
            align-items: center;
            &:last-of-type {
                float: right;
                padding-right: 15px;
            }
        }
    }
`

const SelectArrow = styled.span`
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    border-color: ${({ theme }) => theme.colors.primaryColor} transparent
        transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
`

const PrefixContainer = styled.div`
    input {
        padding: 12px 12px 12px 36px !important;
        text-align: center !important;
    }
`

const NumberContainer = styled.div``

const Container = styled.div`
    ${SelectArrow} {
        ${({ isOpen }) =>
            isOpen &&
            `
            border-color: transparent transparent ${({ theme }) =>
                theme.colors.primaryColor};
            border-width: 0 5px 5px;
        `}
    }
    ${NumberContainer} {
        input {
            padding: 1.2rem 1.7rem;
            border-left: 0 !important;
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
            border-bottom-right-radius: unset;
            box-shadow: inset -4px 4px 0.5rem -4px rgba(0, 0, 0, 0.15) !important;
            ${({ hasDanger }) =>
                hasDanger &&
                `
                border: solid 0.1rem #d9534f;
            `}
            ${({ isActive, theme }) =>
                isActive &&
                `
                border: solid 0.1rem ${theme.colors.primaryColor};
            `}
        }
    }
    ${PrefixContainer} {
        position: relative;

        ${InputField} {
            position: relative;
        }

        ${SelectArrow} {
            position: absolute;
            top: calc(50% - 1px);
            right: 8px;
        }
        input {
            ${({ readOnly }) =>
                !readOnly &&
                `
                background-color: #fafafa;
                cursor: pointer;
            `}
            padding: 12px 12px 12px 44px;
            border-bottom-right-radius: unset;
            border-bottom-left-radius: unset;
            border-top-right-radius: unset;
            box-shadow: inset 4px 4px 0.5rem -4px rgba(0, 0, 0, 0.15) !important;
            ${({ hasDanger }) =>
                hasDanger &&
                `
                border: solid 0.1rem #d9534f;
            `}
            ${({ isActive }) =>
                isActive &&
                `
                border: solid 0.1rem  ${({ theme }) =>
                    theme.colors.primaryColor};
            `}

            &:focus {
                caret-color: transparent;
            }
        }
    }
`

const InputFlag = styled.div`
    top: 15px;
    left: 10px;
    background: url('/css/flags.png');
    width: 24px;
    height: 24px;
    position: absolute;
    background-position: 0 0;
    top: 15px;
    right: 30px;
`

const Dropdown = styled.div`
    position: absolute;
    top: 72px;
    left: 0;
    z-index: 99;
    display: none;
    margin-top: 0.1rem;
    color: #333;
    ${({ isOpen }) =>
        isOpen &&
        `
        display: block;
        .Select-placeholder {
            padding-left: 45px;
        }
        .Select-input {
            padding-left: 45px;
            width: 100%;

            input {
                width: 100%!important;
            }
        }
        .Select-control {
            display: block;
            border-top: none;
            border-radius: 0;
            box-shadow: unset;
        }
        .Select-noresults {
            padding-left: 45px;
        }
    `}
`

class PhoneField extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            isActive: false,
            countryCodeName: 'default',
        }
    }

    componentWillMount() {
        const { phoneCode, countries } = this.props

        if (phoneCode) {
            const countryCodeName = this.getCountryCode(countries, phoneCode)
            this.setState({ countryCodeName })
        }

        document.addEventListener('click', this.handleOutsideClick, false)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false)
    }

    componentDidUpdate(prevProps) {
        const { phoneCode, countries } = this.props

        if (phoneCode !== prevProps.phoneCode) {
            const countryCodeName = this.getCountryCode(countries, phoneCode)
            this.setState({ countryCodeName })
        }
    }

    handleOutsideClick = e => {
        const { isOpen } = this.state

        if (isOpen) {
            this.handleSelectRef(this.selectRef)
        }

        if (!ReactDOM.findDOMNode(this).contains(e.target)) {
            this.setState({
                isActive: false,
                isOpen: false,
            })
        }
    }

    onlyNumbers = e => {
        const re = /^[0-9]+/g

        if (!re.test(e.key)) {
            e.preventDefault()
        }
    }

    handlePhoneNumberClickEvent = () => {
        this.setState({
            isActive: true,
            isOpen: false,
        })
    }

    getCountryFlagImg = (code, title) => {
        return (
            <img
                src="images/blank.gif"
                className={`flag flag-${code.toLowerCase()} empty-flag`}
                alt={title}
            />
        )
    }

    handlePhonePaste = e => {
        const pastedValue = e.clipboardData.getData('Text')
        const digitRegex = /^\d+$/

        if (
            !digitRegex.test(
                pastedValue
                    .replace('+', '')
                    .replace(/\s+/g, '')
                    .replace(/-/g, '')
            )
        ) {
            e.preventDefault()
        }

        return false
    }

    getOptions = () => {
        const { countries } = this.props

        return (countries ? countries.payload : [])
            .filter(item => !!item.phone_code)
            .map(item => ({
                id: item.id,
                code: item.code,
                value: item.phone_code,
                title: item.title,
                label: `${item.phone_code}`,
                name: 'phone_code',
            }))
    }

    renderOption = option => {
        return (
            <SearchItem>
                <SearchItemRow>
                    <div>
                        <SearchItemFlag>
                            {this.getCountryFlagImg(option.code, option.title)}
                        </SearchItemFlag>
                        <div>{option.title}</div>
                    </div>
                    <div>{option.label}</div>
                </SearchItemRow>
            </SearchItem>
        )
    }

    filterOption = (option, filterString) => {
        if (
            Object.values(option).some(function(k) {
                return ~k.toLowerCase().indexOf(filterString.toLowerCase())
            })
        ) {
            return true
        }

        return false
    }

    handleOptionChange = option => {
        const { prefixInputName, onChange } = this.props
        const event = {
            target: {
                value: option.id,
                name: prefixInputName,
            },
        }

        this.setState({
            isOpen: false,
            isActive: false,
        })

        this.numberFieldRef.focus()
        onChange(event)
    }

    handleSelectRef = ref => {
        if (ref && ref.focus) {
            ref.focus()
            ref.setState({ isOpen: true, isFocused: true })
        }
    }

    getCountryCode = (countries, id) => {
        const phoneCode = (countries ? countries.payload : []).filter(
            country => country.id === id
        )[0]

        return phoneCode && phoneCode.code
    }

    getCountryPhoneCode = (countries, code) => {
        const phoneCode = (countries ? countries.payload : []).filter(
            country => country.id === code
        )[0]

        return _get(phoneCode, 'phone_code', '')
    }

    renderDropDown = () => {
        const { isOpen } = this.state

        return (
            <Dropdown
                isOpen={isOpen}
                className="col-24 col-sm-24 col-md-24 dropdownas"
            >
                <SearchIconWrapper>
                    <img src="images/icons/search.svg" alt="search" />
                </SearchIconWrapper>
                <SelectField
                    selectProps={{
                        ref: ref => {
                            this.selectRef = ref
                        },
                        options: this.getOptions(),
                        name: 'phone_code_option',
                        onChange: this.handleOptionChange,
                        optionRenderer: this.renderOption,
                        filterOption: this.filterOption,
                        clearable: false,
                        closeOnSelect: false,
                        searchable: true,
                        placeholder: '',
                        autoComplete: 'do-not',
                        loadingPlaceholder: t`common.placeholder.loading`,
                        searchPromptText: t`common.placeholder.searchPrompt`,
                    }}
                />
            </Dropdown>
        )
    }

    render() {
        const {
            placeholder,
            maxLength,
            minLength,
            onDrop,
            label,
            numberInputName,
            prefixInputName,
            errorText,
            value,
            phoneCode,
            readOnly,
            countries,
        } = this.props
        const { isOpen, isActive, countryCodeName } = this.state

        return (
            <Container
                isOpen={isOpen}
                isActive={isActive}
                hasDanger={!!errorText}
                readOnly={readOnly}
            >
                <label>{label}</label>
                <StyledRow className="form-group">
                    <div className="col-8 col-sm-8 col-md-8 pr-0">
                        <PrefixContainer>
                            <InputFlag>
                                {countryCodeName &&
                                    this.getCountryFlagImg(
                                        countryCodeName,
                                        'country_flag'
                                    )}
                            </InputFlag>
                            <InputField
                                type="text"
                                autoComplete="do-not"
                                placeholder={t`common.placeholder.phonecode`}
                                readOnly={readOnly}
                                name={prefixInputName}
                                value={
                                    this.getCountryPhoneCode(
                                        countries,
                                        phoneCode
                                    ) || ''
                                }
                                onChange={e => e.preventDefault()}
                                onClick={e => {
                                    if (readOnly) {
                                        e.preventDefault()
                                    } else {
                                        this.selectRef.setState({
                                            isOpen: !isOpen,
                                        })
                                        this.setState({ isOpen: !isOpen })
                                    }
                                }}
                                onTouchEnd={e => {
                                    e.preventDefault()
                                    e.target.blur()
                                    this.selectRef.setState({ isOpen: !isOpen })
                                    this.setState({
                                        isOpen: !isOpen,
                                        isActive: true,
                                    })
                                }}
                                onFocus={() => {
                                    this.setState({ isActive: true })
                                }}
                            />
                            <SelectArrow />
                        </PrefixContainer>
                    </div>
                    <div className="col-16 col-sm-16 col-md-16 pl-0">
                        <NumberContainer>
                            <InputField
                                type="tel"
                                innerRef={ref => (this.numberFieldRef = ref)}
                                name={numberInputName}
                                placeholder={placeholder}
                                value={value}
                                autoComplete="do-not-autofill"
                                readOnly={readOnly}
                                onKeyPress={this.onlyNumbers}
                                maxLength={maxLength}
                                minLength={minLength}
                                onDrop={onDrop}
                                onPaste={this.handlePhonePaste}
                                onClick={this.handlePhoneNumberClickEvent}
                                onChange={this.props.onChange}
                                onFocus={() => {
                                    this.setState({ isActive: true })
                                }}
                                onBlur={() => {
                                    this.setState({ isActive: false })
                                }}
                                disabled={
                                    !(
                                        this.getCountryPhoneCode(
                                            countries,
                                            phoneCode
                                        ) || ''
                                    ) && !value
                                }
                            />
                        </NumberContainer>
                    </div>
                    {this.renderDropDown()}
                    <ErrorContainer>
                        <ErrorText text={errorText} />
                    </ErrorContainer>
                </StyledRow>
            </Container>
        )
    }
}

PhoneField.propTypes = {
    countries: PropTypes.shape({}),
    errorText: PropTypes.string,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    numberInputName: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onDrop: PropTypes.func,
    onPaste: PropTypes.func,
    phoneCode: PropTypes.string,
    placeholder: PropTypes.string,
    prefixInputName: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

PhoneField.defaultProps = {
    label: '',
    placeholder: '',
    autoComplete: 'off',
    value: '',
    phoneCode: '+370',
    maxLength: 14,
    minLength: 4,
    onDrop: e => e.preventDefault(),
    onPaste: null,
    readOnly: false,
    errorText: '',
}

export default PhoneField
