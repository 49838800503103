import React, { Component } from 'react'
import t from '../../../shared/translations'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _get from 'lodash/get'
import styled from 'styled-components'
import { applyTranslationAction, applyTranslationElement } from '../../../main'
import {
    tablet,
    mobile,
    desktop,
} from '../../../shared/components/helpers/styled_queries'
import {
    Col,
    Row,
    PageContent,
    InfoBlock,
    CheckMarkBox,
} from '../../../shared/components/V2/Layout'
import Helmet from 'react-helmet'
import V2SignBox from '../../../shared/components/V2/V2SignBox'
import TermsAndConditions from '../../../views/V2/components/TermsAndConditions'
import moment from 'moment'
import InputField from '../../../shared/components/V2/InputField'
import DatePicker from '../../../shared/components/V2/DatePicker'
import CountryInput from '../../../views/V2/components/CountryInput'
import { browserHistory } from 'react-router'
import { V2Button } from '../../../shared/components/V2/V2Buttons'
import { getCurrencies, setLanguage } from '../../../shared/actions/index'
import { values, isEmpty } from 'lodash'
import _ from 'lodash'
import {
    showModal,
    setSignaturePageConfig,
    resetSignaturePageConfig,
} from '../../../shared/actions/v2_actions'
import AgreementModal from '../../V2/components/AgreementModal'
import AgreementModalSig from '../../V2/components/AgreementModalSig'
import {
    getApiClient,
    V2handleSignatureLength,
    isValidSpecialChars,
    loadScreenOn,
    loadScreenOff,
    dataURItoBlob,
    isFeatureEnabled,
} from '../../../main'
import {
    MainContainer,
    CountryInputCol,
    CurrencyInputCol,
    HeaderContainer,
    TermsContainer,
    AgreementContainer,
    SubscribeContainer,
} from './styles'
import Subscribe from '../../V2/components/Subscribe'
import CurrencyInput from '../../V2/components/CurrencyInput'
import { featureToggles } from '../../../shared/constants'
import V2CheckBox from '../../../shared/components/V2/V2CheckBox'
import { CheckBoxInner } from '../../V2/steps/PaymentPage/paymentStyles'

let selectErrors = {}

const AirlineName = styled.div`
    display: inline;
    font-weight: 700;
`

const AgreementLabel = styled.span`
    font-weight: 700;
`

const Title = styled.div`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 28px;
    color: #353d51;
    text-align: center;
`

const DesktopBtn = styled.button`
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    border: 0;
    min-width: 104px;
    min-height: 48px;
    border-radius: 24px;
    text-align: center;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    ${({ theme }) =>
        `${
            theme.colors.actionButton
                ? `background-color: ${theme.colors.actionButton};`
                : `background-image: linear-gradient(to top, ${theme.colors.primaryColor}, ${theme.colors.primaryColor}15)};`
        }`}
    color: #ffffff;
    outline: 0 none;
    font-size: 14px;

    &:hover {
        filter: brightness(105%);
    }

    &:focus {
        outline: 0 none;
    }
`

const DesktopBorderBtn = styled(DesktopBtn)`
    background: transparent;
    border: solid 1px
        ${({ theme, signatureConfigs }) =>
            (signatureConfigs && signatureConfigs.buttonColor) ||
            theme.colors.primaryColor};
    width: 100%;
    color: ${({ theme, signatureConfigs }) =>
        (signatureConfigs && signatureConfigs.buttonColor) ||
        theme.colors.primaryColor};

    ${desktop`
        width: 100%;
    `}
    border-radius: ${({ signatureConfigs }) =>
        signatureConfigs && signatureConfigs.buttonBorderRadius};
`

const StyledCol = styled(Col)`
    ${mobile`
        display: flex;
        justify-content: center;
    `}

    ${tablet`
        display: flex;
        justify-content: center;
    `}
`

const Subheader = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #7f8fa4;
    margin: 0;
    margin-bottom: 8px;
    text-align: center;
    margin-right: 8px;

    span {
        font-weight: 600;
        color: #353d51;
    }

    &.ml-8 {
        margin-left: 8px;
    }

    ${mobile`
        &.mobile-text-left {
            text-align: left;
        }

        span {
            color: #36a56b;
            font-weight: bold;
        }

        span:hover {
            cursor: pointer;
            filter: brightness(105%);
        }
    `}

    ${tablet`
        span {
            color: #36a56b;
            font-weight: bold;
        }

        span:hover {
            color: #36a56b;
            cursor: pointer;
            filter: brightness(105%);
        }
    `}

    ${desktop`
        text-align: left;
        margin-bottom: 0px;
    `}
`

const StyledRow = styled(Row)`
    border-radius: 8px;
    background-color: #ffffff;
    margin-bottom: 24px;

    ${desktop`
        padding: 10px 10px 10px 10px;
        border: solid 1px #f1f2f5;
        margin-bottom: 16px;
        ${({ mobileOnly }) => mobileOnly && 'display: none;'}
    `}

    ${tablet`
        ${({ desktopOnly }) => desktopOnly && 'display: none;'}
    `}

    ${mobile`
        ${({ desktopOnly }) => desktopOnly && 'display: none;'}
    `}

    ${Col} {
        padding: 0 !important;
    }
`

const StyledAgreementContainer = styled(AgreementContainer)`
    border: none;
    padding: unset;
`

const ConfirmV2Button = styled(V2Button)`
    background: ${({ signatureConfigs }) =>
        signatureConfigs && signatureConfigs.buttonColor};

    border-radius: ${({ signatureConfigs }) =>
        signatureConfigs && signatureConfigs.buttonBorderRadius};
`

class LeadAgreement extends Component {
    state = {
        address: '',
        town: '',
        country: '',
        birthdate: '',
        initialData: {},
        termsCheck: false,
        manualBankValidation: false,
        signature: '',
        agreement: '',
        subscribe: false,
        type: '',
        bankDataRequired: false,
        bankDataApproved: false,
        needManualValidation: false,
        alertCheckbox: false,
        airlineTitle: 'airline',
        ibanLabel: 'payment_page.iban',
        isCountrySupportIban: true,
        ibanValid: true,
        agreements: null,
    }
    componentDidMount() {
        const { getCurrencies, setSignaturePageConfig } = this.props
        getCurrencies()
        loadScreenOn()
        const urlParams = new URLSearchParams(window.location.search)
        const leadId = urlParams.get('leadId')
        const passengerId = urlParams.get('passengerId')
        const apiClient = getApiClient()
        const url = `/api/leads/${leadId}/passengers/${passengerId}`
        const agreementsUrl = `api/leads/${leadId}/get-agreements?passengerId=${passengerId}`

        apiClient
            .get(url)
            .then(res => {
                this.setState({
                    ...mapInitialData(res.data),
                    initialData: res.data,
                    leadId,
                    passengerId,
                })
            })
            .catch(err => console.warn(err))

        apiClient
            .get(agreementsUrl)
            .then(res => {
                this.setState({ agreements: res.data })
            })
            .catch(err => console.warn(err))

        apiClient
            .get(`api/leads/${leadId}`)
            .then(res => {
                const locale = _.get(res, 'data[user][locale]', 'en')
                this.props.setLanguage(locale)
                setSignaturePageConfig(
                    _.get(res, 'data[signature_page_config]', null)
                )
                this.setState({
                    airlineTitle: _.get(res, 'data[airline_title]', 'airline'),
                })
            })
            .catch(err => console.warn(err))

        loadScreenOff()
    }

    componentDidUpdate(prev) {
        if (prev.lang !== this.props.lang) {
            loadScreenOn()
            const urlParams = new URLSearchParams(window.location.search)
            const leadId = urlParams.get('leadId')
            const passengerId = urlParams.get('passengerId')
            const apiClient = getApiClient()

            const agrreementUrl = `api/leads/${leadId}/get-agreements?passengerId=${passengerId}`

            apiClient
                .get(agrreementUrl, {
                    headers: { 'Accept-language': this.props.lang },
                })
                .then(res => {
                    this.setState({ agreements: res.data })
                })
                .catch(err => console.warn(err))
                .finally(() => loadScreenOff())
        }
    }

    componentWillUnmount() {
        const { resetSignaturePageConfig } = this.props
        resetSignaturePageConfig()
        this.setState({})
    }

    componentDidCatch(err) {
        loadScreenOff()
        console.log(err)
    }
    handleTermsAndConditionsChange = () => {
        this.setState({ termsCheck: !this.state.termsCheck })
    }

    handleSubscribeChange = () => {
        this.setState({ subscribe: !this.state.subscribe })
    }

    setSignatureState = signature => {
        V2handleSignatureLength(signature, 5000)
        this.setState({ signature })
    }

    handleCountryInput = e => {
        const { countries } = this.props
        const countryList = _get(countries, 'payload', [])
        const filteredCountry = countryList.find(country => country.title === e)

        if (filteredCountry) {
            this.setState({ country: filteredCountry.id })
        }
    }

    handleChangeEvent = e => {
        const { ibanValid, isCountrySupportIban } = this.state
        if (!e) return

        const target = e.target || e

        if (target.name === 'birthdate') {
            const val = target.value
            target.value = moment(val).format('YYYY-MM-DD')
        }

        if ('bankCountry' === target.name) {
            this.checkBankCountry(target.code)
        }

        if (['address', 'town'].includes(target.name)) {
            if (!isValidSpecialChars(target.value)) {
                selectErrors[
                    target.name
                ] = t`common.label.is_required.special_symbols`
                this.forceUpdate()
                return
            }
        }

        if ('holderName' === target.name) {
            const targetValue = target.value
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/ +(?= )/g, '')
                .replace(/[^a-zA-Z0-9\d\s]/g, '')

            this.setState({ [target.name]: targetValue })
            return
        }

        if (
            isFeatureEnabled(featureToggles.isSwiftValidationEnabled) &&
            ('routingNumber' === target.name || 'iban' === target.name)
        ) {
            this.setState({
                [target.name]: target.value
                    .toUpperCase()
                    .replace(/[^a-zA-Z0-9]/g, ''),
            })
            return
        }

        this.setState({ [target.name]: target.value })
        selectErrors = {}
        if (isCountrySupportIban && !ibanValid) {
            selectErrors['iban'] = t`invalid.iban.number`
        }
    }

    checkBankCountry = countryCode => {
        const url = '/api/validate/iban-country'
        const apiClient = getApiClient()
        const fd = new FormData()
        fd.append('country_code', countryCode)
        apiClient
            .post(url, fd)
            .then(res => {
                this.setState({
                    ibanLabel:
                        res.data.status === 'valid'
                            ? 'payment_page.iban'
                            : 'payment_page.account',
                    isCountrySupportIban: res.data.status === 'valid',
                    needManualValidation: res.data.status !== 'valid',
                })
            })
            .catch(err => {
                console.warn(err)
            })
    }

    isBankDataRequired = () => {
        return this.state.type === 'usr' && this.state.bankDataRequired
    }

    clearCountry = () => {
        this.setState({ country: '' })
    }

    clearBankCountry = () => {
        this.setState({
            bankCountry: '',
            isCountrySupportIban: true,
            needManualValidation: false,
        })
    }

    clearAccountCurrency = () => {
        this.setState({ currency: '' })
    }

    checkRequired = (field, translation) => {
        if (!this.state[field] || this.state[field].trim() === '') {
            selectErrors[field] = translation
        }
    }

    validateSubmit = () => {
        const {
            birthdate,
            address,
            country,
            town,
            iban,
            bankCountry,
            bankTitle,
            routingNumber,
            currency,
            holderName,
            isCountrySupportIban,
            ibanValid,
        } = this.state
        selectErrors = {}

        if (!birthdate) {
            this.checkRequired(
                'birthdate',
                t`common.info.passenger_birthdate_required`
            )
        }

        if (!address) {
            this.checkRequired('address', t`common.label.is_required.address`)
        }
        if (!town) {
            this.checkRequired('town', t`common.label.is_required.town`)
        }
        if (!country) {
            this.checkRequired('country', t`common.label.is_required.country`)
        }

        if (
            bankCountry ||
            bankTitle ||
            routingNumber ||
            currency ||
            holderName ||
            iban
        ) {
            if (!iban || (isCountrySupportIban && !ibanValid)) {
                selectErrors['iban'] = t`invalid.iban.number`
            }
            if (!holderName) {
                this.checkRequired(
                    'holderName',
                    t`common.label.is_required.account_holder_name`
                )
            }

            if (holderName && holderName.match('[^a-zA-Z\\s]')) {
                selectErrors[
                    'holderName'
                ] = t`common.label.is_required.latin_only`
            }
        }

        const onlyLettersAndNumbers = /([^a-zA-Z0-9\s])+/

        if (
            isFeatureEnabled(featureToggles.isSwiftValidationEnabled) &&
            !(routingNumber.length > 7 && routingNumber.length < 12) &&
            this.isBankDataRequired()
        ) {
            selectErrors['routingNumber'] = t`commons.label.swift_code_length`
        } else if (
            isFeatureEnabled(featureToggles.isSwiftValidationEnabled) &&
            onlyLettersAndNumbers.test(routingNumber)
        ) {
            selectErrors[
                'routingNumber'
            ] = t`commons.label.swift_code_only_latin`
        } else {
            selectErrors['routingNumber'] = null
        }
        this.setState({ errors: selectErrors.length > 0 })
    }

    validateSuggestedIban = value => {
        if (!isFeatureEnabled(featureToggles.featureIsBankPrefillEnabled))
            return

        const apiClient = getApiClient()
        const url = '/api/iban/info'

        apiClient
            .post(url, { iban: value })
            .then(({ data }) => {
                if (data.bic) {
                    this.setState({
                        routingNumber: data.bic,
                    })
                }
                if (data.bankTitle) {
                    this.setState({
                        bankTitle: data.bankTitle,
                    })
                }
            })
            .catch(e => console.error(e))
    }

    validateIban = target => {
        const { isCountrySupportIban } = this.state
        const apiClient = getApiClient()
        const url = '/api/validate/iban'
        const fd = new FormData()

        fd.append('iban', target.value)
        apiClient
            .post(url, fd)
            .then(res => {
                this.setState({ needManualValidation: false, ibanValid: true })
                if (res.data.status === 'valid') {
                    delete selectErrors[target.name]
                }
                if (
                    isCountrySupportIban &&
                    (res.data.status === 'invalid' ||
                        res.data.status === 'invalid_country')
                ) {
                    selectErrors[target.name] = t`invalid.iban.number`
                    this.setState({ ibanValid: false })
                }
                if (
                    !isCountrySupportIban &&
                    res.data.status === 'invalid_country'
                ) {
                    this.setState({
                        needManualValidation: true,
                        ibanValid: false,
                    })
                }

                this.setState({ errors: selectErrors.length > 0 })
                this.validateSuggestedIban(target.value)
            })
            .catch(err => {
                console.warn(err)
            })
    }

    accountLabel = () => {
        return `${t`${this.state.ibanLabel}`}`
    }

    handleBankDataManualValidation = () => {
        this.setState({ bankDataApproved: !this.state.bankDataApproved })
    }

    submitData = data => {
        const {
            leadId,
            passengerId,
            name,
            surname,
            signature,
            subscribe,
            agreements,
        } = this.state
        const apiClient = getApiClient()
        const url = `/api/leads/${leadId}/passengers/${passengerId}/documents`
        const fd = new FormData()

        const blob = dataURItoBlob(signature)

        fd.append('full_name', `${name} ${surname}`)

        if (data.birthdate) {
            fd.append('birthdate', moment(data.birthdate).format('YYYY-MM-DD'))
        }

        if (data.address) {
            fd.append('address', data.address)
        }

        if (data.town) {
            fd.append('town', data.town)
        }

        if (data.country) {
            fd.append('country', data.country)
        }

        if (subscribe) {
            fd.append('subscribe', subscribe)
        }

        if (!!agreements.sil && !!agreements.sig) {
            fd.append('attachment[sig]', blob)
            fd.append('attachment[sil]', blob)
            fd.append('attachment[sls]', blob)
        } else {
            fd.append('attachment[sig]', blob)
        }

        if (data.bankCountry) {
            fd.append('account[country]', data.bankCountry)
        }

        if (data.bankTitle) {
            fd.append('account[bankTitle]', data.bankTitle)
        }

        if (data.routingNumber) {
            fd.append('account[routingNumber]', data.routingNumber)
        }

        if (data.currency) {
            fd.append('account[currency]', data.currency)
        }

        if (data.holderName) {
            fd.append('account[holderName]', data.holderName)
        }

        if (data.iban) {
            fd.append('account[iban]', data.iban)
        }

        apiClient
            .post(url, fd)
            .then(res => {
                browserHistory.push(
                    `lead/documents?leadId=${leadId}&passengerId=${passengerId}`
                )
            })
            .catch(err => {
                console.warn(err)
                browserHistory.push(`lead/list?leadId=${leadId}`)
            })
            .finally(() => {
                loadScreenOff()
            })
    }

    completeSigning = () => {
        const {
            signature,
            termsCheck,
            birthdate,
            needManualValidation,
            bankDataApproved,
            ibanValid,
            isCountrySupportIban,
        } = this.state

        const signatureDate = new Date()

        this.validateSubmit()

        if (
            !termsCheck ||
            !signature ||
            V2handleSignatureLength(signature, 5000)
        ) {
            selectErrors.termsCheck = t`common.label.is_required.terms_and_conditions`
            this.setState({ errors: 'true' })
        } else if (
            !isCountrySupportIban &&
            (!ibanValid || needManualValidation) &&
            !bankDataApproved
        ) {
            selectErrors.bankDataApproved = 'Please check approval'
            this.setState({ alertCheckbox: true, errors: 'true' })
        } else if (
            values(selectErrors).every(isEmpty) &&
            signature &&
            !V2handleSignatureLength(signature, 5000) &&
            birthdate
        ) {
            selectErrors = {}
            loadScreenOn()

            this.setState({ signatureDate })
            this.submitData(this.state)
        }
    }

    render() {
        const {
            currencies,
            showModal,
            signatureConfigs,
            primaryColor,
        } = this.props
        const {
            address,
            town,
            country,
            birthdate,
            name,
            surname,
            termsCheck,
            signature,
            subscribe,
            type,
            iban,
            bankTitle,
            bankCountry,
            routingNumber,
            holderName,
            currency,
            needManualValidation,
            bankDataApproved,
            alertCheckbox,
            isCountrySupportIban,
            airlineTitle,
            agreements,
        } = this.state
        if (!this.props.trans || !agreements) return null
        return (
            <MainContainer>
                <PageContent>
                    <Helmet>
                        <title>{`Skycop - ${t`sign_form.title_part1`} ${t`sign_form.title_part2_bold`}`}</title>
                    </Helmet>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <HeaderContainer>
                                {/* <h1>
                                    {t`sign_form.title_part1`}
                                    <span>
                                        {' '}
                                        {t`sign_form.title_part2_bold`}
                                    </span>
                                </h1> */}
                                <Title>
                                    {t`v2_claim.missing_details.title`}
                                </Title>
                            </HeaderContainer>
                        </Col>
                    </Row>
                    <AgreementModal title={t`sign_form.contract_signed`}>
                        {agreements && (
                            <StyledAgreementContainer
                                dangerouslySetInnerHTML={{
                                    __html: agreements.sig,
                                }}
                            />
                        )}
                    </AgreementModal>
                    <AgreementModalSig title={t`sign_form.contract_signed`}>
                        {agreements && (
                            <StyledAgreementContainer
                                dangerouslySetInnerHTML={{
                                    __html: agreements.sil,
                                }}
                            />
                        )}
                    </AgreementModalSig>
                    {/* MOBILE */}
                    <StyledRow mobileOnly>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <Subheader>
                                {agreements &&
                                agreements.sig &&
                                agreements.sil ? (
                                    <>
                                        {applyTranslationAction(
                                            t`v2_claim.claim_signature.please_sign1`,
                                            '<span>',
                                            '</span>',
                                            () => showModal('agreementModal')
                                        )}
                                        &nbsp;
                                        {applyTranslationAction(
                                            t`v2_claim.claim_signature.please_sign2`,
                                            '<span>',
                                            '</span>',
                                            () => showModal('agreementModalSig')
                                        )}
                                    </>
                                ) : agreements && agreements.sig ? (
                                    applyTranslationAction(
                                        t`v2_claim.claim_signature.please_sign_sig`,
                                        '<span>',
                                        '</span>',
                                        () => showModal('agreementModal')
                                    )
                                ) : (
                                    agreements &&
                                    agreements.sil &&
                                    applyTranslationAction(
                                        t`v2_claim.claim_signature.please_sign_sil_only`,
                                        '<span>',
                                        '</span>',
                                        () => showModal('agreementModalSig')
                                    )
                                )}
                            </Subheader>
                        </Col>
                    </StyledRow>
                    {agreements && agreements.sig && (
                        <StyledRow desktopOnly>
                            <Col xs={12} sm={12} md={8} lg={8}>
                                <Subheader
                                    dangerouslySetInnerHTML={{
                                        __html: t`v2_claim.claim_signature.please_sign`,
                                    }}
                                />
                            </Col>
                            <StyledCol xs={12} sm={12} md={4} lg={4}>
                                <DesktopBorderBtn
                                    signatureConfigs={signatureConfigs}
                                    onClick={() => {
                                        showModal('agreementModal')
                                    }}
                                >
                                    {t`v2_claim.claim_signature.read_agreement`}
                                </DesktopBorderBtn>
                            </StyledCol>
                        </StyledRow>
                    )}
                    {agreements && agreements.sil && (
                        <StyledRow desktopOnly>
                            <Col xs={12} sm={12} md={8} lg={8}>
                                <Subheader>
                                    <>
                                        {applyTranslationElement(
                                            t`v2_claim.claim_signature.please_sign_sil`,
                                            '%airlineName%',
                                            <AirlineName>
                                                {airlineTitle}
                                            </AirlineName>
                                        )}
                                        &nbsp;
                                        <AgreementLabel>{t`v2_claim.claim_signature.please_sign_agreement_on_legal`}</AgreementLabel>
                                    </>
                                </Subheader>
                            </Col>
                            <StyledCol xs={12} sm={12} md={4} lg={4}>
                                <DesktopBorderBtn
                                    signatureConfigs={signatureConfigs}
                                    onClick={() => {
                                        showModal('agreementModalSig')
                                    }}
                                >
                                    {t`v2_claim.claim_signature.read_agreement`}
                                </DesktopBorderBtn>
                            </StyledCol>
                        </StyledRow>
                    )}

                    <Row>
                        <Col xs={12} sm={12}>
                            {/* <AgreementContainer
                                className="h-mb-8"
                                dangerouslySetInnerHTML={{
                                    __html: agreement,
                                }}
                            /> */}
                        </Col>
                    </Row>
                    <div id="scroll-top-div" />
                    <Row className="h-mb-8">
                        <InputField
                            name="address"
                            label={t`v2_common.label.address`}
                            required
                            placeholder={t`common.placeholder.address`}
                            value={address}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['address']}
                            autoComplete="street-address"
                        />
                        <InputField
                            name="town"
                            label={t`v2_common.label.city`}
                            placeholder={t`common.placeholder.city`}
                            required
                            value={town}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['town']}
                            autoComplete="address-level2"
                        />
                    </Row>
                    <Row className="h-mb-8">
                        <CountryInputCol xs={12} md={12} lg={6}>
                            <CountryInput
                                name="country"
                                required
                                value={country}
                                defaultValue={country || 'lt'}
                                onChange={this.handleChangeEvent}
                                errorText={selectErrors['country']}
                                clear={this.clearCountry}
                                autoComplete="country"
                                onInputChange={this.handleCountryInput}
                            />
                        </CountryInputCol>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <DatePicker
                                dateFormat="YYYY-MM-DD"
                                label={t`v2_common.label.birthdate`}
                                required
                                type="text"
                                name="birthdate"
                                isValidDate={() => true}
                                initialViewDate={moment('1990')}
                                startDate={2020}
                                endDate={120}
                                maxDate={new Date()}
                                InitialViewMode="years"
                                placeholder={t`v2_common.placeholder.birthdate`}
                                value={birthdate}
                                handleChange={this.handleChangeEvent}
                                errorText={selectErrors['birthdate']}
                            />
                        </Col>
                    </Row>
                    {this.isBankDataRequired() ? (
                        <Row>
                            <Col>
                                <p style={{ fontSize: '16px' }}>
                                    {t`v2_common.lead.bank_section_title`.replace(
                                        '%airline title%',
                                        airlineTitle
                                    )}
                                </p>
                            </Col>
                        </Row>
                    ) : null}
                    {this.isBankDataRequired() ? (
                        <Row className="h-mb-8">
                            <CountryInputCol xs={12} md={12} lg={6}>
                                <CountryInput
                                    name="bankCountry"
                                    label={t`payment_page.bank_country`}
                                    placeholder={t`payment_page.placeholder.country`}
                                    value={bankCountry}
                                    defaultValue={bankCountry || 'lt'}
                                    onChange={this.handleChangeEvent}
                                    errorText={selectErrors['bankCountry']}
                                    clear={this.clearBankCountry}
                                    autoComplete="bankCountry"
                                />
                            </CountryInputCol>
                            <CurrencyInputCol xs={12} md={12} lg={6}>
                                <CurrencyInput
                                    value={currency}
                                    onChange={e => {
                                        if (
                                            !isFeatureEnabled(
                                                featureToggles.featureCurrencyRestriction
                                            )
                                        ) {
                                            this.handleChangeEvent(e)
                                            return
                                        }

                                        if (
                                            currencies.length === 3 ||
                                            currencies.length === 2
                                        ) {
                                            this.handleChangeEvent(e)
                                        }
                                    }}
                                    clear={this.clearAccountCurrency}
                                    name="currency"
                                    placeholder={t`payment_page.placeholder.currency`}
                                    label={t`payment_page.currency`.replace(
                                        '*',
                                        ''
                                    )}
                                    errorText={selectErrors['currency']}
                                    id="currency"
                                />
                            </CurrencyInputCol>
                        </Row>
                    ) : null}
                    {this.isBankDataRequired() ? (
                        <Row className="h-mb-8">
                            <InputField
                                name="iban"
                                label={this.accountLabel()}
                                placeholder={t`payment_page.placeholder.iban`}
                                required={
                                    currency ||
                                    holderName ||
                                    routingNumber ||
                                    bankCountry ||
                                    bankTitle ||
                                    iban
                                }
                                value={iban}
                                onChange={this.handleChangeEvent}
                                errorText={selectErrors['iban']}
                                autoComplete="iban"
                                onBlur={e => {
                                    this.validateIban(e.target)
                                }}
                            />
                            <InputField
                                name="routingNumber"
                                label={t`payment_page.swift`}
                                placeholder={t`payment_page.placeholder.swift`}
                                value={routingNumber}
                                onChange={this.handleChangeEvent}
                                errorText={selectErrors['routingNumber']}
                                autoComplete="routingNumber"
                            />
                        </Row>
                    ) : null}
                    {this.isBankDataRequired() ? (
                        <Row className="h-mb-8">
                            <InputField
                                name="bankTitle"
                                label={t`payment_page.bank_name`}
                                placeholder={t`payment_page.placeholder.bank_name`}
                                value={bankTitle}
                                onChange={this.handleChangeEvent}
                                errorText={selectErrors['bankTitle']}
                                autoComplete="bankTitle"
                            />
                            <InputField
                                name="holderName"
                                label={t`payment_page.holder_name`}
                                placeholder={t`payment_page.placeholder.receiver_name`}
                                required={
                                    bankCountry ||
                                    iban ||
                                    routingNumber ||
                                    currency ||
                                    bankTitle ||
                                    holderName
                                }
                                value={holderName}
                                onChange={this.handleChangeEvent}
                                errorText={selectErrors['holderName']}
                                autoComplete="holderName"
                            />
                        </Row>
                    ) : null}
                    {this.isBankDataRequired() && !isCountrySupportIban ? (
                        <React.Fragment>
                            <InfoBlock>
                                <Row>
                                    <Col>
                                        <CheckMarkBox
                                            label={t`payment_page.check_text.first`}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <CheckMarkBox
                                            label={t`payment_page.check_text.second`}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <CheckMarkBox
                                            label={t`payment_page.check_text.third`}
                                        />
                                    </Col>
                                </Row>
                            </InfoBlock>
                            <InfoBlock>
                                <Row>
                                    <Col>
                                        <V2CheckBox
                                            label={() => (
                                                <CheckBoxInner>
                                                    <p>
                                                        {t`payment_page.checkbox.valid_data`}
                                                    </p>
                                                </CheckBoxInner>
                                            )}
                                            type="checkbox"
                                            required={needManualValidation}
                                            onChange={
                                                this
                                                    .handleBankDataManualValidation
                                            }
                                            name="agree-terms"
                                            id="agree-terms"
                                            alert={
                                                needManualValidation &&
                                                alertCheckbox
                                            }
                                            checked={bankDataApproved}
                                        />
                                    </Col>
                                </Row>
                            </InfoBlock>
                        </React.Fragment>
                    ) : null}
                    <Row className="h-mb-8 h-mt-4">
                        <Col md={12} lg={12}>
                            <V2SignBox
                                border
                                name={name}
                                surname={surname}
                                handleSignatureState={this.setSignatureState}
                                clearLabel={t`v2_common.clear`}
                                signature={signature}
                                hasBox={true}
                                signatureConfigs={signatureConfigs}
                                primaryColor={primaryColor}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TermsContainer>
                                <TermsAndConditions
                                    text={t`v2_claim.passenger_details.terms_and_conditions_price_list`}
                                    onChange={
                                        this.handleTermsAndConditionsChange
                                    }
                                    checked={termsCheck}
                                />
                            </TermsContainer>
                        </Col>
                    </Row>
                    {selectErrors['termsCheck'] ? (
                        <Row>
                            <Col className="form-control-feedback has-danger text-center h-mb15">
                                {selectErrors['termsCheck']}
                            </Col>
                        </Row>
                    ) : null}
                    {type === 'usr' ? (
                        <Row style={{ marginTop: '-15px' }}>
                            <Col>
                                <SubscribeContainer>
                                    <Subscribe
                                        text={t`subscription.description.subscribe`}
                                        onChange={this.handleSubscribeChange}
                                        checked={subscribe}
                                    />
                                </SubscribeContainer>
                            </Col>
                        </Row>
                    ) : null}
                    <Row style={{ justifyContent: 'center' }}>
                        <Col md={4} lg={4}>
                            <ConfirmV2Button
                                signatureConfigs={signatureConfigs}
                                onClick={this.completeSigning}
                            >
                                {t`signature_form.button.complete_signing`}
                            </ConfirmV2Button>
                        </Col>
                    </Row>
                </PageContent>
            </MainContainer>
        )
    }
}

function mapInitialData(data) {
    return {
        address: data.address,
        town: data.town,
        country: data.country,
        birthdate: data.birthdate,
        name: data.first_name,
        surname: data.last_name,
        type: data.type,
        currency: data.currency || '',
        iban: data.iban || '',
        bankTitle: data.bankTitle || '',
        routingNumber: data.routingNumber || '',
        holderName: data.holderName || '',
        bankCountry: data.bankCountry || '',
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setLanguage,
            getCurrencies,
            showModal,
            setSignaturePageConfig,
            resetSignaturePageConfig,
        },
        dispatch
    )
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        modal: state.toggle_modal,
        lang: state.language,
        currencies: state.currencies,
        countries: state.countries,
        signatureConfigs: state.signaturePageConfig,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadAgreement)
