import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import styled from 'styled-components'
import {
    tablet,
    mobile,
    desktop,
} from '../../shared/components/helpers/styled_queries'
import { PageContent } from '../../shared/components/V2/Layout'
import { Col, Row } from '../../shared/components/V2/Layout'
import FileDragAndDrop from 'react-file-drag-and-drop'
import t from '../../shared/translations'
import _ from 'lodash'
import { bytesToSize, scrollComponentToView } from '../../main'
import {
    DragDropContainer,
    StyledStepContent,
    DragFile,
    TextContainer,
    FileUploadText,
    FilesIcon,
    IconContainer,
    LineBreak,
    MobileIconText,
    MobileUploadContainer,
    FileInput,
    FileInputLabel,
    CameraIcon,
    IconDisplay,
    DocumentInner,
    DocumentOuter,
    DocumentName,
    RemoveButtonContainer,
    DocumentProgress,
    DocumentSize,
    DocumentsContainer,
    StyledP,
} from '../V2/steps/FlightDocuments/flightDocumentsStyles'
import { UploadIcon } from '../../shared/components/V2/V2Icons'
import { FailedDocIcon, CloseIcon } from '../../shared/components/V2/V2Icons'
import { DocumentIcon } from '../../shared/components/V2/V2Icons'
import {
    uploadDocument,
    setV2Claim,
    addUserDocuments,
    removeUserDocument,
    validateStepFive,
    setProgress,
    checkUUID,
    getClaimDraftDocuments,
    setPreloadedClaimStep,
    showModal,
} from '../../shared/actions/v2_actions'
import { V2Button } from '../../shared/components/V2/V2Buttons'
import TermsAndConditions from '../V2/components/TermsAndConditions'

const MainContainer = styled.div`
    flex-grow: 1;
    max-width: 992px;
    margin: 40px auto 80px;

    .h-mb-8 {
        margin-bottom: 8px;
    }

    .h-mt-4 {
        margin-top: 4px;
    }

    h3 {
        font-family: 'Open Sans', sans-serif;
    }

    ${tablet`
        margin-bottom: 100px;
    `}

    ${mobile`
        margin-bottom: 100px;
    `}

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
`

const StyledStepContent2 = styled(StyledStepContent)`
    margin: 0 0 0 0;
`

const Title = styled.div`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 28px;
    color: #353d51;
    margin-bottom: 80px;
    text-align: center;
`

const AirlineDescription = styled.div`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    color: #7f8fa4;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 20px 16px 20px;
`

const StyledPageContent = styled(PageContent)`
    ${desktop`
      width: ${({ width }) => width}px;
  `}
`

const StyledV2Button = styled(V2Button)`
    max-width: 246px;
    margin-top: 30px;

    ${mobile`
max-width: unset;
`};
`

const TermsContainer = styled.div`
    display: flex;

    p {
        font-weight: normal;
    }

    span {
        color: #cf2e2e;
        font-weight: 700;
    }
`

const StyledLi = styled.li`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    color: #7f8fa4;
`

class MissingDocuments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            termsCheck: false || props.missingDetails.isMissingSignature,
            shouldValidateTerms: false,
            componentState: {
                flightNumber: '',
                canRender: false,
                uploadErrors: [],
            },
            innerWidth: window.innerWidth,
        }
    }
    dragContainer = React.createRef()

    componentDidMount() {
        const { flightDocs } = this.props

        scrollComponentToView('pageTopNavbar', false, true)
        window.addEventListener('resize', this._onResize)
        window.addEventListener('dragenter', this.preventDragDrop)
        window.addEventListener('dragover', this.preventDragDrop)
        window.addEventListener('drop', this.preventDragDrop)

        if (!!flightDocs.documents.length) {
            this.setState(prevState => ({
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    canRender: true,
                },
            }))
        }
    }

    componentDidUpdate(prevProps) {
        const { flightDocs } = this.props

        if (
            prevProps.flightDocs.documents.length !==
            flightDocs.documents.length
        ) {
            if (!!flightDocs.documents.length) {
                setTimeout(() => {
                    this.setState(prevState => ({
                        ...prevState,
                        componentState: {
                            ...prevState.componentState,
                            canRender: true,
                        },
                    }))
                }, 300)
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        canRender: false,
                    },
                }))
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._onResize)
        window.removeEventListener('dragenter', this.preventDragDrop)
        window.removeEventListener('dragover', this.preventDragDrop)
        window.removeEventListener('drop', this.preventDragDrop)
    }

    preventDragDrop = e => {
        if (!e.target.closest('#dd-box')) {
            // eslint-disable-next-line no-restricted-globals
            ;(e || event).preventDefault()
            e.dataTransfer.effectAllowed = 'none'
            e.dataTransfer.dropEffect = 'none'
        } else {
            e.dataTransfer.effectAllowed = 'initial'
            e.dataTransfer.dropEffect = 'initial'
        }
    }

    _onResize = () => {
        const width = window.innerWidth

        this.setState({ innerWidth: width })
    }

    handleDrop = dataTransfer => {
        let e = { target: { files: [] } }
        e.target.files = dataTransfer.files
        this.fileOnDragLeave()
        this.handleFileChange(e)
    }

    fileOnDragOver = () => {
        const el = this.dragContainer && this.dragContainer.current

        if (el) {
            el.classList.add('hover')
        }
    }

    fileOnDragLeave = () => {
        const el = this.dragContainer && this.dragContainer.current

        if (el && el.classList.contains('hover')) {
            el.classList.remove('hover')
        }
    }

    handleDrop = dataTransfer => {
        let e = { target: { files: [] } }
        e.target.files = dataTransfer.files
        this.fileOnDragLeave()
        this.handleFileChange(e)
    }

    handleFileChange = e => {
        const { uploadDocument, addUserDocuments, missingDetails } = this.props

        this.setState(prevState => ({
            ...prevState,
            componentState: {
                ...prevState.componentState,
                uploadErrors: [],
            },
        }))
        const { files } = e.target
        const fileTypeReg = new RegExp(
            '(.*?).(docx|doc|pdf|xml|bmp|jpg|png|jpeg|odt)$'
        )

        Array.prototype.forEach.call(files, (file, i) => {
            if (file.name && !fileTypeReg.test(file.name.toLowerCase())) {
                this.setState(prevState => ({
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        uploadErrors: [
                            ...prevState.componentState.uploadErrors,
                            {
                                name: file.name,
                                error: t`v2_claim.file.type.not.supported`,
                            },
                        ],
                        canRender: true,
                    },
                }))
            } else {
                const fileSize = (file.size / 1024 / 1024).toFixed(4)

                if (fileSize < 5) {
                    addUserDocuments(file)
                    uploadDocument(file, missingDetails.id)
                } else {
                    this.setState(prevState => ({
                        ...prevState,
                        componentState: {
                            ...prevState.componentState,
                            uploadErrors: [
                                ...prevState.componentState.uploadErrors,
                                {
                                    name: file.name,
                                    error: t`v2_claim.file.size.limit.reached`,
                                },
                            ],
                        },
                    }))
                }
            }
        })

        e.target.value = []
    }

    renderDocumentsList = (documents, failedDocuments) => {
        const { removeUserDocument } = this.props
        const { innerWidth } = this.state

        const handleName = name => {
            let availableLength = 25
            let lettersCount = 10
            let docName = ''

            if (innerWidth < 960) {
                if (innerWidth < 420) {
                    availableLength = 29
                } else if (innerWidth < 520) {
                    availableLength = 45
                } else if (innerWidth < 720) {
                    availableLength = 70
                    lettersCount = 20
                } else {
                    availableLength = 60
                    lettersCount = 20
                }
            } else {
                if (innerWidth >= 960 && innerWidth < 1145) {
                    availableLength = 15
                }
            }

            if (name) {
                if (name.length > availableLength) {
                    const firstPart = name.substring(0, lettersCount)
                    const last = name.substring(lettersCount)
                    const lastPart = last.substring(
                        last.length - (availableLength - 3 - lettersCount)
                    )
                    docName = `${firstPart}...${lastPart}`
                } else {
                    docName = name
                }
            }

            return docName ? docName : ''
        }

        if (
            (documents && !!documents.length) ||
            (failedDocuments && !!failedDocuments.length)
        ) {
            const docs = documents.map((document, index) => {
                const { file } = document
                const { name } = file

                const docName = handleName(name)

                return (
                    <DocumentOuter key={index}>
                        <DocumentIcon />
                        <DocumentInner>
                            <DocumentName>{docName}</DocumentName>
                            <DocumentProgress proc={document.progress} />
                            <DocumentSize>
                                {file.size && bytesToSize(file.size)}
                            </DocumentSize>
                        </DocumentInner>
                        <RemoveButtonContainer
                            done={!!document.id}
                            onClick={() => {
                                removeUserDocument(document.id)
                            }}
                        >
                            <CloseIcon />
                        </RemoveButtonContainer>
                    </DocumentOuter>
                )
            })

            const failedDocs = (failedDocuments || []).map((doc, i) => {
                const { name } = doc
                const docName = handleName(name)

                return (
                    <DocumentOuter key={i}>
                        <FailedDocIcon />
                        <DocumentInner>
                            <DocumentName className="document-name">
                                {docName}
                            </DocumentName>
                        </DocumentInner>
                        <RemoveButtonContainer
                            onClick={() => {
                                this.setState(prevState => ({
                                    ...prevState,
                                    componentState: {
                                        ...prevState.componentState,
                                        uploadErrors: [],
                                    },
                                }))
                            }}
                        >
                            <CloseIcon />
                        </RemoveButtonContainer>
                    </DocumentOuter>
                )
            })

            return (
                <DocumentsContainer>
                    <StyledP className="mb-16">{t`v2_common.uploaded_files`}</StyledP>
                    {docs}
                    {failedDocs}
                </DocumentsContainer>
            )
        } else {
            return null
        }
    }

    handleCheckboxChange = () => {
        this.setState({ termsCheck: !this.state.termsCheck })
        this.setState({ shouldValidateTerms: false })
    }

    validateDisable = () => {
        this.setState({ shouldValidateTerms: true })
    }

    render() {
        const { componentState, termsCheck, shouldValidateTerms } = this.state
        const { flightDocs, trans, handleNext, missingDetails } = this.props
        const documentsExist = !!flightDocs.documents.length

        if (!trans) return null

        return (
            <MainContainer>
                <StyledPageContent width={window.innerWidth * 0.8}>
                    <ContentWrapper>
                        <Title>{t`v2_claim.missing_details.title`}</Title>
                    </ContentWrapper>
                    <Row>
                        <Col>
                            <AirlineDescription>
                                {t`flight_documents.required_documents`.replace(
                                    '%AIRLINE%',
                                    missingDetails.airlineTitle
                                )}
                            </AirlineDescription>
                            {missingDetails.missingDocuments.map(docName => (
                                <StyledLi>{docName}</StyledLi>
                            ))}
                        </Col>
                    </Row>
                    <FileDragAndDrop
                        onDrop={this.handleDrop}
                        onDragOver={this.fileOnDragOver}
                        onDragLeave={this.fileOnDragLeave}
                        onDragEnd={this.fileOnDragLeave}
                    >
                        <StyledStepContent2>
                            <Row>
                                <Col
                                    md={documentsExist ? 7 : 12}
                                    lg={documentsExist ? 7 : 12}
                                >
                                    <DragDropContainer
                                        innerRef={this.dragContainer}
                                    >
                                        <FileInputLabel>
                                            <FileInput
                                                type="file"
                                                id="file"
                                                name="fileselect[]"
                                                multiple="multiple"
                                                onChange={this.handleFileChange}
                                                accept="image/*,application/msword,application/vnd.oasis.opendocument.text,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            />
                                            <MobileUploadContainer>
                                                <IconContainer>
                                                    <CameraIcon />
                                                    <MobileIconText>
                                                        {t`v2_claim.flight_documents.take_photo`}
                                                    </MobileIconText>
                                                </IconContainer>
                                                <LineBreak>
                                                    <span />
                                                    <p>{t`v2_common.label.or`}</p>
                                                    <span />
                                                </LineBreak>
                                                <IconContainer>
                                                    <FilesIcon />
                                                    <MobileIconText>
                                                        {t`v2_claim.flight_documents.select_files`}
                                                    </MobileIconText>
                                                </IconContainer>
                                            </MobileUploadContainer>
                                            <FileUploadText
                                                className="inputText"
                                                id="dd-box"
                                            >
                                                <TextContainer>
                                                    {
                                                        <IconDisplay>
                                                            <UploadIcon />
                                                        </IconDisplay>
                                                    }
                                                    <DragFile
                                                        dangerouslySetInnerHTML={{
                                                            __html: t`v2_claim.flight_documents.drag_drop`,
                                                        }}
                                                    />
                                                </TextContainer>
                                            </FileUploadText>
                                        </FileInputLabel>
                                    </DragDropContainer>
                                </Col>
                                {(!!_.get(
                                    componentState,
                                    'uploadErrors.length',
                                    0
                                ) ||
                                    (componentState.canRender &&
                                        documentsExist)) && (
                                    <Col className="pdt-0 pdb-0" md={5} lg={5}>
                                        {this.renderDocumentsList(
                                            flightDocs.documents,
                                            componentState.uploadErrors
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </StyledStepContent2>
                    </FileDragAndDrop>
                    <ContentWrapper>
                        {!missingDetails.isMissingSignature && (
                            <TermsContainer>
                                <TermsAndConditions
                                    text={t`v2_claim.missing_documents.terms_and_conditions`}
                                    onChange={this.handleCheckboxChange}
                                    checked={termsCheck}
                                    alert={!termsCheck && shouldValidateTerms}
                                />
                            </TermsContainer>
                        )}

                        <StyledV2Button
                            disabledClick={this.validateDisable}
                            disabled={
                                !termsCheck ||
                                !documentsExist ||
                                flightDocs.uploading
                            }
                            onClick={() => {
                                handleNext()
                            }}
                        >
                            {t`v2_claim.missing_documents.submit`}
                        </StyledV2Button>
                    </ContentWrapper>
                </StyledPageContent>
            </MainContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        flightDocs: state.v2_user_documents,
        trans: state.translations,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            uploadDocument,
            removeUserDocument,
            addUserDocuments,
            validateStepFive,
            setV2Claim,
            setProgress,
            checkUUID,
            getClaimDraftDocuments,
            setPreloadedClaimStep,
            showModal,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(MissingDocuments)
