import React from 'react'
import styled from 'styled-components'
import { Col as LibCol, Row as LibRow } from 'react-styled-flexboxgrid'
import {
    desktop,
    tablet,
    mobile,
    tabletLG,
    tabletSM,
} from '../helpers/styled_queries'

export const Col = styled(({ ...props }) => (
    <LibCol {...props} className={`item-col ${props.className}`} />
))`
    width: 100%;
    margin: 0;
    padding: 12px 16px;
    flex: 1 1 auto;

    ${desktop`
        padding: 8px 12px;
    `}

    ${tabletLG`
        ${({ skipTabletRestyle }) =>
            skipTabletRestyle ? '' : 'max-width: 66.66666666666667%;'}
    `}

    ${tabletSM`
        ${({ skipTabletRestyle }) =>
            skipTabletRestyle ? '' : 'max-width: 100%;'}
    `}
`

export const Row = styled(LibRow)`
    width: 100%;
    margin: 0;
    padding: 0;

    flex-wrap: nowrap;

    ${mobile`
        flex-direction: column;
    `}

    ${tablet`
        flex-direction: column;
    `}

    ${tabletLG`
        flex-direction: column;
        align-items: center;
    `}

    &.mt-48 {
        margin-top: 48px;
    }

    &.mb-14 {
        margin-bottom: 14px;
    }

    &.mb-16 {
        margin-bottom: 16px;
    }

    &.mb-24 {
        margin-bottom: 24px;
    }

    &.mb-40 {
        margin-bottom: 40px;
    }

    &.mb-61 {
        margin-bottom: 61px;
    }

    &.mb-72 {
        margin-bottom: 72px;
    }

    &.mb-96 {
        margin-bottom: 96px;
    }
`

export const SidebarContainer = styled.div`
    display: none;

    ${desktop`
        padding-top: ${({ paddingTop }) => paddingTop};
        display: flex;
        flex: 0 0 324px;
    `}
`

export const PageViewContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    padding: 0px;

    ${desktop`
        padding: 16px;
        padding-bottom: ${({ paddingBottom }) => paddingBottom || '84px'};
    `} /* 
    .mobile-focused {
        padding-bottom: 300px;
        animation: none;
        transition: none;
    } */
`

export const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1 auto;
    background: #fff;
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;

    ${Row}, ${Col} {
        animation: appear-step 0.2s linear;
        transition: all 0.2s linear;
        @keyframes appear-step {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
    &:before {
        opacity: 0;
        transition: all 13s linear 0s;
    }

    &:after {
        opacity: 1;
        transition: all 13s linear 0s;
    }

    ${desktop`
        display: flex;
        display: -ms-flexbox;
        border-radius: 24px;
        box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);
        padding: ${({ padding }) => padding || '32px 40px;'} 
        border: 1px solid #e0e0e0;
        ${({ paddingTop }) => (paddingTop ? `padding-top: ${paddingTop};` : '')}
        max-width: 984px;
        margin: 0 auto;
    `}
`

export const SocialShareContainer = styled(PageContent)`
    padding: 0px;
    flex-direction: row;
    flex: unset;
    margin: 0;
    align-self: end;
    margin-bottom: 32px;
    overflow: hidden;
    border: unset;
    box-shadow: unset;

    ${desktop`
    margin-right: 24px;
        `}

    ${mobile`
        flex-direction: column;
        margin-bottom: 0px;
            border-top-right-radius: 24px;
            border-top-left-radius: 24px;

    `}

    ${tablet`
        flex-direction: column;
    `}
`

export const TrustpilotContainer = styled(PageContent)`
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px;
    flex-direction: row;
    flex: unset;
    background-color: transparent;
    border: none;
    box-shadow: none;

    ${mobile`
        flex-direction: column;
    `}

    ${tablet`
        flex-direction: column;
    `}
`
export const StepContent = styled.div`
    width: 100%;
    padding: 20px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 0;
    margin: 0;

    ${desktop`
        // margin: 0 0 40px 0;
    `}

    .m-0 {
        margin: 0;
    }

    .pd-0 {
        padding: 0;
    }

    .pdt-0 {
        padding-top: 0;
    }

    .pdb-0 {
        padding-bottom: 0;
    }

    .mb-12 {
        margin-bottom: 12px;
    }

    .mb-24 {
        margin-bottom: 24px;
    }

    .mb-30 {
        margin-bottom: 30px;
    }

    .mb-32 {
        margin-bottom: 32px;
    }

    .form-group {
        margin-bottom: 0;
    }
`

export const ElementTitle = styled.div`
    color: #717171;
    margin-bottom: 16px;
    ${({ noMarginBottom }) =>
        noMarginBottom ? 'margin-bottom: 0px;' : 'margin-bottom: 16px;'}
     margin-bottom: ${({ marginBottom }) => marginBottom};
    font-weight: 600;
    font-family: 'Noto Sans';
    font-size: 16px;
    line-height: 22.4px;
    text-align: left;

    ${desktop`
        ${({ noWrap }) => noWrap && 'white-space: nowrap;'}
    `}

    span {
        color: #FE6050;
    }
`

const InfoContainer = styled.div`
    h1 {
        padding: 0 8px;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;

        ${desktop`
            margin-bottom: 16px;
            padding: 8px 12px;
            text-align: left;
        `}

        ${tablet`
            font-size: 24px;
        `}

        ${mobile`
            font-size: 22px;
        `}
    }
    
    &.pdt-40 {
        padding-top: 40px;
    }

    .form-group {
        margin-bottom: 0 !important;
    }
`

const CheckMarkContainer = styled.div`
    display: flex;

    .checkmark-container {
        width: 22px;
        min-width: 22px;
        display: flex;
        justify-content: center;
        overflow: hidden;

        .checkmark {
            transform: rotate(45deg);
            height: ${({ height }) => height || '16px'};
            width: ${({ width }) => width || '8px'};
            border-bottom: ${({ borderWidth }) => borderWidth || '2px'} solid
                ${({ borderColor, theme }) =>
                    borderColor || theme.colors.primaryColor};
            border-right: ${({ borderWidth, theme }) => borderWidth || '2px'}
                solid
                ${({ borderColor, theme }) =>
                    borderColor || theme.colors.primaryColor};
        }
    }

    .label {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #353d51;
        padding-left: 16px;
    }
`

export const RowTitle = ({ title, required }) => (
    <ElementTitle style={{ marginBottom: 0 }}>
        {title}
        {required && <span> *</span>}
    </ElementTitle>
)

export const ContentBox = styled.div`
    border-radius: 8px;
    box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);
    background-color: #ffffff;

    ${tablet`
        box-shadow: none;
    `}

    ${mobile`
        box-shadow: none;
    `}
`
export const InfoBlock = ({ title, children, ...props }) => {
    return (
        <InfoContainer {...props}>
            <h1>{title}</h1>
            {children}
        </InfoContainer>
    )
}

export const CheckMarkBox = ({
    borderWidth,
    height,
    width,
    borderColor,
    label,
}) => {
    return (
        <CheckMarkContainer
            borderWidth={borderWidth}
            height={height}
            width={width}
            borderColor={borderColor}
        >
            <div className="checkmark-container">
                <div className="checkmark"></div>
            </div>
            <p className="label">{label}</p>
        </CheckMarkContainer>
    )
}
