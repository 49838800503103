import styled from 'styled-components'
import { PageContent, Col } from '../../../shared/components/V2/Layout'
import {
    desktop,
    tablet,
    mobile,
} from '../../../shared/components//helpers/styled_queries'
import { V2Button } from '../../../shared/components/V2/V2Buttons'
import { PageTitle } from '../../../shared/components/V2/TextElements'

export const H2 = styled.h2`
    color: #333;
    font-family: Open Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
`

export const Header = styled(PageTitle)`
    #page-view-title {
        padding: 0px 12px 10px;
    }
`
export const Subtitle = styled.p`
    color: #353d51;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;

    span {
        color: ${({ theme }) => theme.colors.primaryColor};
        font-size: 14px;
        line-height: 23.94px;
        text-decoration-line: underline;
    }

    ${tablet`
       text-align: center;
    `}

    ${mobile`
       text-align: center;
    `}
`
export const BodyText = styled.p`
    color: #7f8fa4;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.94px;

    span {
        color: ${({ theme }) => theme.colors.primaryColor};
        font-size: 14px;
        line-height: 23.94px;
        text-decoration-line: underline;
        cursor: pointer;
    }
`

export const Button = styled(V2Button)`
    /* border-radius: 24px;
    background: ${({ theme }) => theme.colors.primaryColor};

    ${({ disabled }) =>
        disabled
            ? 'background-color: #f3f5f7;'
            : `background-color: ${({ theme }) => theme.colors.primaryColor};
           `} */
`

export const StyledPageContent = styled(PageContent)`
    background: no-repeat url('/images/planned-trips.png');
    background-size: 525px;
    background-position: top 20px right 70px;
    background-color: #ffffff;

    ${desktop`
        height: fit-content;
        padding: 16px;
        padding-left: 28px;
        padding-top: 22px;
        max-width: 1190px;
    `}

    ${tablet`
        background-position: top 150px right 10%;
        background-color: #ffffff;
    `}
    
    ${mobile`
        background: no-repeat url('/images/planned-trip-mobile.svg');
        background-position: top 150px right 50%;
        background-color: #ffffff;
    `}

    padding-bottom: 130px;

    .form-group {
        margin-bottom: 0;
    }

    .submit-button {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
            display: flex;
        }
    }
`
export const FormContainer = styled(Col)`
    border-radius: 20px;
    border: 1px solid #cbd3df;
    background: #fff;
    max-width: 585px;
    padding: 22px 28px;

    ${mobile`
        margin-top: 150px;
        padding: 0;
    `}

    & .button-container {
        margin-top: 14px;
        flex: 0;
    }
`

export const InnerInfo = styled.div`
    /* position: absolute; */
    text-align: left;
    right: 0;
    min-height: fit-content;
    color: #495363;
    background-color: #fff;
    display: flex;
    padding: 12px;
    transition: all 1.5s ease;
    font-weight: normal;
    animation: appearAll;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    transition: opacity 211ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 141ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0 3px 9px -5px rgba(53, 61, 81, 0.23);
    box-shadow: 0 4px 9px 1px rgba(53, 61, 81, 0.23);
    border-radius: 4px;
    border: 0 none;
    position: absolute;
    z-index: 550;
    font-weight: normal;
    line-height: 1.69;
    min-width: 300px;
    max-width: 350px;
    width: unset;

    p {
        margin: 0;
    }

    .svg-close {
        display: none;
    }

    a {
        font-weight: bold;
    }

    ${desktop`
        padding: 16px;
        right: 0;
       
    `}
`

export const TooltipInner = styled.div`
    p {
        color: #495363;
        font-family: Open Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.5px; /* 150% */
        margin: 0;
        text-align: left;
    }

    .svg-close {
        display: none;
    }

    a {
        font-weight: bold;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.primaryColor};
        transition: none;
    }
`
export const InfoBoxTooltip = styled(InnerInfo)`
    display: flex;
    animation: appearAllDesktop;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    transition: opacity 211ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 141ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0 3px 9px -5px rgba(53, 61, 81, 0.23);
    box-shadow: 0 4px 9px 1px rgba(53, 61, 81, 0.23);
    border: 0 none;
    position: absolute;
    border-radius: 4px;
    background-color: #fff;
    top: 17px;
    padding: 16px;
    right: 0;
    z-index: 550;
    font-weight: normal;
    line-height: 1.69;
    min-width: 300px;
    max-width: 350px;
    width: unset;

    p {
        margin: 0;
    }

    .svg-close {
        display: none;
    }

    a {
        font-weight: bold;
    }
`
