import React, { Component } from 'react'
import styled from 'styled-components'
import { Row, Col } from '../../../../shared/components/V2/Layout'
import { claimStatus } from '../../../../shared/constants'
import { StatusBox } from '../../components/ClaimItemCard'
import t from '../../../../shared/translations'
import { PriceListLink } from '../../../../shared/components/V2/V2PayoutPlans'
import { InfoIcon } from '../../../../shared/components/V2/V2Icons'
import ToolTip from '../../../../shared/components/V2/V2Tooltip'

const Container = styled.div`
    padding: 12px 12px 24px 12px;
`

const H1 = styled.h1`
    margin: 20px 0 24px;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #353d51;
`

export const InfoCol = styled(Col)`
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #353d51;

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }

    &.bold {
        font-weight: 600;
    }

    &.grey {
        color: #76869c;
    }

    .sidebar-tooltip {
        text-align: left;
        font-size: 11px;
        max-width: 240px;
    }
`

class ClaimSummary extends Component {
    handleCanPercentage = noAmount => {
        const { isOldPayoutTitle } = this.props
        const trans = t`v2.price_list_amount_description_canada.renumeration`

        if (noAmount && isOldPayoutTitle) {
            return trans
                .replace('25', '30')
                .replace('36.3', '30')
                .replace('36,3', '30')
        } else {
            return trans
        }
    }

    claimSummaryRenderer = () => {
        const {
            status,
            transactionStatus,
            payoutConfig,
            amount,
            payableAmount,
            isFastPayout = false,
            isFastPayoutDescription = false,
            isLegalPayout = false,
            regulation,
            primaryColor,
        } = this.props

        const transactionDescription = payoutConfig
            ? t`${transactionStatus}_description${
                  isFastPayoutDescription ? '.fast_payout' : ''
              }`.replace('%period%', payoutConfig)
            : t`${transactionStatus}_description`
        const description = t`${status}_description`
        const statusClaim = transactionStatus || status
        const fee = parseFloat(amount - payableAmount).toFixed(2)
        const noAmount =
            (amount === 0 || amount === '0') && regulation === 'reg.canada'

        return (
            <Container>
                <Row>
                    <Col>
                        <H1>{t`inner_claim.summary`}</H1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StatusBox
                            className={`box-status--${
                                (
                                    claimStatus({ primaryColor })[
                                        statusClaim
                                    ] || claimStatus.default
                                ).statusColor
                            }`}
                        >
                            <div className="icon">
                                {(
                                    claimStatus({ primaryColor })[
                                        statusClaim
                                    ] || claimStatus.default
                                ).icon()}
                            </div>
                            <p className="title">{t`${statusClaim}`}</p>
                            <p>
                                {transactionStatus
                                    ? transactionDescription
                                    : description}
                            </p>
                        </StatusBox>
                    </Col>
                </Row>
                {!noAmount ? (
                    <React.Fragment>
                        <Row>
                            <InfoCol sm={7} md={7} lg={7} className="left bold">
                                {t`inner_claim.your_compensation`}
                            </InfoCol>
                            <InfoCol
                                sm={5}
                                md={5}
                                lg={5}
                                className="right bold"
                            >
                                €{parseFloat(amount).toFixed(2)}
                            </InfoCol>
                        </Row>
                        <Row>
                            <InfoCol sm={8} md={8} lg={8} className="left grey">
                                {t`v2.payout_rework.skycop_renumeration${
                                    !isFastPayout && !isLegalPayout
                                        ? '.VAT'
                                        : ''
                                }`}
                            </InfoCol>
                            <InfoCol
                                sm={4}
                                md={4}
                                lg={4}
                                className="right grey"
                            >
                                -€{fee}
                            </InfoCol>
                        </Row>
                        <Row>
                            <InfoCol sm={7} md={7} lg={7} className="left grey">
                                {t`inner_claim.flight_compensation`}
                            </InfoCol>
                            <InfoCol
                                sm={5}
                                md={5}
                                lg={5}
                                className="right grey"
                            >
                                €{parseFloat(payableAmount).toFixed(2)}
                            </InfoCol>
                        </Row>
                        {!isFastPayout && (
                            <PriceListLink
                                dangerouslySetInnerHTML={{
                                    __html: t`v2.price_list.see_full_info`,
                                }}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <Row>
                        <InfoCol sm={7} md={7} lg={7} className="left bold">
                            {t`inner_claim.your_compensation`}
                        </InfoCol>
                        <InfoCol sm={5} md={5} lg={5} className="right bold">
                            <span
                                data-tip="disabled"
                                data-for="compensation"
                                id="compensation"
                            >
                                <InfoIcon />
                            </span>
                            <ToolTip
                                dataTip="disabled"
                                dataFor="compensation"
                                place="left"
                                force="left"
                                id="compensation"
                                type="info"
                                className="sidebar-tooltip"
                            >
                                {t`v2.price_list_amount_description_canada.final_amount`}
                                &#32;{this.handleCanPercentage(noAmount)}
                            </ToolTip>
                        </InfoCol>
                    </Row>
                )}
            </Container>
        )
    }

    render() {
        return this.claimSummaryRenderer()
    }
}

export default ClaimSummary
