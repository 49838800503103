import V2_STEP_COMPONENTS from '../views/V2/steps'
import {
    RejectedStatus,
    ValidatingStatus,
    LegalStatus,
    PostTrialStatus,
    CaaAdrStatus,
    TransferStatus,
    DeptorStatus,
    WaitingForReviewStatus,
    CompletedStatus,
    ApprovedStatus,
} from './components/V2/StatusIcons'
import {
    RejectedTimelineStatus,
    ValidatingTimelineStatus,
    LegalTimelineStatus,
    PostTrialTimelineStatus,
    CaaAdrTimelineStatus,
    TransferTimelineStatus,
    DeptorTimelineStatus,
    WaitingForReviewTimelineStatus,
    CompletedTimelineStatus,
    ApprovedTimelineStatus,
} from './components/V2/TimelineStatusIcons'
export const V2_prePath = '/'
export const V2_SMART_DELAY_PRE_PATH = '/smart-delay'

export const additionalPaxGuardianOptions = t => [
    {
        id: 'father',
        label: t`v2_common_label_father_guardian`,
        name: 'legalGuardian',
        value: 'father',
    },
    {
        id: 'mother',
        label: t`v2_common_label_mother_guardian`,
        name: 'legalGuardian',
        value: 'mother',
    },
    {
        id: 'other',
        label: t`v2_common_label_other_guardian`,
        name: 'legalGuardian',
        value: 'other',
    },
]

export const adultAge = 18

export const serviceFeePercentage = 1.21

export const inactiveTimeoutAwaitTime = 20000

export const BASE_FEE_TRANS = '30'

export const reasonCoefficients = {
    rai: 0.26,
    rte: 0.45,
    rwi: 0.4,
    rbw: 0.1,
    bno: 0.28,
    rst: 0.26,
    rof: 0.33,
    rdr: 0.31,
    yes: 1,
}

export const draftLogActions = {
    SAVE: 'SAVE',
    CLOSE: 'CLOSE',
    SHOW: 'SHOW',
    POST: 'POST',
}

export const draftLogActionReasons = {
    SUBMIT: 'SUBMIT',
    IDLE: 'IDLE',
    CLOSE: 'CLOSE',
    MANUAL: 'MANUAL',
}

export const configTypes = {
    string: 'string',
    boolean: 'boolean',
}

export const featureToggles = {
    emailMagic: 'feature_emailMagic',
    boeingNotify: 'feature_boeingNotify',
    disableTransFallback: 'disable_translations_fallback',
    saveDraftFeature: 'feature_saveDraft',
    deprecatedKeysFeature: 'feature_deprecatedKeys',
    feedbackScreens: 'feature_feedbackScreens',
    featureCurrencyRestriction: 'feature_curr_restrict',
    featureOnClose: 'feature_onClose',
    featureEmailPartial: 'feature_emailPartial',
    autoSaveDraft: 'feature_autoSaveDraft',
    inactiveTimeout: 'feature_inactiveTimeout',
    extraordinary_alert: 'feature_extraordinary_alert',
    sc_menu_item: 'feature_sc_menu_item',
    trastpilot_widget: 'trustpilot_widget',
    reject_promo_codes: 'reject_promo_codes',
    leave_flight_itinerary_codes: 'leave_flight_itinerary_codes',
    skycopCarePromo: 'feature_skyco_care_promotion',
    claimCompletePromoCodes: 'claim_complete_promo_codes',
    featureFastPayout: 'feature_fast_payout',
    sc_family_edit: 'feature_sc_family_edit',
    featureIsPhoneVerificationEnabled: 'feature_is_phone_verification_enabled',
    about_us_survey_enabled: 'about_us_survey_enabled',
    featureIsWhatsAppBoxVisible: 'feature_is_whats_app_box_visible',
    isSwiftValidationEnabled: 'feature_swift_validation',
    featureIsBankPrefillEnabled: 'feature_is_bank_prefill_enabled'
}

export const actionTitles = {
    GET_CONFIG: 'getConfig',
}

export const cpActions = [actionTitles.GET_CONFIG]

export const oopsUrl = 'https://oops.skycop.com'

export const paths = {
    PAYMENT: '/payment',
    SORRY: '/sorry',
    ERROR: '/error',
    DUPLICATE: '/duplicate',
    PASSENGER_SIGNATURE: '/passenger-signature',
    PASSENGER_SIGNATURE_COMPLETED: '/passenger-signature-completed',
    PASSENGER_LEGAL_SIGNATURE: '/passenger-legal-signature',
    SIGNATURE: '/signature',
    SIGNATURE_COMPLETED: 'signature-completed',
    LEGAL_SIGNATURE: 'legal-signature',
    AGREEMENT_SIGNED: '/agreement-signed',
    MARKETING_UNSUBSCRIBE: '/marketing-unsubscribe',
    CLAIM: '/claim',
    CLAIMS: '/claims',
    PROFILE: '/profile',
    CHANGE_PASSWORD: '/change-password',
    CHANGE_PASSWORD_SUCCESS: '/change-password-success',
    NEW_PASSWORD: '/new-password',
    NEW_PASSWORD_SUCCESS: '/new-password-success',
    EMAIL_PREFERENCES: '/email-preferences',
    BRANDED: '/branded',
    SUBSCRIPTIONS: '/subscriptions',
    PLANNED_TRIPS: '/planned-trips',
    MISSING_DETAILS: `${V2_prePath}missing-details`,
    ADD_REVIEW: `${V2_prePath}add-review`,
    V2: {
        STEP_1_FLIGHT_ITINERARY: `${V2_prePath}`,
        STEP_2_DISRUPTION_DETAILS: `${V2_prePath}disruption-details`,
        STEP_3_DISRUPTION_REASON: `${V2_prePath}disruption-reason`,
        STEP_4_EMAIL_DETAILS: `${V2_prePath}flight-details/(:email)`,
        STEP_4_FLIGHT_DETAILS: `${V2_prePath}flight-details`,
        STEP_5_PASSENGER_DETAILS: `${V2_prePath}passenger-details`,
        STEP_6_CLAIM_SIGNATURE: `${V2_prePath}claim-signature`,
        STEP_7_FLIGHT_DOCUMENTS: `${V2_prePath}flight-documents`,
        STEP_8_THANK_YOU: `${V2_prePath}thanks`,
        SIGN_IN: `${V2_prePath}sign-in`,
        DUPLICATE_PAGE: `${V2_prePath}duplicate-page`,
        MY_PROFILE: `${V2_prePath}profile`,
        SC_SUBSCRIPTIONS: `${V2_prePath}subscriptions`,
        SC_SUBSCRIPTIONS_HISTORY: `${V2_prePath}subscriptions/history/:id`,
        SC_CANCEL_SUBSCRIPTIONS: `${V2_prePath}subscriptions/cancel/:id`,
        PAYMENT: `${V2_prePath}payment`,
        LOGIN: `${V2_prePath}login`,
        REGISTER: `${V2_prePath}register`,
        DRAFT_EXPIRED: `${V2_prePath}draft-expired`,
        MY_CLAIMS: `${V2_prePath}claims`,
        CLAIM_INNER: `${V2_prePath}claim`,
        NOT_SUPPORTED_AIRLINES: `${V2_prePath}not-suported-airlines`,
        REMARKETING_UNSUBSCRIBE: `${V2_prePath}unsubscribe`,
    },
    V2_SMART_DELAY: {
        STEP_SMART_DELAY: `${V2_SMART_DELAY_PRE_PATH}/how-it-works`,
        STEP_1_FLIGHT_DETAILS: `${V2_SMART_DELAY_PRE_PATH}/flight-details`,
        STEP_2_PASSENGER_DETAILS: `${V2_SMART_DELAY_PRE_PATH}/passenger-details`,
        STEP_3_CONFIRMATION_PAGE: `${V2_SMART_DELAY_PRE_PATH}/confirmation`,
        STEP_4_REGISTRATION_COMPLETED: `${V2_SMART_DELAY_PRE_PATH}/registration-completed`,
    },
}

const SMART_DELAY = {
    SMART_DELAY: {
        path: paths.V2_SMART_DELAY.STEP_SMART_DELAY,
        component: V2_STEP_COMPONENTS.SMART_DELAY_PAGE,
    },
    STEP_1_SMART_DELAY: {
        path: paths.V2_SMART_DELAY.STEP_1_FLIGHT_DETAILS,
        component: V2_STEP_COMPONENTS.STEP_1_FLIGHT_DETAILS,
    },
    STEP_2_SMART_DELAY: {
        path: paths.V2_SMART_DELAY.STEP_2_PASSENGER_DETAILS,
        component: V2_STEP_COMPONENTS.STEP_2_PASSENGER_DETAILS,
    },
    STEP_3_SMART_DELAY: {
        path: paths.V2_SMART_DELAY.STEP_3_CONFIRMATION_PAGE,
        component: V2_STEP_COMPONENTS.STEP_3_CONFIRMATION_PAGE,
    },
    STEP_4_SMART_DELAY: {
        path: paths.V2_SMART_DELAY.STEP_4_REGISTRATION_COMPLETED,
        component: V2_STEP_COMPONENTS.STEP_4_REGISTRATION_COMPLETED,
    },
}

export const V2_CORE = {
    V2_prePath,
    STEP_1: {
        path: paths.V2.STEP_1_FLIGHT_ITINERARY,
        component: V2_STEP_COMPONENTS.STEP_1_FlightItinerary,
    },
    STEP_2: {
        path: paths.V2.STEP_2_DISRUPTION_DETAILS,
        component: V2_STEP_COMPONENTS.STEP_2_DisruptionDetails,
    },
    STEP_3: {
        path: paths.V2.STEP_3_DISRUPTION_REASON,
        component: V2_STEP_COMPONENTS.STEP_3_DisurptionReason,
    },
    STEP_4_Email: {
        path: paths.V2.STEP_4_EMAIL_DETAILS,
        component: V2_STEP_COMPONENTS.STEP_4_EmailDetails,
    },
    STEP_4: {
        path: paths.V2.STEP_4_FLIGHT_DETAILS,
        component: V2_STEP_COMPONENTS.STEP_4_FlightDetails,
    },
    STEP_5: {
        path: paths.V2.STEP_5_PASSENGER_DETAILS,
        component: V2_STEP_COMPONENTS.STEP_5_PassengerDetails,
    },
    STEP_6: {
        path: paths.V2.STEP_6_CLAIM_SIGNATURE,
        component: V2_STEP_COMPONENTS.STEP_6_ClaimSignature,
    },
    STEP_7: {
        path: paths.V2.STEP_7_FLIGHT_DOCUMENTS,
        component: V2_STEP_COMPONENTS.STEP_7_FlightDocuments,
    },
    STEP_8: {
        path: paths.V2.STEP_8_THANK_YOU,
        component: V2_STEP_COMPONENTS.STEP_8_ThankYou,
    },
    DUPLICATE_PAGE: {
        path: paths.V2.DUPLICATE_PAGE,
        component: V2_STEP_COMPONENTS.DUPLICATE_PAGE,
    },
    MY_PROFILE: {
        path: paths.V2.MY_PROFILE,
        component: V2_STEP_COMPONENTS.MY_PROFILE,
    },
    SC_SUBSCRIPTIONS: {
        path: paths.V2.SC_SUBSCRIPTIONS,
        component: V2_STEP_COMPONENTS.SC_SUBSCRIPTIONS,
    },
    SC_SUBSCRIPTIONS_HISTORY: {
        path: paths.V2.SC_SUBSCRIPTIONS_HISTORY,
        component: V2_STEP_COMPONENTS.SC_SUBSCRIPTIONS_HISTORY,
    },
    SC_CANCEL_SUBSCRIPTIONS: {
        path: paths.V2.SC_CANCEL_SUBSCRIPTIONS,
        component: V2_STEP_COMPONENTS.SC_CANCEL_SUBSCRIPTIONS,
    },
    PAYMENT: {
        path: paths.V2.PAYMENT,
        component: V2_STEP_COMPONENTS.PAYMENT,
    },
    IMPROVE_PAGE: {
        path: paths.V2.IMPROVE_PAGE,
        component: V2_STEP_COMPONENTS.IMPROVE_PAGE,
    },
    SOCIAL_PAGE: {
        path: paths.V2.SOCIAL_PAGE,
        component: V2_STEP_COMPONENTS.SOCIAL_PAGE,
    },
    SHARE_KNOWLEDGE_PAGE: {
        path: paths.V2.SHARE_KNOWLEDGE_PAGE,
        component: V2_STEP_COMPONENTS.SHARE_KNOWLEDGE_PAGE,
    },
    LOGIN: {
        path: paths.V2.LOGIN,
        component: V2_STEP_COMPONENTS.LOGIN,
    },
    REGISTER: {
        path: paths.V2.REGISTER,
        component: V2_STEP_COMPONENTS.REGISTER,
    },
    DRAFT_EXPIRED: {
        path: paths.V2.DRAFT_EXPIRED,
        component: V2_STEP_COMPONENTS.DRAFT_EXPIRED,
    },
    MY_CLAIMS: {
        path: paths.V2.MY_CLAIMS,
        component: V2_STEP_COMPONENTS.MY_CLAIMS,
    },
    CLAIM_INNER: {
        path: paths.V2.CLAIM_INNER,
        component: V2_STEP_COMPONENTS.CLAIM_INNER,
    },
    NOT_SUPPORTED_AIRLINES: {
        path: paths.V2.NOT_SUPPORTED_AIRLINES,
        component: V2_STEP_COMPONENTS.NOT_SUPPORTED_AIRLINES,
    },
    REMARKETING_UNSUBSCRIBE: {
        path: paths.V2.REMARKETING_UNSUBSCRIBE,
        component: V2_STEP_COMPONENTS.REMARKETING_UNSUBSCRIBE,
    },
    ...SMART_DELAY,
}

export const V2_STEPS = {
    STEP_1: 'FLIGHT_ITINERARY',
    STEP_2: 'DISRUPTION_DETAILS',
    STEP_3: 'DISRUPTION_REASON',
    STEP_4: 'FLIGHT_DETAILS',
    STEP_5: 'FLIGHT_DOCUMENTS',
    STEP_6: 'PASSENGER_DETAILS',
    STEP_7: 'CLAIM_SIGNATURE',
    STEP_8: 'THANK_YOU',
}

export const V2_BREAKPOINTS = {
    XSM: 320,
    SM: 480,
    MD: 720,
    LG: 960,
    XL: 1200,
    XXL: 1440,
}

export const modalTypes = {
    loginModal: 'login',
    forgotPasswordModal: 'forgotPassword',
    onboardingModal: 'onboardingModal',
    paymentPlanModal: 'paymentPlan',
    sorryModal: 'sorryPopup',
    leavingModal: 'leavingModal',
    payoutDetailsMobileModal: 'payoutDetails',
    infoMobileModal: 'infoMobile',
    claimDraftModal: 'draft',
    socialShareModal: 'socialShareModal',
    ratingModal: 'ratingModal',
    flightSuggestModal: 'flightSuggestModal',
    flightSuggestModalCodeshares: 'flightSuggestModalCodeshares',
    stripePassengerModal: 'stripePassengerModal',
    stripeFailedModal: 'stripeFailedModal',
    skycopPayModal: 'skycopPayModal',
    skycopCareModal: 'skycopCareModel',
    uploadPlannedTripModal: 'uploadPlannedTripModal',
    promoGiftModal: 'promoGiftModal',
    smartDelayCancelRegistration: 'smartDelayCancelRegistration',
    smartDelayCancelListRegistration: 'smartDelayCancelListRegistration',
    congratsSurveyModal: 'congratsSurveyModal',
    payoutOfferSubmitModal: 'payoutOfferSubmitModal',
    payoutOfferInfoModal: 'payoutOfferInfoModal',
}

export const claimStatus = ({ primaryColor }) => ({
    wait_for_review: {
        icon: WaitingForReviewStatus,
        timeLineIcon: WaitingForReviewTimelineStatus,
        statusColor: 'yellow',
        color: '#FFB620',
    },
    reject: {
        icon: RejectedStatus,
        timeLineIcon: RejectedTimelineStatus,
        statusColor: 'red',
        color: '#F16964',
    },
    claim_rejected: {
        icon: RejectedStatus,
        timeLineIcon: RejectedTimelineStatus,
        statusColor: 'red',
        color: '#F16964',
    },
    claim_approved: {
        icon: ApprovedStatus,
        timeLineIcon: ApprovedTimelineStatus,
        statusColor: 'green',
        color: primaryColor,
    },
    claim_completed: {
        icon: CompletedStatus,
        timeLineIcon: CompletedTimelineStatus({ color: primaryColor }),
        statusColor: 'green',
        color: primaryColor,
    },
    paid: {
        icon: CompletedStatus,
        timeLineIcon: CompletedTimelineStatus({ color: primaryColor }),
        statusColor: 'green',
        color: primaryColor,
    },
    trans_paid: {
        icon: CompletedStatus,
        timeLineIcon: CompletedTimelineStatus({ color: primaryColor }),
        statusColor: 'green',
        color: primaryColor,
    },
    caa: {
        icon: CaaAdrStatus,
        timeLineIcon: CaaAdrTimelineStatus,
        statusColor: 'teal',
        color: '#2852AD',
    },
    legal: {
        icon: LegalStatus,
        timeLineIcon: LegalTimelineStatus,
        statusColor: 'teal',
        color: '#2852AD',
    },
    post_trial: {
        icon: PostTrialStatus,
        timeLineIcon: PostTrialTimelineStatus,
        statusColor: 'teal',
        color: '#2852AD',
    },
    transferred: {
        icon: TransferStatus,
        timeLineIcon: TransferTimelineStatus,
        statusColor: 'green',
        color: primaryColor,
    },
    transfer_pending: {
        icon: ValidatingStatus,
        timeLineIcon: ValidatingTimelineStatus,
        statusColor: 'yellow',
        color: '#FFB620',
    },
    wait_for_bank_details: {
        icon: ValidatingStatus,
        timeLineIcon: ValidatingTimelineStatus,
        statusColor: 'yellow',
        color: '#FFB620',
    },
    transfer_processing: {
        icon: '',
        timeLineIcon: '',
        statusColor: 'yellow',
        color: '#FFB620',
    },
    transfer_rejected: {
        icon: '',
        timeLineIcon: '',
        statusColor: 'red',
        color: '#F16964',
    },
    transfer_completed: {
        icon: TransferStatus,
        timeLineIcon: TransferTimelineStatus,
        statusColor: 'green',
        color: primaryColor,
    },
    trans_ready_to_pay: {
        icon: ValidatingStatus,
        timeLineIcon: ValidatingTimelineStatus,
        statusColor: 'yellow',
        color: '#FFB620',
    },
    client_payment_pending: {
        icon: ValidatingStatus,
        timeLineIcon: ValidatingTimelineStatus,
        statusColor: 'yellow',
        color: '#FFB620',
    },
    debtor: {
        icon: DeptorStatus,
        timeLineIcon: DeptorTimelineStatus,
        statusColor: 'red',
        color: '#F16964',
    },
    default: {
        icon: WaitingForReviewStatus,
        timeLineIcon: WaitingForReviewTimelineStatus,
        statusColor: 'yellow',
        color: '#FFB620',
    },
})

export const CLAIM_FORM_VERSIONS = {
    V0: 'v0',
    V1: 'v1',
}
