import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import t from '../../translations'
import { CashIcon, RabbitLargeIcon, InfoIcon, QuestionMark } from './V2Icons'
import { Col } from './Layout'
import {
    StopWatch,
    AbsoluteCorner,
} from '../../../views/V2/components/InfoSidebar'
import Tooltip from './V2Tooltip'

const StyledTooltip = styled(Tooltip)`
    max-width: 200px;
`

const Icon = styled.div`
    margin-bottom: 16px;
`

const DetailsTextBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    &.mb-16 {
        margin-bottom: 16px;
    }
`

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const Container = styled.div`
    padding: 24px;
    min-height: 431px;

    ${({ noBackground }) =>
        noBackground &&
        `
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);
    `}
`

const HeaderContainer = styled.div`
    h3 {
        font-family: 'Open Sans', sans-serif;
        font-size: 23px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.39;
        letter-spacing: normal;
        text-align: center;
        color: ${({ theme }) => theme.colors.primaryColor};
        margin-top: 16px;
        margin-bottom: 24px;
    }
`

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 20px 24px 24px 24px;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    border: solid 1px ${({ theme }) => theme.colors.primaryColor};
    background-color: #ffffff;
    margin-bottom: 24px;
    ${({ isFastSelected, theme }) =>
        isFastSelected &&
        `
        background-color: ${theme.colors.primaryColor};
        border: 0 none;
    `}
`

export const DetailsText = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #76869c;
    margin: 0;

    span {
        font-size: 16px;
        font-weight: bold;
        color: #353d51;
    }

    &.dark-text {
        margin-top: 8px;
        line-height: 1.14;
        color: #353d51;
    }

    &.left-side {
        display: flex;
        align-items: center;
        text-align: left;
        flex: 1 1 auto;

        .tooltip-span {
            display: flex;
            padding-left: 8px;
        }
    }

    &.right-side {
        text-align: right;
        flex: 1 1 100px;
    }

    ${({ isFastSelected }) =>
        isFastSelected &&
        `
        color: #fff;

        span {
            color: #fff;
        }

        &.dark-text {
            color: #fff;
        }
    `}
`

export const AmountText = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #353d51;
    margin: 0;
    margin-top: 16px;

    ${({ isFastSelected }) =>
        isFastSelected &&
        `
        color: #fff;
    `}
`

const IconContainer = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 8px;
`

const InfoBox = styled.div`
    margin-top: 16px;
    padding: 12px 14px 12px 12px;
    border-radius: 8px;
    backdrop-filter: blur(17px);
    background-color: rgba(82, 150, 255, 0.08);
    display: flex;

    ${Col} {
        flex: 0 0 auto;
    }

    p {
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: normal;
        color: #7f8fa4;
        margin: 0;
        text-align: left;

        span {
            font-weight: bold;
            color: #353d51;
        }
    }
`

class FlightPayoutDetailsBox extends React.Component {
    render() {
        const {
            details,
            noBackground,
            isFastSelected,
            primaryColor,
        } = this.props
        const { amount, serviceFee, payout } = details

        if (amount && serviceFee && payout) {
            return (
                <React.Fragment>
                    <Container noBackground={!!noBackground}>
                        <HeaderContainer>
                            <h3>{t`v2_claim.payout_sidebar.title`}</h3>
                        </HeaderContainer>
                        <DetailsContainer isFastSelected={!!isFastSelected}>
                            {isFastSelected && (
                                <React.Fragment>
                                    <AbsoluteCorner />
                                    <StopWatch color={primaryColor} />
                                </React.Fragment>
                            )}
                            <Icon>
                                {isFastSelected ? (
                                    <RabbitLargeIcon />
                                ) : (
                                    <CashIcon color={primaryColor} />
                                )}
                            </Icon>
                            <AmountText
                                isFastSelected={!!isFastSelected}
                            >{`€${payout}`}</AmountText>
                            <DetailsText
                                className="dark-text"
                                isFastSelected={!!isFastSelected}
                                dangerouslySetInnerHTML={{
                                    __html: t`v2_claim.sidebar.payout_in${
                                        isFastSelected ? '.fast_payout' : ''
                                    }`,
                                }}
                            />
                        </DetailsContainer>
                        <TextBox>
                            <DetailsTextBox className="mb-16">
                                <DetailsText className="left-side">{t`v2_claim.payout_sidebar.compensation`}</DetailsText>
                                <DetailsText className="right-side">{`€${amount}`}</DetailsText>
                            </DetailsTextBox>
                            <DetailsTextBox className="mb-16">
                                <DetailsText className="left-side">
                                    {t`v2_claim.payout_sidebar.service_fee${
                                        isFastSelected ? '.fast_payout' : ''
                                    }`}
                                    {!isFastSelected && (
                                        <span
                                            className="tooltip-span"
                                            data-tip="disabled"
                                            data-for="serviceFee"
                                        >
                                            <QuestionMark />
                                        </span>
                                    )}
                                </DetailsText>
                                <DetailsText className="right-side">{`-€${serviceFee}`}</DetailsText>
                            </DetailsTextBox>
                            <DetailsTextBox>
                                <DetailsText className="left-side">
                                    {t`v2_claim.payout_sidebar.total`}
                                </DetailsText>
                                <DetailsText className="right-side">
                                    <span>{`€${payout}`}</span>
                                </DetailsText>
                            </DetailsTextBox>
                        </TextBox>
                        <InfoBox>
                            <IconContainer>
                                <InfoIcon />
                            </IconContainer>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: t`v2_claim.payout_sidebar.subtitle`,
                                }}
                            />
                        </InfoBox>
                    </Container>
                    {!isFastSelected && (
                        <StyledTooltip
                            place="top"
                            id="serviceFee"
                            type="info"
                            effect="float"
                        >
                            <span>
                                {t`v2_claim.payout_sidebar.service_fee.tooltip`}
                            </span>
                        </StyledTooltip>
                    )}
                </React.Fragment>
            )
        } else {
            return null
        }
    }
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        trans: state.translations,
        details: state.v2_payout_details,
        isFastSelected: state.isFastSelected,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FlightPayoutDetailsBox)
