import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { browserHistory } from 'react-router'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'
import { Helmet } from 'react-helmet'
import { V2Button } from '../../../../shared/components/V2/V2Buttons'
import { paths } from '../../../..//shared/constants.js'
import { scrollComponentToView } from '../../../../main'

import { StyledSmartDelayContainer, TitleContainer } from './SmartDelayPage'
import t from '../../../../shared/translations'
import { showModal } from '../../../../shared/actions/v2_actions'
import { PageViewContainer } from '../../../../shared/components/V2/Layout'
import { applyTranslationElement } from '../../../../main'
import {
    desktop,
    mobile,
    tablet,
} from '../../../../shared/components/helpers/styled_queries'
import CancelModal from './CancelModal'
import { modalTypes } from '../../../../shared/constants'
import SmartDelayStepper from './SmartDelayStepper'
import {
    setSmartDelayFlightDetails,
    setSmartDelayPassengerDetails,
    setSmartDelayFlightDetailsRegisteredFlightIds,
} from '../../../../shared/actions/v2_actions'
import {
    initialFlightDetailsEntity,
    initialPassengerDetailsEntity,
} from '../../../../shared/reducers/V2/reducer_smart_delay'

const PageHeader = styled.p`
    color: #353d51;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 30px;
`

const ContentWrapper = styled.div`
    margin-left: 12px;

    ${mobile`
    margin-left: 18px;
`}

    ${tablet`
    margin-left: unset;
`}
`

const ContentHeader = styled.div`
    font-family: Open Sans;
    color: #525f7f;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 12px;
`

const ContentText = styled.div`
    font-family: Open Sans;
    color: #353d51;
    font-weight: 400;
    font-size: 14px;
`

const LastContentText = styled(ContentText)`
    margin-bottom: 30px;
`

const ContentTextSubItem = styled.span`
    font-family: Open Sans;
    color: ${({ theme }) => theme.colors.primaryColor};
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
`

const ButtonWrapper = styled.div`
    margin-top: 30px;
`

const StyledButton = styled(V2Button)`
    margin-left: auto;
    width: fit-content;
    height: fit-content;
    align-self: flex-start;

    img {
        margin-right: 8px;
        vertical-align: middle;
    }

    ${tablet`
    margin: 0;
    margin-top: 12px;
`}

    ${mobile`
    margin: 0;
    margin-top: 12px;
`}
`

const CancelExplainDescription = styled.div`
    font-family: Open Sans;
    color: #353d51;
    font-weight: 400;
    font-size: 14px;
`

const RegisterButton = styled(StyledButton)`
    ${desktop`
margin-right: 20px;
`}
    ${tablet`
margin-right: 20px;
`}
`

class RegistrationCompleted extends React.Component {
    componentDidMount() {
        scrollComponentToView('pageTopNavbar', false, true)

        const { flightDetailsData, passengerDetailsData } = this.props

        if (
            browserHistory.getCurrentLocation().pathname ===
                paths.V2_SMART_DELAY.STEP_4_REGISTRATION_COMPLETED &&
            (this.isObjectEmpty(flightDetailsData) ||
                this.isObjectEmpty(passengerDetailsData))
        ) {
            browserHistory.push(paths.V2_SMART_DELAY.STEP_SMART_DELAY)
        }
    }

    isObjectEmpty = obj =>
        Object.keys(obj).some(key => values(obj[key]).some(isEmpty))

    handleNewRegistration = () => {
        const {
            setSmartDelayFlightDetails,
            setSmartDelayPassengerDetails,
            setSmartDelayFlightDetailsRegisteredFlightIds,
        } = this.props

        setSmartDelayFlightDetails({ '1': initialFlightDetailsEntity })
        setSmartDelayPassengerDetails({ '1': initialPassengerDetailsEntity })
        setSmartDelayFlightDetailsRegisteredFlightIds({})
        browserHistory.push(paths.V2_SMART_DELAY.STEP_1_FLIGHT_DETAILS)
    }

    render() {
        const { trans, showModal } = this.props

        if (!trans) {
            return null
        }
        return (
            <>
                <PageViewContainer>
                    <StyledSmartDelayContainer>
                        <TitleContainer>
                            <Helmet>
                                <title>{`Skycop - ${t`smart_delay.title`} - ${t`smart_delay.registration_completed_page_header`}`}</title>
                            </Helmet>
                            <SmartDelayStepper />
                            <PageHeader>{t`smart_delay.registration_completed_page_header`}</PageHeader>
                        </TitleContainer>
                        <ContentWrapper>
                            <div>
                                <ContentHeader>{t`smart_delay.registration_completed_content_header`}</ContentHeader>
                                <ContentText>{t`smart_delay.registration_completed_content_text1`}</ContentText>
                                <br />
                                <LastContentText>{t`smart_delay.registration_completed_content_text2`}</LastContentText>
                            </div>
                            <div>
                                <ContentHeader>{t`smart_delay.registration_completed_content_header2`}</ContentHeader>
                                <CancelExplainDescription>
                                    {applyTranslationElement(
                                        t`smart_delay.registration_completed_content_text3`,
                                        '%subitem%',
                                        <ContentTextSubItem
                                            onClick={() =>
                                                showModal(
                                                    modalTypes.smartDelayCancelRegistration
                                                )
                                            }
                                        >{t`smart_delay.registration_completed_content_text3_subitem`}</ContentTextSubItem>
                                    )}
                                </CancelExplainDescription>
                            </div>
                            <ButtonWrapper>
                                <RegisterButton
                                    id="btn-register-another"
                                    onClick={() => this.handleNewRegistration()}
                                >
                                    {t`smart_delay.registration_completed_register_another`}
                                </RegisterButton>
                                <StyledButton
                                    onClick={() =>
                                        browserHistory.push(
                                            paths.V2_SMART_DELAY
                                                .STEP_SMART_DELAY
                                        )
                                    }
                                    id="btn-view-registered"
                                    type="borderBtn"
                                >
                                    {t`smart_delay.registration_completed_view_registered`}
                                </StyledButton>
                            </ButtonWrapper>
                        </ContentWrapper>
                    </StyledSmartDelayContainer>
                </PageViewContainer>
                <CancelModal />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        passengerDetailsData: state.smartDelay.passengerDetails,
        flightDetailsData: state.smartDelay.flightDetails,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showModal,
            setSmartDelayFlightDetails,
            setSmartDelayPassengerDetails,
            setSmartDelayFlightDetailsRegisteredFlightIds,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(RegistrationCompleted)
