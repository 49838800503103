import React from 'react'
import styled from 'styled-components'
import { desktop } from '../helpers/styled_queries'

const Input = styled.input`
    height: 0;
    width: 0;
    opacity: 0;
    z-index: -1;
`
const Container = styled.div`
    ${desktop`
        width: 146px;
    `}
    height: 56px;
    gap: 12px;
    border-radius: 16px;
    border: 1px solid #717171;

    border: ${({ error }) => error && `1px solid #FE6050`};

    &:hover {
        cursor: pointer;
        #indicator {
            ${Input} {
                border: 1px solid ${({ theme }) => theme.colors.primaryColor};
                border-radius: 1em;
                width: 20px;
                height: 20px;
                position: absolute;
                top: ${({ isIcon }) => (isIcon ? '6px' : '1px')};
                left: -26px;
            }
            ${Input}&::after {
                display: block;
                border: solid ${({ theme }) => theme.colors.primaryColor};
                border-radius: 1em;
                background-color: ${({ theme }) => theme.colors.primaryColor};
                width: 8px;
                height: 8px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                animation-duration: 0.3s;
                animation-fill-mode: forwards;
            }
        }
    }
    ${({ checked, theme }) =>
        checked &&
        `
    #indicator {
         ${Input} {
            border: 1px solid ${theme.colors.primaryColor};
            border-radius: 1em;
            width: 20px;
            height: 20px;
            position: absolute;
            top: ${({ isIcon }) => (isIcon ? '6px' : '1px')};
            left: -26px;
        }
        ${Input}&::after {
            display: block;
            border: solid ${theme.colors.primaryColor};
            border-radius: 1em;
            background-color: ${theme.colors.primaryColor};
            width: 8px;
            height: 8px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
        }
    }
        `}
`

const Label = styled.label`
    position: relative;
    display: inline-block;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    margin: ${({ isIcon }) => (isIcon ? '11px 42px' : '16px 42px')};
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    height: 32px;
`

const Indicator = styled.div`
    border: 1px solid #717171;
    border-radius: 1em;
    width: 20px;
    height: 20px;
    position: absolute;
    top: ${({ isIcon }) => (isIcon ? '6px' : '1px')};
    left: -26px;

    border: ${({ theme, checked }) =>
        checked && `1px solid ${theme.colors.primaryColor}`};

    &::after {
        content: '';
        position: absolute;
        display: none;
    }

    ${Input}:disabled + & {
        pointer-events: none;
        opacity: 0.6;
        background: #e6e6e6;
    }
`

export const RadioButton = ({
    value,
    onChange,
    name,
    id,
    label,
    disabled,
    checked,
    error,
    isIcon,
    children,
}) => (
    <Container
        id={id}
        error={error}
        isIcon={!!children}
        checked={checked}
        onClick={onChange}
    >
        <Label htmlFor={id} disabled={disabled} isIcon={!!children}>
            {label || children}
            <Input
                id={id}
                type="radio"
                role="radio"
                name={name}
                value={value}
                disabled={disabled}
                checked={checked}
            />
            <Indicator id="indicator" checked={checked} isIcon={!!children} />
        </Label>
    </Container>
)
