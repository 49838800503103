import React from 'react'
import styled from 'styled-components'
import { browserHistory } from 'react-router'
import moment from 'moment'
import _get from 'lodash/get'
import t from '../../../../shared/translations'
import { Row, Col } from '../../../../shared/components/V2/Layout'
import { ContentItem, CardContent } from '../ClaimInner'
import { Card } from './Card'
import {
    mobile,
    tablet,
} from '../../../../shared/components/helpers/styled_queries'
import {
    ActiveIcon,
    ActiveFamilyIcon,
    DotsIcon,
    ArchivedIcon,
    FamilyIcon,
} from './CardIcons'

const CardContainer = styled(Card)`
    & .item-col {
        ${mobile`
            padding: 0;
        `}
    }
`

const DotsContainer = styled.div`
    padding: 4px 0px 4px 4px;
    cursor: pointer;
`
const StyledContent = styled(ContentItem)`
    display: flex;
    flex-direction: row;

    p {
        color: #353d51;
        font-weight: normal;
        text-align: left;
    }

    a {
        color: ${({ theme }) => theme.colors.primaryColor};
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
    }

    ${({ isExpired }) =>
        !isExpired
            ? ''
            : `
        p {
            color: #f16464;
        }
    `}
`

const Menu = styled.ul`
    position: absolute;
    z-index: 9999;
    background: #ffffff;
    padding: 0;
    border-radius: 13px;
    top: 40px;
    right: 13px;
    list-style: none;
    box-shadow: 0px 4px 10px 0px rgba(39, 42, 70, 0.15);

    ${mobile`
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    `}

    & li:not(:last-child) {
        border-bottom: 1px solid #cbd3df;
    }
`

const MenuItem = styled.li`
    display: flex;
    align-items: center;
    padding: 16px;
    color: black;
    font-weight: normal;
    white-space: nowrap;
    min-width: 210px;

    svg {
        margin-right: 12px;
    }

    &:hover {
        cursor: pointer;
        opacity: 0.6;
    }
`

const CardInnerContent = styled(CardContent)`
    padding: 0;

    & .card-row {
        ${mobile`
            align-items: flex-start;
        `}
        ${tablet`
            align-items: flex-start;
        `}
    }
`
const ButtonCol = styled.div``

export const SubscriptionCard = ({
    id,
    sub,
    isOpen,
    showMenu,
    enabledEditing,
    primaryColor,
}) => {
    const {
        active,
        title,
        family,
        validTill,
        startDate,
        amount,
        nextPaymentDate,
        status,
        type,
        firstAirport,
        lastAirport,
        personCount,
    } = sub

    const customersCount = _get(sub, 'childCustomersFullName', []).length + 1
    const customerNames = [
        _get(sub, 'customerFullName', ''),
        ..._get(sub, 'childCustomersFullName', []),
    ].join(', ')
    const mapStatusTexts = () => {
        switch (status) {
            case 'active':
                return (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t`sc_subscriptions.active_status`
                                .replace('%DATE%', formatDate(nextPaymentDate))
                                .replace('%AMOUNT%', `  ${amount / 100}`),
                        }}
                    />
                )
            case 'inactive':
                return (
                    <p
                        style={{ color: '#736B6B', fontWeight: 'bold' }}
                    >{t`sc_subscriptions.stopped`}</p>
                )
            case 'stopped':
                return active ? (
                    <>
                        <p>
                            {t`sc_subscriptions.status_no_charges`}{' '}
                            <span
                                style={{ color: '#FE6050' }}
                            >{t`sc_subscriptions.status_canceled`}</span>
                        </p>
                    </>
                ) : (
                    <p>{t`sc_subscriptions.status_no_charges`}</p>
                )
            case 'failed':
                return (
                    <p
                        style={{ color: '#736B6B', fontWeight: 'bold' }}
                    >{t`sc_subscriptions.stopped_payment`}</p>
                )
            case 'prepaid':
                return active ? (
                    <p>{t`sc_card_title.prepaid_status`}</p>
                ) : (
                    <p>{t`sc_subscriptions.status_no_charges`}</p>
                )
            case 'capi_promo':
                return active ? (
                    <p>{t`sc_card_title.prepaid_status`}</p>
                ) : (
                    <p>{t`sc_subscriptions.status_no_charges`}</p>
                )
            default:
                return ''
        }
    }

    const periodText = (
        type,
        startDate,
        validTill,
        firstAirport,
        lastAirport
    ) => {
        switch (type) {
            case 'single_flight':
                return `${t`sc_one_time.covered_period`
                    .replace('%first_flight_date%', formatDate(startDate))
                    .replace('%first_airport%', firstAirport)
                    .replace(
                        '%last_airport%',
                        lastAirport
                    )} <a href="/">${t`sc_subscriptions.claim_link`}</a>`

            default:
                return `${t`sc_subscriptions.covered_period`
                    .replace('%FROM_DATE%', formatDate(startDate))
                    .replace(
                        '%TILL_DATE%',
                        formatDate(validTill)
                    )} <a href="/">${t`sc_subscriptions.claim_link`}</a>`
        }
    }

    const getStatusTextByType = type => {
        switch (type) {
            case 'single_flight':
                return <p>{t`sc_one_time.purchased_on_partners_site`}</p>

            default:
                return mapStatusTexts()
        }
    }

    const getIconBasedOnType = type => {
        if (type === 'single_flight') {
            return null
        } else {
            return active
                ? family
                    ? ActiveFamilyIcon({ color: primaryColor })
                    : ActiveIcon
                : ArchivedIcon
        }
    }

    const getMainTitleBasedOnType = (type, personCount, productName) => {
        if (type === 'single_flight') {
            if (personCount === 1) {
                return t`sc_one_time.plan.one-time-plan`
            } else {
                return `${t`sc_one_time.passengers_count`.replace(
                    '%passengers_number%',
                    personCount
                )}`
            }
        } else {
            return t`sc_subscriptions.plan.${productName}`
        }
    }

    return (
        <CardContainer
            active={active}
            key={`${title}-${id}`}
            icon={getIconBasedOnType(type)}
            title={getMainTitleBasedOnType(type, personCount, sub.productName)}
            style={{
                marginBottom: 32,
                backgroundColor: '#fff',
            }}
            menu={() =>
                sub.mainBuyer && (
                    <ButtonCol sm={2} md={2} lg={2}>
                        {sub.status !== 'prepaid' &&
                            sub.status !== 'capi_promo' &&
                            type !== 'single_flight' && (
                                <DotsContainer
                                    onClick={e => {
                                        showMenu(e, sub.careRegistryId)
                                    }}
                                >
                                    <DotsIcon />
                                </DotsContainer>
                            )}
                        {isOpen === sub.careRegistryId && (
                            <Menu id="menu-dropdown">
                                {enabledEditing && family && (
                                    <MenuItem
                                        onClick={() =>
                                            browserHistory.push(
                                                `/subscriptions/family-edit/${sub.careRegistryId}`
                                            )
                                        }
                                    >
                                        {t`sc_subscriptions.button_family_edit`}
                                    </MenuItem>
                                )}
                                <MenuItem
                                    onClick={() =>
                                        browserHistory.push(
                                            `/subscriptions/history/${sub.careRegistryId}`
                                        )
                                    }
                                >
                                    {t`sc_subscriptions.button_history`}
                                </MenuItem>
                                {status === 'active' && (
                                    <MenuItem
                                        onClick={() =>
                                            browserHistory.push(
                                                `/subscriptions/cancel/${sub.careRegistryId}`
                                            )
                                        }
                                    >
                                        {t`sc_subscriptions.button_cancel_subscription`}
                                    </MenuItem>
                                )}
                            </Menu>
                        )}
                    </ButtonCol>
                )
            }
        >
            <CardInnerContent>
                <Col>
                    <Row>
                        <StyledContent>
                            <Row className="card-row">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: periodText(
                                            type,
                                            startDate,
                                            validTill,
                                            firstAirport,
                                            lastAirport
                                        ),
                                    }}
                                ></p>
                            </Row>
                        </StyledContent>
                    </Row>
                    {family && (
                        <Row className="card-row">
                            <StyledContent>
                                <p style={{ textAlign: 'left' }}>
                                    <FamilyIcon />
                                    &nbsp;
                                    {customersCount}
                                    &nbsp;
                                    {t`sc_subscriptions.persons.included`}
                                    &nbsp;
                                    {`(${customerNames})`}
                                </p>
                            </StyledContent>
                        </Row>
                    )}
                    <Row className="card-row">
                        <StyledContent>
                            {getStatusTextByType(type)}
                        </StyledContent>
                    </Row>
                </Col>
            </CardInnerContent>
        </CardContainer>
    )
}

function formatDate(date) {
    return date ? moment(date).format('YYYY-MM-DD') : ''
}
