import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Swipe from 'react-easy-swipe'
import t from '../../../shared/translations'
import {
    setOnboardingSlider,
    hideModal,
} from '../../../shared/actions/v2_actions'
import {
    LeftArrow,
    RightArrow,
    Globe1,
    Globe3,
    Globe2,
    CancelledPlaneIcon,
    DelayedPlaneIcon,
    OverbookedIcon,
} from '../../../shared/components/V2/V2Icons'
import {
    mobile,
    desktop,
    tablet,
} from '../../../shared/components/helpers/styled_queries'
import { V2Button } from '../../../shared/components/V2/V2Buttons'

const SliderContainer = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &::-webkit-scrollbar {
        display: none;
    }
`

const Bubble = styled.div`
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background-color: ${({ active }) => (active ? '#3b4057' : '#dcdfe5')};
    cursor: pointer;
    margin: 0 8px;

    &:hover {
        filter: brightness(85%);
    }
`

const ArrowContainer = styled.div`
    position: absolute;
    display: flex;
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.right {
        right: 0;
    }

    &:hover {
        opacity: 0.5;
    }

    ${tablet`
        display: none;
    `}

    ${mobile`
        display: none;
    `}
`

const ControlContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 28px 0px;

    ${desktop`
        padding-top: 0px;
        min-height: 48px;
        margin: 16px 0px 32px;
    `}

    &.mobile {
        ${desktop`
            display: none;
        `}
    }

    &.desktop {
        ${mobile`
            display: none;
        `}

        ${tablet`
            display: none;
        `}
    }

    @media screen and (min-width: 959px) and (max-width: 1184px) {
        margin: 8px 0px 24px;
    }
`

const Slider = styled.div`
    position: relative;
    width: 100%;
    margin: 8px auto auto;
    overflow: hidden;
    white-space: nowrap;

    ${desktop`
        margin-top: 0px;
    `}
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    width: 300%;
    transform: ${({ value }) => `translateX(${value}px)`};
    transition: transform ease-out 0.45s;
`

const Slide = styled.div`
    width: 100%;
    padding: 0px 16px 16px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
        display: none;
    }

    ${desktop`
        padding: 40px 16px 0px;
    `}

    ${mobile`
        display: flex;
        flex-direction: column;
    `}

    @media screen and (min-width: 959px) and (max-width: 1184px) {
        padding: 24px 24px 0px;
    }
`

const FlexBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;

    ${desktop`
        flex-direction: row;
        padding: 0 48px;
    `}
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    padding: 0 48px;
    margin-bottom: 24px;
`

const SlideHeader = styled.h1`
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #353d51;
    white-space: pre-wrap;

    ${desktop`
        font-size: 28px;
    `}
`

const SlideSubheader = styled.h2`
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #525f7f;
    white-space: pre-wrap;
    margin: 0;
`

const ImgContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px;

    img {
        max-height: 101px;
        max-width: 180px;
    }

    ${desktop`
        padding: 0px;
    `}
`

const IconContainer = styled.div`
    svg {
        width: 74px;
        height: 74px;
    }

    ${desktop`
            svg {
                width: 96px;
                height: 96px;
            }
        `}

    &.globe {
        svg {
            width: 68px;
            height: 72px;
        }
    }
`

const BtnContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 16px 0px 32px;

    button {
        width: auto;
        flex-basis: auto;
    }

    @media screen and (min-width: 959px) and (max-width: 1184px) {
        margin: 8px 0px 24px;
    }

    &.mobile {
        ${desktop`
            display: none;
        `}
    }

    &.desktop {
        ${mobile`
            display: none;
            height: 1px;
        `}

        ${tablet`
            display: none;
            height: 1px;
        `}
    }
`

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    ${desktop`
        height: 100%;
    `}
`

const GlobeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: solid 1px #cbd3df;
    background-color: #ffffff;
    padding: 24px;
    overflow: hidden;
    white-space: pre-wrap;
    margin: 8px 0 8px 0;
    flex: 1;
    min-width: 200px;

    h3,
    p {
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3a3f56;
        width: 100%;
    }

    h3 {
        font-size: 16px;
        font-weight: 600;

        &.uppercase {
            text-transform: uppercase;
        }
    }

    p {
        font-size: 12px;
        font-weight: normal;
        color: #525f7f;

        &.amount {
            font-size: 24px;
            font-weight: bold;
            color: ${({ theme }) => theme.colors.tertiaryColor};
            margin: 0;
        }

        &.mr-0 {
            margin: 0;
        }
    }

    .globe-content {
        padding-top: 24px;
        width: 100%;
    }

    ${desktop`
        &.spaceb {
            justify-content: space-between;
        }

        margin: 16px;
        padding: 32px 24px 32px 24px;

        p {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: center;
            color: #525f7f;

        }

        .globe-content {
            padding-top: 14px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
                width: 100%;
            }
        }
    `}

    @media screen and (min-width: 959px) and (max-width: 1184px) {
        margin: 8px;
        padding: 16px;
    }
`

class OnboardingSlider extends React.Component {
    state = {
        currentIndex: 0,
        translateValue: 0,
    }

    componentDidMount() {
        window.addEventListener('resize', this.resetSlides)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resetSlides)
    }

    resetSlides = () => {
        this.setSlide(0)
    }

    goToPrevSlide = e => {
        if (e) {
            e.preventDefault()
        }

        const { setOnboardingSlider, steps } = this.props
        let currentIndex = steps.currentIndex
        let translateValue = steps.translateValue

        if (currentIndex === 0) {
            currentIndex = 2
            translateValue = 0 + -this.slideWidth() * 2

            setOnboardingSlider(currentIndex, translateValue)
        } else {
            currentIndex -= 1
            translateValue += this.slideWidth()

            setOnboardingSlider(currentIndex, translateValue)
        }
    }

    goToNextSlide = e => {
        if (e) {
            e.preventDefault()
        }

        const { setOnboardingSlider, steps } = this.props
        let currentIndex = steps.currentIndex
        let translateValue = steps.translateValue

        if (steps.currentIndex === 2) {
            setOnboardingSlider(0, 0)
        } else {
            currentIndex += 1
            translateValue += -this.slideWidth()

            setOnboardingSlider(currentIndex, translateValue)
        }
    }

    setSlide = id => {
        const { setOnboardingSlider } = this.props

        const slidePositions = {
            0: 0,
            1: -this.slideWidth(),
            2: -this.slideWidth() * 2,
        }

        setOnboardingSlider(id, slidePositions[id])
    }

    slideWidth = () => {
        return document.querySelector('.slide').clientWidth
    }

    render() {
        const { steps, trans, hideModal, primaryColor } = this.props

        if (!trans) return null

        return (
            <Swipe
                style={{ minHeight: '100%' }}
                tolerance={60}
                onSwipeRight={() => this.goToPrevSlide()}
                onSwipeLeft={() => this.goToNextSlide()}
            >
                <SliderContainer>
                    <ControlContainer className="mobile">
                        <Bubble
                            active={steps.currentIndex === 0}
                            onClick={() => this.setSlide(0)}
                        />
                        <Bubble
                            active={steps.currentIndex === 1}
                            onClick={() => this.setSlide(1)}
                        />
                        <Bubble
                            active={steps.currentIndex === 2}
                            onClick={() => this.setSlide(2)}
                        />
                    </ControlContainer>
                    <Slider>
                        <Wrapper value={steps.translateValue}>
                            <Slide className="slide">
                                <Header>
                                    <SlideHeader>
                                        {t`v2.onboarding.slide1.title`}
                                    </SlideHeader>
                                    <SlideSubheader>
                                        {t`v2.onboarding.slide1.subtitle`}
                                    </SlideSubheader>
                                </Header>
                                <MainContainer>
                                    <FlexBox>
                                        <GlobeContainer className="spaceb">
                                            <IconContainer>
                                                <DelayedPlaneIcon />
                                            </IconContainer>
                                            <h3 className="uppercase">
                                                {t`v2.onboarding.delayed_title`}
                                            </h3>
                                            <p>
                                                {t`v2.onboarding.delayed_text`}
                                            </p>
                                        </GlobeContainer>
                                        <GlobeContainer className="spaceb">
                                            <IconContainer>
                                                <CancelledPlaneIcon />
                                            </IconContainer>
                                            <h3 className="uppercase">
                                                {t`v2.onboarding.cancelled_title`}
                                            </h3>
                                            <p>
                                                {t`v2.onboarding.cancelled_text`}
                                            </p>
                                        </GlobeContainer>
                                        <GlobeContainer className="spaceb">
                                            <IconContainer>
                                                <OverbookedIcon />
                                            </IconContainer>
                                            <h3 className="uppercase">
                                                {t`v2.onboarding.overbooked_title`}
                                            </h3>
                                            <p>
                                                {t`v2.onboarding.overbooked_text`}
                                            </p>
                                        </GlobeContainer>
                                    </FlexBox>
                                    <ArrowContainer
                                        className="right"
                                        onClick={this.goToNextSlide}
                                    >
                                        <RightArrow />
                                    </ArrowContainer>
                                </MainContainer>
                            </Slide>
                            <Slide className="slide">
                                <Header>
                                    <SlideHeader>
                                        {t`v2.onboarding.slide2.title`}
                                    </SlideHeader>
                                    <SlideSubheader>
                                        {t`v2.onboarding.slide2.subtitle`}
                                    </SlideSubheader>
                                </Header>

                                <MainContainer>
                                    <ArrowContainer
                                        onClick={this.goToPrevSlide}
                                    >
                                        <LeftArrow />
                                    </ArrowContainer>
                                    <FlexBox>
                                        <GlobeContainer className="spaceb">
                                            <IconContainer className="globe">
                                                <Globe1 />
                                            </IconContainer>
                                            <div className="globe-content">
                                                <h3>{t`v2.onboarding.upto1500`}</h3>
                                                <p>
                                                    {t`v2.onboarding.upto1500.description`}
                                                </p>
                                                <p className="amount">250EUR</p>
                                            </div>
                                        </GlobeContainer>
                                        <GlobeContainer className="spaceb">
                                            <IconContainer className="globe">
                                                <Globe2 />
                                            </IconContainer>
                                            <div className="globe-content">
                                                <h3>
                                                    {t`v2.onboarding.1500to3000`}
                                                </h3>
                                                <p>
                                                    {t`v2.onboarding.1500to3000.description`}
                                                </p>
                                                <p className="amount">400EUR</p>
                                            </div>
                                        </GlobeContainer>
                                        <GlobeContainer className="spaceb">
                                            <IconContainer className="globe">
                                                <Globe3 color={primaryColor} />
                                            </IconContainer>
                                            <div className="globe-content">
                                                <h3>{t`v2.onboarding.3000more`}</h3>
                                                <p>
                                                    {t`v2.onboarding.3000more.description`}
                                                </p>
                                                <p className="amount">600EUR</p>
                                            </div>
                                        </GlobeContainer>
                                    </FlexBox>
                                    <ArrowContainer
                                        className="right"
                                        onClick={this.goToNextSlide}
                                    >
                                        <RightArrow />
                                    </ArrowContainer>
                                </MainContainer>
                            </Slide>
                            <Slide className="slide">
                                <Header>
                                    <SlideHeader>
                                        {t`v2.onboarding.slide3.title`}
                                    </SlideHeader>
                                    <SlideSubheader>
                                        {t`v2.onboarding.slide3.subtitle`}
                                    </SlideSubheader>
                                </Header>

                                <MainContainer>
                                    <ArrowContainer
                                        onClick={this.goToPrevSlide}
                                    >
                                        <LeftArrow />
                                    </ArrowContainer>
                                    <FlexBox>
                                        <GlobeContainer>
                                            <ImgContainer>
                                                <img
                                                    src="/images/v2/land-eu.svg"
                                                    alt="Onboarding airport"
                                                />
                                            </ImgContainer>
                                            <div className="globe-content">
                                                <h3 className="uppercase">
                                                    {t`v2.onboarding.fromeuflight`}
                                                </h3>
                                                <p className="mr-0">
                                                    {t`v2.onboarding.fromeuflight.desciption`}
                                                </p>
                                            </div>
                                        </GlobeContainer>
                                        <GlobeContainer>
                                            <ImgContainer>
                                                <img
                                                    src="/images/v2/take-off-eu.svg"
                                                    alt="Onboarding plane"
                                                />
                                            </ImgContainer>
                                            <div className="globe-content">
                                                <h3 className="uppercase">
                                                    {t`v2.onboarding.witheuairlines`}
                                                </h3>
                                                <p className="mr-0">
                                                    {t`v2.onboarding.witheuairlines.description`}
                                                </p>
                                            </div>
                                        </GlobeContainer>
                                        <BtnContainer className="mobile">
                                            <V2Button onClick={hideModal}>
                                                {' '}
                                                Done
                                            </V2Button>
                                        </BtnContainer>
                                    </FlexBox>
                                </MainContainer>
                            </Slide>
                        </Wrapper>
                        {steps.currentIndex !== 2 ? (
                            <ControlContainer className="desktop">
                                <Bubble
                                    active={steps.currentIndex === 0}
                                    onClick={() => this.setSlide(0)}
                                />
                                <Bubble
                                    active={steps.currentIndex === 1}
                                    onClick={() => this.setSlide(1)}
                                />
                                <Bubble
                                    active={steps.currentIndex === 2}
                                    onClick={() => this.setSlide(2)}
                                />
                            </ControlContainer>
                        ) : (
                            <BtnContainer className="desktop">
                                <V2Button
                                    onClick={hideModal}
                                >{t`v2_button.done`}</V2Button>
                            </BtnContainer>
                        )}
                    </Slider>
                </SliderContainer>
            </Swipe>
        )
    }
}

function mapStateToProps(state) {
    return {
        steps: state.v2_onboarding_slider,
        trans: state.translations,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setOnboardingSlider, hideModal }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingSlider)
