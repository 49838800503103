import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { desktop, mobile, tablet, tabletLG } from '../helpers/styled_queries'
import t from '../../translations'
import { V2Button2 } from '../V2/V2Buttons'
import { FbIcon, TikTokIcon, YouTubeIcon, InstagramIcon } from './images/images'
import { RadioButton } from './RadioButton'

const AppStyled = styled.div`
    display: flex;
    ${tablet`
      flex-flow: row wrap;
      `}
    ${mobile`
      flex-flow: row wrap;
      `}

    > * {
        flex: 1;
    }

    > :not(:last-child) {
        margin-right: 1rem;
    }

    @media (max-width: 960px) {
        > :not(:last-child) {
            margin-right: initial;
        }
        > :nth-child(2n + 1) {
            margin-right: 1rem;
        }

        > :not(:last-child),
        > :not(:nth-last-child(2)) {
            margin-bottom: 1rem;
        }

        > * {
            min-width: 40%;
        }
    }
`

const Wrapper = styled.div`
    height: 100%;
`

const StyledRow = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;

    ${desktop`
        flex-direction: row;
    `}

    ${tabletLG`
        flex-direction: row;
    `}
`

const ImageContainer = styled.div`
    height: 100%;

    img {
        height: 100%;
        width: 100%;
        ${desktop`
            width: 312px;
        `}
        ${tabletLG`
            width: 312px;
        `}
    }
`

const Textarea = styled.textarea`
    padding: 18px 18px 0px 18px;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid #717171;
    opacity: 0px;
    width: 100%;
    background: #fafafa;
    box-shadow: 0px 1px 2px 0px #1018280d;
    min-height: 74px;
    color: ${({ checked }) => (checked ? '#000000' : '#717171')};

    &:focus {
        border: 1px solid ${({ theme }) => theme.colors.primaryColor};
        outline: 0 none;
    }

    &::placeholder {
        font-family: Noto Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.72px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #717171;
    }
    border: ${({ error }) => error && '1px solid #FE6050'};
`

const Label = styled.div`
    color: #717171;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 12px;
    ${desktop`
    margin-top: 24px;
    `}
`

const Title = styled.div`
    font-family: Noto Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 42.24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin-bottom: 16px;

    span {
        color: ${({ theme }) => theme.colors.primaryColor};
    }
`

const ThankYouTitle = styled.div`
    font-family: Noto Sans;
    font-size: 48px;
    font-weight: 600;
    line-height: 57.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    font-family: Noto Sans;
    font-size: 48px;
    font-weight: 600;
    line-height: 57.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: #000000;
    margin-bottom: 16px;

    ${mobile`
        font-size: 32px;
        line-height: 42.24px;
        `}

    span {
        color: ${({ theme }) => theme.colors.primaryColor};
    }
`

const ContentWrapper = styled.div`
    margin: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${desktop`
        margin: 60px 60px 60px 84px;    
    `}
`

const Description = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin-bottom: 16px;
    span {
        color: ${({ theme }) => theme.colors.primaryColor};
    }
`

const Button = styled(V2Button2)`
    height: 59px;
    padding-right: 32px;
    padding-left: 32px;
    margin-top: 24px;

    margin-bottom: 20px;
    width: 100%;

    ${desktop`
        margin-bottom: 0px;  
        width: inherit;
    `}
`

const Error = styled.div`
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.72px;
    color: #fe6050;
`

class SurveyQuestions extends React.Component {
    render() {
        const {
            trans,
            isError,
            isSurveyCompleted,
            surveyQuestions,
            changeRadioValue,
            handleCommentChange,
            handleSurveySubmit,
            onRedirectClick,
        } = this.props
        if (!trans) return null

        return (
            <Wrapper>
                <StyledRow id="wrapper">
                    <ImageContainer>
                        <picture>
                            <source
                                media="(max-width: 479px)"
                                srcSet="/images/mobileSurveyImage.png"
                            />
                            <source
                                media="(min-width: 480px) and (max-width: 719px)"
                                srcSet="/images/tabletLgSurveyImage.png"
                            />
                            <source
                                media="(min-width: 720px) and (max-width: 960px)"
                                srcSet="/images/desktopLgSurveyImage.png"
                            />
                            <source
                                media="(min-width: 961px)"
                                srcSet="/images/desktopSurveyImage.png"
                            />
                            <img
                                alt="survey"
                                src="/images/desktopSurveyImage.png"
                            />
                        </picture>
                    </ImageContainer>
                    <ContentWrapper>
                        {isSurveyCompleted ? (
                            <div>
                                <ThankYouTitle
                                    dangerouslySetInnerHTML={{
                                        __html: t`survey.modal.v2.thank_you.title`,
                                    }}
                                />
                                <Description
                                    dangerouslySetInnerHTML={{
                                        __html: t`survey.modal.v2.thank_you.description`,
                                    }}
                                />
                                <div>
                                    <Button
                                        onClick={onRedirectClick}
                                    >{t`survey.modal.v2.thank_you.activate_skycop_care.button`}</Button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Title
                                    dangerouslySetInnerHTML={{
                                        __html: t`survey.modal.v2.title`,
                                    }}
                                />
                                <Description
                                    dangerouslySetInnerHTML={{
                                        __html: t`survey.modal.v2.description`,
                                    }}
                                />
                                <Label>{t`survey.modal.v2.social.media.title`}</Label>
                                <AppStyled>
                                    <div>
                                        <RadioButton
                                            name="facebook"
                                            error={isError}
                                            checked={
                                                surveyQuestions.facebook
                                                    .isChecked
                                            }
                                            onChange={() =>
                                                changeRadioValue('facebook')
                                            }
                                        >
                                            <FbIcon />
                                        </RadioButton>
                                    </div>
                                    <div>
                                        <RadioButton
                                            name="instagram"
                                            error={isError}
                                            checked={
                                                surveyQuestions.instagram
                                                    .isChecked
                                            }
                                            onChange={() =>
                                                changeRadioValue('instagram')
                                            }
                                        >
                                            <InstagramIcon />
                                        </RadioButton>
                                    </div>
                                    <div>
                                        <RadioButton
                                            name="tiktok"
                                            error={isError}
                                            checked={
                                                surveyQuestions.tiktok.isChecked
                                            }
                                            onChange={() =>
                                                changeRadioValue('tiktok')
                                            }
                                        >
                                            <TikTokIcon />
                                        </RadioButton>
                                    </div>
                                    <div>
                                        <RadioButton
                                            name="youtube"
                                            error={isError}
                                            checked={
                                                surveyQuestions.youtube
                                                    .isChecked
                                            }
                                            onChange={() =>
                                                changeRadioValue('youtube')
                                            }
                                        >
                                            <YouTubeIcon />
                                        </RadioButton>
                                    </div>
                                </AppStyled>
                                <Label>{t`survey.modal.v2.or`}</Label>
                                <AppStyled>
                                    <div>
                                        <RadioButton
                                            label={t`survey.modal.v2.google.option`}
                                            name="google"
                                            error={isError}
                                            checked={
                                                surveyQuestions.google.isChecked
                                            }
                                            onChange={() =>
                                                changeRadioValue('google')
                                            }
                                        />
                                    </div>
                                    <div>
                                        <RadioButton
                                            label={t`survey.modal.v2.influencer.option`}
                                            name="influencer"
                                            error={isError}
                                            checked={
                                                surveyQuestions.influencer
                                                    .isChecked
                                            }
                                            onChange={() =>
                                                changeRadioValue('influencer')
                                            }
                                        />
                                    </div>
                                    <div>
                                        <RadioButton
                                            error={isError}
                                            label={t`survey.modal.v2.friend.option`}
                                            name="friend"
                                            checked={
                                                surveyQuestions.friend.isChecked
                                            }
                                            onChange={() =>
                                                changeRadioValue('friend')
                                            }
                                        />
                                    </div>
                                    <div>
                                        <RadioButton
                                            error={isError}
                                            label={t`survey.modal.v2.airport.option`}
                                            name="airport"
                                            checked={
                                                surveyQuestions.airport
                                                    .isChecked
                                            }
                                            onChange={() =>
                                                changeRadioValue('airport')
                                            }
                                        />
                                    </div>
                                </AppStyled>
                                <div>
                                    <Label>{t`survey.modal.v2.free_text.option`}</Label>
                                    <Textarea
                                        name="other"
                                        error={isError}
                                        placeholder={t`survey.modal.v2.free_text.option.placeholder`}
                                        value={surveyQuestions.other.value}
                                        checked={
                                            surveyQuestions.other.isChecked
                                        }
                                        onChange={e =>
                                            handleCommentChange(
                                                e.target.value,
                                                'other'
                                            )
                                        }
                                    ></Textarea>
                                    {isError && (
                                        <Error>{t`survey.modal.v2.validation.error`}</Error>
                                    )}
                                </div>
                                <div>
                                    <Button
                                        onClick={handleSurveySubmit}
                                    >{t`survey.modal.v2.get_skycop_care.button`}</Button>
                                </div>
                            </div>
                        )}
                    </ContentWrapper>
                </StyledRow>
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

export default connect(mapStateToProps)(SurveyQuestions)
