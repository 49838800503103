import React from 'react'
import { connect } from 'react-redux'
import latinize from 'latinize'
import _get from 'lodash/get'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'
import { Container, ContentContainer } from '../../V2'
import ErrorBox from '../../V2/components/ErrorsBox'
import {
    PageViewContainer,
    PageContent,
    Row,
    Col,
} from '../../../shared/components/V2/Layout'
import { desktop } from '../../../shared/components/helpers/styled_queries'
import t from '../../../shared/translations'
import ToolTip from '../../../shared/components/V2/V2Tooltip'
import { V2Button } from '../../../shared/components/V2/V2Buttons'
import {
    BubbleSubtitle,
    InfoBox,
    QuestionMarkIcon,
    TooltipInner,
    PlusIcon,
    DashedLine,
} from './SkycopCareV2'
import {
    loadScreenOn,
    loadScreenOff,
    isValidEmailInput,
    scrollTo,
} from '../../../main'
import {
    getSkycopCareSubscriptions,
    updateSkycopCareFamilyMembers,
} from '../../../shared/actions/v2_actions'
import { FamilyMembers } from './FamilyMembers'

const StyledPageContent = styled(PageContent)`
    ${desktop`
        padding: 20px 60px 30px;
        max-width: 900px;
    `}

    padding-bottom: 130px;

    .form-group {
        margin-bottom: 0;
    }

    .submit-button {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
            display: flex;
        }
    }

    .slider-row {
        flex-direction: row;
    }
`

const ErrorText = styled.p`
    padding: 0 0 0 16px;
    margin: 0;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.85;
    letter-spacing: normal;
    color: #ffffff;

    span {
        font-weight: bold;
    }
`

class FamilyMembersEdit extends React.Component {
    state = {
        id: '',
        loading: false,
        passengers: [],
    }
    async componentDidMount() {
        const { params } = this.props

        try {
            loadScreenOn()
            const data = await getSkycopCareSubscriptions()
            const subscription =
                data && data.find(d => d.careRegistryId === params.id)
            const customers = _get(subscription, 'childCustomers', [])
            const passengers = customers
                ? customers.map((c, index) => ({
                      id: c.id,
                      index: index + 1,
                      name: c.name,
                      surname: c.lastname,
                      email: c.email,
                  }))
                : []
            this.setState({ id: params.id, passengers })
        } catch (e) {
            console.log(e)
        } finally {
            loadScreenOff()
        }
    }

    handlePassengerChange = (e, id) => {
        if (!e || !id) return

        const { name, value } = _get(e, 'target', e)
        let newValue = value

        if (['name', 'surname'].includes(name)) {
            newValue = latinize(value)
        } else if (name === 'email') {
            if (!isValidEmailInput(value)) {
                return
            }
        }

        const updatedPassengers = this.state.passengers.map(passenger =>
            passenger.index === id
                ? {
                      ...passenger,
                      [name]: newValue,
                  }
                : passenger
        )

        this.setState({
            passengers: updatedPassengers,
        })
    }

    handlePassengerRemove = id => {
        if (!id) return

        const filteredPassenger = this.state.passengers.filter(
            p => p.index !== id
        )
        const updatedPassengers = filteredPassenger.reduce(
            (acc, curr, index) => {
                return [...acc, { ...curr, index: index + 1 }]
            },
            []
        )

        this.setState({ passengers: updatedPassengers })
    }

    handleAddNewPassenger = () => {
        const { passengers } = this.state

        if (passengers.length >= 4) return

        this.setState({
            passengers: [
                ...passengers,
                {
                    index: passengers.length + 1,
                    name: '',
                    surname: '',
                    email: '',
                    sc_errors: [],
                },
            ],
        })
    }

    update = async () => {
        const { id, passengers } = this.state
        const childCustomers = passengers.map(p => ({
            id: p.id,
            name: p.name,
            lastname: p.surname,
            ...(p.email ? { email: p.email } : {}),
        }))

        if (id) {
            const data = await updateSkycopCareFamilyMembers(id, childCustomers)
            const status = _get(data, 'response.status', true)
            const errorMessage = _get(
                data,
                'response.data.violations[0].title',
                null
            )

            if (status === 400) {
                this.setState({ error: true, errorMessage })
                scrollTo(document.body, 0)
            } else {
                browserHistory.push('/subscriptions')
            }
        }
    }

    render() {
        const { passengers, loading, error, errorMessage } = this.state
        const { primaryColor } = this.props
        const validFamilyMembers = passengers.every(
            passenger => passenger.name && passenger.surname
        )

        return (
            <Container>
                <ContentContainer>
                    <PageViewContainer id="family-edit-view">
                        <StyledPageContent>
                            <Row>
                                <Col>
                                    {error && (
                                        <ErrorBox
                                            noTitle
                                            onClick={() =>
                                                this.setState({
                                                    error: false,
                                                    errorMessage: null,
                                                })
                                            }
                                        >
                                            <ErrorText noTitle>
                                                {errorMessage
                                                    ? t`sc.family_member.${errorMessage}`
                                                    : t`sc.family_member.update_error`}
                                            </ErrorText>
                                        </ErrorBox>
                                    )}
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    marginTop: '20px',
                                    marginBottom: '8px',
                                }}
                            >
                                <Col style={{ display: 'flex' }}>
                                    <BubbleSubtitle>{t`sc.title.family_member_info`}</BubbleSubtitle>
                                    <InfoBox
                                        data-tip
                                        data-for={`tooltip-family-members`}
                                    >
                                        <QuestionMarkIcon s />
                                        <ToolTip
                                            clickable={true}
                                            dataTip="disabled"
                                            place="bottom"
                                            color="light"
                                            effect="solid"
                                            id={`tooltip-family-members`}
                                            delayHide={100}
                                            className="customHover"
                                        >
                                            <TooltipInner
                                                dangerouslySetInnerHTML={{
                                                    __html: `${t`sc.family_plan.tooltip`}`,
                                                }}
                                            />
                                        </ToolTip>
                                    </InfoBox>
                                </Col>
                            </Row>
                            {passengers.map((passenger, index) => (
                                <React.Fragment key={index}>
                                    <FamilyMembers
                                        user={passenger}
                                        index={index + 1}
                                        sc_errors={passenger.sc_errors}
                                        s
                                        handleChange={event =>
                                            this.handlePassengerChange(
                                                event,
                                                index + 1
                                            )
                                        }
                                        handleRemove={() =>
                                            this.handlePassengerRemove(
                                                index + 1
                                            )
                                        }
                                    />
                                    <Row>
                                        {passengers.length > index + 1 && (
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                            >
                                                <DashedLine />
                                            </Col>
                                        )}
                                    </Row>
                                </React.Fragment>
                            ))}
                            <Row>
                                <Col sm={12} md={4} lg={4}>
                                    <V2Button
                                        disabledStyle={passengers.length >= 4}
                                        type="borderBtn"
                                        className="submit-button"
                                        onClick={() =>
                                            this.handleAddNewPassenger()
                                        }
                                    >
                                        <PlusIcon fill={primaryColor} />
                                        {t`sc.family_member.add_new`}
                                    </V2Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <DashedLine />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={4} lg={4}>
                                    <V2Button
                                        className="submit-button"
                                        disabledStyle={!validFamilyMembers}
                                        onClick={() => {
                                            if (!validFamilyMembers) return
                                            this.update()
                                        }}
                                    >
                                        {loading ? (
                                            <Loader
                                                type="Oval"
                                                color="#848484"
                                                height="23"
                                                width="23"
                                            />
                                        ) : (
                                            t`sc.family_member.update_members`
                                        )}
                                    </V2Button>
                                </Col>
                                <Col sm={12} md={5} lg={5}>
                                    <V2Button
                                        disabledStyle={passengers.length >= 4}
                                        type="borderBtn"
                                        className="submit-button"
                                        onClick={() =>
                                            browserHistory.push(
                                                '/subscriptions'
                                            )
                                        }
                                    >
                                        {t`sc.family_member.back`}
                                    </V2Button>
                                </Col>
                            </Row>
                        </StyledPageContent>
                    </PageViewContainer>
                </ContentContainer>
            </Container>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isAuthenticated: state.auth.authenticated,
        trans: state.translations,
        countries: state.countries,
        is_valid_smtp: state.valid_email_smtp,
        sc_errors: state.sc_errors,
        productsV2: state.sc_products,
        products: state.sc_v2_products,
        postedClaim: state.v2_posted_claim,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyMembersEdit)
