import store from '../services/store'
import { V2_ACTIONS, DUPLICATE_CLAIM } from './actionTypes'
import { browserHistory } from 'react-router'
import _get from 'lodash/get'
import moment from 'moment'
import {
    getApiClient,
    dataURItoBlob,
    setStorage,
    removeStorage,
    getStorage,
    acceptLang,
    appendAnalyticsCookie,
    getStatus,
    loadScreenOn,
    loadScreenOff,
    triggerAffiliatePixel,
    deleteAffiliateCookies,
    getAdblockGaStatus,
    getCookie,
    onlyUnique,
    isProduction,
    deleteCookie,
    isFeatureEnabled,
} from '../../main'
import {
    serviceFeePercentage,
    paths,
    modalTypes,
    featureToggles,
} from '../constants'
import { getUserClaims, getUserData as getFullUserData } from './user_actions'
import { logKibana, logout, setLandingParameters, setLanguage } from './actions'
import { setClaimErrors, postClaimSuccessTransaction, getAirports } from '.'
import { createAirportObj } from '../../render'
import { groupPayload } from '../reducers/V2/reducer_suggested_flights'
import t from '../translations'
import { getLocale } from '../services/utils'
import { logRaw, logLevel } from '../services/logger'

/* global Raven */

export const setDraftEmail = payload => {
    store.dispatch({
        type: V2_ACTIONS.SET_DRAFT_EMAIL,
        payload,
    })
}

export const getAirportById = id => {
    const airports = [
        ...store.getState().v2_selectedAirports,
        ...store.getState().v2_UsedAirports,
    ]
    return (airports || []).filter(a => a.id === id)[0]
}

export const getAirlineById = id => {
    const airlines = [
        ...store.getState().v2_selectedAirlines,
        ...store.getState().v2_UsedAirlines,
    ]
    return (airlines || []).filter(a => a.id === id)[0]
}

export const getCountryById = id => {
    const countries = [...store.getState().countries.payload]
    return (countries || []).filter(a => a.id === id)[0]
}

export const setNavStep = index => {
    store.dispatch({
        type: V2_ACTIONS.STEP_STATE,
        payload: index === -1 ? 0 : index,
    })
}

export const nextStep = (replace, otherPath) => dispatch => {
    const storeSteps = store.getState().v2_step

    dispatch({
        type: V2_ACTIONS.STEP_STATE,
        payload: storeSteps.stepIndex + 1,
    })
    if (replace) {
        browserHistory.replace(otherPath || storeSteps.next)
    } else {
        browserHistory.push(otherPath || storeSteps.next)
    }
}

export const prevStep = () => dispatch => {
    const storeSteps = store.getState().v2_step

    dispatch({
        type: V2_ACTIONS.STEP_STATE,
        payload: storeSteps.stepIndex - 1,
    })
    browserHistory.push(storeSteps.prev)
}

export const setStepOne = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_STEP_ONE,
        payload,
    })
}

export const clearStepOne = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.RESET_STEP_ONE,
    })
}

export const setStepTwo = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_STEP_TWO,
        payload,
    })
}

export const clearStepTwo = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.RESET_STEP_TWO,
    })
}

export const setStepThree = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_STEP_THREE,
        payload,
    })
}

export const clearStepThree = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.RESET_STEP_THREE,
    })
}

export const setStepFour = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_STEP_FOUR,
        payload,
    })
}

export const clearStepFour = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.RESET_STEP_FOUR,
    })
}

export const setStepFive = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_STEP_FIVE,
        payload,
    })
}

export const setStepSix = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_STEP_SIX,
        payload,
    })
}

export const validateStepOne = (payload, singleField) => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_STEP_ONE_ERRORS,
        payload,
        singleField,
    })
    return store.getState().v2_step_one_errors.errors
}

export const validateStepTwo = (payload, singleField) => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_STEP_TWO_ERRORS,
        payload,
        singleField,
    })
    return store.getState().v2_step_two_errors.errors
}

export const validateStepThree = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_STEP_THREE_ERRORS,
        payload,
    })
}

export const validateStepFour = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_STEP_FOUR_ERRORS,
        payload,
    })
}

export const validateStepFive = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_STEP_FIVE_ERRORS,
        payload,
    })
}

export const validateStepSix = (payload, checkRequired) => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_STEP_SIX_ERRORS,
        payload,
        checkRequired,
    })
    return store.getState().v2_step_six_errors.errors
}

export const validateProfileView = (payload, checkRequired) => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_PROFILE_ERRORS,
        payload,
        checkRequired,
    })
    return store.getState().profileViewErrors
}

export const validateSkycopCareView = (payload, checkRequired) => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_SKYCOPCARE_ERRORS,
        payload,
        checkRequired,
    })
    return store.getState().sc_errors
}

export const validatePaymentView = (
    payload,
    checkRequired,
    options
) => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_PAYMENT_ERRORS,
        payload,
        checkRequired,
        options,
    })
    return store.getState().paymentViewErrors
}

export const setV2Claim = claim => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_V2_CLAIM,
        payload: claim,
    })
}

export const setTopBarState = data => dispatch => {
    dispatch({
        type: V2_ACTIONS.TOP_BAR,
        payload: data,
    })
}

export const showModal = (modalType, props) => dispatch => {
    dispatch({
        type: V2_ACTIONS.SHOW_MODAL,
        modalType,
        props,
    })
}

export const setSignaturePageConfig = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_SIGNATURE_PAGE_CONFIG,
        payload,
    })
}

export const resetSignaturePageConfig = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.RESET_SIGNATURE_PAGE_CONFIG,
        payload,
    })
}

export const setLeavingModalClaimId = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.LEAVING_MODAL,
        payload,
    })
}

export const setReferralModalShareCode = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_REFERRAL_SHARE_CODE,
        payload,
    })
}

export const setPromoCode = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_PROMO_CODE,
        payload,
    })
}

export const setPromoCodeInvalid = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_PROMO_CODE_INVALID,
    })
}

export const setSmartDelayPassengerDetails = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_SMART_DELAY_PASSENGER_DETAILS,
        payload,
    })
}

export const setSmartDelayFlightDetailsErrors = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_SMART_DELAY_FLIGHT_DETAILS_ERRORS,
        payload,
    })
}

export const setSmartDelayFlightDetailsRegisteredFlightIds = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_SMART_DELAY_FLIGHT_DETAILS_REGISTERED_FLIGHT_IDS,
        payload,
    })
}

export const setSmartDelayFlightDetails = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_SMART_DELAY_FLIGHT_DETAILS,
        payload,
    })
}

export const hideModal = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.HIDE_MODAL,
    })
}

export const setSignature = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_SIGNATURE,
        payload,
    })
}

export const setPostedClaim = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.POSTED_CLAIM,
        payload,
    })
}

export const resetPostedClaim = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.RESET_POSTED_CLAIM,
    })
}

var progressTimeout
export const setProgress = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.PROGRESS_BAR,
        payload,
    })

    if (progressTimeout) {
        clearTimeout(progressTimeout)
    }

    progressTimeout = setTimeout(() => {
        dispatch({
            type: V2_ACTIONS.PROGRESS_BAR,
            payload,
            prev: payload,
        })
    }, 2000)
}

export const resetProgress = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.RESET_PROGRESS_BAR,
    })

    if (progressTimeout) {
        clearTimeout(progressTimeout)
    }
}
export const setPayoutPlan = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_PAYOUT_PLAN,
        payload,
    })
}

export const setUsedAirports = (payload, reset) => dispatch => {
    dispatch({
        type: V2_ACTIONS.USED_AIRPORTS,
        payload,
        reset: !!reset,
    })

    return Promise.resolve()
}

export const setSelectedAirports = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SELECTED_AIRPORTS,
        payload,
    })
}

export const setUsedAirlines = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.USED_AIRLINES,
        payload,
    })

    return Promise.resolve(payload)
}

export const postCodeFieldCheck = () => dispatch => {
    let isEnabledPostCode = false
    const supportedPostCodes = store.getState().supportedPostcodes
    const selectedAirlines = store.getState().v2_selectedAirlines

    supportedPostCodes.forEach(supported => {
        selectedAirlines.forEach(selected => {
            if (selected.iata === supported.toUpperCase()) {
                isEnabledPostCode = true
            }
        })
    })

    dispatch({
        type: V2_ACTIONS.ENABLED_POSTCODE,
        payload: isEnabledPostCode,
    })
}

export const setSelectedAirlines = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SELECTED_AIRLINES,
        payload,
    })

    return Promise.resolve()
}

export const addDocuments = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_FLIGHT_DOCUMENTS,
        payload,
    })
}

export const removeDocuments = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.CLEAR_FLIGHT_DOCUMENTS,
    })
}

export const setPrice = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_PRICE,
        payload,
    })
}

export const setPartnerID = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_PARTER_ID,
        payload,
    })
}

export const setArrivalAirport = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_ARRIVAL_AIRPORT,
        payload,
    })
}

export const setDepartureAirport = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_DEPARTURE_AIRPORT,
        payload,
    })
}

export const setDepartureTime = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_DEPARTURE_TIME,
        payload,
    })
}

export const setReservationNumber = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_RESERVATION_NUMBER,
        payload,
    })
}

export const setProblemFLights = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_PROBLEM_FLIGHTS_COUNT,
        payload,
    })
}

export const addComment = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_COMMENT,
        payload,
    })
}

export const clearSuggestedFlight = arrayIndex => {
    store.dispatch({
        type: V2_ACTIONS.CLEAR_SPECIFIC_SUGGESTIONS,
        arrayIndex,
    })
}

export const clearAllSuggestedFlights = () => {
    store.dispatch({
        type: V2_ACTIONS.CLEAR_SUGGESTED_FLIGHTS,
    })
}

export const setToClaimable = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_CLAIMABLE,
    })
}

export const toggleTermsAndConditions = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.TOGGLE_TERMS_CONDITIONS,
    })
}

export const addPassengers = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_PASSENGERS,
        payload,
    })
}

export const addFlights = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_FLIGHTS,
        payload,
    })
}

export const addDetails = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_DETAILS,
        payload,
    })
}

export const setUser = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_USER_DATA,
        payload,
    })
}

export const setPassengersDetails = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_PASSENGER_DETAILS,
        payload,
    })
}

export const setFlightDetails = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_FLIGHT_DETAILS,
        payload,
    })
}

export const clearFlightDetails = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.CLEAR_FLIGHT_DETAILS,
    })
}

export const setDisruptionReason = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_DISRUPTION_REASON,
        payload,
    })
}

export const setProvidedReason = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_PROVIDED_REASON,
        payload,
    })
}

export const createUser = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_USER_DATA,
        payload,
    })
}

export const addUserDocuments = file => dispatch => {
    dispatch({
        type: V2_ACTIONS.ADD_USER_DOCUMENTS,
        file,
    })
}

export const setOnboardingSlider = (
    currentIndex,
    translateValue
) => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_ONBOARDING_SLIDER_STEPS,
        currentIndex,
        translateValue,
    })
}

export const resetAmountDistance = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_CLAIM_AMOUNT,
        payload: '',
    })
    dispatch({
        type: V2_ACTIONS.SET_FLIGHT_DISTANCE,
        payload: '',
    })
}

export const setClaimAmounts = (amount, passengers) => dispatch => {
    const totalAmount =
        passengers.length > 0 ? (passengers.length + 1) * amount : amount
    dispatch({
        type: V2_ACTIONS.SET_TOTAL_CLAIM_AMOUNT,
        payload: totalAmount,
    })
    dispatch({
        type: V2_ACTIONS.SET_CLAIM_AMOUNT,
        payload: amount,
    })
}

export const setTotalClaimAmount = amount => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_TOTAL_CLAIM_AMOUNT,
        payload: amount,
    })
}

export const setSelectedPayout = id => dispatch => {
    dispatch({
        type: V2_ACTIONS.SELECTED_PAYOUT,
        payload: id,
    })
}

export const setNotSupportedAirlines = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_NOT_SUPPORTED_AIRLINES,
        payload,
    })
}

export const clearNotSupportedAirlines = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.CLEAR_NOT_SUPPORTED_AIRLINES,
    })
}

export const checkIfHasAccount = email => dispatch => {
    const url = '/api/email/check'
    const apiClient = getApiClient()
    const fd = new FormData()
    fd.append('email', email)

    return apiClient
        .post(url, fd)
        .then(response => {
            return !!_get(response, 'data.exist', false)
        })
        .catch(error => {
            return false
        })
}

export const getUUID = () => dispatch => {
    const url = '/api/get-uuid'
    const apiClient = getApiClient()

    return apiClient
        .get(url)
        .then(response => {
            dispatch({
                type: V2_ACTIONS.CLAIM_UUID,
                payload: response.data.uuid,
            })

            return response.data.uuid
        })
        .catch(error => {})
}

export const resetClaimDocuments = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.DOCUMENTS_CLEAR,
    })
}

export const setIsFastPayout = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.IS_FAST_SELECTED,
        payload,
    })
}

const getPlan = planId => {
    const selectedPayout = planId || store.getState().selectedPayout
    const payoutPlan = store.getState().v2_payoutPlan
    const payouts = store.getState().v2_payoutPlan

    let plan = payoutPlan && payoutPlan[0] && payoutPlan[0].percent
    if (payouts && selectedPayout) {
        const selectedPlan = payouts.find(p => p.id === selectedPayout)
        if (selectedPlan && selectedPlan.percent) {
            plan = selectedPlan.percent
        }
    }

    return plan
}

const getMultiplier = planId => {
    const selectedPayout = planId || store.getState().selectedPayout
    const payoutPlan = store.getState().v2_payoutPlan
    const payouts = store.getState().v2_payoutPlan

    let multiplier = payoutPlan && payoutPlan[0] && payoutPlan[0].multiplier
    if (payouts && selectedPayout) {
        const selectedPlan = payouts.find(p => p.id === selectedPayout)
        if (selectedPlan && selectedPlan.multiplier) {
            multiplier = selectedPlan.multiplier
        }
    }

    return multiplier
}

export const setPayoutDetails = (payload, planId) => dispatch => {
    const {
        amount,
        serviceFee,
        payout,
        plan = getPlan(planId),
        multiplier = getMultiplier(planId),
    } = payload

    if (amount && serviceFee && payout) {
        dispatch({
            type: V2_ACTIONS.SET_PAYOUT_DETAILS,
            payload: {
                amount,
                serviceFee,
                payout,
            },
        })
    } else {
        const newAmount = parseFloat(amount).toFixed(2)
        let newServiceFee =
            newAmount * (plan / 100) * (multiplier || serviceFeePercentage)
        let newPayout = newAmount - newServiceFee

        newServiceFee = parseFloat(
            Math.floor(newServiceFee * 100, 10) / 100
        ).toFixed(2)
        newPayout = parseFloat(Math.ceil(newPayout * 100) / 100, 10).toFixed(2)

        dispatch({
            type: V2_ACTIONS.SET_PAYOUT_DETAILS,
            payload: {
                amount: newAmount,
                serviceFee: newServiceFee,
                payout: newPayout,
            },
        })
    }
}

export const clearPayoutDetails = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.CLEAR_PAYOUT_DETAILS,
    })
}

export const resetV2Claim = claim => dispatch => {
    dispatch({
        type: V2_ACTIONS.RESET_V2_CLAIM,
        payload: claim,
    })
    dispatch(resetProgress())
    dispatch(clearStepOne())
    dispatch(clearStepTwo())
    dispatch(clearStepThree())
    dispatch(clearStepFour())
    dispatch(setArrivalAirport(null))
    dispatch(setDepartureAirport(null))
    dispatch(resetAmountDistance())
    dispatch(setSelectedAirports([]))
    dispatch(setUsedAirports([], true))
    dispatch(resetClaimDocuments())
    dispatch(setTotalClaimAmount(''))
    dispatch({
        type: V2_ACTIONS.GET_PAYOUT_PLANS,
        payload: null,
    })
    dispatch({
        type: V2_ACTIONS.SET_FAST_PAYOUT,
        payload: {
            payout: {},
            hasFast: false,
        },
    })
    dispatch(setSelectedPayout(null))
    dispatch({
        type: V2_ACTIONS.IS_FAST_SELECTED,
        payload: false,
    })
    dispatch({
        type: V2_ACTIONS.SET_DRAFT_EMAIL,
        payload: '',
    })
    dispatch({
        type: V2_ACTIONS.RESET_CLAIM_DRAFT_PROPS,
    })
    dispatch({
        type: V2_ACTIONS.RESET_CLAIM_DRAFT,
    })
    dispatch({
        type: V2_ACTIONS.RESET_CLAIM_UUID,
    })
    dispatch(getUUID())
    clearAllSuggestedFlights()
}

export const getClaimAmount = (from, to, passengers) => dispatch => {
    const url = '/api/claim/claimable-amount'
    const apiClient = getApiClient()

    let data = new FormData()

    if (!from || !to) {
        return
    }

    data.append('airports[0]', from)
    data.append('airports[1]', to)

    return apiClient
        .post(url, data)
        .then(res => {
            const { data } = res
            if (passengers && !!passengers.length) {
                const amount = (passengers.length + 1) * data.payload
                dispatch({
                    type: V2_ACTIONS.SET_CLAIM_AMOUNT,
                    payload: data.payload,
                })
                dispatch(setTotalClaimAmount(amount))
            } else {
                dispatch({
                    type: V2_ACTIONS.SET_CLAIM_AMOUNT,
                    payload: data.payload,
                })
                dispatch(setTotalClaimAmount(''))
            }
            dispatch({
                type: V2_ACTIONS.SET_REGULATION,
                payload: _get(data, 'context.regulation', ''),
            })
            dispatch({
                type: V2_ACTIONS.GET_PAYOUT_PLANS,
                payload: null,
            })
            dispatch({
                type: V2_ACTIONS.SET_FAST_PAYOUT,
                payload: {
                    payout: {},
                    hasFast: false,
                },
            })
            dispatch(setSelectedPayout(null))
            dispatch({
                type: V2_ACTIONS.IS_FAST_SELECTED,
                payload: false,
            })
        })
        .catch(err => console.log(err))
}

export const getFlightDistance = (from, to) => dispatch => {
    const url = '/api/airports-distance'
    const apiClient = getApiClient()

    let data = new FormData()

    if (!from || !to) {
        return
    }

    data.append('airports[0]', from)
    data.append('airports[1]', to)

    return apiClient
        .post(url, data)
        .then(res => {
            dispatch({
                type: V2_ACTIONS.SET_FLIGHT_DISTANCE,
                payload: res.data,
            })
        })
        .catch(err => console.log(err))
}

export const getAllDrafts = () => dispatch => {
    const url = `/api/account/draft-claims`
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    apiClient
        .get(url, { headers: { Authorization: authString } })
        .then(resp => {
            dispatch({
                type: V2_ACTIONS.DRAFTS_ALL,
                payload: _get(resp, 'data', []),
            })
        })
        .catch(err => console.log(err))
}

export const removeDraftItem = id => dispatch => {
    const url = `/api/account/draft-claim/${id}`
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()
    loadScreenOn()
    apiClient
        .delete(url, { headers: { Authorization: authString } })
        .then(() => {
            dispatch(getAllDrafts())
            loadScreenOff()
        })
        .catch(err => {
            loadScreenOff()
            console.log(err)
        })
}

export const removeUserDocument = id => {
    const url = `/api/delete-claim-draft-document/${id}`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .post(url)
            .then(res => {
                dispatch({
                    type: V2_ACTIONS.REMOVE_USER_DOCUMENT,
                    id,
                })
            })
            .catch(err => console.log(err))
    }
}

const userDocumentsLoading = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.DOCUMENTS_UPLOADING,
        payload,
    })
}

export const uploadUserDocument = (files, claimId) => {
    const url = `/api/account/add-claim-document/${claimId}`
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    return function(dispatch) {
        if (files && !!files.length) {
            return new Promise((resolve, reject) => {
                files.forEach((file, index) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = function() {
                        let blob = dataURItoBlob(reader.result)
                        let fd = new FormData()
                        fd.append('claim_document[file]', blob, file.name)
                        apiClient
                            .post(url, fd, {
                                headers: { Authorization: authString },
                            })
                            .then(response => {
                                if (index === files.length - 1) {
                                    resolve(response)
                                }
                            })
                            .catch(error => {
                                Raven.setUserContext({
                                    claimId: claimId,
                                    totalFiles: files.length,
                                    fileType: files
                                        .map(file => file.type)
                                        .join(', '),
                                })
                                Raven.captureException(
                                    'UPLOAD USER DOCUMENT ERROR: ' +
                                        JSON.stringify(error)
                                )
                                console.log(error)
                                reject(error)
                            })
                    }
                })
            })
        } else {
            return new Promise(resolve => resolve())
        }
    }
}

export function uploadDocumentAlternative(file, uuid) {
    const url = `/api/add-claim-draft-document`
    const apiClient = getApiClient()

    if (!file || !uuid) {
        return
    }

    return function(dispatch) {
        if (file) {
            return new Promise((resolve, reject) => {
                dispatch(userDocumentsLoading(true))
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = function() {
                    let blob = dataURItoBlob(reader.result)
                    let fd = new FormData()
                    fd.append(
                        'claim_draft_document[type]',
                        'p_rebooked_boarding_pass_or_itinerary'
                    )
                    fd.append('claim_draft_document[claimDraft]', uuid)
                    fd.append('claim_draft_document[file]', blob, file.name)
                    return apiClient
                        .post(url, fd, {
                            onUploadProgress: progressEvent => {
                                const progress = Math.round(
                                    (progressEvent.loaded * 100) /
                                        progressEvent.total
                                )
                                dispatch({
                                    type: V2_ACTIONS.UPDATE_USER_DOCUMENTS,
                                    file,
                                    progress: progress,
                                })
                            },
                        })
                        .then(res => {
                            dispatch({
                                type: V2_ACTIONS.UPDATE_USER_DOCUMENTS,
                                file,
                                id: res.data.id,
                            })
                            resolve(res.data.id)
                            dispatch(userDocumentsLoading(false))
                        })
                        .catch(err => {
                            dispatch({
                                type: V2_ACTIONS.REMOVE_USER_BY_NAME,
                                name: file.name,
                            })
                            dispatch(userDocumentsLoading(false))
                        })
                }
            })
        }
    }
}

export function uploadDocument(file, uuid) {
    const url = `/api/add-claim-draft-document`
    const apiClient = getApiClient()

    if (!file || !uuid) {
        return
    }

    return function(dispatch) {
        if (file) {
            return new Promise((resolve, reject) => {
                dispatch(userDocumentsLoading(true))
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = function() {
                    let blob = dataURItoBlob(reader.result)
                    let fd = new FormData()
                    fd.append('claim_draft_document[claimDraft]', uuid)
                    fd.append('claim_draft_document[file]', blob, file.name)
                    return apiClient
                        .post(url, fd, {
                            onUploadProgress: progressEvent => {
                                const progress = Math.round(
                                    (progressEvent.loaded * 100) /
                                        progressEvent.total
                                )
                                dispatch({
                                    type: V2_ACTIONS.UPDATE_USER_DOCUMENTS,
                                    file,
                                    progress: progress,
                                })
                            },
                        })
                        .then(res => {
                            dispatch({
                                type: V2_ACTIONS.UPDATE_USER_DOCUMENTS,
                                file,
                                id: res.data.id,
                            })
                            resolve(res.data.id)
                            dispatch(userDocumentsLoading(false))
                        })
                        .catch(err => {
                            dispatch({
                                type: V2_ACTIONS.REMOVE_USER_BY_NAME,
                                name: file.name,
                            })
                            dispatch(userDocumentsLoading(false))
                        })
                }
            })
        }
    }
}

export const checkUUID = uuid => dispatch => {
    const url = `/api/check-uuid/${uuid}`
    const apiClient = getApiClient()

    return apiClient
        .get(url)
        .then(response => {
            const status = _get(response, 'data.status', false)

            if (status) {
                dispatch(getUUID())
            }
            return status
        })
        .catch(error => {
            console.log(error)
            return true
        })
}

export const setClaimDraftId = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.CLAIM_DRAFT_ID,
        payload,
    })
}

export const setPreloadedClaimStep = step => dispatch => {
    dispatch({
        type: V2_ACTIONS.CLAIM_DRAFT_PRELOAD_STEP,
        step,
    })
}

export const getClaimDraftDocuments = id => dispatch => {
    const url = `/api/get-claim-draft-documents/${id}`
    const apiClient = getApiClient()

    return apiClient
        .get(url)
        .then(res => {
            const documents = _get(res, 'data', [])

            documents.forEach(doc => {
                let existFile = false
                const loadedDocs = store.getState().v2_user_documents

                if (loadedDocs.documents.length > 0) {
                    loadedDocs.documents.forEach(loadedDoc => {
                        if (loadedDoc.id === doc.id) {
                            existFile = true
                        }
                    })
                }

                if (!existFile) {
                    dispatch({
                        type: V2_ACTIONS.ADD_USER_DRAFT_DOCUMENTS,
                        file: { name: doc.original_name },
                        id: doc.id,
                        name: doc.original_name,
                    })
                }
            })
        })
        .catch(err => console.log(err))
}

export const saveClaimDraft = (uuid, data) => dispatch => {
    const url = `/api/claim-draft`
    const apiClient = getApiClient()
    let fd = new FormData()
    const locale = getLocale()
    const draftProps = data || store.getState().claimDraftProps
    const draftUser = _get(draftProps, 'user', {})
    const UUID = uuid || store.getState().claim_UUID
    const landingParameters = _get(store.getState(), 'landing_parameters', {})
    const claimableResponse = _get(
        store.getState().claimDraftProps,
        'claimableResponse',
        {}
    )

    const props = {
        ...draftProps,
        user: {
            ...draftUser,
            locale,
        },
        landing_parameters: landingParameters,
        claimable_response: claimableResponse,
    }

    if (!UUID || !_get(props, 'id', '')) {
        return Promise.resolve()
    }

    fd.append('claim_draft[id]', UUID)
    fd.append('claim_draft[draft]', JSON.stringify(props))

    return apiClient
        .post(url, fd)
        .then(res => {
            dispatch({
                type: V2_ACTIONS.DRAFT_SUCCESS,
                payload: true,
            })
            dispatch({
                type: V2_ACTIONS.SET_IS_TRANSPORT_AVAILABLE,
                payload: res.data.isTransportAvailable,
            })
            return res
        })
        .catch(error => {
            const errorMessage = _get(error, 'response.data.errors', null)
            if (errorMessage) {
                dispatch({
                    type: V2_ACTIONS.SET_DRAFT_SAVE_ERROR,
                    payload: true,
                })
            }
        })
}

export const setClaimDraftProps = payload => dispatch => {
    const draftProps = store.getState().claimDraftProps
    const arrayPaths = Object.keys(paths.V2).map(key => paths.V2[key])
    const currPathIndex = arrayPaths.indexOf(window.location.pathname)

    const prevMaxPathIndex = arrayPaths.indexOf(
        _get(draftProps, 'componentState.maxPath', '/')
    )
    const maxPath =
        prevMaxPathIndex < currPathIndex
            ? arrayPaths[currPathIndex]
            : arrayPaths[prevMaxPathIndex]
    const isLoggedIn = _get(store.getState().auth, 'authenticated', false)

    dispatch({
        type: V2_ACTIONS.SET_CLAIM_DRAFT_PROPS,
        payload: {
            ...payload,
            componentState: {
                ...payload.componentState,
                lastPath: window.location.pathname,
                maxPath: maxPath || window.location.pathname,
                isLoggedIn,
            },
        },
    })
}

export const getClaimDraft = id => dispatch => {
    const url = `/api/claim-draft/${id}`
    const apiClient = getApiClient()
    return apiClient
        .get(url)
        .then(res => {
            const locale = _get(res, 'data.draft.user.locale', 'en')

            if (_get(res, 'data.expired', false)) {
                browserHistory.push(paths.V2.DRAFT_EXPIRED)
            } else {
                dispatch(setLanguage(locale))
                dispatch(setClaimDraftId(id))
                dispatch({
                    type: V2_ACTIONS.GET_CLAIM_DRAFT,
                    payload: _get(res, 'data.draft', null),
                })
                dispatch(
                    setLandingParameters(
                        _get(res, 'data.draft.landing_parameters', null)
                    )
                )
            }
        })
        .catch(err => {
            if (getStatus(err) === 404) {
                browserHistory.push(paths.V2.DRAFT_EXPIRED)
            } else {
                Raven.captureException(
                    `Failed To Load Draft, ID: ${id}. ${JSON.stringify(err)}`
                )
            }
        })
        .finally(() => {
            loadScreenOff()
        })
}

export const sendClaimDraftEmail = (uuid, email) => dispatch => {
    const url = `/api/send-claim-draft/${uuid}/${email}`
    const apiClient = getApiClient()

    apiClient.post(url).catch(error => {
        const errorMessage = _get(error, 'response.data.error', null)

        if (errorMessage) {
            dispatch({
                type: V2_ACTIONS.SET_DRAFT_EMAIL_ERROR,
                payload: true,
            })
        }
    })
}

export const clearClaimDraftErrors = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.CLEAR_DRAFT_ERRORS,
    })
}

export const postPayoutPlan = (
    amount,
    payout_id,
    claim_id,
    reload = false
) => dispatch => {
    const url = `/api/claim/payout`
    const apiClient = getApiClient()
    let fd = new FormData()

    if (!payout_id || !claim_id) {
        return Promise.resolve()
    }

    fd.append('claim_payout[amount]', amount)
    fd.append('claim_payout[claim]', claim_id)
    fd.append('claim_payout[payout]', payout_id)

    const payouts = store.getState().v2_payoutPlan
    const selectedPayout = payout_id || store.getState().selectedPayout

    if (payouts && selectedPayout) {
        const isFast = (payouts || []).find(p => p.id === selectedPayout)
        if (isFast && !!isFast.is_fast_payout) {
            dispatch({
                type: V2_ACTIONS.IS_FAST_SELECTED,
                payload: true,
            })
        } else {
            dispatch({
                type: V2_ACTIONS.IS_FAST_SELECTED,
                payload: false,
            })
        }
    }

    apiClient
        .post(url, fd)
        .then(response => {
            if (reload) {
                window.location.reload()
            }
        })
        .catch(error => {
            Raven.setUserContext({
                claimId: claim_id,
                payout: payout_id,
                amount,
            })
            Raven.captureException(
                'V2 PAYOUT POST FAILED:' + JSON.stringify(error)
            )
            if (reload) {
                window.location.reload()
            }
        })
}

export const postV2Claim = (d, passengerLength) => dispatch => {
    loadScreenOn()
    const data = d
    const url = '/api/new-claim'
    const apiClient = getApiClient()

    const getRavenContext = () => ({
        userEmail: data.get('claim[user][email]'),
        userPhone: data.get('claim[user][phone]'),
        airportArrival: data.get('claim[airportArrival]'),
        airportDeparture: data.get('claim[airportDeparture]'),
        price: data.get('claim[price]'),
        isV2Claim: 'true',
    })

    const appendData = (name, value) => data.append(name, value)
    appendAnalyticsCookie('GASession', 'sessionId', appendData)
    appendAnalyticsCookie('MediumCookie', 'medium', appendData)
    appendAnalyticsCookie('SourceCookie', 'source', appendData)
    appendAnalyticsCookie('KeywordCookie', 'keyword', appendData)
    appendAnalyticsCookie('CampaignCookie', 'campaign', appendData)

    data.append(
        'claim[partnerId]',
        getCookie('idev') ? getCookie('idev').split('-')[0] : ''
    )

    const mediumCookie = getCookie('partner_medium')
    if (mediumCookie) {
        data.append('claim[metadata][medium]', mediumCookie)
    }

    const landingParameters = _get(store.getState(), 'landing_parameters', null)

    if (landingParameters) {
        data.append(
            'claim[landingParameters]',
            JSON.stringify(landingParameters)
        )
    }

    data.append(
        'claim[landingParametersCookie]',
        getCookie('idev_p') ? getCookie('idev_p') : null
    )

    const successHandler = response => {
        const clickId = getCookie('partner_tid1')
        const countries = _get(store.getState(), 'countries.payload', [])
        const userCountryID = data.get('claim[user][country]')
        const userCity = data.get('claim[user][city]')
        const userAddress = data.get('claim[user][address]')
        const userCountry = countries.filter(c => c.id === userCountryID)[0]
        const fullAddress = `${userAddress}, ${userCity}, ${
            userCountry ? userCountry.title : ''
        }`
        const updatedClaim = {
            claimId: response.id,
            userName: response.user.name,
            userSurname: response.user.surname,
            userLanguage: data.get('claim[user][locale]') || getStorage('lang'),
            userBirthdate: data.get('claim[user][birthdate]'),
            userEmail: data.get('claim[user][email]'),
            userAddress: fullAddress,
            passengerLength,
            flightproblem: data.get('claim[details][0][value]'),
            delayedfor: data.get('claim[details][1][value]'),
            reasonbyairlines:
                data.get('claim[details][3][value]') ||
                data.get('claim[details][2][value]'),
            carrier: data.get('claim[flights][0][airline]'),
            origin: data.get('claim[airportDeparture]'),
            destination: data.get('claim[airportArrival]'),
        }

        const auth = store.getState().auth
        if (auth && auth.authenticated) {
            dispatch(getUserClaims())
        }

        triggerAffiliatePixel(clickId, updatedClaim.claimId)
        deleteAffiliateCookies()
        const airports = [
            ...store.getState().v2_UsedAirports,
            ...store.getState().v2_selectedAirports,
        ]
        const airlines = [
            ...store.getState().v2_UsedAirlines,
            ...store.getState().v2_selectedAirlines,
        ]
        postClaimSuccessTransaction(
            updatedClaim,
            dispatch,
            true,
            airlines,
            airports,
            store.getState().v2_total_amount
        )
        process.env.REACT_APP_USE_ADDITION_TRACKERS === 'true' &&
            window.fbq &&
            window.fbq('track', 'CompleteRegistration')
        dispatch(
            logKibana(
                'v2_user_adblock_and_ga',
                'POST /new-claim',
                'warning',
                getAdblockGaStatus()
            )
        )
    }

    const errorHandler = (error, stringError, initialText) => {
        const errorMessage = _get(error, 'response.data.error', null)
        const errorsMessage = _get(error, 'response.data.errors', null)
        const claimErrors = errorsMessage || errorMessage

        if (claimErrors) {
            const duplicateClaim = _get(
                claimErrors,
                'problemClaimFlight[0]',
                null
            )
            const userErrors = _get(claimErrors, 'user', null)
            const flightsErrors = _get(claimErrors, 'flights', null)

            if (duplicateClaim) {
                dispatch({
                    type: DUPLICATE_CLAIM,
                    payload: _get(claimErrors, 'problemClaimFlight[1]', null),
                })
                dispatch({
                    type: V2_ACTIONS.SKIP_SIGNATURE,
                    payload: true,
                })
                browserHistory.push(paths.V2.DUPLICATE_PAGE)
                return {
                    isDuplicate: true,
                }
            } else if (userErrors || flightsErrors) {
                dispatch(setClaimErrors(claimErrors))
                return {
                    isClaimErrors: true,
                }
            } else {
                Raven.setUserContext(getRavenContext())
                Raven.captureException(
                    `${initialText} FAILED DUPLICATE CHECK. /error Redirect: ${stringError}`
                )
                dispatch(
                    logKibana(
                        'v2_transaction_create_failed',
                        'POST /new-claim',
                        'error',
                        stringError
                    )
                )
            }
        } else {
            Raven.setUserContext(getRavenContext())
            Raven.captureException(
                `${initialText} /error Redirect. UNKNOWN ERROR MESSAGE: ${stringError}`
            )
            dispatch(
                logKibana(
                    'v2_CLAIM_POST_unhandled',
                    'POST /new-claim',
                    'error',
                    stringError
                )
            )
            //@TODO error page?
            //browserHistory.push('/error?q=claim');
        }
    }

    return apiClient
        .post(url, data)
        .then(({ data }) => {
            dispatch(setPostedClaim(data))
            const isDraft = _get(data, 'draft', false)
            const amount = _get(data, 'amount', '')
            const payout = _get(data, 'amount_payable', '')

            if (isDraft) {
                dispatch({
                    type: V2_ACTIONS.SKIP_SIGNATURE,
                    payload: true,
                })
            }

            if (amount && payout) {
                dispatch(setPayoutDetails({ amount, payout }))
            }

            const resp = _get(data, 'draft', data)
            successHandler(resp)
            dispatch(resetV2Claim())
            // loadScreenOff()
            return data
        })
        .catch(err => {
            let statusCode = getStatus(err)
            let stringError = JSON.stringify(err)
            let initialText = `V2 CLAIM POST ERROR: ${statusCode}.`
            loadScreenOff()
            return errorHandler(err, stringError, initialText)
        })
}

export function getReasonsByAirline() {
    const url = `/api/claim/details/reason/l14`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .get(url)
            .then(response => {
                dispatch({
                    type: V2_ACTIONS.REASONS_BY_AIRLINE,
                    payload: response.data.payload,
                })
                return response.data.payload
            })
            .catch(error => {
                // @TODO: getReasonsByAirline errors
                // const statusCode = getStatus(error);
                // if (statusCode && statusCode !== 404) {
                //     Raven.setUserContext({
                //         reasonId: id,
                //         stepNumber: stepNr
                //     });
                //     Raven.captureException("FAILED TO GET CLAIM REASON: " + JSON.stringify(error));
                // }
            })
    }
}

export function uploadUserSignature(data, isLegal) {
    let url
    const apiClient = getApiClient()
    const claimId = _get(store.getState().v2_posted_claim, 'id', null)
    const payoutId = store.getState().selectedPayout
    const amount = _get(store.getState().v2_posted_claim, 'amount', null)

    if (isLegal) {
        url = `/api/legal-agreement-signature`
    } else {
        url = `/api/agreement-signature`
    }

    return function(dispatch) {
        return apiClient
            .post(url, data)
            .then(res => {
                dispatch(postPayoutPlan(amount, payoutId, claimId))
                return res
            })
            .catch(error => {
                const { response } = error

                try {
                    const dataArray = Object.fromEntries(data.entries())
                    const logData = [
                        { error },
                        { response },
                        { data: dataArray },
                    ]

                    logRaw(logLevel.warning, 'upload_passenger_signature', {
                        sUrl: `POST ${url}`,
                        sData: JSON.stringify(logData),
                    })
                } catch (e) {}
            })
    }
}

export const uploadUserSignatures = data => {
    const url = `/api/agreements-signature`

    const apiClient = getApiClient()
    const claimId = _get(store.getState().v2_posted_claim, 'id', null)
    const payoutId = store.getState().selectedPayout
    const amount = _get(store.getState().v2_posted_claim, 'amount', null)

    return function(dispatch) {
        return apiClient
            .post(url, data)
            .then(res => {
                dispatch(postPayoutPlan(amount, payoutId, claimId))
                return res
            })
            .catch(error => {
                const { response } = error

                try {
                    const dataArray = Object.fromEntries(data.entries())
                    const logData = [
                        { error },
                        { response },
                        { data: dataArray },
                    ]

                    logRaw(logLevel.warning, 'upload_passenger_signature', {
                        sUrl: `POST ${url}`,
                        sData: JSON.stringify(logData),
                    })
                } catch (e) {}
            })
    }
}

export const setLoginLoading = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.LOGIN_LOADING,
        payload,
    })
}

export function loginUser(
    idEntry,
    password,
    invalidPassText,
    redirect,
    callback
) {
    const url = '/api/login'
    const apiClient = getApiClient()
    let data = new FormData()

    data.append('username', idEntry)
    data.append('password', password)

    return function(dispatch) {
        return apiClient
            .post(url, data)
            .then(response => {
                dispatch(generateUrlToken(response, invalidPassText)).then(
                    token => {
                        dispatch(setLoginLoading(false))
                        dispatch(getAllDrafts())
                        if (token && redirect) {
                            browserHistory.push(redirect)
                        }
                        if (typeof callback === 'function') {
                            callback()
                        }
                    }
                )
            })
            .catch(error => {
                // const statusCode = getStatus(error);
                // if (statusCode && statusCode !== 401) {
                // Raven.captureException("EMAIL LOGIN ERROR: " + JSON.stringify(error));
                // }
                dispatch(setLoginLoading(false))
                dispatch({
                    type: 'AUTH_ERROR',
                    payload: invalidPassText,
                })
                return error
            })
    }
}

export function getFieldErrors(error) {
    let errors = []
    for (var key in error) {
        if (error.hasOwnProperty(key)) {
            if (typeof error[key] !== 'string') {
                for (var nKey in error[key]) {
                    errors = [...errors, error[key][nKey]]
                }
            } else {
                errors = [...errors, error[key]]
            }
        }
    }

    return errors
}

export function registerUser(
    data,
    unableToRegisterText,
    emailExistsText,
    lang,
    callback
) {
    const url = '/api/register'
    const apiClient = getApiClient()

    data.append('user[locale]', getStorage('lang'))

    return function(dispatch) {
        return apiClient
            .post(url, data, acceptLang(lang))
            .then(response => {
                dispatch(setLoginLoading(false))
                dispatch(
                    generateUrlToken(response, false, unableToRegisterText)
                ).then(() => {
                    if (typeof callback === 'function') {
                        callback()
                    }
                    dispatch(getAllDrafts())
                })
            })
            .catch(error => {
                const errorMessage = _get(error, 'response.data.message', '')
                const errorField = _get(error, 'response.data.errors', '')
                if (errorMessage === 'email.exists') {
                    dispatch({ type: 'UNAUTH_USER' })
                    dispatch({
                        type: 'AUTH_ERROR',
                        payload: emailExistsText || unableToRegisterText,
                    })
                    dispatch(setLoginLoading(false))
                } else {
                    const errors = getFieldErrors(
                        _get(error, 'response.data.errors', [])
                    )
                    dispatch({ type: 'UNAUTH_USER' })
                    dispatch({
                        type: 'AUTH_ERROR',
                        payload: errors,
                    })
                    dispatch(setLoginLoading(false))
                }
                return errorField || errorMessage
            })
    }
}

export function loginExternalUser(userObj, callback) {
    const url = '/api/social-login'
    const apiClient = getApiClient()
    let data = new FormData()

    data.append('email', userObj.email)
    data.append('firstName', userObj.firstName)
    data.append('lastName', userObj.lastName)
    data.append('socialId', userObj.socialId)
    data.append('type', userObj.type)
    data.append('locale', getStorage('lang') || 'en')

    return function(dispatch) {
        apiClient
            .post(url, data)
            .then(response => {
                dispatch(generateUrlToken(response)).then(() => {
                    if (typeof callback === 'function') {
                        callback()
                    }
                    dispatch(getAllDrafts())
                })
                dispatch(hideModal())
                dispatch({ type: 'REMOVE_ERROR' })
            })
            .catch(payload => {
                dispatch({ type: 'SOCIAL_LOGIN_ERROR', payload })
                return payload
            })
    }
}

export function generateUrlToken(response, message = '') {
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .get('/oauth/v2/token', {
                params: {
                    client_id: response.data.public_key,
                    client_secret: response.data.secret_key,
                    grant_type: 'http://skycop/grants/api_key',
                    api_key: response.data.api_key,
                },
            })
            .then(response => {
                const token = response.data.access_token
                dispatch({ type: 'AUTH_USER' })

                setStorage('login', true)
                setStorage('token', token)
                dispatch(getUserData())
                dispatch(getFullUserData())

                return Promise.resolve(token)
            })
            .catch(error => {
                // Raven.captureException("GENERATE URL TOKEN ERROR: " + JSON.stringify(error));
                dispatch({ type: 'UNAUTH_USER' })
                dispatch({
                    type: 'AUTH_ERROR',
                    payload: message,
                })
                removeStorage('login')
                removeStorage('token')
            })
    }
}

export const getRemappedUserFields = payload => {
    const {
        name,
        surname,
        birthdate,
        email,
        address,
        city,
        country,
        phone_code,
        post_code,
        phone,
        subscribe,
    } = payload

    const code = _get(phone_code, 'phone_code', '')
    const newPhone = code ? phone.replace(code, '') : phone

    return {
        name,
        surname,
        birthdate,
        email,
        address,
        city,
        country: _get(country, 'id', ''),
        phone: newPhone,
        phoneCode: _get(phone_code, 'id', ''),
        postCode: post_code,
        subscribe,
    }
}

export const getScannedClaimDraft = scanBase64Code => {
    const url = '/api/prefill'
    const token = getStorage('token')
    const authString = `Bearer ${token}`
    const apiClient = getApiClient()
    if (!scanBase64Code) return () => null
    const data = { data: scanBase64Code }
    return function(dispatch) {
        return apiClient
            .post(
                url,
                data,
                token && { headers: { Authorization: authString } }
            )
            .then(({ data }) => {
                const locale = _get(data, 'user.locale', 'en')
                const draftId = _get(data, 'id', null)
                dispatch(setLanguage(locale))
                dispatch(setClaimDraftId(draftId))
                dispatch({
                    type: V2_ACTIONS.GET_CLAIM_DRAFT,
                    payload: {
                        ...data,
                        componentState: {
                            isDirect: true,
                            stopsCount: 0,
                            stopsState: [],
                            lastPath: '/',
                        },
                    },
                })
            })
            .catch(error => {
                console.log('error', error)
            })
            .finally(() => {
                loadScreenOff()
            })
    }
}

export function getUserData() {
    const url = '/api/account/get-info/'
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    return function(dispatch) {
        if (!getStorage('token')) {
            return Promise.resolve()
        } else {
            return apiClient
                .get(url, { headers: { Authorization: authString } })
                .then(res => {
                    const email = _get(res, 'data.payload.email', '')
                    const payload = {
                        ...res.data.payload,
                        email: email === 'undefined' ? '' : email,
                    }
                    const user = getRemappedUserFields(payload)

                    const claim = {
                        user: {
                            ...user,
                        },
                    }

                    dispatch({
                        type: 'USER',
                        payload,
                    })
                    dispatch(hideModal())
                    dispatch(setV2Claim(claim))
                    //eslint-disable-next-line
                    getSkycopCareSubscriptions()

                    const draftProps = store.getState().claimDraftProps
                    const arrayPaths = Object.keys(paths.V2).map(
                        key => paths.V2[key]
                    )
                    if (
                        draftProps &&
                        draftProps.user &&
                        draftProps.id &&
                        _get(draftProps, 'componentState.maxPath', '/') !==
                            '/' &&
                        arrayPaths
                            .slice(1, 7)
                            .includes(_get(window, 'location.pathname', '/'))
                    ) {
                        const newDraftProps = {
                            ...draftProps,
                            user: {
                                ...user,
                            },
                        }

                        store.dispatch(
                            saveClaimDraft(draftProps.id, newDraftProps)
                        )
                    }
                    return payload
                })
                .catch(error => {
                    const errStatus = getStatus(error)
                    if (errStatus === 401) {
                        dispatch(logout())
                    } else {
                        Raven.captureException(
                            `GET USER INFO ERROR, STATUS: ${errStatus}, ${JSON.stringify(
                                error
                            )}`
                        )
                        console.log(error)
                    }
                })
        }
    }
}

export function getPayoutPlans(
    amount,
    airlines = [],
    withRegulation = false,
    flightNumber = null,
    flightAirline = null,
    flightDate = null,
    airportDeparture = null,
    airportArrival = null,
    claimPayoutId = null,
    claimId = null
) {
    const url = `/api/claim/payouts`
    const apiClient = getApiClient()
    let fd = new FormData()

    if (!amount && !withRegulation) {
        return
    }

    fd.append('amount', amount)
    const uniquAirlines = airlines.filter(onlyUnique)
    uniquAirlines.forEach((a, i) => {
        if (!!a) {
            fd.append(`airlines[${i}]`, a)
        }
    })

    if (
        flightDate &&
        flightNumber &&
        flightAirline &&
        airportArrival &&
        airportDeparture
    ) {
        fd.append('claim_data[flightNumber]', flightNumber)
        fd.append('claim_data[flightAirline]', flightAirline)
        fd.append('claim_data[flightDate]', flightDate)
        fd.append('claim_data[airportDeparture]', airportDeparture)
        fd.append('claim_data[airportArrival]', airportArrival)

        if (claimId) {
            fd.append('claim_data[claimId]', claimId)
        }
    }

    return function(dispatch) {
        apiClient
            .post(url, fd)
            .then(({ data }) => {
                const { payload } = data
                const claimPayout = (payload || []).filter(
                    p => p.id === claimPayoutId
                )[0]
                const regularPayout = (payload || []).filter(
                    p => !p.is_fast_payout
                )[0]
                const hasFast =
                    isFeatureEnabled(featureToggles.featureFastPayout) &&
                    (payload || []).filter(p => !!p.is_fast_payout).length > 0
                const selectedPayout = store.getState().selectedPayout

                dispatch({
                    type: V2_ACTIONS.GET_PAYOUT_PLANS,
                    payload: payload,
                })
                if (payload.length > 1) {
                    dispatch({
                        type: V2_ACTIONS.SET_FAST_PAYOUT,
                        payload: {
                            payout: regularPayout || {},
                            hasFast,
                        },
                    })
                } else {
                    dispatch({
                        type: V2_ACTIONS.SET_FAST_PAYOUT,
                        payload: {
                            payout: regularPayout || {},
                            hasFast: false,
                        },
                    })
                    dispatch(setSelectedPayout((regularPayout || {}).id))
                    dispatch({
                        type: V2_ACTIONS.IS_FAST_SELECTED,
                        payload: false,
                    })
                }

                if (claimPayout && claimPayout.id && !selectedPayout) {
                    dispatch(setSelectedPayout(claimPayout.id))
                    dispatch({
                        type: V2_ACTIONS.IS_FAST_SELECTED,
                        payload: !!claimPayout.is_fast_payout,
                    })
                } else if (
                    regularPayout &&
                    regularPayout.id &&
                    !selectedPayout
                ) {
                    dispatch(setSelectedPayout(regularPayout.id))
                    dispatch({
                        type: V2_ACTIONS.IS_FAST_SELECTED,
                        payload: false,
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
}

function convertDate(date) {
    if (date) {
        return moment(date).format('YYYY-MM-DD')
    }
}

export function checkIfClaimable(claim, lang, step) {
    const {
        airportArrival,
        airportDeparture,
        flights,
        details,
        problemClaimFlight,
    } = claim
    const apiClient = getApiClient()

    const url = `/api/claim/claimable`
    let data = new FormData()

    if (step) {
        data.append('claimable_array[step]', step)
    }

    if (airportArrival && airportDeparture) {
        data.append('claimable_array[airportArrival]', airportArrival)
        data.append('claimable_array[airportDeparture]', airportDeparture)
    }

    if (flights && !!flights.length) {
        if (flights.length > 1) {
            flights.forEach(flight => {
                data.append(
                    `claimable_array[connectingFlights][${flight.flightOrder}][airportDeparture]`,
                    flight.airportDeparture
                )
                data.append(
                    `claimable_array[connectingFlights][${flight.flightOrder}][airportArrival]`,
                    flight.airportArrival
                )
                if (flight.airline) {
                    data.append(
                        `claimable_array[connectingFlights][${flight.flightOrder}][airline]`,
                        flight.airline
                    )
                }
            })
        }

        if (problemClaimFlight >= 0 && flights[problemClaimFlight]) {
            const { timeDeparture, airline, number } = flights[
                problemClaimFlight
            ]

            if (timeDeparture && airline && number) {
                data.append(
                    `claimable_array[flightDate]`,
                    convertDate(timeDeparture)
                )
                data.append(`claimable_array[airline]`, airline)
                data.append(`claimable_array[flightNumber]`, number)
            }
        }
    }

    if (details) {
        const reasons = details.reduce((reasonsArray, reason, index, array) => {
            let parentKey = 'reasons'
            let value = reason.value

            if (index !== 0) {
                parentKey = array[index - 1].value
            }

            reasonsArray.push({ parentKey, value })
            return reasonsArray
        }, [])

        reasons.forEach((reason, index) => {
            Object.keys(reason).forEach(key => {
                data.append(
                    `claimable_array[reasons][${index}][${key}]`,
                    reason[key]
                )
            })
        })
    }

    return function(dispatch) {
        return apiClient
            .post(url, data, { headers: { 'Accept-language': lang } })
            .then(res => {
                return res.data.payload
            })
            .catch(err => {
                console.log(err)
                return { claimable: true }
            })
    }
}

export function resetPassword(email) {
    const url = '/api/password-reset'
    const apiClient = getApiClient()
    let fd = new FormData()

    fd.append('email', email)

    return function(dispatch) {
        return apiClient
            .post(url, fd)
            .then(response => {
                return response.data
            })
            .catch(error => {
                const message = _get(error, 'response.data.message', '')
                if (message && message === 'email.not_found') {
                    dispatch({
                        type: 'AUTH_ERROR',
                        payload: 'common.label.email_invalid',
                    })
                } else {
                    Raven.captureException(
                        'RESET PASSWORD ERROR: ' + JSON.stringify(error)
                    )
                    dispatch({
                        type: V2_ACTIONS.UNAVAILABLE_SERVICE,
                        payload: true,
                    })
                }
            })
    }
}

export const clearAuthErrors = () => dispatch => {
    dispatch({
        type: 'AUTH_ERROR',
        payload: '',
    })
    dispatch({
        type: 'SOCIAL_LOGIN_ERROR',
        payload: null,
    })
}

export const sendFeedback = props => dispatch => {
    const url = '/api/feedback'
    const apiClient = getApiClient()
    const keys = Object.keys(props)
    let fd = new FormData()

    keys.forEach(key => {
        fd.append(`feedback[${key}]`, props[key])
    })

    return apiClient
        .post(url, fd)
        .then(res => {})
        .catch(err => console.log(err))
}

export const asyncPreloadAirport = (query, cb = () => null) => dispatch => {
    if (query && typeof cb === 'function') {
        dispatch(getAirports(query)).then(results => {
            const selection = ((results && results.payload) || []).filter(
                a => a.iata.toLowerCase() === query.toLowerCase()
            )[0]
            if (selection && selection.id) {
                const airportObj = createAirportObj(selection)
                cb(airportObj)
            }
        })
    }
}

export function updateUserDetails(data, lang) {
    const url = '/api/account/edit'
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .post(url, data, {
                headers: {
                    Authorization: authString,
                    'Accept-Language': lang || 'en',
                },
            })
            .then(response => {
                dispatch(getUserData())

                return response
            })
            .catch(error => {
                const errors = _get(error, 'response.data.form', null)
                dispatch({
                    type: 'AUTH_ERROR',
                    payload: errors,
                })
                if (!errors) {
                    Raven.captureException(
                        'UPDATE USER INFO ERROR. TYPE UNKNOWN: ' +
                            JSON.stringify(error)
                    )
                    browserHistory.push(`/error?q=other&ref=profile`)
                }
            })
    }
}

export const logDraftAction = (
    sIsPosted = false,
    sAction,
    sActionReason,
    sPath,
    sClaimId
) => dispatch => {
    const apiClient = getApiClient()
    const url = `/api/log/draft-flow`
    let fd = new FormData()

    const claimId = sClaimId || _get(store.getState(), 'V2Claim.id', '')
    fd.append('sClaimId', claimId)
    fd.append('sIsPosted', sIsPosted)
    fd.append('sAction', sAction)
    fd.append('sActionReason', sActionReason)
    fd.append('sPath', sPath)
    fd.append('level', 'WARNING')

    return apiClient
        .post(url, fd)
        .then(() => {
            return true
        })
        .catch(error => {
            Raven.captureException(
                'LOG DRAFT ACTION ERROR: ' + JSON.stringify(error)
            )
            return false
        })
}

export const getUnsubscribeInfo = draftId => {
    const apiClient = getApiClient()
    const url = `/api/claim-draft/${draftId}/unsubscribe`

    return apiClient
        .get(url)
        .then(resp => {
            return (resp || {}).data
        })
        .catch(e => {
            return false
        })
}

export const postUnsubscribeInfo = draftId => {
    const apiClient = getApiClient()
    const url = `/api/claim-draft/${draftId}/unsubscribe`

    return apiClient
        .post(url)
        .then(resp => {
            return (resp || {}).data
        })
        .catch(e => {
            return false
        })
}

export const getSuggestedFlights = (
    { depAirport, arrAirport, flightDate },
    arrayIndex,
    callback
) => dispatch => {
    const apiClient = getApiClient()
    const url = `/api/flight-list`

    let fd = new FormData()
    fd.append('depAirport', depAirport)
    fd.append('arrAirport', arrAirport)
    fd.append('flightDate', flightDate)

    return apiClient.post(url, fd).then(res => {
        const payload = _get(res, 'data.payload', [])
        dispatch({
            type: V2_ACTIONS.SUGGESTED_FLIGHTS,
            payload: payload,
            arrayIndex,
        })
        if (payload.length > 3) {
            callback(groupPayload(payload), depAirport, arrAirport)
        }
    })
}

export const getSkycopCareSubscriptions = () => {
    const apiClient = getApiClient()
    const url = `/api/account/skycopcare`
    const authString = `Bearer ${getStorage('token')}`

    return apiClient
        .get(url, { headers: { Authorization: authString } })
        .then(resp => {
            store.dispatch({
                type: V2_ACTIONS.SET_SKYCOPCARE_SUBS,
                payload: resp.data,
            })
            return resp.data
        })
        .catch(err => {
            console.log('Get Skycop Care Subscrptions Error', err)
        })
}

export const getSkycopCareSubscriptionsById = registryId => {
    const apiClient = getApiClient()
    const url = `/api/account/skycopcare/${registryId}/purchases`
    const authString = `Bearer ${getStorage('token')}`

    return apiClient
        .get(url, { headers: { Authorization: authString } })
        .then(resp => {
            return resp.data
        })
        .catch(err => {
            console.log('Get Skycop Care Subscrptions Error', err)
        })
}

export const getSkycopCareSubscriptionsHistory = id => {
    const apiClient = getApiClient()
    const url = `/api/account/skycopcare/${id}/purchases`
    const authString = `Bearer ${getStorage('token')}`

    return apiClient
        .get(url, { headers: { Authorization: authString } })
        .then(resp => {
            return resp.data
        })
        .catch(err => {
            console.log('Get Skycop Care Subscrptions Purschases Error', err)
        })
}

export const cancelSkycopCareSubscriptions = id => {
    const apiClient = getApiClient()
    const url = `/api/account/skycopcare/${id}/cancel`
    const authString = `Bearer ${getStorage('token')}`
    loadScreenOn()

    return apiClient
        .put(url, {}, { headers: { Authorization: authString } })
        .then(resp => {
            loadScreenOff()
            return resp.data
        })
        .catch(err => {
            loadScreenOff()
            console.log('Skycop Care Cancel Subscrptions Error', err)
        })
}
export const updateSkycopCareFamilyMembers = (id, childCustomers) => {
    const apiClient = getApiClient()
    const url = `/api/account/skycopcare/family`
    const authString = `Bearer ${getStorage('token')}`
    loadScreenOn()

    return apiClient
        .put(
            url,
            {
                id,
                childCustomers,
            },
            { headers: { Authorization: authString } }
        )
        .then(resp => {
            loadScreenOff()
            return resp.data
        })
        .catch(err => {
            loadScreenOff()
            console.log('Skycop Care Family Update Error')
            return err
        })
}
export const getSkycopCareProducts = promoType => {
    const apiClient = getApiClient()
    const url = promoType
        ? `/api/skycopcare/product-list?type=${promoType}`
        : `/api/skycopcare/product-list`

    return apiClient
        .get(url)
        .then(resp => {
            const payload = _get(resp, 'data', []).map(d => ({
                price: d.price,
                ...d.product,
                discount: _get(d.discounts, '[0]', null),
            }))
            store.dispatch({
                type: V2_ACTIONS.SET_SKYCOPCARE_PRODUCTS_V2,
                payload,
            })

            if (resp.status === 404) {
                return null
            }
            return payload
        })
        .catch(err => {
            console.log('SkycopCare Products Error', err)
            store.dispatch(
                logKibana(
                    'Error getting skycopcare products',
                    'GET skycopcare/product-list',
                    'warning',
                    JSON.stringify(err)
                )
            )
        })
}

export const getStripeProducts = (coupon = '') => {
    const apiClient = getApiClient()
    const url = `/api/sc/prices`

    let fd = new FormData()
    fd.append(`couponId`, coupon.toUpperCase())

    return apiClient
        .post(url, fd)
        .then(resp => {
            store.dispatch({
                type: V2_ACTIONS.SET_SKYCOPCARE_PRODUCTS,
                payload: _get(resp, 'data.prices', []),
            })

            return resp.data
        })
        .catch(err => {
            console.log('Stripe Error', err)
            store.dispatch(
                logKibana(
                    'Error getting stripe prices',
                    'GET /sc/prices',
                    'warning',
                    JSON.stringify(err)
                )
            )
        })
}

export const validateStripeCoupon = coupon => {
    const apiClient = getApiClient()
    const url = `/api/sc/coupon/${coupon.toUpperCase()}`

    return apiClient
        .get(url)
        .then(resp => {
            return resp.data
        })
        .catch(err => {
            const status = getStatus(err)
            if (status !== 400 && status !== 404) {
                console.log('Validate Stripe Coupon error', err)
                store.dispatch(
                    logKibana(
                        `Error searching for a coupon ${coupon}`,
                        'GET /sc/coupon',
                        'warning',
                        JSON.stringify(err)
                    )
                )
            }
        })
}

export const submitStripePayment = (
    productId,
    maxUsage,
    user,
    familyPlans,
    childCustomers,
    promoType
) => {
    const apiClient = getApiClient()
    const url = `/api/skycopcare`
    const lang = getStorage('lang')
    const defaultData = {
        productId,
        maxUsage,
        customer: {
            email: user.email,
            name: user.name,
            lastname: user.surname,
            countryCode: user.countryCode,
        },
    }

    const idevId = getCookie('idev') ? getCookie('idev').split('-')[0] : null
    const idevP = getCookie('idev_p') ? getCookie('idev_p') : null

    if (idevId !== null) {
        defaultData.partnerAffiliateCode = idevId
    }

    if (idevP !== null) {
        defaultData.landingParameters = idevP
    }

    const data = promoType
        ? JSON.stringify({ ...defaultData, campaignType: promoType })
        : familyPlans
        ? JSON.stringify({ ...defaultData, childCustomers })
        : JSON.stringify(defaultData)

    return apiClient
        .post(url, data, {
            headers: { 'Accept-language': lang },
        })
        .then(resp => {
            return resp.data
        })
        .catch(err => {
            console.log('Submit Stripe Payment Error', err)
            store.dispatch(
                logKibana(
                    'Error submiting stripe payment',
                    'POST /api/skycopcare',
                    'warning',
                    JSON.stringify(err) + JSON.stringify({ user })
                )
            )
        })
}

export const createStripeCustomer = user => {
    const apiClient = getApiClient()
    const url = `/api/sc/customer`
    let country = getCountryById(user.country) || {}
    let phoneCountry = getCountryById(user.phoneCode) || {}
    const lang = getStorage('lang')

    let fd = new FormData()
    fd.append(`name`, user.name)
    fd.append(`surname`, user.surname)
    fd.append(`phone`, `${phoneCountry.phone_code}${user.phone}`)
    fd.append(`countryCode`, country.code)
    fd.append(`email`, user.email)

    return apiClient
        .post(url, fd, { headers: { 'Accept-language': lang } })
        .then(resp => {
            return resp.data
        })
        .catch(err => {
            console.log('Create Customer Error', err)
            store.dispatch(
                logKibana(
                    'Error creating stripe customer',
                    'POST /sc/customer',
                    'warning',
                    JSON.stringify(err) + JSON.stringify({ user })
                )
            )
        })
}

export const createStripeSubscription = ({
    customerId,
    paymentMethodId,
    planId,
    user,
    passengers,
    setLoading,
    stripe,
    showModal,
    coupon,
}) => {
    const apiClient = getApiClient()
    const url = `/api/sc/subscribe`
    const affiliateId = getCookie('idev_sc')
    const plans = store.getState().sc_products
    const selectedPlan = plans.find(p => p.id === planId)
    const lang = getStorage('lang')

    let fd = new FormData()
    fd.append(`customerId`, customerId)
    fd.append(`paymentMethodId`, paymentMethodId)
    fd.append(`priceId`, planId)
    fd.append(`couponId`, coupon.toUpperCase())
    if (affiliateId) {
        fd.append(`affiliateId`, affiliateId)
    }

    if (passengers.length > 0) {
        passengers.forEach((pax, i) => {
            fd.append(`passengers[${i}][name]`, pax.name)
            fd.append(`passengers[${i}][surname]`, pax.surname)
            if (pax.email) {
                fd.append(`passengers[${i}][email]`, pax.email)
            }
        })
    } else {
        fd.append('passengers[]', '')
    }

    Object.keys(user).forEach((key, i) => {
        fd.append(`user[${key}]`, user[key])
    })

    let redirect = () => {
        if (selectedPlan && window && window.dataLayer) {
            const layerData = {
                event: 'SkycopCare-Purchase',
                planname: selectedPlan.title,
                monthvalue: selectedPlan.amountMonth / 100,
                planvalue: selectedPlan.amountYear / 100,
                currency: 'EUR',
            }
            window.dataLayer.push(layerData)
        }
        store.dispatch(
            logKibana(
                'Stripe Purchase Succeeded',
                'POST stripe.confirmCardPayment',
                'warning',
                'Purchase complete'
            )
        )
        deleteCookie('idev_sc', '*.skycop.com')
        deleteCookie('idev_sc', 'secure.skycop.com')
        deleteCookie('idev_sc', 'claim.skycop.com')
        deleteCookie('idev_sc', 'skycop.com')
        window.location.replace(
            `https://${
                isProduction() ? '' : 'stage-'
            }www.skycop.com${t`sc.redirect.path`}`
        )
    }

    if (selectedPlan && window && window.dataLayer) {
        const layerData = {
            event: 'SkycopCare-Selection',
            planname: selectedPlan.title,
            monthvalue: selectedPlan.amountMonth / 100,
            planvalue: selectedPlan.amountYear / 100,
            currency: 'EUR',
        }
        window.dataLayer.push(layerData)
    }

    return apiClient
        .post(url, fd, { headers: { 'Accept-language': lang } })
        .then(resp => {
            const requiresAction = _get(resp, 'data.requiresAction', false)
            if (requiresAction) {
                const secret = _get(resp, 'data.clientSecret', '')
                stripe
                    .confirmCardPayment(secret, {
                        payment_method: paymentMethodId,
                    })
                    .then(a => {
                        if (
                            _get(a, 'paymentIntent.status', 'failed') ===
                            'succeeded'
                        ) {
                            redirect()
                        } else if (_get(a, 'error', null)) {
                            const message = _get(
                                a,
                                'error.message',
                                t`sc.common.payment_failed`
                            )
                            store.dispatch(
                                showModal(modalTypes.stripeFailedModal, {
                                    message,
                                })
                            )
                            setLoading(false)
                            store.dispatch(
                                logKibana(
                                    'Stripe payment failed',
                                    'POST stripe.confirmCardPayment',
                                    'warning',
                                    message +
                                        JSON.stringify({
                                            user,
                                            customerId,
                                            passengers,
                                        })
                                )
                            )
                        }
                    })
                    .catch(sErr => {
                        console.log(sErr)
                        const message = _get(
                            sErr,
                            'error.message',
                            t`sc.common.payment_failed`
                        )
                        store.dispatch(
                            showModal(modalTypes.stripeFailedModal, { message })
                        )
                        setLoading(false)
                        store.dispatch(
                            logKibana(
                                'Stripe payment catch',
                                'POST stripe.confirmCardPayment',
                                'warning',
                                JSON.stringify(sErr) +
                                    JSON.stringify({
                                        user,
                                        customerId,
                                        passengers,
                                    })
                            )
                        )
                    })
            } else {
                redirect()
            }

            return resp.data
        })
        .catch(err => {
            setLoading(false)
            console.log('Subscription Error', err)
            if (getStatus(err) === 400) {
                store.dispatch(
                    showModal(modalTypes.stripeFailedModal, {
                        message: t`sc.common.payment_failed`,
                    })
                )
            } else {
                store.dispatch(
                    logKibana(
                        'Error creating stripe subscription',
                        'POST /sc/subscribe',
                        'warning',
                        JSON.stringify(err) +
                            JSON.stringify({ user, customerId, passengers })
                    )
                )
            }
        })
}

export const getScSubscriptions = () => {
    const apiClient = getApiClient()
    const url = `/api/account/sc/subscriptions`
    const authString = `Bearer ${getStorage('token')}`

    return apiClient
        .post(url, null, { headers: { Authorization: authString } })
        .then(resp => {
            store.dispatch({
                type: V2_ACTIONS.SET_SKYCOPCARE_SUBS,
                payload: resp.data,
            })
            return resp.data
        })
        .catch(err => {
            console.log('Get SC Subscrptions Error', err)
        })
}

export const getVirtualCards = () => {
    const apiClient = getApiClient()
    const url = `/api/account/virtual-card`
    const authString = `Bearer ${getStorage('token')}`

    return apiClient
        .get(url, { headers: { Authorization: authString } })
        .then(resp => {
            return resp.data
        })
        .catch(err => {
            console.log('Get Virtual Cards Error', err)
        })
}

export const setModalPromoCode = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_MODAL_PROMO_CODE,
        payload,
    })
}

export const submitPlannedTrip = data => {
    const apiClient = getApiClient()
    const url = `/api/account/planned-trips`
    const authString = `Bearer ${getStorage('token')}`

    return apiClient
        .post(url, data, { headers: { Authorization: authString } })
        .then(resp => {
            return resp.data
        })
        .catch(err => {
            console.log('Post Planned Trips Error', err)
            return
        })
}

export const getPlannedTrips = () => {
    const apiClient = getApiClient()
    const url = `/api/account/planned-trips`
    const authString = `Bearer ${getStorage('token')}`

    return apiClient
        .get(url, { headers: { Authorization: authString } })
        .then(resp => {
            return resp.data
        })
        .catch(err => {
            console.log('Post Planned Trips Error', err)
            return
        })
}

export const deletePlannedTrip = id => {
    const apiClient = getApiClient()
    const url = `/api/account/planned-trips/${id}`
    const authString = `Bearer ${getStorage('token')}`

    return apiClient
        .delete(url, { headers: { Authorization: authString } })
        .then(resp => {
            return resp.data
        })
        .catch(err => {
            console.log('Delete Planned Trips Error', err)
            return
        })
}

export const doItLaterBtnClick = () => dispatch => {
    dispatch({
        type: V2_ACTIONS.DO_IT_LATER_BTN_CLICK,
    })
}

export const setSorryModalPromoCode = payload => dispatch => {
    dispatch({
        type: V2_ACTIONS.SET_SORRY_MODAL_PROMO_CODE,
        payload,
    })
}
