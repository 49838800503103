import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import t from '../../../shared/translations'
import { getAboutUs } from '../../../shared/actions'
import { sendFeedback } from '../../../shared/actions/v2_actions'
import { v2DefaultConfig } from '../../../shared/theme/config'
import {
    toggleFooter,
    countTextPercentage,
    handleMobileInputFocus,
    handleMobileInputBlur,
} from '../../../main'
import { desktop } from '../../../shared/components/helpers/styled_queries'
import { Row, Col, ElementTitle } from '../../../shared/components/V2/Layout'
import CircleProgressBar from '../../../shared/components/V2/CircleProgressBar'
import NewSelectField from '../../../shared/components/V2/NewSelectField'
import { EmailIcon } from '../../../shared/components/V2/V2Icons'
import { V2Button } from '../../../shared/components/V2/V2Buttons'
// import Select from "react-select";

const Container = styled.div`
    margin: 0 auto;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 32px;

    ${desktop`
        width: 568px;
        border-radius: 8px;
        box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);
        background-color: #ffffff;
    `}

    .pd20 {
        padding: 20px;
    }

    div {
        .text-center {
            text-align: center;
        }
    }
`

const SocialContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 32px;
`

const IconContainer = styled.div`
    margin: 0 8px;

    svg {
        circle {
            fill: #353d51;
        }
    }
`

const H1 = styled.h1`
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: ${v2DefaultConfig.colors.darkText};
`

const ButtonContainer = styled.div`
    width: 100%;
    border-top: 1px solid #e4e7eb;
    padding: 32px;

    ${desktop`
        padding: 32px 174px;
    `}
`

const SubheaderContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;

    ${desktop`
        padding: 0 115px;
    `}
`

const Subheader = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #7f8fa4;

    span {
        font-weight: bold;
        color: #353d51;
    }
`

const TextareaContainer = styled.div`
    position: relative;
    width: 100%;

    ${desktop`
    `}
`

const Textarea = styled.textarea`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    min-height: 105px;
    border-radius: 6px;
    border: solid 1px #bcc5d3;
    padding: 10px 64px 10px 16px;
    outline: 0 none;

    &:focus {
        border: 1px solid ${({ theme }) => theme.colors.primaryColor};
        outline: 0 none;
    }

    &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #b4bdca;
    }
`

const CircleContainer = styled.div`
    position: absolute;
    width: 20px;
    height: 20px;
    right: 24px;
    bottom: 24px;
`

class ImprovePage extends React.Component {
    state = {
        comment: '',
        maxCount: 1500,
        rating: 4,
        answer: '',
        multi: true,
        multiValue: [],
        value: undefined,
    }

    componentDidMount() {
        const { aboutUs, getAboutUs } = this.props
        toggleFooter().hide()

        if (aboutUs) {
        } else {
            getAboutUs()
        }

        this.props.setParentState({
            nextDisabled: false,
            noBack: true,
            hideButton: true,
        })
    }

    componentWillUnmount() {
        toggleFooter().show()
    }

    handleCommentChange = e => {
        const { target } = e

        this.setState({ [target.name]: target.value })
    }

    handleRating = rating => {
        this.setState({ rating })
    }

    submitFeedback = () => {
        const { answer, rating } = this.state
        const { sendFeedback } = this.props

        sendFeedback({
            claim: 'ebe94896-6fe1-11e9-a808-922cf0cf0aaf',
            answer,
            rating: parseInt(rating, 10),
        })
    }

    getOptions = () => {
        const { aboutUs } = this.props

        if (!aboutUs) return []

        return aboutUs.map(option => ({
            value: option,
            label: t`${option}`,
        }))
    }

    handleOnChange = value => {
        const { multi } = this.state
        if (multi) {
            this.setState({ multiValue: value })
        } else {
            this.setState({ value })
        }
    }

    render() {
        const {
            comment,
            maxCount,
            answer,
            multi,
            multiValue,
            value,
        } = this.state

        const { primaryColor } = this.props

        return (
            <Container>
                <div className="pd20">
                    <SocialContainer>
                        <IconContainer>
                            <EmailIcon />
                        </IconContainer>
                    </SocialContainer>
                    <H1 className="text-center">Share the knowledge</H1>
                    <SubheaderContainer>
                        <Subheader>
                            Your friends and family may have experienced flight
                            disruptions too. Tell them about us!
                        </Subheader>
                    </SubheaderContainer>
                    <Row>
                        <Col>
                            <TextareaContainer>
                                <ElementTitle>
                                    Refer a person you know
                                </ElementTitle>
                                <NewSelectField
                                    hideArrow={true}
                                    selectProps={{
                                        isCreatable: true,
                                        multi,
                                        clearable: false,
                                        closeOnSelect: true,
                                        onChange: e => this.handleOnChange(e),
                                        value: multi ? multiValue : value,
                                        showNewOptionAtTop: false,
                                    }}
                                />
                                <Textarea
                                    placeholder="Let them know why you’re reffering them "
                                    value={comment}
                                    name="comment"
                                    onChange={this.handleCommentChange}
                                    maxLength={maxCount}
                                    onFocus={e => {
                                        e.preventDefault()
                                        handleMobileInputFocus()
                                    }}
                                    onBlur={e => {
                                        e.preventDefault()
                                        handleMobileInputBlur()
                                    }}
                                />
                                <CircleContainer>
                                    <CircleProgressBar
                                        percentage={countTextPercentage(
                                            comment.length,
                                            maxCount
                                        )}
                                        strokeColor={primaryColor}
                                        backgroundColor={'#dfe3e9'}
                                        strokeWidth={15}
                                    />
                                </CircleContainer>
                            </TextareaContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <NewSelectField
                                label="Where did you hear about us?"
                                errorText=""
                                selectProps={{
                                    name: 'answer',
                                    value: answer,
                                    clearable: false,
                                    isSearchable: false,
                                    isOpen: false,
                                    options: this.getOptions(),
                                    onChange: e => {
                                        this.setState({ answer: e.value })
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                <ButtonContainer>
                    <Row>
                        <V2Button onClick={this.submitFeedback}>
                            Submit your feedback
                        </V2Button>
                    </Row>
                </ButtonContainer>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        aboutUs: state.about_us,
        trans: state.translations,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getAboutUs, sendFeedback }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ImprovePage)
