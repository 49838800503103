import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import {
    loginPageShow,
    loginPageHide,
    loadScreenOff,
    loadScreenOn,
} from '../../../main'
import t from '../../../shared/translations'
import styled from 'styled-components'
import {
    tablet,
    desktop,
    mobile,
} from '../../../shared/components/helpers/styled_queries'
import { V2Button } from '../../../shared/components/V2/V2Buttons'
import { getApiClient } from '../../../main'
import { addBeforeUnload, removeBeforeUnload } from '../../../main'

const PageWrapper = styled.div`
border-radius: 8px;
box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);
background-color: #ffffff;

margin: 0 auto;
margin-top: 32px;
display: flex;
padding: 32px;
flex-direction: column;
justify-content: space-between;

${desktop`
    width: 864px;
`}

${tablet`
    box-shadow: none;
`}

${mobile`
    box-shadow: none;
`}
`

const Title = styled.div`
    font-family: Open Sans;
    font-size: 28px;
    font-weight: 400;
    line-height: 29.5px;
    text-align: center;
    color: #353d51;
    margin-bottom: 32px;
`

const TextAreaLabel = styled.div`
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.94px;
    text-align: left;
    color: #7f8fa4;
    margin-bottom: 12px;
`

const Textarea = styled.textarea`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    min-height: 105px;
    border-radius: 6px;
    border: solid 1px #bcc5d3;
    padding: 10px 64px 10px 16px;
    outline: 0 none;
    resize: none;
    margin-bottom: 32px;

    &:focus {
        border: 1px solid ${({ theme }) => theme.colors.primaryColor};
        outline: 0 none;
    }

    &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #aaa;
    }
`

const ButtonWrapper = styled.div`
    width: 100%;
    align-items: center;
    ${desktop`
    width: 245px;
   ;
`}
`

const SubmittedDescription = styled.div`
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.94px;
    text-align: center;
    color: #353d51;
`

const Button = styled(V2Button)`
    background: ${({ theme }) => theme.colors.primaryColor};

    ${({ disabled, theme }) =>
        disabled
            ? 'background-color: #f3f5f7;'
            : `background-color: ${theme.colors.primaryColor};
           `}
`

const CenterItems = styled.div`
    display: flex;
    justify-content: center;
`

const SpaceDiv = styled.div`
    height: 0px;
    ${desktop`
      height: 32px;
    ;
  `}
`

class AddReviewPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isSubmitted: false,
            reviewValue: '',
            trustpilot: null,
        }
    }

    componentDidMount() {
        const { location } = this.props
        if (location && location.query) {
            this.setState({ trustpilot: location.query.trustpilot })
        }
        loginPageShow(true)
        this.props.setParentState({
            isCentered: true,
            hideNav: true,
        })

        addBeforeUnload(() => this.beforeCloseTab())
    }

    componentDidUpdate() {
        window.onbeforeunload = e => {
            e.stopPropagation()
            e.preventDefault()
            this.beforeCloseTab()
            return null
        }
    }

    beforeCloseTab = () => {
        const { trustpilot } = this.state
        const apiClient = getApiClient()
        const url = `/api/trustpilot/${trustpilot}`
        apiClient
            .put(url, { comment: null })
            .then(() => {})
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                removeBeforeUnload()
            })
    }

    componentWillUnmount() {
        removeBeforeUnload()
        loginPageHide()
        this.props.setParentState({
            isCentered: false,
            hideNav: false,
        })
    }

    changeReviewValue = val => {
        this.setState({ reviewValue: val.target.value })
    }

    handleSave = () => {
        const { reviewValue, trustpilot } = this.state
        loadScreenOn()

        const apiClient = getApiClient()
        const url = `/api/trustpilot/${trustpilot}`
        apiClient
            .put(url, { comment: reviewValue })
            .then(() => {
                this.setState({ isSubmitted: true })
                removeBeforeUnload()
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                loadScreenOff()
            })
    }

    render() {
        const { trans } = this.props
        const { isSubmitted, reviewValue } = this.state

        if (!trans) {
            return null
        }

        return (
            <PageWrapper>
                <Helmet>
                    <title>{`Skycop - ${t`add.review.page.title`}`}</title>
                </Helmet>
                {!isSubmitted ? (
                    <div>
                        <Title>{t`add.review.page.title`}</Title>
                        <TextAreaLabel>
                            {t`add.review.page.description`}
                        </TextAreaLabel>
                        <Textarea
                            value={reviewValue}
                            onChange={this.changeReviewValue}
                            placeholder={t`add.review.page.textarea.placeholder`}
                        ></Textarea>
                        <CenterItems>
                            <ButtonWrapper>
                                <Button
                                    onClick={() => this.handleSave()}
                                    disabled={!reviewValue.length}
                                >
                                    {t`add.review.page.submit.button`}
                                </Button>
                            </ButtonWrapper>
                        </CenterItems>
                    </div>
                ) : (
                    <div>
                        <Title>{t`add.review.success.page.title`}</Title>
                        <SpaceDiv />
                        <SubmittedDescription>
                            {t`add.review.success.page.description1`}
                        </SubmittedDescription>
                        <SubmittedDescription>
                            {t`add.review.success.page.description2`}
                        </SubmittedDescription>
                        <SubmittedDescription>
                            {' '}
                            {t`add.review.success.page.description3`}
                        </SubmittedDescription>
                        <SpaceDiv />
                    </div>
                )}
            </PageWrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

export default connect(mapStateToProps)(AddReviewPage)
