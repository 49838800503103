import styled from 'styled-components'
import {
    mobile,
    tablet,
} from '../../../../shared/components/helpers/styled_queries'

export const RightHr = styled.hr`
    top: 18px;
    position: absolute;
    width: 50%;
    transform: translateX(100%);
    border-top: unset;
    padding: unset;
    margin: unset;
    height: -webkit-fill-available;

    background-image: ${({ isChecked, theme }) =>
        isChecked
            ? `linear-gradient(
        to right,
        ${theme.colors.primaryColor} 100%,
        rgb(20, 153, 84, 0) 0%
    )`
            : `
    linear-gradient(
        to right,
                 ${theme.colors.primaryColor || 'rgb(254, 171, 51)'} 40%,
        rgb(254, 171, 51, 0) 0%
)`};

    // background-image: linear-gradient(
    //     to right,
    //     rgb(254, 171, 51) 40%,
    //     rgb(254, 171, 51, 0) 0%
    // );
    background-position: top;
    background-size: 10px 2px;
    background-repeat: repeat-x;
`

export const LeftHr = styled.hr`
    top: 18px;
    position: absolute;
    width: 50%;
    border-top: unset;
    padding: unset;
    margin: unset;
    height: -webkit-fill-available;

    background-image: ${({ isChecked, theme }) =>
        isChecked
            ? `linear-gradient(
        to right,
        ${theme.colors.primaryColor} 100%,
        rgb(20, 153, 84, 0) 0%
    )`
            : `
    linear-gradient(
        to right,
         ${theme.colors.primaryColor || 'rgb(254, 171, 51)'} 40%,
        rgb(254, 171, 51, 0) 0%
)`};
    background-position: top;
    background-size: 10px 2px;
    background-repeat: repeat-x;
`

export const NextStepIndex = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    top: 8px;
    left: 16px;
`

export const CurrentStepIndex = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    top: 8px;
    left: 16px;
    color: #fff;
`

export const NextStepWrapper = styled.div`
    position: relative;

    svg {
        circle {
            stroke: ${({ theme }) => theme.colors.primaryColor || '#FEAB33'};
        }
    }
`

export const CurrentStepWrapper = styled.div`
    position: relative;


    // &:after {
    //     content: '';
    //     width: 100px;
    //     background: #fff;
    //     border-radius: 10px;
    //     top: -18px;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     border: 1px solid black;
    }
`

export const NavListContainer = styled.div`
    display: none;
    height: auto;
    margin-bottom: 20px;
    margin-top: 12px;
    border-bottom: 2px solid rgba(170, 176, 191, 0.3);

    ${tablet`
        display: flex;
    `}
    ${mobile`
        display: flex;
    `}

    .scroll-container {
        // overflow-x: scroll;
        width: 100%;

        ::-webkit-scrollbar {
            display: none;
        }
    }
`
export const NavList = styled.div`
    position: relative;
    display: inline-flex;
    flex-wrap: nowrap;
    touch-action: pan-x;

    width: 100%;

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`

// export const StyledHr = styled.hr`
//     background-image: linear-gradient(
//         to right,
//         #333 40%,
//         rgba(255, 255, 255, 0) 0%
//     );
//     background-position: top;
//     background-size: 10px 1px;
//     background-repeat: repeat-x;

//     // height: 48px;
//     // border: none;
//     // border-top: 1px dashed #cbd3df;
//     // color: #fff;
//     // height: 1px;
//     // margin: 20px 14px;
// `

export const NavItem = styled.div`
    position: relative;
    flex-grow: 1;
    // max-width: 70px;

    :last-child {
        margin-right: -20px;
    }

    :first-child {
        margin-left: -20px;
    }

    // &:not(:last-child) {
    //     margin-right: 24px;
    // }

    & .icon {
        display: flex;
        justify-content: center;
        margin-bottom: 8px;

        svg {
            mask-size: cover;
            -webkit-mask-size: cover;
        }
    }
`

export const Text = styled.p`
    color: ${({ disabled }) => (disabled ? '#AAB0BF' : '#525f7f')};
    text-align: center;
    font-weight: ${({ semibold }) => (semibold ? '600' : 'normal')};
    width: 62px;
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
`

export const TextWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
