import React from 'react'
import styled from 'styled-components'
import { CloseButton } from '../../../shared/components/V2/V2Buttons'
import { SuccessMark } from '../../../shared/components/V2/V2Icons'
import {
    tablet,
    desktop,
} from '../../../shared/components/helpers/styled_queries'

const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.primaryColor};
    border-radius: 8px;
    position: relative;
    padding: 12px;
    margin-bottom: 16px;

    ${({ noTitle }) => noTitle && 'display: flex'};

    ${tablet`
        margin: unset;
        margin-bottom: 16px;
    `}

    ${desktop`
        margin: unset;
        margin-bottom: 16px;
    `}
`

const StyledCloseButton = styled(CloseButton)`
    top: 12px;
`

const HeaderContainer = styled.div`
    display: flex;

    h1 {
        padding: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.85;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0;
        margin-left: 8px;
        padding-right: 30px;
        ${({ css }) => css};

        ${tablet`
            margin-left: 24px;
        `}

        ${desktop`
            margin-left: 24px;
        `}
    }
`

const SuccessBox = ({
    title,
    onClick,
    children,
    css,
    primaryColor,
    ...rest
}) => (
    <Container {...rest}>
        {onClick && (
            <StyledCloseButton
                onClick={onClick}
                fill={'#1A8346'}
                stroke="#FFF"
            />
        )}
        <HeaderContainer>
            <SuccessMark color={primaryColor} />
            {title && <h1>{title}</h1>}
        </HeaderContainer>
        {children}
    </Container>
)

export default SuccessBox
