import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import _get from 'lodash/get'
import Helmet from 'react-helmet'
import moment from 'moment'
import t from '../../../../shared/translations'
import store from '../../../../shared/services/store'
import ClaimItemCard from '../../components/ClaimItemCard'
import { Link } from 'react-router'

import {
    getUserData,
    getUserClaims,
    getUserRelatedClaims,
    getBufferedClaims,
    resetAgreement,
    saveUserClaim,
} from '../../../../shared/actions'
import {
    resetV2Claim,
    getAllDrafts,
} from '../../../../shared/actions/v2_actions'
import { V2_ACTIONS } from '../../../../shared/actions/actionTypes'

import { paths } from '../../../../shared/constants'

// Layout
import {
    Col,
    PageContent,
    InfoBlock,
    PageViewContainer,
} from '../../../../shared/components/V2/Layout'
import {
    desktop,
    tablet,
    mobile,
    tabletLG,
} from '../../../../shared/components/helpers/styled_queries'
import { loadScreenOff, loadScreenOn, scrollTo } from '../../../../main'
import DraftClaims from './DraftClaims'
import { WarnIcon } from '../../../../shared/components/svgIcons'
import { Row } from 'react-styled-flexboxgrid'

const StyledCol = styled(Col)`
    padding: 16px;

    ${tabletLG`
        max-width: 50%;
    `}
`

const ClaimsHeader = styled(InfoBlock)`
    display: flex;
    justify-content: space-between;
    margin: 0px 16px;
    align-items: center;
`

const StyledLink = styled(Link)`
    padding: 13px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid #7f8fa4;
    background: #fff;
    opacity: 0.9;
    cursor: pointer;
    color: #7f8fa4;
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    height: fit-content;
    display: none;

    ${tablet`
        display: flex;
    `}

    ${mobile`
        display: flex;
    `}
`

const MyClaimsContainer = styled(PageContent)`
    height: fit-content;
    padding: 16px;
    padding-left: 28px;
    padding-top: 40px;
    max-width: 1190px;

    .btn-row {
        ${Col} {
            display: flex;
            justify-content: center;
            margin-top: 24px;

            button {
                ${desktop`
                    width: unset;
                `}
            }
        }
    }

    ${tablet`
        padding: 12px;
        padding-top: 32px;
    `}

    ${mobile`
        padding: 8px;
        padding-top: 24px;
    `}
`

const BewareContainer = styled(PageContent)`
    height: fit-content;
    padding: 16px;
    padding-left: 28px;
    padding-top: 40px;
    max-width: 1190px;
    
    backdrop-filter: blur(17px);
    background-color: ${({ theme }) => theme.colors.errorColor}10;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn-row {
        ${Col} {
            display: flex;
            justify-content: center;
            margin-top: 24px;

            button {
                ${desktop`
                    width: unset;
                `}
            }
        }
    }

    ${desktop`
        border: unset;
        border-radius: 8px;
    `}

    ${tablet`
        padding: 12px;
        padding-top: 32px;
        padding-left: 44px;
        padding-right: 44px;
    `}

    ${mobile`
        padding: 8px;
        padding-top: 24px;
         padding-left: 40px;
        padding-right: 40px;
    `}
`

const BewarePageViewContainer = styled(PageViewContainer)`
   
     ${desktop`
        padding-top: unset;
        padding-bottom: unset;
    `}
`

const InfoBoxContent = styled.div`
    display: flex;
    align-items: center;
`

const ClaimsList = styled.div`
    display: flex;
    flex-wrap: wrap;
`


const IconBox = styled.div`
    display: flex;
    margin-right: 16px;
    align-items: center;

    ${mobile`
         align-items: start;
         padding-top: 6px;
    `}
`

const BewareTitle = styled.div`
        font-weight: 600;
         font-family: 'Noto sans';
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: ${({ theme }) => theme.colors.errorColor};
        font-weight:
        margin: 0;
`

const BewareDescription = styled(BewareTitle)`
        font-weight: 400;
`

class MyClaims extends Component {
    componentDidMount() {
        const {
            user,
            getUserData,
            getUserClaims,
            getUserRelatedClaims,
            getBufferedClaims,
            userClaims,
            getAllDrafts,
        } = this.props

        if (user && !user.active) {
            browserHistory.push(paths.PROFILE)
        }

        if (!userClaims) {
            loadScreenOn()

            getUserClaims()
                .then(() => {
                    loadScreenOff()
                })
                .catch(() => {
                    loadScreenOff()
                })
        }

        getUserRelatedClaims()

        if (!user) {
            getUserData().then(userData => {
                if (userData && userData.email) {
                    getBufferedClaims(userData.email)
                    getAllDrafts()
                }
            })
        } else {
            const { email } = user

            if (email) {
                getBufferedClaims(email)
                getAllDrafts()
            }
        }
    }

    sortClaims = (a, b) => {
        a = moment(a.updated_at)
        b = moment(b.updated_at)
        return a.diff(b) > 0 ? -1 : 1
    }

    renderClaimList = () => {
        const { userClaims, primaryColor } = this.props

        if (userClaims) {
            return userClaims.sort(this.sortClaims).map((item, index) => {
                const id = _get(item, 'id', '')
                const arrival = _get(item, 'airport_arrival.title', '')
                const code = _get(item, 'code', '#')
                const departure = _get(item, 'airport_departure.title', '')
                const reason = _get(item, 'details[0].value', '')
                const airline = _get(
                    item,
                    'problem_claim_flight.airline.title',
                    ''
                )
                const iata = _get(item, 'problem_claim_flight.airline.iata', '')
                const number = _get(item, 'problem_claim_flight.number', '')
                const date = moment
                    .utc(_get(item, 'time_departure', ''))
                    .format('YYYY-MM-DD')
                const status = _get(item, 'status', '')
                const transactionStatus = _get(item, 'transaction_status', '')
                const payoutConfig = _get(
                    item,
                    'transactions[0].payout_config',
                    ''
                )
                const isFastPayout =
                    _get(item, 'transactions[0].isFastPayout', false) &&
                    transactionStatus === 'trans_ready_to_pay'

                return (
                    <StyledCol md={4} lg={4} key={index}>
                        <ClaimItemCard
                            to={`/claim?id=${id}`}
                            departure={departure}
                            arrival={arrival}
                            reason={t`${reason}`}
                            airline={airline}
                            flightNo={`${iata}${number}`}
                            date={date}
                            transactionStatus={transactionStatus}
                            status={status}
                            payoutConfig={payoutConfig}
                            isFastPayout={isFastPayout}
                            code={code}
                            primaryColor={primaryColor}
                        />
                    </StyledCol>
                )
            })
        } else return null
    }

    renderRelatedClaims = () => {
        const { relatedClaims, primaryColor } = this.props

        if (relatedClaims) {
            return relatedClaims.sort(this.sortClaims).map((item, index) => {
                const id = _get(item, 'id', '')
                const arrival = _get(item, 'airport_arrival.title', '')
                const departure = _get(item, 'airport_departure.title', '')
                const reason = _get(item, 'details[0].value', '')
                const airline = _get(
                    item,
                    'problem_claim_flight.airline.title',
                    ''
                )
                const iata = _get(item, 'problem_claim_flight.airline.iata', '')
                const number = _get(item, 'problem_claim_flight.number', '')
                const date = moment(_get(item, 'time_departure', '')).format(
                    'YYYY-MM-DD'
                )
                const status = _get(item, 'status', '')
                const transactionStatus = _get(item, 'transaction_status', '')
                const payoutConfig = _get(
                    item,
                    'transactions[0].payout_config',
                    ''
                )
                const isFastPayout = _get(
                    item,
                    'transactions[0].isFastPayout',
                    false
                )
                const code = _get(item, 'code', '#')

                return (
                    <StyledCol md={4} lg={4} key={index}>
                        <ClaimItemCard
                            to={`/claim?id=${id}&related=true`}
                            departure={departure}
                            arrival={arrival}
                            reason={t`${reason}`}
                            airline={airline}
                            flightNo={`${iata}${number}`}
                            date={date}
                            transactionStatus={transactionStatus}
                            status={status}
                            payoutConfig={payoutConfig}
                            isFastPayout={isFastPayout}
                            code={code}
                            primaryColor={primaryColor}
                        />
                    </StyledCol>
                )
            })
        } else return null
    }

    renderBufferedClaims = () => {
        const { bufferedClaims, idAssets, primaryColor } = this.props

        if (bufferedClaims && idAssets) {
            return bufferedClaims.sort(this.sortClaims).map((item, index) => {
                const {
                    airportArrival,
                    airportDeparture,
                    flights,
                    problemClaimFlight,
                } = item.draft
                const status = 'wait_for_review'
                const problemFlight = flights[problemClaimFlight]
                const airlineId = problemFlight.airline
                const number = problemFlight.number
                const date = moment(
                    _get(problemFlight, 'timeDeparture', '')
                ).format('YYYY-MM-DD')
                const iata = _get(idAssets[airlineId], 'iata', '')
                const arrival = _get(idAssets[airportArrival], 'title', '')
                const departure = _get(idAssets[airportDeparture], 'title', '')
                const airline = _get(idAssets[airlineId], 'title', '')
                const reason = _get(item, 'draft.details[0].value', '')

                if (
                    departure &&
                    arrival &&
                    reason &&
                    airline &&
                    iata &&
                    number &&
                    date
                ) {
                    return (
                        <StyledCol md={4} lg={4} key={index}>
                            <ClaimItemCard
                                className="buffered"
                                departure={departure}
                                arrival={arrival}
                                reason={t`${reason}`}
                                airline={airline}
                                flightNo={`${iata}${number}`}
                                date={date}
                                status={status}
                                primaryColor={primaryColor}
                            />
                        </StyledCol>
                    )
                } else {
                    return null
                }
            })
        } else return null
    }

    handleNewClaimClick = () => {
        const {
            resetV2Claim,
            resetAgreement,
            saveUserClaim,
            isSkycopCare,
        } = this.props
        resetAgreement()
        saveUserClaim(undefined)
        store.dispatch({
            type: V2_ACTIONS.STEP_RESET,
            payload: 0,
        })
        resetV2Claim()
        browserHistory.push(isSkycopCare ? '/new-claim' : '/')
        scrollTo(document.body, 0)
    }

    render() {
        const { trans } = this.props

        if (!trans) return null

        return (
            <div>
                <BewarePageViewContainer>
                    <BewareContainer>
                        <Row>
                            <InfoBoxContent>
                                <IconBox>
                                    <WarnIcon />
                                </IconBox>
                                <div>
                                    <BewareTitle>{t`v2_claim.thank_you.beware_title`}</BewareTitle>
                                    <BewareDescription>{t`v2_claim.thank_you.beware_description`}</BewareDescription>
                                </div>
                            </InfoBoxContent>
                        </Row>
                    </BewareContainer>
                </BewarePageViewContainer>
            <PageViewContainer>
                <MyClaimsContainer>
                    <Helmet>
                        <title>{`Skycop - ${t`menu.my_claims`}`}</title>
                    </Helmet>
                    <DraftClaims />
                    <ClaimsHeader title={t`my_claims.title`}>
                        <StyledLink
                            id="new-claim-button"
                            onClick={() => this.handleNewClaimClick()}
                        >
                            {t`my_claims.button.create_new_claim`}
                        </StyledLink>
                    </ClaimsHeader>
                    <ClaimsList>
                        {this.renderBufferedClaims()}
                        {this.renderClaimList()}
                        {this.renderRelatedClaims()}
                    </ClaimsList>
                </MyClaimsContainer>
            </PageViewContainer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        userClaims: state.user_claims,
        user: state.user,
        bufferedClaims: state.bufferedClaims,
        relatedClaims: state.related_claims,
        idAssets: state.idAssets,
        isSkycopCare: state.isSkycopCare,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUserData,
            getUserClaims,
            getUserRelatedClaims,
            getBufferedClaims,
            resetAgreement,
            resetV2Claim,
            saveUserClaim,
            getAllDrafts,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MyClaims)
