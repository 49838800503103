import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { showModal, hideModal } from '../../../shared/actions/v2_actions'
import Modal from '../../../shared/components/V2/Modal'
import { CloseButton } from '../../../shared/components/V2/V2Buttons'
import V2PayoutPlans from '../../../shared/components/V2/V2PayoutPlans'
import { mobile } from '../../../shared/components/helpers/styled_queries'
import t from '../../../shared/translations'
import { modalTypes } from '../../../shared/constants'

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
    padding-top: 40px;
`

const Title = styled.h1`
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #353d51;
    text-align: center;

    padding-top: 16px;
    padding-bottom: 25px;
`

const DesktopBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    border: 0;
    min-width: 200px;
    width: 100%;
    height: 48px;
    border-radius: 24px;
    margin-top: 12px;
    padding: 10px 30px;
    text-align: center;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    ${({ disabled, theme }) =>
        disabled
            ? 'background-color: #f3f5f7;'
            : theme.colors.actionButton
            ? `background-color: ${theme.colors.actionButton};`
            : `background-image: linear-gradient(to top, ${theme.colors.primaryColor}, ${theme.colors.primaryColor}15)};`}
    color: ${({ disabled }) => (disabled ? '#c7cdd4' : '#ffffff')};
    outline: 0 none;
    font-size: 14px;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    div {
        display: flex;
        align-items: center;
    }

    &:hover {
        filter: brightness(105%);
    }

    &:focus {
        outline: 0 none;
    }
`

const ButtonContainer = styled.div`
    max-width: 252px;
    display: flex;
    margin: auto;

    ${mobile`
        margin-bottom: 56px;
    `}
`

class PaymentPlanModal extends React.Component {
    handleSubmit = () => {
        const { modal, hideModal } = this.props
        if (modal) {
            const { props } = modal
            if (props.callBack && typeof props.callBack === 'function') {
                props.callBack()
            }
        }
        hideModal()
    }

    render() {
        const { trans } = this.props

        if (!trans) return null

        return (
            <Modal
                maxWidth="844px"
                height="auto"
                modalType={modalTypes.paymentPlanModal}
                onEscClick={this.handleSubmit}
            >
                <Container>
                    <CloseButton
                        id="btn-close-payment-plan-modal"
                        onClick={this.handleSubmit}
                    />
                    <Title>{t`claim.complete.step_two.description_choose_payout_option`}</Title>
                    <V2PayoutPlans />
                    <ButtonContainer>
                        <DesktopBtn
                            id="btn-submit-payment-plan"
                            onClick={this.handleSubmit}
                        >
                            {t`v2_button.done`}
                        </DesktopBtn>
                    </ButtonContainer>
                </Container>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        selectedPayout: state.selectedPayout,
        modal: state.toggle_modal,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showModal, hideModal }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPlanModal)
