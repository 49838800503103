import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isMobileDevice, applyTranslationAction } from '../../../main'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import {
    V2ClearIcon2,
    DesktopSignIcon2,
} from '../../../shared/components/V2/V2Icons'
import { desktop, mobile, tablet, desktopSM } from '../helpers/styled_queries'
import t from '../../translations'
import AirlineDocsInfoBox from '../../../views/V2/components/AirlineDocsInfoBox'
import { downloadUserAgreement } from '../../actions/actions'
import store from '../../services/store'

const InfoCircle = ({ color }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 9H12.01M11 12H12V16H13M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12Z"
            stroke={color}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

const Title = styled.div`
    position: absolute;
    top: 12px;
    left: 0;
    justify-content: center;
    width: 100%;
    display: flex;

    h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #000000;
        text-align: center;

        span {
            color: #717171;
        }
    }

    ${desktop`
        h3 {
            font-size: 16px;
        }
    `}
`

const SignatureWrapper = styled.div`
    position: relative;
    width: 100%;
    background-color: #f3f5f7;
    border-radius: 16px;
    padding: 86px 16px 16px;

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 6px;
        box-shadow: none;
        background: none;
        width: 100%;
        height: ${border => (border ? '222px !important;' : '296px;')}
        background: transparent;
        border: ${({ border, theme, signatureConfigs }) =>
            border
                ? `2px solid ${(signatureConfigs &&
                      signatureConfigs.componentsColor) ||
                      theme.colors.primaryColor}}`
                : 'none'};
    }

    button {
        user-select: none;
    }

    .desktop-box {
        display: none;
        border-radius: 6px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .mobile-box {
        border-radius: 6px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    ${({ isLanscape }) =>
        isLanscape &&
        `
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 99999;

        .signatureCanvas {
            background-color: white;
            height: 99%;
            z-index: 99999;
        }
    `}

    ${mobile`
        padding: 120px 20px 32px;
    `}

    ${desktop`
        padding: 100px 32px 32px;

        canvas {
            height: 200px;
            background-position-y: 50px;
        }

        .mobile-box {
            display: none;
        }

        .desktop-box {
            display: flex;
        }
    `}
`

const BoxText = styled.div`
    display: ${({ showIcon }) => (showIcon ? 'flex' : 'none')};
    position: absolute;
    flex-direction: column;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;

    // p {
    //     margin: 0;
    //     font-family: 'Noto sans';
    //     font-size: 13px;
    //     font-weight: normal;
    //     font-style: normal;
    //     font-stretch: normal;
    //     line-height: 1.85;
    //     letter-spacing: normal;
    //     color: #7f8fa4;

    //     &.bold {
    //         font-size: 16px;
    //         line-height: 1.5;
    //         font-weight: bold;
    //     }
    // }

    svg {
        margin-bottom: 10px;
        max-height: 56px;
    }

    ${desktopSM`
        svg {
            max-height: 48px;
        }
    `}

    ${mobile`
        padding: 0px 8px;
        // p {
        //     &.bold {
        //         font-size: 14px;
        //     }
        // }

        svg {
            max-height: 48px;
        }
    `}


    ${tablet`
        svg {
            max-height: 64px;
        }
    `}
`

const ClearButton = styled.button`
    font-family: 'Noto sans';
    display: flex;
    right: calc(50% - 32px);
    cursor: pointer;
    margin: 0 !important;
    outline: 0 !important;
    padding: 8px;
    z-index: 15;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryColor};
    position: absolute;
    background: none;
    border: none;
    text-decoration: underline;

    svg {
        margin-left: 8px;
    }

    ${mobile`
        bottom: 32px;
    `}

    ${tablet`
        bottom: 32px;
    `}

    ${desktop`
        right: 48px;
        top: 270px;
    `}
`

const CanvasContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 296px;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 100px;
    cursor: url('images/v2/pen.svg'), auto;
    background-color: #fff;
    border-radius: 6px;
    border-top-left-radius: ${({ limitedRadius }) =>
        limitedRadius ? '0' : '6'}px;
    border-top-right-radius: ${({ limitedRadius }) =>
        limitedRadius ? '0' : '6'}px;

    ${desktop`
        height: 220px;
        padding-bottom: 55px;

        border-radius: 6px;
        border-bottom-left-radius: ${({ limitedRadius }) =>
            limitedRadius ? '0' : '6'}px;
        border-bottom-right-radius: ${({ limitedRadius }) =>
            limitedRadius ? '0' : '6'}px;
    `}
`

const SignBoxTitle = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
`

const SignBoxDescription = styled.h3`
    padding-left: 19px;
    padding-right: 5px;

    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
`

const SignBoxInnerTitle = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    color: #717171;
    margin-bottom: 8px;
`

const SignBoxInnerDescription = styled.div`
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.72px;
    color: #717171;
`

var document_width
var document_height
var isResizing

var getWidth = () =>
    document.body.clientWidth ||
    window.innerWidth ||
    document.documentElement.clientWidth
var getHeight = () =>
    document.body.clientHeight ||
    window.innerHeight ||
    document.documentElement.clientHeight
var getPctChange = (oldNo, newNo) => ((oldNo - newNo) / newNo) * 100

class SignBox extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dpr: 1,
            isLanscape: false,
            showIcon: true,
            clearCount: 0,
        }

        this.infoBoxRef = React.createRef()
    }

    _clearCanvas = e => {
        e.preventDefault()
        const { handleSignatureState } = this.props
        const { clearCount } = this.state

        this.setState({ showIcon: true, clearCount: clearCount + 1 })
        handleSignatureState('')
    }

    _didResize = () => {
        if (isMobileDevice()) {
            return (
                getPctChange(document_width, getWidth()) > 10 ||
                getPctChange(document_height, getHeight()) > 10
            )
        } else {
            return (
                document_width !== getWidth() || document_height !== getHeight()
            )
        }
    }

    _resize = () => {
        const { isLanscape } = this.props
        clearTimeout(isResizing)
        isResizing = setTimeout(() => {
            if (this._didResize() && !isLanscape) {
                document_width = getWidth()
                document_height = getHeight()
                this._resizeCanvas()
                this.forceUpdate()
            }
        }, 300)
    }

    _resizeCanvas = skipLandscape => {
        const { signature } = this.props
        let ratio = Math.max(window.devicePixelRatio || 1, 1)
        let canvas = document.getElementById('sigCanvas')
        let canvasContext = canvas.getContext('2d')

        canvas.width = canvas.offsetWidth * ratio
        canvas.height = canvas.offsetHeight * ratio
        canvasContext.scale(ratio, ratio)
        this.setState({ dpr: window.devicePixelRatio })

        if (canvasContext && signature) {
            var signatureObj = new Image()
            signatureObj.onload = function() {
                canvasContext.drawImage(this, 0, 0)
            }

            signatureObj.src = signature
        }

        if (!skipLandscape) {
            //this._checkLandscape();
        }
        this.forceUpdate()
    }

    _handleDrawState = e => {
        const { handleSignatureState } = this.props

        if (this.refs && this.refs.sigCanvas) {
            handleSignatureState(this.refs.sigCanvas.toDataURL())
            if (this.state.dpr !== window.devicePixelRatio) {
                this._resizeCanvas()
            }
        }
    }

    _checkLandscape = () => {
        if (isMobileDevice()) {
            if (
                window.innerHeight < window.innerWidth &&
                !this.state.isLanscape
            ) {
                this.setState({ isLanscape: true })
                setTimeout(() => {
                    this._resizeCanvas(true)
                    this.forceUpdate()
                }, 300)
                return true
            } else if (
                window.innerHeight > window.innerWidth &&
                this.state.isLanscape
            ) {
                this.setState({ isLanscape: false })
                setTimeout(() => {
                    this._resizeCanvas(true)
                    this.forceUpdate()
                }, 300)
                return false
            }
        } else if (this.state.isLanscape) {
            this.setState({ isLanscape: false })
            return false
        }
        return false
    }

    componentDidMount() {
        window.addEventListener('resize', this._resize, { passive: true })
        document.addEventListener('click', this.handleOutsideClick, {
            passive: false,
        })
        document.addEventListener(
            'touchend',
            e => this.handleOutsideClick(e, false),
            { passive: false }
        )
        document.addEventListener('touchmove', this.handleOutsideClick, {
            passive: false,
        })
        document.addEventListener('scroll', this.handleOutsideClick, {
            passive: false,
        })

        //eslint-disable-next-line
        if (activateSigPad) {
            //eslint-disable-next-line
            activateSigPad({
                drawOnly: true,
                drawBezierCurves: true,
                lineTop: 500,
                bgColour: 'transparent',
                defaultAction: 'drawIt',
                penCap: 'butt',
                lineColour: '#021B8E',
                penColour: '#021B8E',
                lineWidth: 2,
                stateChange: this._handleDrawState,
            })
        }
        document_width = getWidth()
        document_height = getHeight()
        this._resizeCanvas()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._resize, { passive: true })
        document.removeEventListener('click', this.handleOutsideClick, {
            passive: false,
        })
        document.removeEventListener(
            'touchend',
            e => this.handleOutsideClick(e, false),
            { passive: false }
        )
        document.removeEventListener('touchmove', this.handleOutsideClick, {
            passive: false,
        })
        document.removeEventListener('scroll', this.handleOutsideClick, {
            passive: false,
        })
    }

    componentDidUpdate() {
        const canvas = document.getElementById('sigCanvas')
        const canvasContext = canvas.getContext('2d')
        if (canvas && canvasContext.strokeStyle !== '#021B8E') {
            canvasContext.strokeStyle = '#021B8E'
            canvasContext.lineWidth = 2
        }
    }

    onMouseDown = e => {
        this.setState({ showIcon: false })
        if (isMobileDevice()) {
            disableBodyScroll(document.body)
        }
    }

    handleOutsideClick = (e, shouldLock = true) => {
        const sigPadContainer = ReactDOM.findDOMNode(this.sigPadContainer)
        if (!sigPadContainer || !sigPadContainer.contains(e.target)) {
            clearAllBodyScrollLocks()
            return true
        } else {
            if (shouldLock && e && e.cancelable) {
                e.preventDefault()
            }
        }
    }

    render() {
        const {
            clearLabel,
            name,
            surname,
            border,
            hasBox,
            downloadEnabled,
            agreementType,
            claimId,
            userAddress,
            userName,
            userBirthdate,
            legalGuardianFullName,
            signatureConfigs,
            primaryColor,
        } = this.props
        const { showIcon, clearCount } = this.state

        return (
            <SignatureWrapper
                id="signaturePadContainer"
                showIcon={showIcon}
                border={border}
                innerRef={ref => (this.sigPadContainer = ref)}
                signatureConfigs={signatureConfigs}
            >
                <Title>
                    <SignBoxTitle>
                        {legalGuardianFullName ? (
                            <div>
                                <h3>
                                    {`${legalGuardianFullName} `}
                                    <span>
                                        {t`v2_legal_guardian_signature_box_signing_for`
                                            .replace('%passengerName%', name)
                                            .replace(
                                                '%passengerSurname%',
                                                surname
                                            )}
                                    </span>
                                </h3>
                            </div>
                        ) : (
                            <h3>
                                {`${name} ${surname}`}{' '}
                                <span>({t`v2_common.label.you`})</span>
                            </h3>
                        )}
                        <SignBoxDescription>{t`v2_claim.claim_signature.box.description`}</SignBoxDescription>
                    </SignBoxTitle>
                </Title>
                {clearCount > 2 &&
                    (!downloadEnabled || clearCount < 5) &&
                    hasBox && (
                        <AirlineDocsInfoBox
                            className="mobile-box"
                            ref={this.infoBoxRef}
                            bgColor={`${primaryColor}15`}
                            Icon={() => <InfoCircle color={primaryColor} />}
                            Content={t`FAQ.signature.clear_text.first`}
                        />
                    )}
                {clearCount >= 5 && hasBox && downloadEnabled && (
                    <AirlineDocsInfoBox
                        minHeight={90}
                        className="mobile-box"
                        ref={this.infoBoxRef}
                        bgColor={`${primaryColor}15`}
                        Icon={() => <InfoCircle color={primaryColor} />}
                        Content={applyTranslationAction(
                            t`FAQ.signature.clear_text.second`,
                            "<a href='#'>",
                            '</a>',
                            () =>
                                store.dispatch(
                                    downloadUserAgreement(
                                        claimId,
                                        userName,
                                        agreementType,
                                        userBirthdate,
                                        userAddress
                                    )
                                ),
                            'link',
                            true
                        )}
                    />
                )}
                <CanvasContainer
                    showIcon={showIcon}
                    limitedRadius={clearCount >= 3 && hasBox}
                >
                    <BoxText showIcon={showIcon} onClick={this.onMouseDown}>
                        <span>
                            {window.innerWidth < 961 ? (
                                <DesktopSignIcon2 color={primaryColor} />
                            ) : (
                                <DesktopSignIcon2 color={primaryColor} />
                            )}
                        </span>
                        <SignBoxInnerTitle>
                            {isMobileDevice()
                                ? t`v2_claim.signature_pad.click_and_draw_mobile`
                                : t`v2_claim.signature_pad.click_and_draw`}
                        </SignBoxInnerTitle>
                        <SignBoxInnerDescription>{t`v2_claim.signature_pad.it_must_match`}</SignBoxInnerDescription>
                    </BoxText>
                    <canvas
                        ref="sigCanvas"
                        id="sigCanvas"
                        className="signatureCanvas"
                        onTouchStart={this.onMouseDown}
                        onMouseDown={this.onMouseDown}
                        onMouseUp={() => clearAllBodyScrollLocks()}
                        onTouchEnd={() => clearAllBodyScrollLocks()}
                    />
                </CanvasContainer>
                <ClearButton
                    className="clearButton"
                    onClick={this._clearCanvas}
                >
                    {clearLabel}
                    <V2ClearIcon2 color={primaryColor} />
                </ClearButton>
                <input
                    ref="signatureInput"
                    id="signatureInput"
                    type="hidden"
                    className="output"
                />
                {clearCount > 2 &&
                    (!downloadEnabled || clearCount < 5) &&
                    hasBox && (
                        <AirlineDocsInfoBox
                            className="desktop-box"
                            ref={this.infoBoxRef}
                            bgColor={`${primaryColor}15`}
                            Icon={() => <InfoCircle color={primaryColor} />}
                            Content={t`FAQ.signature.clear_text.first`}
                        />
                    )}
                {clearCount >= 5 && hasBox && downloadEnabled && (
                    <AirlineDocsInfoBox
                        minHeight={78}
                        className="desktop-box"
                        ref={this.infoBoxRef}
                        bgColor={`${primaryColor}15`}
                        Icon={() => <InfoCircle color={primaryColor} />}
                        Content={applyTranslationAction(
                            t`FAQ.signature.clear_text.second`,
                            "<a href='#'>",
                            '</a>',
                            () =>
                                store.dispatch(
                                    downloadUserAgreement(
                                        claimId,
                                        userName,
                                        agreementType,
                                        userBirthdate,
                                        userAddress
                                    )
                                ),
                            'link',
                            true
                        )}
                    />
                )}
            </SignatureWrapper>
        )
    }
}

SignBox.propTypes = {
    handleSignatureState: PropTypes.func.isRequired,
    clearLabel: PropTypes.string.isRequired,
    signature: PropTypes.any,
    signatureConfigs: PropTypes.shape({}),
}

SignBox.defaultProps = {
    signature: '',
    signatureConfigs: null,
}

export default SignBox
