import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import _get from 'lodash/get'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { Col, Row, StepContent } from '../../../../shared/components/V2/Layout'
import StepView from '../../components/StepView'
import InputField from '../../../../shared/components/V2/InputField'
import TermsAndConditions from '../../components/TermsAndConditions'
import DatePicker from '../../../../shared/components/V2/DatePicker'
import V2PhoneField from '../../../../shared/components/V2/V2PhoneField'
import { V2Button } from '../../../../shared/components/V2/V2Buttons'
import {
    desktop,
    tablet,
    mobile,
    tabletLG,
} from '../../../../shared/components/helpers/styled_queries'
import {
    postV2Claim,
    showModal,
    setV2Claim,
    validateStepSix,
    getUserData,
    setProgress,
    nextStep,
    setTotalClaimAmount,
    setNavStep,
    setPayoutDetails,
    updateUserDetails,
    setClaimDraftProps,
    setPreloadedClaimStep,
    logDraftAction,
    saveClaimDraft,
    setPostedClaim,
    setPromoCode,
    setPromoCodeInvalid,
} from '../../../../shared/actions/v2_actions'
import CountryInput from '../../components/CountryInput'
import {
    mapFormData,
    getStorage,
    getAgeInYears,
    scrollComponentToView,
    isValidEmail,
    isValidLatinChars,
    changeToUppercase,
    isValidEmailInput,
    isProduction,
    scrollTo,
    applyTranslationAction,
    addBeforeUnload,
    removeBeforeUnload,
    getApiClient,
    isFeatureEnabled,
} from '../../../../main'
import t from '../../../../shared/translations'
import ErrorsBox from '../../components/ErrorsBox'
import {
    adultAge,
    V2_prePath,
    modalTypes,
    V2_STEPS,
    draftLogActions,
    draftLogActionReasons,
    paths,
    featureToggles,
} from '../../../../shared/constants'
import { emailMagic } from '../../../../shared/validators'
import { validateEmailSmtp, clearClaimErrors } from '../../../../shared/actions'
import { mapSteps } from '../../../../shared/reducers/V2/reducer_steps'
import latinize from 'latinize'
import store from '../../../../shared/services/store'
import { H1, FlexSpan } from '../../../../shared/components/V2/TextElements'
import SidebarProgress from '../../components/SidebarProgress'
import { ENTER_KEY, SPACE_KEY } from '../../../../shared/components/V2/Modal'
import { V2_ACTIONS } from '../../../../shared/actions/actionTypes'
import {
    TooltipTitle,
    TooltipContent,
    TooltipContainer,
} from '../../../../shared/components/V2/V2Tooltip'
import WhatsAppBox from '../../components/WhatsAppBox'
import AdditionalPax from './AdditionalPax'
import { getLocale } from '../../../../shared/services/utils'
import Subscribe from '../../components/Subscribe'
import { fireGtagEvent } from '../../../../shared/services/events'
import PhoneVerificationField from '../../PhoneVerificationField'
import { mapAlternativeStepState } from '../FlightDetails'

const PromoCodeBtn = styled.div`
    display: flex;
    align-items: ${({ isPromoCodeValid }) =>
        isPromoCodeValid ? 'end' : 'center'};
    height: 100%;
    width: fit-content;
`

const PromoCodeApplied = styled.div`
    display: flex;
    align-items: end;
    height: 100%;
    width: fit-content;
    color: ${({ theme }) => theme.colors.primaryColor};
    font-weight: 600;
    font-family: 'Noto Sans';
    font-size: 16px;
    line-height: 22.4px;
    text-align: left;

    ${desktop`
        padding-bottom: 17px;    
    `}
`

const StyledFieldsRow = styled(Row)`
    margin-bottom: 16px;

    ${tablet`
        margin-bottom: 0px;
        `}

    ${mobile`
        margin-bottom: 0px;
        `}
`

const userSchema = {
    name: {
        type: 'string',
        required: true,
        validLatin: true,
        minLength: 2,
        errorMessages: {
            type_error: 'type_error',
            required_error: 'required_error',
            validLatin_error: 'latin_error',
            minLength_error: 'too_short',
        },
    },
    surname: {
        type: 'string',
        required: true,
        validLatin: true,
        minLength: 2,
        errorMessages: {
            type_error: 'type_error',
            required_error: 'required_error',
            validLatin_error: 'latin_error',
            minLength_error: 'too_short',
        },
    },
    birthdate: {
        type: 'string',
        required: true,
        errorMessages: {
            type_error: 'type_error',
            required_error: 'required_error',
        },
    },
    email: {
        type: 'string',
        required: true,
        emailValid: true,
        errorMessages: {
            type_error: 'type_error',
            required_error: 'required_error',
            emailValid_error: 'valid_error',
        },
    },
    address: {
        type: 'string',
        required: true,
        errorMessages: {
            type_error: 'type_error',
            required_error: 'required_error',
        },
    },
    city: {
        type: 'string',
        required: true,
        errorMessages: {
            type_error: 'type_error',
            required_error: 'required_error',
        },
    },
    country: {
        type: 'string',
        required: true,
        errorMessages: {
            type_error: 'type_error',
            required_error: 'required_error',
        },
    },
    phone: {
        type: 'string',
        required: true,
        minLength: 5,
        errorMessages: {
            type_error: 'type_error',
            required_error: 'required_error',
        },
    },
}

const Content = styled(StepContent)`
    padding: 0;
    display: flex;
    flex-direction: column;
`

const CheckboxContainer = styled.div`
    margin-top: 32px;
    padding: 0px 12px;

    ${mobile`
        padding: 0px 16px;
    `}

    ${tabletLG`
        max-width: 66.6666666666667%;
    `}

    ${tablet`
        padding: 0px 16px;
    `}
`

const CheckboxSelectAll = styled(CheckboxContainer)`
    margin-top: unset;
`

const SignInContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    margin: 0;

    ${tablet`
        text-align: center;
    `}

    ${mobile`
        text-align: center;
    `}
`

const SignInBox = styled.div`
    display: flex;
    margin-bottom: 8px;

    ${desktop`
        margin: 0;
    `}
`

const StyledSpan = styled.span`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 22.4px;
    color: #717171 !important;
`

const TextBox = styled.div`
    margin-bottom: 24px;
    p {
        font-family: 'Noto sans';
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #7f8fa4;
        margin: 0;
        text-align: left;
    }

    span {
        font-weight: 600;
        color: #000000;
    }

    .link {
        color: ${({ theme }) => theme.colors.primaryColor};
        cursor: pointer;

        &:hover {
            filter: brightness(110%);
        }
    }
`

const Text = styled.div`
    font-family: Noto Sans;
    font-size: 32px;
    font-weight: 600;
    color: #000000;
    text-transform: capitalization;
    span {
        color: ${({ theme }) => theme.colors.primaryColor};
    }
`

const passengerCategories = {
    adu: 'adu',
    chi: 'chi',
    inf: 'inf',
}

const DashedLine = styled.div`
    margin-top: 32px;
    padding-bottom: 32px;
    height: 1px;
    background-image: linear-gradient(
        to right,
        #717171 25%,
        rgba(255, 255, 255, 0) 0%
    );
    margin-left: 12px;
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;
`

const ErrorList = styled.ul`
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
    padding: 0px 48px;
    margin: 0;

    ${desktop`
        padding: 0px 86px;
    `}

    div {
        padding: 8px 0;

        p {
            margin: 0;
            margin-left: -16px;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #ffffff;
        }

        li {
            padding-left: 0px;
        }
    }
`

export const UserInfoContainer = styled.div`
    ${desktop`
        width: 100%;
    `}

    ${tablet`
        margin: 0px 16px;
    `}

    ${tabletLG`
        margin: 0px 16px;
        width: 66.66666666666667%;

        ${Col} {
            max-width: 100%;
        }
    `}

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
`

export const PagePadding = styled.div`
    ${desktop`
    padding: 0px 40px;
`}
`

const PagePaddingTitle = styled(PagePadding)`
     padding: 32px 40px 16px 32px;

//     ${desktop`
// `}

${mobile`
    padding: 24px 40px 16px 0px; 
    `}
`

const V2ButtonStyledBorder = styled(V2Button)`
    border-radius: 16px;
    font-family: 'Noto sans';
    color: ${({ theme }) => theme.colors.primaryColor};
    border: 1px solid ${({ theme }) => theme.colors.primaryColor};
    font-weight: 500;

    :hover {
        color: ${({ theme }) => theme.colors.primaryColor};
    }
`

class PassengerDetails extends React.Component {
    state = {
        isSelectAllChecked: false,
        user: {
            ...this.props.user,
        },
        passengers: [...this.props.passengers],
        ...this.props.V2Claim,
        componentState: {
            termsConditions: false,
            showSubscribeCbx: false,
            shouldValidateTerms: false,
            userIsOpen: true,
            enabledAdd: true,
            // eslint-disable-next-line no-restricted-globals
            innerWidth,
            paxEmailErrors: [],
            ...mapAlternativeStepState(this.props.claimDraftProps),
        },
        verificationCode: '',
        isVerificationCodeSent: false,
        isCodeValid: true,
        isVerificationSubmitClicked: false,
        isResendButtonActive: false,
    }

    componentDidMount() {
        if (
            isFeatureEnabled(featureToggles.featureIsPhoneVerificationEnabled)
        ) {
            window.addEventListener('DOMContentLoaded', e => {
                const input = document.getElementById('one-time-code')

                if (!input) return
                const ac = new AbortController()
                const form = input.closest('form')
                if (form) {
                    form.addEventListener('submit', e => {
                        ac.abort()
                    })
                }
                navigator.credentials
                    .get({
                        otp: { transport: ['sms'] },
                        signal: ac.signal,
                    })
                    .then(otp => {
                        input.value = otp.code
                        this.handleVerificationCodeSubmit(otp.code)
                        this.setState({ verificationCode: otp.code })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
        }

        try {
            const newsText = window.document.querySelector('.news-text')
            if (newsText) {
                const newsTextLink = newsText.querySelector('a')

                if (newsTextLink) {
                    newsTextLink.addEventListener('click', e => {
                        fireGtagEvent({ event: 'step6_pd_price_list' }, true)
                    })
                }
            }
            setNavStep(mapSteps.indexOf(this.props.route.path))
        } catch (e) {}

        const {
            v2_amount,
            setParentState,
            setTotalClaimAmount,
            setPayoutDetails,
            totalAmount,
            V2Claim,
            isEnabledPostCode,
            claimDraft,
            claimPreloaded,
        } = this.props
        if (
            (!V2Claim ||
                !V2Claim.airportArrival ||
                !V2Claim.airportDeparture) &&
            isProduction()
        ) {
            setNavStep(0)
            browserHistory.replace(V2_prePath)
        }

        window.addEventListener('resize', this.handleResize)

        if (isEnabledPostCode) {
            userSchema.postCode = {
                type: 'string',
                required: true,
                minLength: 2,
                errorMessages: {
                    type_error: 'type_error',
                    required_error: 'required_error',
                    minLength_error: 'too_short',
                },
            }
        } else {
            delete userSchema.postCode
        }

        setParentState({
            nextDisabled: true,
            renderPriceList: true,
            buttonText: t`v2.button.claim_now`,
            disabledClick: this.disabledValidate,
        })

        scrollComponentToView('pageTopNavbar', false, true)
        const { getUserData } = this.props
        getUserData()

        this.setState({
            componentState: {
                ...this.state.componentState,
                showSubscribeCbx: !this.state.user.subscribe,
            },
        })

        if (!totalAmount) {
            setTotalClaimAmount(v2_amount)
            setPayoutDetails({ amount: v2_amount, plan: this.getPlan() })
        }

        if (!!this.state.passengers.length) {
            this.setPassengersAmount()
        }

        if (claimDraft && !claimPreloaded[V2_STEPS.STEP_6]) {
            this.setState(
                {
                    componentState: {
                        ...this.state.componentState,
                        ...claimDraft.componentState,
                        userIsOpen: true,
                    },
                },
                () => {
                    this.props.setPreloadedClaimStep([V2_STEPS.STEP_6])
                }
            )
        }

        addBeforeUnload(() => this.props.showModal(modalTypes.claimDraftModal))
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
        this.props.setParentState({
            disabledClick: null,
            renderPriceList: false,
        })
    }

    handleResize = debounce(() => {
        const { componentState } = this.state
        const innerWidth = window.innerWidth

        this.setState({
            componentState: {
                ...componentState,
                innerWidth,
            },
        })
    })

    getPlan = () => {
        const { payoutPlan, selectedPayout, payouts } = this.props
        let plan = payoutPlan && payoutPlan[0] && payoutPlan[0].percent
        if (payouts && selectedPayout) {
            const selectedPlan = payouts.find(p => p.id === selectedPayout)
            if (selectedPlan && selectedPlan.percent) {
                plan = selectedPlan.percent
            }
        }

        return plan
    }

    setPassengersAmount = () => {
        const { setTotalClaimAmount, setPayoutDetails, v2_amount } = this.props
        const flightDate = this.getFlightDate()

        const passengerCount = this.state.passengers.filter(
            passenger =>
                getAgeInYears(passenger.birthdate, flightDate) > 2 ||
                !passenger.birthdate
        )
        const amount = (passengerCount.length + 1) * parseFloat(v2_amount)

        setTotalClaimAmount(amount)
        setPayoutDetails({ amount, plan: this.getPlan() })
    }

    componentDidUpdate(prevProps, prevState) {
        const { setParentState, isDisabled, trans, authenticated } = this.props
        const { passengers } = this.state

        if (!authenticated && prevState.user.contactViaWhatsapp === undefined) {
            this.setState({
                user: {
                    ...prevState.user,
                    contactViaWhatsapp: true,
                },
            })
        }

        if (
            prevProps.user.name !== this.props.user.name ||
            prevProps.user.phone !== this.props.user.phone
        ) {
            const { user } = this.props

            if (user) {
                this.setState({ user })
            }
        }

        if (prevState.passengers.length !== passengers.length) {
            this.setPassengersAmount()
        }

        if (isDisabled && !this.checkNextStep()) {
            setParentState({
                nextDisabled: false,
            })
        } else if (!isDisabled && this.checkNextStep()) {
            setParentState({
                nextDisabled: true,
            })
        }

        if (
            trans['v2.sidebar_step.flight_itinerary'] !==
            prevProps.trans['v2.sidebar_step.flight_itinerary']
        ) {
            setParentState({
                buttonText: t`v2.button.claim_now`,
            })
            this.forceUpdate()
        }
    }

    _handleNextStep = () => {
        const {
            setV2Claim,
            postV2Claim,
            setNoBack,
            nextStep,
            validateStepSix,
            authenticated,
            fullUserData,
            isEnabledPostCode,
            updateUserDetails,
            V2Claim,
            selectedPayout,
            v2_amount,
            shareCode,
            isPromoCodeApplied,
            isPromoCodeValid,
            promoCodeValue,
        } = this.props
        const {
            componentState,
            isVerificationSubmitClicked,
            isCodeValid,
            isVerificationCodeSent,
            verificationCode,
        } = this.state

        let newState = { ...this.state }
        if (
            !authenticated &&
            isFeatureEnabled(
                featureToggles.featureIsPhoneVerificationEnabled
            ) &&
            !(
                isVerificationSubmitClicked &&
                isCodeValid &&
                isVerificationCodeSent &&
                !!verificationCode
            )
        ) {
            return false
        }

        const errors = validateStepSix(newState)

        const userPhone = _get(newState, 'user.phone', '')
        if (
            userPhone &&
            !this.validatePhoneLength(userPhone, userSchema.phone.minLength)
        ) {
            return false
        }

        const userPostCode = _get(newState, 'user.postCode', '')
        if (
            isEnabledPostCode &&
            userPostCode &&
            !this.validatePostCodeLength(
                userPostCode,
                userSchema.postCode.minLength
            )
        ) {
            return false
        }

        if (errors || componentState.paxEmailErrors.length > 0) {
            const userErrors = _get(errors, 'user', '')
            const passengerErrors = _get(errors, 'passengers', '')

            if (
                userErrors ||
                passengerErrors ||
                componentState.paxEmailErrors.length > 0
            ) {
                const { name, surname, address, city, country } = userErrors
                if (
                    (name || surname || address || city || country) &&
                    !!authenticated &&
                    fullUserData.active
                ) {
                    delete userErrors.name
                    delete userErrors.surname
                    delete userErrors.address
                    delete userErrors.city
                    delete userErrors.country
                }
                if (
                    !!Object.keys(userErrors).length ||
                    !!passengerErrors.length
                ) {
                    this.disabledValidate()
                    return false
                }
                if ((componentState.paxEmailErrors || []).length > 0) {
                    return false
                }
            }
        }

        const userEmail = _get(newState, 'user.email', '')
        const userRepeatEmail = _get(newState, 'user.repeatEmail', '')

        if (
            !authenticated &&
            (!userEmail ||
                !userRepeatEmail ||
                userEmail.toLowerCase() !== userRepeatEmail.toLowerCase())
        ) {
            this.setState({
                componentState: {
                    ...componentState,
                    repeatEmailError: t`common.label.emails_dont_match`,
                },
            })

            return false
        }

        this._handleSaveDraft()
        newState.passengers.forEach(passenger => {
            delete passenger.isOpen
            delete passenger.id
        })
        delete newState.componentState
        delete newState.user.repeatEmail
        delete newState.claimableErrors

        newState.flights.forEach(element => {
            delete element.isOpen
            delete element.prefillLetters
            delete element.error
            delete element.errors
            delete element.preselectId
        })

        try {
            newState.user.locale = getLocale()
        } catch (e) {}

        if (!isEnabledPostCode) {
            delete newState.user.postCode
        }

        newState.payoutId = selectedPayout
        newState.price = v2_amount

        setProgress(100)
        setNoBack(true)
        if (newState.user.name && newState.user.surname) {
            const name = newState.user.name
            const replacedName = name.replace(newState.user.surname, '')
            if (replacedName.length > 2) {
                newState.user.name = replacedName
            }
        }
        setV2Claim(newState)

        if (!newState.user.contactViaWhatsapp) {
            delete newState.user.contactViaWhatsapp
        }

        const promoPayoutCode =
            isPromoCodeApplied && isPromoCodeValid ? promoCodeValue : ''

        postV2Claim(
            mapFormData(
                { ...newState, shareCode, promoPayoutCode },
                'claim',
                true
            ),
            (newState.passengers.length || 0) + 1
        ).then(res => {
            if (res) {
                const { isDuplicate, isClaimErrors, id } = res

                if (!!authenticated) {
                    const userKeys = Object.keys(newState.user)
                    let userUpdated = false

                    userKeys.forEach(key => {
                        if (key !== 'locale') {
                            if (V2Claim.user[key] !== newState.user[key]) {
                                userUpdated = true
                            }
                        }
                    })

                    if (newState.user && newState.user.postCode) {
                        store.dispatch({
                            type: 'USER',
                            payload: {
                                ...fullUserData,
                                postCode: newState.user.postCode,
                            },
                        })
                    }

                    if (userUpdated) {
                        updateUserDetails(
                            mapFormData(newState.user, 'user_edit')
                        )
                    }
                }

                if (!isDuplicate && !isClaimErrors) {
                    if (this.props.claimDraft) {
                        store.dispatch(
                            logDraftAction(
                                true,
                                draftLogActions.POST,
                                draftLogActionReasons.SUBMIT,
                                paths.V2.STEP_6_PASSENGER_DETAILS,
                                id
                            )
                        )
                    }
                    this.props.triggerAnalytics()
                    nextStep(true)
                }
            } else {
                this.props.triggerAnalytics()
                nextStep(true)
            }
        })
        removeBeforeUnload()
        return false
    }

    _handlePrevStep = () => {
        const { setV2Claim } = this.props
        let newState = { ...this.state }

        this._handleSaveDraft()
        setV2Claim(newState)
    }

    _handleSaveDraft = () => {
        const { setClaimDraftProps } = this.props
        const stateObj = { ...this.state }

        setClaimDraftProps(stateObj)
        this.props.saveClaimDraft()
    }

    checkEmailMatch = (e, skipCheck = false) => {
        const { authenticated } = this.props
        const { user, componentState } = this.state
        const { email } = user
        const value = _get(e, 'target.value', user.repeatEmail)

        if (
            !authenticated &&
            value &&
            email.toLowerCase() !== value.toLowerCase()
        ) {
            this.setState({
                componentState: {
                    ...componentState,
                    repeatEmailError: skipCheck
                        ? ''
                        : t`common.label.emails_dont_match`,
                },
            })

            if (e && e.target && skipCheck) {
                this.handleUserState(e)
            }
            return false
        } else {
            this.setState({
                componentState: {
                    ...componentState,
                    repeatEmailError: '',
                },
            })

            if (e && e.target && skipCheck) {
                this.handleUserState(e)
            }

            return true
        }
    }

    handleCountryInput = e => {
        const { countries } = this.props
        const { user } = this.state
        const countrylist = _get(countries, 'payload', [])
        const filteredCountry = countrylist.find(country => country.title === e)

        if (filteredCountry) {
            const newState = {
                country: filteredCountry.id,
            }

            if (!user.phoneCode) {
                newState.phoneCode = filteredCountry.id
            }

            this.setState(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    ...newState,
                },
            }))
        }
    }

    recheckErrors = (name, type = 'user', paxId) => {
        const { errors } = this.props
        if (name && errors && errors[type]) {
            if (type === 'user' && errors[type][name]) {
                store.dispatch({
                    type: V2_ACTIONS.SET_STEP_SIX_ERRORS,
                    payload: this.state,
                })
            } else if (type === 'passengers' && typeof paxId === 'number') {
                const paxErrors = [...(errors[type] || [])]
                const paxErr = paxErrors.filter(pax => pax.paxId === paxId)[0]
                if (paxErr && paxErr[name]) {
                    store.dispatch({
                        type: V2_ACTIONS.SET_STEP_SIX_ERRORS,
                        payload: this.state,
                    })
                }
            }
        }
    }

    handlePromoCode = e => {
        if (!e) return

        const { setPromoCode } = this.props
        const { value } = _get(e, 'target', e)

        if (value.length < 21) {
            setPromoCode({
                code: value.toUpperCase(),
                valid: true,
                applied: false,
            })
        }
    }

    handleUserState = e => {
        if (!e) return

        const { componentState, user } = this.state
        const { name, value } = _get(e, 'target', e)

        if (name === 'country') {
            fireGtagEvent({ event: 'step6_pd_country' }, true)
            if (!user.phoneCode) {
                this.setState(
                    prevState => ({
                        ...prevState,
                        user: {
                            ...prevState.user,
                            phoneCode: value,
                        },
                    }),
                    () => this.recheckErrors(name)
                )
            }
        }
        if (name === 'birthdate') {
            fireGtagEvent({ event: 'step6_pd_birthdate' }, true)
        }

        if (['name', 'surname', 'address', 'city'].includes(name)) {
            const newValue = latinize(changeToUppercase(value))

            if (name === 'name') {
                fireGtagEvent({ event: 'step6_pd_first_name_input' }, true)
            }

            if (name === 'surname') {
                fireGtagEvent({ event: 'step6_pd_last_name_input' }, true)
            }

            if (name === 'address') {
                fireGtagEvent({ event: 'step6_pd_address_input' }, true)
            }

            if (name === 'city') {
                fireGtagEvent({ event: 'step6_pd_city_input' }, true)
            }

            this.setState(
                prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        [name]: newValue,
                    },
                }),
                () => this.recheckErrors(name)
            )
        } else if (['email', 'repeatEmail'].includes(name)) {
            if (!isValidEmailInput(value)) {
                return
            }

            this.setState(
                prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        [name]: (value || '').toLowerCase().replace(/\s/g, ''),
                    },
                }),
                () => {
                    if (name === 'email') {
                        this.checkEmailMatch(null)
                    }
                    this.recheckErrors(name)
                }
            )
        } else if (name === 'contactViaWhatsapp') {
            this.setState(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    contactViaWhatsapp: !prevState.user.contactViaWhatsapp,
                },
            }))
        } else {
            this.setState(
                prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        [name]: value,
                    },
                }),
                () => this.recheckErrors(name)
            )
        }

        if (name === 'phone') {
            fireGtagEvent({ event: 'step6_pd_phone_input' }, true)
            this.setState(
                {
                    componentState: {
                        ...componentState,
                        phoneLengthError: '',
                    },
                },
                () => this.recheckErrors(name)
            )
        }
    }

    clearUserCountry = () => {
        this.setState(prevState => ({
            ...prevState,
            user: {
                ...prevState.user,
                country: '',
            },
        }))
    }

    handlePassengerState = (e, id) => {
        const { passengers } = this.state
        const flightDate = this.getFlightDate()

        const newPasengers = [...passengers]
        const { name, value } = _get(e, 'target', e)

        if (name === 'birthdate') {
            const age = getAgeInYears(value, flightDate)
            let category = passengerCategories.adu

            if (age < 2) {
                category = passengerCategories.inf
            } else if (age < adultAge) {
                category = passengerCategories.chi
            }

            newPasengers[id]['category'] = category
        }

        if (['name', 'surname'].includes(name)) {
            const newValue = latinize(changeToUppercase(value))

            newPasengers[id][name] = newValue
        } else if (name === 'email') {
            if (!isValidEmailInput(value)) {
                return
            }
            newPasengers[id][name] = value
        } else {
            newPasengers[id][name] = value
        }

        this.setPassengersAmount()

        this.setState(
            prevState => {
                return {
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        paxEmailErrors: [],
                    },
                    passengers: newPasengers,
                }
            },
            () => {
                const { componentState } = this.state
                this.setState({
                    componentState: {
                        ...componentState,
                        paxEmailErrors: this.checkPaxEmails(),
                    },
                })
                this.recheckErrors(name, 'passengers', id)
            }
        )
    }

    checkPaxEmails = () => {
        const { user, passengers } = this.state

        if (!passengers || passengers.length === 0) {
            return []
        }

        let duplicates = []
        const pushDuplicate = id => duplicates.push({ type: 'duplicate', id })

        passengers.some(item => {
            if (item.email) {
                const itemMail = item.email.trim().toLowerCase()
                const userMail =
                    (user && user.email && user.email.trim().toLowerCase()) ||
                    ''
                const otherEmails = passengers.filter(
                    (pax, paxId) =>
                        pax.email &&
                        pax.email.trim().toLowerCase() === itemMail &&
                        paxId !== item.id
                )
                if (otherEmails && otherEmails.length > 0) {
                    otherEmails.some(pax => {
                        pushDuplicate(pax.id)
                        return ''
                    })
                }
                if (userMail && userMail === itemMail) {
                    pushDuplicate(item.id)
                }
            }
            return ''
        })

        return duplicates
    }

    handleTermsChange = () => {
        const { user, componentState } = this.state
        this.setState(prevState => ({
            ...prevState,
            componentState: {
                ...prevState.componentState,
                termsConditions: !prevState.componentState.termsConditions,
                shouldValidateTerms: false,
            },
        }))
        this.isSelectAllCheckboxChecked(
            user.subscribe,
            !componentState.termsConditions
        )
    }

    handleSubscribe = () => {
        const { componentState, user } = this.state
        this.setState(prevState => ({
            ...prevState,
            user: {
                ...prevState.user,
                subscribe: !prevState.user.subscribe,
            },
        }))
        this.isSelectAllCheckboxChecked(
            componentState.termsConditions,
            !user.subscribe
        )
    }

    handleSelectAll = () => {
        const { isSelectAllChecked } = this.state
        if (!isSelectAllChecked) {
            this.setState(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    subscribe: true,
                },
            }))
            this.setState(prevState => ({
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    termsConditions: true,
                    shouldValidateTerms: false,
                },
            }))
        } else {
            this.setState(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    subscribe: false,
                },
            }))
            this.setState(prevState => ({
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    termsConditions: false,
                    shouldValidateTerms: false,
                },
            }))
        }
        this.setState(prevState => ({
            ...prevState,
            isSelectAllChecked: !prevState.isSelectAllChecked,
        }))
    }

    isSelectAllCheckboxChecked = (terms, subscribe) => {
        this.setState({ isSelectAllChecked: terms && subscribe })
    }

    addPassenger = () => {
        fireGtagEvent({ event: 'step6_pd_add_passenger' }, true)
        const { passengers } = this.state
        const newPasengers = {
            name: '',
            surname: '',
            birthdate: '',
            email: '',
            phone: '',
            phoneCode: '',
            isOpen: true,
            category: 'inf',
            id: passengers.length,
        }

        this.setState(prevState => ({
            ...prevState,
            passengers: [...prevState.passengers, newPasengers],
        }))
    }

    removePassenger = (e, id) => {
        e.stopPropagation()
        const { errors } = this.props
        const { passengers } = this.state
        const newPasengers = [...passengers]
        newPasengers.splice(id, 1)

        this.setState(
            prevState => ({
                ...prevState,
                passengers: newPasengers,
                componentState: {
                    ...prevState.componentState,
                    paxEmailErrors: [],
                    enabledAdd: false,
                },
            }),
            () => {
                if (((errors || {}).passengers || []).length > 0) {
                    this.disabledValidate()
                }
                setTimeout(() => {
                    this.setState({
                        ...this.state,
                        componentState: {
                            ...this.state.componentState,
                            enabledAdd: true,
                        },
                    })
                }, 500)
            }
        )
    }

    renderPhoneField = () => {
        const { user, componentState } = this.state
        const {
            countries,
            errors,
            authenticated,
            fullUserData = {},
            primaryColor,
        } = this.props

        const phoneTooltip = {
            children: () => (
                <TooltipContainer>
                    <TooltipTitle>{t`tooltip_user_header`}</TooltipTitle>
                    <TooltipContent>
                        {t`tooltip_user_phone_info`}
                    </TooltipContent>
                </TooltipContainer>
            ),
            dataTip: 'disabled',
            dataFor: 'phoneInfo',
            place: 'right',
            id: 'phoneInfo',
            questionId: 'qm-phone',
            type: 'info',
        }

        // if (user.phone && !user.phoneCode) {
        //     return (
        //         <InputField
        //             required
        //             disabled={!!authenticated && fullUserData.active}
        //             name="phone"
        //             id="user-phone"
        //             label={t`v2_common.label.phone`}
        //             placeholder={t`v2_common.placeholder.phone`}
        //             value={user.phone}
        //             onChange={this.handleUserState}
        //             errorText={
        //                 t`${_get(errors, 'user.phone', '')}` ||
        //                 componentState.phoneLengthError ||
        //                 ''
        //             }
        //             tooltip={phoneTooltip}
        //         />
        //     )
        // } else {
        return (
            <Col xs={12} md={12} lg={6}>
                <V2PhoneField
                    stickyOptionsList={true}
                    isFocusHighligh={true}
                    required
                    disabled={!!authenticated && fullUserData.active}
                    readOnly={!!authenticated && fullUserData.active}
                    label={t`v2_common.label.phone`}
                    placeholder={t`v2_common.placeholder.phone`}
                    onChange={this.handleUserState}
                    maxLength={16}
                    autoComplete="off"
                    phoneCode={user.phoneCode}
                    value={user.phone}
                    prefixInputName="phoneCode"
                    numberInputName="phone"
                    idPrefix="user"
                    countries={countries}
                    onDrop={e => e.preventDefault()}
                    errorText={
                        t`${_get(errors, 'user.phone', '')}` ||
                        componentState.phoneLengthError ||
                        ''
                    }
                    tooltip={phoneTooltip}
                    primaryColor={primaryColor}
                />
            </Col>
        )
        // }
    }

    validPaxBirthdate = current => {
        const daysBefore = 365 * 150
        const today = moment()
        const last = moment().subtract(daysBefore, 'days')
        return current.isBefore(today) && current.isAfter(last)
    }

    validUserDate = current => {
        const daysBefore = 365 * 150
        const today = moment().subtract(18, 'years')
        const last = moment().subtract(daysBefore, 'days')
        return current.isBefore(today) && current.isAfter(last)
    }

    getFlightDate = () => {
        const { V2Claim } = this.props
        return _get(
            V2Claim,
            `flights[${_get(V2Claim, 'problemClaimFlight', 0)}].timeDeparture`,
            moment().format('YYYY-MM-DD')
        )
    }

    isPhoneFieldValidForVerification = () => {
        const { V2Claim } = this.props
        const userPhone = _get(this.state, 'user.phone', '')
        const userPhoneCode = _get(this.state, 'user.phoneCode', '')
        return (
            !!V2Claim &&
            !!userPhoneCode &&
            !!userPhone &&
            this.validatePhoneLength(userPhone, userSchema.phone.minLength)
        )
    }

    handleVerificationCodeChange = val => {
        const re = /^\d{1,6}$/

        if (val === '' || re.test(val)) {
            this.setState({
                verificationCode: val,
            })
        }
        this.setState({ isCodeValid: true })
    }

    handleVerificationCodeSubmit = code => {
        const apiClient = getApiClient()
        const url = `/api/phone-confirmation`

        apiClient
            .post(`${url}/${code}`)
            .then(res => {
                this.setState({ isVerificationSubmitClicked: true })
                this.setState({ isCodeValid: true })
            })
            .catch(err => {
                console.log('err', err)
                this.setState({ isVerificationSubmitClicked: false })
                this.setState({ isCodeValid: false })
            })
    }

    applyPromoCode = () => {
        const { promoCodeValue, setPromoCodeInvalid } = this.props

        const { setPromoCode } = this.props

        const apiClient = getApiClient()
        const url = `/api/promo-payout/code`

        apiClient
            .get(`${url}/${promoCodeValue}`)
            .then(res => {
                setPromoCode({
                    code: promoCodeValue,
                    valid: true,
                    applied: true,
                })
            })
            .catch(err => {
                console.log('err', err)
                setPromoCodeInvalid()
            })
    }

    sendVerificationCode = () => {
        const { V2Claim } = this.props
        const userPhone = _get(this.state, 'user.phone', '')
        const userPhoneCode = _get(this.state, 'user.phoneCode', '')
        const userLocale = _get(this.state, 'user.locale', '')

        let data = new FormData()
        data.append('phone_confirmation[claimDraftId]', V2Claim.id)
        data.append('phone_confirmation[phoneNumber]', userPhone)
        data.append('phone_confirmation[locale]', userLocale)
        data.append('phone_confirmation[phoneCodeCountryId]', userPhoneCode)

        const apiClient = getApiClient()
        const url = `/api/phone-confirmation`

        this.setState({ isResendButtonActive: false })
        setTimeout(() => {
            this.setState({ isResendButtonActive: true })
        }, 15000)

        apiClient
            .post(url, data)
            .then(res => {
                console.log('res', res)
                this.setState({ isVerificationCodeSent: true })
            })
            .catch(err => {
                console.log('err', err)
                this.setState({ isVerificationCodeSent: false })
            })
    }

    renderUser = () => {
        const {
            componentState,
            user,
            verificationCode,
            isVerificationCodeSent,
            isCodeValid,
            isVerificationSubmitClicked,
            isResendButtonActive,
        } = this.state
        const {
            countries,
            trans,
            errors,
            authenticated,
            is_valid_smtp,
            fullUserData = {},
            primaryColor,
            promoCodeValue,
            isPromoCodeValid,
            isPromoCodeApplied,
        } = this.props

        const isPhoneVerificationEnabled = isFeatureEnabled(
            featureToggles.featureIsPhoneVerificationEnabled
        )

        if (!countries && !trans) return null

        return (
            <UserInfoContainer>
                <form
                    autoComplete="on"
                    onSubmit={e => e.preventDefault()}
                    onKeyDown={e => {
                        if (e.keyCode === ENTER_KEY) {
                            e.preventDefault()
                            return false
                        }
                    }}
                >
                    {!authenticated && (
                        <Row>
                            <SignInContainer>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <SignInBox>
                                        <TextBox>
                                            <p>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: t`v2_claim.passenger_details.speed_up_process`,
                                                    }}
                                                />
                                                <StyledSpan>
                                                    {applyTranslationAction(
                                                        t`v2_claim.passenger_details.speed_up_process.link_text`,
                                                        '<a>',
                                                        '</a>',
                                                        () => {
                                                            fireGtagEvent(
                                                                {
                                                                    event:
                                                                        'step6_pd_sign_in',
                                                                },
                                                                true
                                                            )
                                                            this.props.showModal(
                                                                modalTypes.loginModal
                                                            )
                                                        },
                                                        'link'
                                                    )}
                                                </StyledSpan>
                                            </p>
                                        </TextBox>
                                    </SignInBox>
                                </Col>
                            </SignInContainer>
                        </Row>
                    )}
                    <StyledFieldsRow>
                        <InputField
                            borderRadius="12px"
                            disabled={!!authenticated && fullUserData.active}
                            name="name"
                            id="user-name"
                            label={t`v2_common.label.name`}
                            required
                            placeholder={t`v2_common.placeholder.name`}
                            value={user.name}
                            onChange={this.handleUserState}
                            errorText={t`${_get(errors, 'user.name', '')}`}
                            autoComplete="name-first"
                        />
                        <InputField
                            borderRadius="12px"
                            disabled={!!authenticated && fullUserData.active}
                            required
                            label={t`v2_common.label.surname`}
                            name="surname"
                            id="user-surname"
                            placeholder={t`v2_common.placeholder.surname`}
                            value={user.surname}
                            onChange={this.handleUserState}
                            errorText={t`${_get(errors, 'user.surname', '')}`}
                            autoComplete="name-last"
                        />
                    </StyledFieldsRow>
                    <StyledFieldsRow>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <DatePicker
                                primaryColor={primaryColor}
                                readOnly={
                                    !!authenticated &&
                                    fullUserData.active &&
                                    fullUserData.birthdate
                                }
                                dateFormat="YYYY-MM-DD"
                                label={t`v2_common.label.birthdate`}
                                tooltip={{
                                    children: () => (
                                        <TooltipContainer>
                                            <TooltipTitle>
                                                {t`tooltip_user_header`}
                                            </TooltipTitle>
                                            <TooltipContent>
                                                {t`v2_claim.user.birthdate_info`}
                                            </TooltipContent>
                                        </TooltipContainer>
                                    ),
                                    dataTip: 'disabled',
                                    dataFor: 'birthdateInfo',
                                    place: 'right',
                                    id: 'birthdateInfo',
                                    questionId: 'qm-birthdate',
                                    type: 'info',
                                }}
                                required
                                type="text"
                                id="user-birthdate"
                                name="birthdate"
                                initialViewDate={
                                    user.birthdate
                                        ? moment.utc(user.birthdate)
                                        : moment.utc('1989')
                                }
                                isValidDate={this.validUserDate}
                                startDate={moment().diff('18', 'years')}
                                endDate={150}
                                initialViewMode="years"
                                placeholder={t`v2_common.placeholder.birthdate`}
                                value={
                                    !user.birthdate
                                        ? ''
                                        : moment.utc(user.birthdate)
                                }
                                handleChange={this.handleUserState}
                                errorText={t`${_get(
                                    errors,
                                    'user.birthdate',
                                    ''
                                )}`}
                            />
                        </Col>
                        {!!authenticated && (
                            <InputField
                                borderRadius="12px"
                                disabled={
                                    !!authenticated && fullUserData.active
                                }
                                label={t`v2_common.label.email`}
                                name="email"
                                type="email"
                                id="user-email"
                                required
                                placeholder={t`v2_common.placeholder.email`}
                                value={user.email}
                                onChange={this.handleUserState}
                                errorText={t`${_get(errors, 'user.email', '')}`}
                                autoComplete="email"
                            />
                        )}
                    </StyledFieldsRow>
                    {user.birthdate && (
                        <React.Fragment>
                            <FlexSpan bottomPadding="16px">
                                <H1>{t`v2.passenger_details.user.contact_info`}</H1>
                            </FlexSpan>
                            {!authenticated && (
                                <StyledFieldsRow>
                                    <InputField
                                        borderRadius="12px"
                                        disabled={
                                            !!authenticated &&
                                            fullUserData.active
                                        }
                                        label={t`v2_common.label.email`}
                                        name="email"
                                        type="email"
                                        id="user-email"
                                        required
                                        placeholder={t`v2_common.placeholder.email`}
                                        value={user.email}
                                        onChange={this.handleUserState}
                                        errorText={t`${_get(
                                            errors,
                                            'user.email',
                                            ''
                                        )}`}
                                        suggestedText={{
                                            text: emailMagic(user.email),
                                            callback: () => {
                                                this.handleUserState({
                                                    target: {
                                                        name: 'email',
                                                        value: emailMagic(
                                                            user.email
                                                        ),
                                                    },
                                                })
                                                validateEmailSmtp(
                                                    emailMagic(user.email)
                                                )
                                            },
                                        }}
                                        onBlur={() =>
                                            validateEmailSmtp(user.email)
                                        }
                                        isValidSmtp={!!is_valid_smtp}
                                        hasSmtpValidation={true}
                                        autoComplete="do-not-autofill"
                                        onKeyDown={e => {
                                            if (e.keyCode === SPACE_KEY) {
                                                e.preventDefault()
                                                return false
                                            }
                                        }}
                                        hasDanger={
                                            !!is_valid_smtp &&
                                            !!_get(
                                                this,
                                                'state.componentState.repeatEmailError',
                                                ''
                                            )
                                        }
                                    />
                                    <InputField
                                        borderRadius="12px"
                                        disabled={
                                            !!authenticated &&
                                            fullUserData.active
                                        }
                                        label={t`v2_common.label.repeat_email`}
                                        type="email"
                                        required
                                        name="repeatEmail"
                                        id="user-email-repeat"
                                        placeholder={t`v2_common.placeholder.repeat_email`}
                                        value={user.repeatEmail}
                                        onChange={e =>
                                            this.checkEmailMatch(e, true)
                                        }
                                        onBlur={this.checkEmailMatch}
                                        errorText={_get(
                                            this.state,
                                            'componentState.repeatEmailError',
                                            ''
                                        )}
                                        onPaste={e => {
                                            if (isProduction()) {
                                                e.preventDefault()
                                            }
                                        }}
                                        onDrop={e => {
                                            if (isProduction()) {
                                                e.preventDefault()
                                            }
                                        }}
                                        autoComplete="do-not-autofill"
                                        onKeyDown={e => {
                                            if (e.keyCode === SPACE_KEY) {
                                                e.preventDefault()
                                                return false
                                            }
                                        }}
                                    />
                                </StyledFieldsRow>
                            )}
                            <StyledFieldsRow>
                                <InputField
                                    borderRadius="12px"
                                    disabled={
                                        !!authenticated &&
                                        fullUserData.active &&
                                        fullUserData.address
                                    }
                                    label={t`v2_common.label.address`}
                                    required
                                    name="address"
                                    id="user-address"
                                    placeholder={t`v2_common.placeholder.address`}
                                    value={user.address}
                                    onChange={this.handleUserState}
                                    errorText={t`${_get(
                                        errors,
                                        'user.address',
                                        ''
                                    )}`}
                                    autoComplete="street-address"
                                    tooltip={{
                                        children: () => (
                                            <TooltipContainer>
                                                <TooltipTitle>
                                                    {t`tooltip_user_header`}
                                                </TooltipTitle>
                                                <TooltipContent>
                                                    {t`tooltip_user_address_info`}
                                                </TooltipContent>
                                            </TooltipContainer>
                                        ),
                                        dataTip: 'disabled',
                                        dataFor: 'addressInfo',
                                        place: 'right',
                                        id: 'addressInfo',
                                        questionId: 'qm-address',
                                        type: 'info',
                                    }}
                                />
                                <InputField
                                    borderRadius="12px"
                                    disabled={
                                        !!authenticated &&
                                        fullUserData.active &&
                                        fullUserData.city
                                    }
                                    label={t`v2_common.label.city`}
                                    required
                                    name="city"
                                    id="user-city"
                                    placeholder={t`v2_common.placeholder.city`}
                                    value={user.city}
                                    onChange={this.handleUserState}
                                    errorText={t`${_get(
                                        errors,
                                        'user.city',
                                        ''
                                    )}`}
                                    autoComplete="address-level2"
                                />
                            </StyledFieldsRow>
                            {componentState.innerWidth <= 960 &&
                                this.renderPostCode()}
                            <StyledFieldsRow>
                                <Col xs={12} md={12} lg={6}>
                                    <CountryInput
                                        isFocusHighligh={true}
                                        stickyOptionsList={true}
                                        borderRadius="12px"
                                        disabled={
                                            !!(
                                                !!authenticated &&
                                                fullUserData.active &&
                                                fullUserData.country
                                            )
                                        }
                                        required
                                        value={user.country}
                                        defaultValue={user.country}
                                        onChange={this.handleUserState}
                                        name="country"
                                        id="user-country"
                                        errorText={t`${_get(
                                            errors,
                                            'user.country',
                                            ''
                                        )}`}
                                        onInputChange={this.handleCountryInput}
                                        clear={this.clearUserCountry}
                                        autoComplete="country"
                                    />
                                </Col>
                                {this.renderPhoneField()}
                            </StyledFieldsRow>
                            {!authenticated && (
                                <StyledFieldsRow>
                                    {!isPhoneVerificationEnabled ? (
                                        <Col xs={0} sm={6} lg={6} />
                                    ) : (
                                        <Col xs={12} md={12} lg={6}>
                                            <PhoneVerificationField
                                                checkboxColor={primaryColor}
                                                isVerificationSubmitClicked={
                                                    isVerificationSubmitClicked
                                                }
                                                isPhoneValid={this.isPhoneFieldValidForVerification()}
                                                isCodeSent={
                                                    isVerificationCodeSent
                                                }
                                                onSendCode={() =>
                                                    this.sendVerificationCode()
                                                }
                                                onResendClick={() =>
                                                    this.sendVerificationCode()
                                                }
                                                onVerificationCodeChange={
                                                    this
                                                        .handleVerificationCodeChange
                                                }
                                                onVerificationCodeSubmit={
                                                    this
                                                        .handleVerificationCodeSubmit
                                                }
                                                verificationCode={
                                                    verificationCode
                                                }
                                                isCodeValid={isCodeValid}
                                                isResendButtonActive={
                                                    isResendButtonActive
                                                }
                                            />
                                        </Col>
                                    )}
                                    {isFeatureEnabled(
                                        featureToggles.featureIsWhatsAppBoxVisible
                                    ) && (
                                        <Col
                                            xs={12}
                                            sm={8}
                                            lg={6}
                                            style={{
                                                float: 'right',
                                                'align-self': 'flex-end',
                                            }}
                                        >
                                            <WhatsAppBox
                                                name="contactViaWhatsapp"
                                                id="contact-via-whatsapp"
                                                checked={
                                                    user.contactViaWhatsapp ||
                                                    false
                                                }
                                                onChange={this.handleUserState}
                                            />
                                        </Col>
                                    )}
                                </StyledFieldsRow>
                            )}
                            {componentState.innerWidth > 960 &&
                                this.renderPostCode()}
                            <StyledFieldsRow>
                                <InputField
                                    borderRadius="12px"
                                    label={t`common.field.promo.code.input`}
                                    name="promoCode"
                                    type="text"
                                    id="user-promoCode"
                                    placeholder={t`common.field.promo.code.input`}
                                    value={promoCodeValue}
                                    onChange={this.handlePromoCode}
                                    errorText={
                                        !isPromoCodeValid &&
                                        t`common.field.promo.code.invalid`
                                    }
                                    autoComplete="promoCode"
                                    lg="6"
                                    sm="8"
                                    xs="8"
                                />
                                <Col xs={4} md={4} lg={6}>
                                    {!isPromoCodeApplied ? (
                                        <PromoCodeBtn
                                            isPromoCodeValid={isPromoCodeValid}
                                        >
                                            <V2ButtonStyledBorder
                                                type="borderBtn"
                                                text="Check"
                                                onClick={this.applyPromoCode}
                                                disabled={!promoCodeValue}
                                            >
                                                {t`common.promo.code.apply.btn`}
                                            </V2ButtonStyledBorder>
                                        </PromoCodeBtn>
                                    ) : (
                                        <PromoCodeApplied>{t`common.field.promo.code.valid`}</PromoCodeApplied>
                                    )}
                                </Col>
                            </StyledFieldsRow>
                        </React.Fragment>
                    )}
                </form>
            </UserInfoContainer>
        )
    }

    renderAddPassengerBtn = () => {
        const { passengers } = this.state
        const { v2_amount } = this.props

        if (passengers && passengers.length === 0)
            return (
                <PagePadding>
                    <Row>
                        <Col>
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: t`v2_claim_additional_passengers_add_description`.replace(
                                        '%amount%',
                                        `  ${v2_amount}`
                                    ),
                                }}
                            />
                        </Col>
                        <Col style={{ alignSelf: 'end' }}>
                            <V2ButtonStyledBorder
                                type="borderBtn"
                                onClick={e => {
                                    if (this.state.componentState.enabledAdd) {
                                        this.addPassenger(e)
                                    }
                                }}
                            >
                                {t`v2_common.add_passenger`}
                            </V2ButtonStyledBorder>
                        </Col>
                    </Row>
                </PagePadding>
            )

        // if (passengers && passengers.length > 0)
        //     return (
        //         <Row>
        //             <BlockContainer style={{ width: '100%' }}>
        //                 <V2ButtonStyled
        //                     onClick={e => {
        //                         if (this.state.componentState.enabledAdd) {
        //                             this.addPassenger(e)
        //                         }
        //                     }}
        //                 >
        //                     {t`v2_claim_additional_passengers_add_btn`}
        //                     {regulation === 'ca' && v2_amount === 0
        //                         ? ''
        //                         : ` + €${v2_amount}`}
        //                 </V2ButtonStyled>
        //             </BlockContainer>
        //         </Row>
        //     )
    }

    renderPassengers = () => {
        const { passengers, componentState, user } = this.state
        const { paxEmailErrors } = componentState
        const {
            errors,
            countries,
            passenger_smtp,
            regulation,
            v2_amount,
            primaryColor,
        } = this.props
        const flightDate = this.getFlightDate()

        return passengers.map((passenger, id) => {
            const emailError = (paxEmailErrors || []).find(i => i.id === id)
            const emailErrorText = emailError
                ? t`common.info.emails_cannot_repeat`
                : ''
            const paxErrors = _get(errors, 'passengers', []).filter(
                pax => pax.paxId === id
            )
            return (
                <AdditionalPax
                    passengersLength={passengers.length}
                    isAddEnabled={this.state.componentState.enabledAdd}
                    addPassenger={e => this.addPassenger(e)}
                    regulation={regulation}
                    v2_amount={v2_amount}
                    mainUserCountry={user.country}
                    key={id}
                    emailErrorText={emailErrorText}
                    paxErrors={paxErrors}
                    countries={countries}
                    flightDate={flightDate}
                    index={id}
                    removePassenger={this.removePassenger}
                    handlePassengerState={this.handlePassengerState}
                    trans={this.props.trans}
                    paxSmtp={passenger_smtp[`passenger${id}`]}
                    primaryColor={primaryColor}
                    {...passenger}
                />
            )
        })
    }

    validateSchema = (validations, payload, checkRequired = true) => {
        const reducedErrors = Object.keys(validations).reduce(
            (errors, validator) => {
                if (typeof payload[validator] !== validations[validator].type) {
                    errors[validator] =
                        validations[validator].errorMessages.type_error
                }

                if (checkRequired && !payload[validator]) {
                    errors[validator] =
                        validations[validator].errorMessages.required_error
                }

                if (
                    validations[validator].emailValid &&
                    !isValidEmail(payload[validator])
                ) {
                    errors[validator] =
                        validations[validator].errorMessages.emailValid_error
                }

                if (
                    validations[validator].validLatin &&
                    !isValidLatinChars(payload[validator])
                ) {
                    errors[validator] =
                        validations[validator].errorMessages.validLatin_error
                }

                return errors
            },
            {}
        )

        if (reducedErrors && Object.keys(reducedErrors).length > 0) {
            return reducedErrors
        } else {
            return null
        }
    }

    userErrors = user => {
        return this.validateSchema(userSchema, user)
    }

    checkNextStep = () => {
        const {
            isVerificationSubmitClicked,
            isCodeValid,
            isVerificationCodeSent,
            verificationCode,
        } = this.state
        const { authenticated } = this.props
        const flightDate = this.getFlightDate()

        const { isEnabledPostCode } = this.props
        const { passengers, componentState, user } = this.state

        if (!_get(componentState, 'termsConditions', false)) {
            return true
        }

        const missingPassengers = (passengers || []).filter(
            p =>
                !p.name ||
                !p.surname ||
                !p.birthdate ||
                (getAgeInYears(p.birthdate, flightDate) < adultAge
                    ? !p.legalGuardian || !p.legalGuardianFullName
                    : false)
        )

        if (missingPassengers.length > 0) {
            return true
        }

        if (componentState && componentState.repeatEmailError) {
            return true
        }

        if (
            Object.keys(userSchema)
                .map(key => {
                    if (!user[key]) {
                        return !user[key]
                    }

                    // @TODO reapply to all ?
                    // if (userSchema[key].minLength) {
                    //     return user[key].length < userSchema[key].minLength;
                    // }

                    return false
                })
                .filter(v => v).length > 0
        ) {
            return true
        }

        this.validatePhoneLength(user.phone, userSchema.phone.minLength)

        if (isEnabledPostCode && !user.postCode) {
            return true
        }

        if ((componentState.paxEmailErrors || []).length > 0) {
            return true
        }

        if (
            !authenticated &&
            isFeatureEnabled(
                featureToggles.featureIsPhoneVerificationEnabled
            ) &&
            !(
                isVerificationSubmitClicked &&
                isCodeValid &&
                isVerificationCodeSent &&
                !!verificationCode
            )
        ) {
            return true
        }
    }

    validatePhoneLength = (phone, minLength) => {
        const { componentState } = this.state

        if (!componentState.phoneLengthError) {
            if (phone && phone.length < minLength) {
                this.setState({
                    componentState: {
                        ...componentState,
                        phoneLengthError: t`min.length.error.text`,
                    },
                })
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    validatePostCodeLength = (postCode, minLength) => {
        const { componentState } = this.state

        if (postCode && postCode.length < minLength) {
            this.setState({
                componentState: {
                    ...componentState,
                    postCodeError: t`min.length.error.text`,
                },
            })
            return false
        } else {
            return true
        }
    }

    getFieldErrors = error => {
        let errors = []
        for (var key in error) {
            if (error.hasOwnProperty(key)) {
                if (typeof error[key] !== 'string') {
                    for (var nKey in error[key]) {
                        errors = [...errors, error[key][nKey]]
                    }
                } else {
                    errors = [...errors, error[key]]
                }
            }
        }

        return errors
    }

    disabledValidate = () => {
        const formErrors = this.props.validateStepSix(this.state)
        const userErrors = _get(formErrors, 'user', {})
        const paxErrors = _get(formErrors, 'passengers', [])

        this.setState({
            ...this.state,
            componentState: {
                ...this.state.componentState,
                shouldValidateTerms: true,
            },
        })

        if (Object.keys(userErrors).length > 0) {
            const missingInput = document.getElementsByName(
                Object.keys(userErrors)[0]
            )[0]
            this.elemScrollIfPossible(missingInput)
        } else if (Object.keys(paxErrors[0] || {}).length > 0) {
            const paxErr = { ...(paxErrors[0] || {}) }
            if (paxErr && typeof paxErr.paxId === 'number') {
                const paxId = paxErr.paxId
                delete paxErr.paxId
                const elemId = `passenger-${paxId}-${Object.keys(paxErr)[0]}`
                const missingInput = document.getElementById(elemId)
                this.elemScrollIfPossible(missingInput)
            }
        } else if (_get(this, 'state.componentState.repeatEmailError', '')) {
            const missingInput = document.getElementsByName('repeatEmail')[0]
            this.elemScrollIfPossible(missingInput)
        }
    }

    elemScrollIfPossible = elem => {
        if (elem && elem.getBoundingClientRect()) {
            scrollTo(
                document.body,
                elem.getBoundingClientRect().top + window.scrollY - 110,
                1000
            )
        }
    }

    renderErrors = () => {
        const { claimErrors } = this.props
        const userErrors = _get(claimErrors, 'user', {})
        const flightsErrors = _get(claimErrors, 'flights', [])
        const userKeys = Object.keys(userErrors)

        const mappedFlights = flightsErrors.map(flight => {
            const keys = Object.keys(flight)

            return keys.map((key, i) => (
                <div key={i}>
                    <p>{`${key.charAt(0).toUpperCase()}${key.slice(1)}:`}</p>
                    {flight[key].map((error, index) => (
                        <li key={index}>{error}</li>
                    ))}
                </div>
            ))
        })

        return (
            <ErrorList>
                {userKeys.map((key, i) => (
                    <div key={i}>
                        <p>{`${key.charAt(0).toUpperCase()}${key.slice(
                            1
                        )}:`}</p>
                        {userErrors[key].map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </div>
                ))}
                {mappedFlights}
            </ErrorList>
        )
    }

    renderPostCode = () => {
        const { user, componentState } = this.state
        const {
            isEnabledPostCode,
            authenticated,
            fullUserData,
            errors,
        } = this.props

        if (isEnabledPostCode) {
            return (
                <StyledFieldsRow>
                    <InputField
                        disabled={
                            !!authenticated &&
                            fullUserData.active &&
                            user.postCode &&
                            (fullUserData.postCode || fullUserData.post_code)
                        }
                        label={t`v2_common.label.postcode`}
                        required
                        name="postCode"
                        maxLength={10}
                        placeholder={t`v2_common.placeholder.postcode`}
                        value={user.postCode || ''}
                        onChange={this.handleUserState}
                        errorText={
                            t`${_get(errors, 'user.postCode', '')}` ||
                            componentState.postCodeError ||
                            ''
                        }
                        autoComplete="postal-code"
                    />
                    {componentState.innerWidth > 960 && (
                        <Col xs={12} md={12} lg={6}></Col>
                    )}
                </StyledFieldsRow>
            )
        }
    }

    render() {
        const {
            componentState,
            passengers,
            user,
            isSelectAllChecked,
        } = this.state
        const { trans, nav, claimErrors, clearClaimErrors } = this.props

        if (!trans) return null

        return (
            <StepView
                sideBarPaddingTop={'0px'}
                nav={{ ...nav, nextDisabled: this.checkNextStep() }}
                Sidebar={
                    <SidebarProgress
                        activeIndex={5}
                        checkedIndexes={[0, 1, 2, 3, 4]}
                        paddingTop={0}
                    />
                }
                title={
                    <PagePaddingTitle>
                        {t`v2.passenger_details.user.basic_info`}
                    </PagePaddingTitle>
                }
                titleText={t`v2.passenger_details.user.basic_info`}
                isFinish={true}
                titleBottomPadding="16px"
                buttonText={t`v2.button.claim_now`}
                renderPriceList={true}
                renderFPMobile={true}
                disabledClick={this.disabledValidate}
                showBorder={false}
                HeaderInfoBox={() => null}
                padding="unset"
            >
                <PagePadding>
                    {claimErrors && (
                        <ErrorsBox
                            title={t`v2_common.title.error_list_text`}
                            onClick={clearClaimErrors}
                        >
                            {this.renderErrors()}
                        </ErrorsBox>
                    )}
                </PagePadding>

                <Content>
                    <PagePadding>
                        <Row>{this.renderUser()}</Row>
                        {/* <FlexSpan bottomPadding="24px" mobileBottomPadding="24px">
                        <H1>{t`v2.passenger_details.other_passengers`}</H1>
                    </FlexSpan> */}
                        {passengers && passengers.length === 0 && (
                            <DashedLine />
                        )}
                    </PagePadding>
                    {this.renderPassengers()}

                    {(passengers || []).length < 50 &&
                        this.renderAddPassengerBtn()}

                    {/* {(passengers || []).length < 50 && (
                        <Row>
                            <BlockContainer style={{ width: '100%' }}>
                                <PassengerInfoContainer
                                    className="mobile-compact"
                                    style={{ display: 'flex' }}
                                >
                                    <LeftContainer>
                                        <ManIcon />
                                        <span className="passenger-title">
                                            {t`v2_common.add_passenger`}
                                            {regulation === 'ca' &&
                                            v2_amount === 0
                                                ? ''
                                                : ` + €${v2_amount}`}
                                        </span>
                                    </LeftContainer>
                                    <RightContainer>
                                        <CloseIcon
                                            onClick={e => {
                                                if (
                                                    this.state.componentState
                                                        .enabledAdd
                                                ) {
                                                    this.addPassenger(e)
                                                }
                                            }}
                                            style={{
                                                transform: 'rotate(45deg)',
                                            }}
                                            fill="#fff"
                                            stroke="#495363"
                                            width="32"
                                            height="32"
                                        />
                                    </RightContainer>
                                </PassengerInfoContainer>
                            </BlockContainer>
                        </Row>
                    )} */}
                    <PagePadding>
                        {componentState.showSubscribeCbx && (
                            <>
                                <Row style={{ marginTop: '40px' }}>
                                    <CheckboxSelectAll>
                                        <Subscribe
                                            fontWeight={400}
                                            text={t`v2_claim.select_all_terms`}
                                            onChange={this.handleSelectAll}
                                            checked={isSelectAllChecked}
                                        />
                                    </CheckboxSelectAll>
                                </Row>
                            </>
                        )}

                        <Row
                            style={{
                                marginTop:
                                    !componentState.showSubscribeCbx && '40px',
                            }}
                        >
                            {componentState.showSubscribeCbx ? (
                                <CheckboxSelectAll>
                                    <TermsAndConditions
                                        text={t`v2_claim.passenger_details.terms_and_conditions_price_list`}
                                        onChange={this.handleTermsChange}
                                        checked={componentState.termsConditions}
                                        alert={
                                            !componentState.termsConditions &&
                                            componentState.shouldValidateTerms
                                        }
                                    />
                                </CheckboxSelectAll>
                            ) : (
                                <CheckboxContainer>
                                    <TermsAndConditions
                                        text={t`v2_claim.passenger_details.terms_and_conditions_price_list`}
                                        onChange={this.handleTermsChange}
                                        checked={componentState.termsConditions}
                                        alert={
                                            !componentState.termsConditions &&
                                            componentState.shouldValidateTerms
                                        }
                                    />
                                </CheckboxContainer>
                            )}
                        </Row>

                        {componentState.showSubscribeCbx ? (
                            <Row
                                style={{
                                    'margin-top': componentState.showSubscribeCbx
                                        ? '-30px'
                                        : '-25px',
                                }}
                            >
                                <CheckboxContainer>
                                    <Subscribe
                                        text={t`subscription.description.subscribe`}
                                        onChange={this.handleSubscribe}
                                        checked={user.subscribe}
                                    />
                                </CheckboxContainer>
                            </Row>
                        ) : null}
                    </PagePadding>
                </Content>
            </StepView>
        )
    }
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        v2_amount: state.v2_amount,
        totalAmount: state.v2_total_amount,
        payoutPlan: state.v2_payoutPlan,
        selectedPayout: state.selectedPayout,
        payouts: state.v2_payoutPlan,
        V2Claim: state.V2Claim,
        errors: state.v2_step_six_errors.errors,
        countries: state.countries,
        airlines: state.airlines,
        trans: state.translations,
        authenticated: state.auth.authenticated,
        is_valid_smtp: state.valid_email_smtp,
        passenger_smtp: state.passenger_smtp,
        fullUserData: state.user || {},
        user: { ...state.V2Claim.user, promoCode: state.promoCode.code } || {
            name: '',
            surname: '',
            birthdate: '',
            email: '',
            repeatEmail: '',
            address: '',
            city: '',
            country: '',
            phoneCode: '',
            phone: '',
            locale: getStorage('lang'),
            contactViaWhatsapp: false,
            subscribe: false,
            promoCode: state.promoCode.code,
        },
        passengers: state.V2Claim.passengers || [],
        claimErrors: state.claim_errors,
        isEnabledPostCode: state.isEnabledPostCode,
        claimDraft: state.claimDraft,
        claimPreloaded: state.claimPreloaded,
        regulation: state.regulation,
        shareCode: state.referralModal.shareCode,
        primaryColor: state.propsTheme.colors.primaryColor,
        isPromoCodeValid: state.promoCode.valid,
        isPromoCodeApplied: state.promoCode.applied,
        promoCodeValue: state.promoCode.code,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postV2Claim,
            showModal,
            setV2Claim,
            validateStepSix,
            getUserData,
            setProgress,
            nextStep,
            setTotalClaimAmount,
            clearClaimErrors,
            setPayoutDetails,
            updateUserDetails,
            setClaimDraftProps,
            setPreloadedClaimStep,
            saveClaimDraft,
            setPostedClaim,
            setPromoCode,
            setPromoCodeInvalid,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(PassengerDetails)
