import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import t from '../../../shared/translations'
import {
    TwitterShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    TelegramShareButton,
} from 'react-share'
import styled from 'styled-components'
import _get from 'lodash/get'
import { sendFeedback, hideModal } from '../../../shared/actions/v2_actions'
import { v2DefaultConfig } from '../../../shared/theme/config'
import { toggleFooter } from '../../../main'
import {
    desktop,
    tablet,
    mobile,
} from '../../../shared/components/helpers/styled_queries'
import {
    LinkIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIconNew,
    WhatsAppIconNew,
    FacebookIconNew,
} from '../../../shared/components/V2/V2Icons'
import { logKibana } from '../../../shared/actions'

const SocialContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    padding-left: 8px;

    ${tablet`
        margin: auto;
        margin-top: 12px;
    `}

    ${mobile`
        margin: auto;
        margin-top: 12px;
    `}
`

const IconContainer = styled.div`
    margin: 0 8px;

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

    svg {
        width: 40px;
        height: 40px;
    }
`

const H1 = styled.h1`
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: ${v2DefaultConfig.colors.darkText};

    ${tablet`
        text-align: center;
    `}

    ${mobile`
        text-align: center;
    `}
`

const SubheaderContainer = styled.div`
    display: flex;
    margin-top: 8px;

    ${tablet`
        justify-content: center;
    `}

    ${mobile`
        justify-content: center;
    `}
`

const Subheader = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #7f8fa4;
    max-width: 300px;

    span {
        font-weight: bold;
        color: #353d51;
    }

    ${mobile`
        text-align: center;
    `}

    ${tablet`
        text-align: center;
    `}
`

const InfoBox = styled.div`
    position: absolute;
    top: -88px;
    margin-top: 12px;
    left: 2px;
    white-space: nowrap;
    background-color: #353d51;
    border-radius: 8px;
    color: #fff;
    padding: 8px 20px;
    font-size: 14px;

    &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        left: 20%;
        top: 100%;
        border-radius: 3px;
        width: 14px;
        background-color: #353d51;
        margin: -8px -7px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        height: 14px;
    }
`

const HumanIcon = styled.div`
    display: flex;
    position: relative;
    min-width: 200px;
    margin-left: 35px;
    margin-right: 16px;
    margin-bottom: -16px;
    overflow: hidden;

    img {
        width: 100%;
        position: absolute;
        top: 0px;
    }

    ${mobile`
        ${({ desktop }) => desktop && `display: none;`}
        margin-top: 12px;
        height: 160px;

        img {
            top: 8px;
            left: 25px;
            height: 250px;
        }
    `}

    ${tablet`
        ${({ desktop }) => desktop && `display: none;`}

        margin-top: 12px;
        height: 160px;

        img {
            top: 8px;
            left: 25px;
            height: 250px;
        }
    `}

    ${desktop`
        ${({ mobile }) => mobile && `display: none;`}
    `}
`

const Text = styled.div`
    display: flex;
    flex-direction: column;
`

class SocialPage extends React.Component {
    state = {
        showCopiedTooltip: false,
    }

    componentWillUnmount() {
        toggleFooter().show()
    }

    showTooltip = () => {
        this.setState({ showCopiedTooltip: true }, () => {
            setTimeout(() => {
                this.setState({ showCopiedTooltip: false })
            }, 2000)
        })
    }

    copyShareUrl = () => {
        let textArea = document.createElement('textarea')
        textArea.value = `https://www.skycop.com`
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
            document.execCommand('copy')
            this.showTooltip()
        } catch (err) {}

        document.body.removeChild(textArea)
        this.logAction('share_copy_url')
    }

    logAction = message => {
        const { postedClaim, logKibana } = this.props
        const claimId = _get(
            postedClaim,
            'id',
            _get(postedClaim, 'draft.id', '')
        )

        logKibana(`${message}, claimId: ${claimId}`, '/thanks', 'warning')
    }

    renderShareOptions = () => {
        const { primaryColor } = this.props
        const shareOptions = [
            {
                name: 'link',
                icon: (
                    <span style={{ position: 'relative' }}>
                        {this.state.showCopiedTooltip && (
                            <InfoBox>
                                {t`v2_common.copied_to_clipboard`}
                            </InfoBox>
                        )}
                        <LinkIcon color={primaryColor} />
                    </span>
                ),
                onClick: this.copyShareUrl,
            },
            {
                name: 'facebook',
                icon: (
                    <FacebookShareButton
                        quote={t`v2_feedback.social.facebook.text`}
                        hashtag="#skycop"
                        url={t`v2_feedback.social.facebook.url`}
                    >
                        {FacebookIconNew({ color: primaryColor })}
                    </FacebookShareButton>
                ),
                onClick: () => this.logAction('share_facebook'),
            },
            {
                name: 'linkedin',
                icon: (
                    <LinkedinShareButton
                        url={t`v2_feedback.social.linkedin.url`}
                    >
                        {LinkedinIcon({ color: primaryColor })}
                    </LinkedinShareButton>
                ),
                onClick: () => this.logAction('share_linkedin'),
            },
            {
                name: 'twitter',
                icon: (
                    <TwitterShareButton
                        title={t`v2_feedback.social.twitter.text`}
                        hashtags={['skycop', 'compensation']}
                        url={t`v2_feedback.social.twitter.url`}
                    >
                        {TwitterIconNew({ color: primaryColor })}
                    </TwitterShareButton>
                ),
                onClick: () => this.logAction('share_twitter'),
            },
            {
                name: 'whatsappp',
                icon: (
                    <WhatsappShareButton
                        title={t`v2_feedback.social.twitter.text`}
                        hashtags={['skycop', 'compensation']}
                        url={t`v2_feedback.social.twitter.url`}
                    >
                        {WhatsAppIconNew({ color: primaryColor })}
                    </WhatsappShareButton>
                ),
                onClick: () => this.logAction('share_whatsapp'),
            },
            {
                name: 'telegram',
                icon: (
                    <TelegramShareButton
                        title={t`v2_feedback.social.twitter.text`}
                        hashtags={['skycop', 'compensation']}
                        url={t`v2_feedback.social.twitter.url`}
                    >
                        {TelegramIcon({ color: primaryColor })}
                    </TelegramShareButton>
                ),
                onClick: () => this.logAction('share_telegram'),
            },
        ]

        return shareOptions.map((option, i) => {
            return (
                <IconContainer key={i} onClick={option.onClick}>
                    {option.icon}
                </IconContainer>
            )
        })
    }

    render() {
        return (
            <Fragment>
                <HumanIcon desktop>
                    <img
                        src="/images/v2/social_share_man.svg"
                        alt="social-share"
                    />
                </HumanIcon>
                <Text>
                    <H1>{t`v2_feedback.social.page_title`}</H1>
                    <SubheaderContainer>
                        <Subheader>
                            {t`v2_feedback.social.page_description`}
                        </Subheader>
                    </SubheaderContainer>
                </Text>
                <SocialContainer>{this.renderShareOptions()}</SocialContainer>
                <HumanIcon mobile>
                    <img
                        src="/images/v2/social_share_man.svg"
                        alt="social-share"
                    />
                </HumanIcon>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        postedClaim: state.v2_posted_claim,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ sendFeedback, hideModal, logKibana }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialPage)
