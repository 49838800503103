import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import t from '../../../../shared/translations'
import { RedDocumentIcon, DownloadIcon } from './icons'
import { DocumentIcon } from '../../../../shared/components/V2/V2Icons'
import { CloseButton } from '../../../../shared/components/V2/V2Buttons'
import {
    mobile,
    tablet,
} from '../../../../shared/components/helpers/styled_queries'

const DocumentItemContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 0px;
    border-bottom: 1px solid #cbd3df;

    .name-text {
        width: 100%;
        padding: 4px;
        padding-left: 16px;
        overflow: hidden;
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.86;
        letter-spacing: normal;
        color: #354052;
        text-align: left;
        overflow-wrap: break-word;
        word-break: break-all;
        line-height: 1.4;

        ${tablet`
            padding-left: 8px;
        `}

        ${mobile`
            padding-left: 0px;
        `}
    }

    .warning {
        color: #f16464;
        padding-right: 40px;
    }

    .download-text {
        display: flex;
        padding: 0px 16px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        line-height: 1.4;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #353d51;

        svg {
            margin-right: 8px;
        }

        &:hover {
            cursor: pointer;
            color: ${({ theme }) => theme.colors.primaryColor};
        }
    }
`

const DocumentIconContainer = styled.div`
    width: 40px;
    height: 40px;
`

const StyledCloseButton = styled(CloseButton)`
    top: calc(50% - 12px);
    z-index: 5;
`

const DocumentItem = ({ doc, getUserDocument, err, onClose, primaryColor }) => {
    const name = _get(doc, 'original_name', _get(err, 'name', ''))
    return (
        <DocumentItemContainer className="doc-item">
            <DocumentIconContainer>
                {err ? <RedDocumentIcon /> : <DocumentIcon />}
            </DocumentIconContainer>
            <div className={`name-text ${err && 'warning'}`}>{name}</div>
            {typeof getUserDocument === 'function' && (
                <div
                    className="download-text"
                    onClick={() => getUserDocument(doc.id, doc.original_name)}
                >
                    <DownloadIcon color={primaryColor} />
                    {t`claim_inner.button.download`}
                </div>
            )}
            {err && <StyledCloseButton onClick={onClose} />}
        </DocumentItemContainer>
    )
}

export default DocumentItem
