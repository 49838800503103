import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { bindActionCreators } from 'redux'
import latinize from 'latinize'
import _ from 'lodash'
import FileDragAndDrop from 'react-file-drag-and-drop'
import t from '../../../../shared/translations'
import { Col, Row } from '../../../../shared/components/V2/Layout'
import StepView from '../../components/StepView'
import {
    bytesToSize,
    scrollComponentToView,
    isProduction,
    addBeforeUnload,
    isMobileDevice,
    getApiClient,
} from '../../../../main'
import {
    CloseIcon,
    DocumentIcon,
    UploadIconV2,
    FailedDocIcon,
    Document,
} from '../../../../shared/components/V2/V2Icons'

import {
    resetClaimDocuments,
    uploadDocumentAlternative,
    uploadDocument,
    setV2Claim,
    addUserDocuments,
    validateStepFive,
    setProgress,
    checkUUID,
    setNavStep,
    getClaimDraftDocuments,
    setPreloadedClaimStep,
    getAirlineById,
    showModal,
    setSelectedAirports,
    setUsedAirports,
    postCodeFieldCheck,
    setSelectedAirlines,
    getSuggestedFlights,
    setUsedAirlines,
    removeUserDocument,
} from '../../../../shared/actions/v2_actions'
import { mapSteps } from '../../../../shared/reducers/V2/reducer_steps'
import { V2_prePath, V2_STEPS, modalTypes } from '../../../../shared/constants'

import {
    StyledErrorsBox,
    DragDropContainer,
    StyledP,
    FileInputLabel,
    FileInput,
    FileUploadText,
    TextContainer,
    DocumentsContainer,
    DocumentOuter,
    DocumentInner,
    DocumentProgress,
    DocumentName,
    RemoveButtonContainer,
    DocumentSize,
    StyledStepContent,
    IconDisplay,
    DocsRow,
    DragTitle,
    DragDescription,
    UploadedDocsWrapper,
    DocumentWrapper,
    IconWrapper,
    DocumentStatusWrapper,
    ClearIconWrapper,
    DocumentTitle,
    DocumentStatus,
    ReuploadWrapper,
    ReuploadTitle,
    DocWrapper,
} from './flightDocumentsStyles'
import SidebarProgress from '../../components/SidebarProgress'
import {
    getAirlines,
    getPayoutPlans,
    logKibana,
} from '../../../../shared/actions'
import { fireGtagEvent } from '../../../../shared/services/events'
import { Reupload } from '../../../../shared/components/svgIcons'

const TicketIcon = ({ color }) => (
    <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="40" cy="40" r="40" fill={`${color}15`} />
        <rect
            x="16.5"
            y="0.5"
            width="47"
            height="79"
            rx="5.5"
            fill="#FAFAFA"
            stroke="#717171"
        />
        <path
            d="M16 6C16 2.68629 18.6863 0 22 0H58C61.3137 0 64 2.68629 64 6V48H16V6Z"
            fill={color}
        />
        <rect
            opacity="0.6"
            width="23"
            height="16"
            rx="2"
            transform="matrix(1 0 0 -1 22 34)"
            fill="#ECF7F1"
        />
        <rect
            opacity="0.6"
            width="29"
            height="8"
            rx="2"
            transform="matrix(1 0 0 -1 22 14)"
            fill="#ECF7F1"
        />
        <rect
            opacity="0.6"
            width="21"
            height="3"
            rx="1.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 25 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="0.999999"
            rx="0.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 27 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="2"
            rx="1"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 30 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="2"
            rx="1"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 33 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="0.999999"
            rx="0.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 35 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="3"
            rx="1.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 39 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="3"
            rx="1.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 43 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="0.999999"
            rx="0.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 45 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="3"
            rx="1.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 49 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="0.999999"
            rx="0.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 51 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="0.999999"
            rx="0.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 53 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="0.999999"
            rx="0.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 55 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="2"
            rx="1"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 58 74)"
            fill="black"
        />
    </svg>
)

const PassportIcon = ({ color }) => (
    <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="40" cy="40" r="40" fill={`${color}15`} />
        <path
            d="M16 6C16 2.68629 18.6863 0 22 0H58C61.3137 0 64 2.68629 64 6V74C64 77.3137 61.3137 80 58 80H22C18.6863 80 16 77.3137 16 74V6Z"
            fill={color}
        />
        <path
            d="M27.4 23.5H52.6M27.4 32.5H52.6M39.25 14.5C36.723 18.5494 35.3833 23.2268 35.3833 28C35.3833 32.7732 36.723 37.4506 39.25 41.5M40.75 14.5C43.277 18.5494 44.6167 23.2268 44.6167 28C44.6167 32.7732 43.277 37.4506 40.75 41.5M26.5 28C26.5 29.7728 26.8492 31.5283 27.5276 33.1662C28.2061 34.8041 29.2005 36.2923 30.4541 37.5459C31.7076 38.7995 33.1959 39.7939 34.8338 40.4724C36.4717 41.1508 38.2272 41.5 40 41.5C41.7728 41.5 43.5283 41.1508 45.1662 40.4724C46.8041 39.7939 48.2923 38.7995 49.5459 37.5459C50.7995 36.2923 51.7939 34.8041 52.4724 33.1662C53.1508 31.5283 53.5 29.7728 53.5 28C53.5 24.4196 52.0777 20.9858 49.5459 18.4541C47.0142 15.9223 43.5804 14.5 40 14.5C36.4196 14.5 32.9858 15.9223 30.4541 18.4541C27.9223 20.9858 26.5 24.4196 26.5 28Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect
            opacity="0.6"
            width="24"
            height="8"
            rx="2"
            transform="matrix(1 0 0 -1 28 70)"
            fill="#ECF7F1"
        />
        <rect
            opacity="0.6"
            width="36"
            height="8"
            rx="2"
            transform="matrix(1 0 0 -1 22 58)"
            fill="#ECF7F1"
        />
    </svg>
)

const EmailIcon = ({ color }) => (
    <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="40" cy="40" r="40" fill={`${color}15`} />
        <rect
            x="16.5"
            y="0.5"
            width="47"
            height="79"
            rx="5.5"
            fill="#FAFAFA"
            stroke="#717171"
        />
        <path
            d="M16 6C16 2.68629 18.6863 0 22 0H58C61.3137 0 64 2.68629 64 6V48H16V6Z"
            fill={color}
        />
        <rect
            opacity="0.6"
            width="23"
            height="16"
            rx="2"
            transform="matrix(1 0 0 -1 22 34)"
            fill="#ECF7F1"
        />
        <rect
            opacity="0.6"
            width="29"
            height="8"
            rx="2"
            transform="matrix(1 0 0 -1 22 14)"
            fill="#ECF7F1"
        />
        <rect
            opacity="0.6"
            width="21"
            height="3"
            rx="1.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 25 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="0.999999"
            rx="0.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 27 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="2"
            rx="1"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 30 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="2"
            rx="1"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 33 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="0.999999"
            rx="0.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 35 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="3"
            rx="1.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 39 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="3"
            rx="1.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 43 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="0.999999"
            rx="0.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 45 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="3"
            rx="1.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 49 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="0.999999"
            rx="0.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 51 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="0.999999"
            rx="0.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 53 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="0.999999"
            rx="0.5"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 55 74)"
            fill="black"
        />
        <rect
            opacity="0.6"
            width="21"
            height="2"
            rx="1"
            transform="matrix(-4.05891e-08 -1 -1 4.70738e-08 58 74)"
            fill="black"
        />
    </svg>
)

export const CloseCircle = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.75"
            y="0.75"
            width="14.5"
            height="14.5"
            rx="7.25"
            stroke="#717171"
            stroke-width="1.5"
        />
        <path
            d="M10.1842 10.0002L5.82031 6"
            stroke="#717171"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="square"
            stroke-linejoin="round"
        />
        <path
            d="M10.1842 6L5.82031 10.0002"
            stroke="#717171"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="square"
            stroke-linejoin="round"
        />
    </svg>
)

class FlightDocuments extends React.Component {
    state = {
        reservationNumber: '',
        componentState: {
            flightNumber: '',
            canRender: false,
            uploadErrors: [],
        },
        innerWidth: window.innerWidth,
        ...this.props.V2Claim,
    }

    isFaqOpen = false
    dragContainer = React.createRef()

    preventDragDrop = e => {
        if (!e.target.closest('#dd-box')) {
            // eslint-disable-next-line no-restricted-globals
            ;(e || event).preventDefault()
            e.dataTransfer.effectAllowed = 'none'
            e.dataTransfer.dropEffect = 'none'
        } else {
            e.dataTransfer.effectAllowed = 'initial'
            e.dataTransfer.dropEffect = 'initial'
        }
    }

    componentDidMount() {
        try {
            setNavStep(mapSteps.indexOf(this.props.route.path))
        } catch (e) {}

        scrollComponentToView('pageTopNavbar', false, true)
        window.addEventListener('resize', this._onResize)
        window.addEventListener('dragenter', this.preventDragDrop)
        window.addEventListener('dragover', this.preventDragDrop)
        window.addEventListener('drop', this.preventDragDrop)

        const {
            claim,
            claimId,
            claimPreloaded,
            flightDocs,
            setParentState,
        } = this.props

        if (claim && !claimPreloaded[V2_STEPS.STEP_5]) {
            if (claimId) {
                this.props.getClaimDraftDocuments(claimId)
            }

            this.setState(
                {
                    componentState: {
                        ...claim.componentState,
                    },
                },
                () => {
                    this.props.setPreloadedClaimStep([V2_STEPS.STEP_5])
                }
            )
        }

        if (
            (!claim || !claim.airport_arrival || !claim.airport_departure) &&
            isProduction()
        ) {
            setNavStep(0)
            browserHistory.replace(V2_prePath)
        }

        setParentState({
            nextDisabled: false,
            buttonText: '',
        })

        if (!!flightDocs.documents.length) {
            this.setState(prevState => ({
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    canRender: true,
                    canRenderAlternative: true,
                },
            }))
        }

        addBeforeUnload(() => this.props.showModal(modalTypes.claimDraftModal))
    }

    componentDidUpdate(prevProps) {
        const { setParentState, flightDocs, isDisabled, trans } = this.props

        if (
            prevProps.flightDocs.documents.length !==
            flightDocs.documents.length
        ) {
            if (!!flightDocs.documents.length) {
                setTimeout(() => {
                    this.setState(prevState => ({
                        ...prevState,
                        componentState: {
                            ...prevState.componentState,
                            canRender: true,
                            canRenderAlternative: true,
                        },
                    }))
                }, 300)
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        canRender: false,
                        canRenderAlternative: false,
                    },
                }))
            }
        }

        if (prevProps.flightDocs.uploading !== flightDocs.uploading) {
            setParentState({
                nextDisabled: flightDocs.uploading,
            })
        }

        if (!isDisabled && this.checkNextStep()) {
            setParentState({
                nextDisabled: true,
            })
        } else if (isDisabled && !this.checkNextStep()) {
            setParentState({
                nextDisabled: false,
            })
        }

        if (
            trans['v2.sidebar_step.flight_itinerary'] !==
            prevProps.trans['v2.sidebar_step.flight_itinerary']
        ) {
            this.forceUpdate()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._onResize)
        window.removeEventListener('dragenter', this.preventDragDrop)
        window.removeEventListener('dragover', this.preventDragDrop)
        window.removeEventListener('drop', this.preventDragDrop)
    }

    _onResize = () => {
        const width = window.innerWidth

        this.setState({ innerWidth: width })
    }

    _handleNextStep = () => {
        const { setV2Claim, flightDocs, setProgress, claimId } = this.props
        const { documents } = flightDocs
        const newState = { ...this.state }

        const docs = documents
            .filter(doc => !!doc.id)
            .map(doc => ({
                originalName: doc.file.name,
                id: doc.id,
                type: 'res',
            }))

        newState.documents = docs

        if (!!newState.reservationNumber) {
            const url = `/api/claim/${claimId}/booking-reference`
            const apiClient = getApiClient()

            apiClient
                .post(url, { reservationNumber: newState.reservationNumber })
                .catch(error => {
                    console.error(error)
                })
        }

        setV2Claim(newState)
        setProgress(90)
        this.props.triggerAnalytics()
        return true
    }

    getIata = id => {
        const { usedAirlines } = this.props
        let airline
        let iata = ''

        if (id) {
            airline = usedAirlines.filter(airline => airline.id === id)[0]
            iata = airline && airline.iata
        }

        return iata
    }

    _handlePrevStep = () => {
        const { setV2Claim } = this.props
        setV2Claim(this.state)
    }

    fileOnDragOver = () => {
        const el = this.dragContainer && this.dragContainer.current

        if (el) {
            el.classList.add('hover')
        }
    }

    fileOnDragLeave = () => {
        const el = this.dragContainer && this.dragContainer.current

        if (el && el.classList.contains('hover')) {
            el.classList.remove('hover')
        }
    }

    handleDrop = dataTransfer => {
        let e = { target: { files: [] } }
        e.target.files = dataTransfer.files
        this.fileOnDragLeave()
        this.handleFileChange(e)
    }

    handleChange = e => {
        const { target } = e
        if (target.name === 'reservationNumber') {
            fireGtagEvent({ event: 'step5_afd_reservation_number' }, true)
            const value = latinize(target.value.toUpperCase())
            this.setState({ [target.name]: value }, () => {
                this.props.validateStepFive(this.state)
            })
        } else {
            this.setState({ [target.name]: target.value })
        }
    }

    checkNextStep = () => {
        const { flightDocs, errors } = this.props
        if (!_.isEmpty(errors)) return true

        return !!flightDocs.documents.length && flightDocs.uploading
    }

    handleFileChange = e => {
        const {
            claimId,
            uploadDocument,
            addUserDocuments,
            V2Claim,
            logKibana,
        } = this.props

        this.setState(prevState => ({
            ...prevState,
            componentState: {
                ...prevState.componentState,
                uploadErrors: [],
            },
        }))
        const { files } = e.target
        const fileTypeReg = new RegExp(
            '(.*?).(docx|doc|pdf|xml|bmp|jpg|png|jpeg|odt)$'
        )

        fireGtagEvent({ event: 'step5_afd_choose_files' }, true)
        Array.prototype.forEach.call(files, (file, i) => {
            if (file.name && !fileTypeReg.test(file.name.toLowerCase())) {
                this.setState(prevState => ({
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        uploadErrors: [
                            ...prevState.componentState.uploadErrors,
                            {
                                name: file.name,
                                error: t`v2_claim.file.type.not.supported`,
                            },
                        ],
                        canRender: true,
                    },
                }))
                logKibana(
                    `file_upload_failed_type: ${file.name}, claimId: ${V2Claim.id}`,
                    '/flight-documents',
                    'warning'
                )
            } else {
                const fileSize = (file.size / 1024 / 1024).toFixed(4)

                if (fileSize < 5) {
                    addUserDocuments(file)
                    uploadDocument(file, claimId)
                } else {
                    this.setState(prevState => ({
                        ...prevState,
                        componentState: {
                            ...prevState.componentState,
                            uploadErrors: [
                                ...prevState.componentState.uploadErrors,
                                {
                                    name: file.name,
                                    error: t`v2_claim.file.size.limit.reached`,
                                },
                            ],
                        },
                    }))
                    logKibana(
                        `file_upload_failed_size: ${fileSize}, claimId: ${V2Claim.id}`,
                        '/flight-documents',
                        'warning'
                    )
                }
            }
        })

        e.target.value = []
    }

    renderDocumentsListSeparate = (documents, failedDocuments) => {
        const { primaryColor, removeUserDocument } = this.props
        if (
            (documents && !!documents.length) ||
            (failedDocuments && !!failedDocuments.length)
        ) {
            const docs = documents.map((document, index) => {
                const { file, progress } = document
                const { name } = file
                const isSuccess = progress === 100
                const isLoading = progress < 100

                return (
                    <DocumentWrapper>
                        <IconWrapper>
                            <Document />
                        </IconWrapper>
                        <DocumentStatusWrapper>
                            <DocumentTitle>{name}</DocumentTitle>
                            <DocumentStatus
                                isLoading={isLoading}
                                failed={false}
                            >
                                {isSuccess
                                    ? t`upload_document_success`
                                    : t`upload_document_uploading`}
                            </DocumentStatus>
                        </DocumentStatusWrapper>
                        <ClearIconWrapper
                            onClick={() => {
                                removeUserDocument(document.id)
                            }}
                        >
                            <CloseCircle />
                        </ClearIconWrapper>
                    </DocumentWrapper>
                )
            })

            const failedDocs = failedDocuments.map((document, index) => {
                const { file, progress } = document
                const name = file && file.name
                const isSuccess = progress === 100

                return (
                    <DocumentWrapper>
                        <IconWrapper>
                            <Document />
                        </IconWrapper>
                        <DocumentStatusWrapper>
                            <DocumentTitle>{name}</DocumentTitle>
                            <DocumentStatus
                                isLoading={false}
                                failed={!isSuccess}
                            >
                                {isSuccess
                                    ? t`upload_document_uploading`
                                    : t`upload_document_failed`}
                            </DocumentStatus>
                            {!isSuccess && (
                                <ReuploadWrapper
                                    onClick={() => this.handleReupload(file)}
                                >
                                    <ReuploadTitle>{t`reupload_document`}</ReuploadTitle>
                                    <Reupload color={primaryColor} />
                                </ReuploadWrapper>
                            )}
                        </DocumentStatusWrapper>
                        <ClearIconWrapper
                            onClick={() => {
                                this.setState(prevState => ({
                                    ...prevState,
                                    componentState: {
                                        ...prevState.componentState,
                                        uploadErrors: [],
                                    },
                                }))
                            }}
                        >
                            <CloseCircle />
                        </ClearIconWrapper>
                    </DocumentWrapper>
                )
            })

            return (
                <div>
                    {docs}
                    {failedDocs}
                </div>
            )
        }
    }

    renderDocumentsList = (documents, failedDocuments) => {
        const { removeUserDocument } = this.props
        const { innerWidth } = this.state

        const handleName = name => {
            let availableLength = 25
            let lettersCount = 10
            let docName = ''

            if (innerWidth < 960) {
                if (innerWidth < 420) {
                    availableLength = 29
                } else if (innerWidth < 520) {
                    availableLength = 45
                } else if (innerWidth < 720) {
                    availableLength = 70
                    lettersCount = 20
                } else {
                    availableLength = 60
                    lettersCount = 20
                }
            } else {
                if (innerWidth >= 960 && innerWidth < 1145) {
                    availableLength = 15
                }
            }

            if (name) {
                if (name.length > availableLength) {
                    const firstPart = name.substring(0, lettersCount)
                    const last = name.substring(lettersCount)
                    const lastPart = last.substring(
                        last.length - (availableLength - 3 - lettersCount)
                    )
                    docName = `${firstPart}...${lastPart}`
                } else {
                    docName = name
                }
            }

            return docName ? docName : ''
        }

        if (
            (documents && !!documents.length) ||
            (failedDocuments && !!failedDocuments.length)
        ) {
            const docs = documents.map((document, index) => {
                const { file } = document
                const { name } = file

                const docName = handleName(name)

                return (
                    <DocumentOuter key={index}>
                        <DocumentIcon />
                        <DocumentInner>
                            <DocumentName>{docName}</DocumentName>
                            <DocumentProgress proc={document.progress} />
                            <DocumentSize>
                                {file.size && bytesToSize(file.size)}
                            </DocumentSize>
                        </DocumentInner>
                        <RemoveButtonContainer
                            done={!!document.id}
                            onClick={() => {
                                removeUserDocument(document.id)
                            }}
                        >
                            <CloseIcon />
                        </RemoveButtonContainer>
                    </DocumentOuter>
                )
            })

            const failedDocs = (failedDocuments || []).map((doc, i) => {
                const { name } = doc
                const docName = handleName(name)

                return (
                    <DocumentOuter key={i}>
                        <FailedDocIcon />
                        <DocumentInner>
                            <DocumentName className="document-name">
                                {docName}
                            </DocumentName>
                        </DocumentInner>
                        <RemoveButtonContainer
                            onClick={() => {
                                this.setState(prevState => ({
                                    ...prevState,
                                    componentState: {
                                        ...prevState.componentState,
                                        uploadErrors: [],
                                    },
                                }))
                            }}
                        >
                            <CloseIcon />
                        </RemoveButtonContainer>
                    </DocumentOuter>
                )
            })

            return (
                <DocumentsContainer>
                    <StyledP className="mb-16">{t`v2_common.uploaded_files`}</StyledP>
                    {docs}
                    {failedDocs}
                </DocumentsContainer>
            )
        } else {
            return null
        }
    }

    getProblemAirline = () => {
        const { probAirline } = this.props
        return _.get(probAirline, 'problem_claim_flight.airline', '')
    }

    getAirlineDocsList = () => {
        const airline = this.getProblemAirline()

        if (airline) {
            const airlineId = airline.id
            const { airlineDocuments } = getAirlineById(airlineId) || {}
            return airlineDocuments || []
        } else {
            return []
        }
    }

    hasDocList = () => {
        const airlineDocuments = this.getAirlineDocsList()
        const notRequiredDocs = airlineDocuments.filter(
            doc => doc.status === 'not_required'
        )

        if (
            !airlineDocuments ||
            airlineDocuments.length === 0 ||
            notRequiredDocs.length === airlineDocuments.length
        ) {
            return false
        } else {
            return true
        }
    }

    _renderDocList = () => {
        const { primaryColor } = this.props
        const airlineDocuments = this.getAirlineDocsList()

        const restDocs = airlineDocuments.filter(
            doc => doc.documentType !== 'ticket'
        )
        const hasRequiredRest =
            restDocs.filter(doc => doc.status !== 'not_required').length !== 0
        const resultDocs = [
            { documentType: 'ticket', status: 'optional' },
            ...restDocs.filter(doc => doc.status !== 'not_required'),
        ]

        const docTypeComponents = {
            id: 'passport',
            ticket: 'ticket',
            boarding_pass: 'email',
        }

        const getDocTypeIcon = {
            id: PassportIcon({ color: primaryColor }),
            ticket: TicketIcon({ color: primaryColor }),
            email: EmailIcon({ color: primaryColor }),
        }

        if (
            airlineDocuments &&
            airlineDocuments.length > 0 &&
            hasRequiredRest
        ) {
            return (
                <React.Fragment>
                    <Row>
                        <Col>
                            <StyledP style={{ marginBottom: 0 }}>
                                {t`flight_documents.required_documents`.replace(
                                    '%AIRLINE%',
                                    this.getProblemAirline().title || ''
                                )}
                            </StyledP>
                        </Col>
                    </Row>
                    <DocsRow>
                        {resultDocs.map((doc, i) => {
                            const Icon = docTypeComponents[doc.documentType]
                            if (Icon) {
                                return (
                                    <Col key={doc.documentType}>
                                        <DocWrapper>
                                            {getDocTypeIcon[doc.documentType]}
                                        </DocWrapper>

                                        <span className="item-title">{t`icon_label.${
                                            docTypeComponents[doc.documentType]
                                        }`}</span>
                                    </Col>
                                )
                            } else {
                                return null
                            }
                        })}
                    </DocsRow>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Row>
                        <Col>
                            <StyledP style={{ marginBottom: 0 }}>
                                {t`flight_documents.required_documents`.replace(
                                    '%AIRLINE%',
                                    this.getProblemAirline().title || ''
                                )}
                            </StyledP>
                        </Col>
                    </Row>
                    <DocsRow>
                        <Col>
                            <DocWrapper>
                                <TicketIcon color={primaryColor} />
                            </DocWrapper>
                            <span className="item-title">{t`doc_type_ticket`}</span>
                        </Col>
                    </DocsRow>
                </React.Fragment>
            )
        }
    }

    render() {
        const { componentState } = this.state
        const { flightDocs, nav, trans } = this.props
        const documentsExist = !!flightDocs.documents.length

        if (!trans) return null

        return (
            <StepView
                sideBarPaddingTop={'0px'}
                nav={{ ...nav, nextDisabled: this.checkNextStep() }}
                Sidebar={
                    <SidebarProgress
                        activeIndex={4}
                        checkedIndexes={[0, 1, 2, 3]}
                        paddingTop={0}
                    />
                }
                title={t`flight_documents.add_documents`}
                renderFPMobile={true}
                titleBottomPadding="22px"
            >
                <FileDragAndDrop
                    onDrop={this.handleDrop}
                    onDragOver={this.fileOnDragOver}
                    onDragLeave={this.fileOnDragLeave}
                    onDragEnd={this.fileOnDragLeave}
                >
                    <StyledStepContent>
                        {this._renderDocList()}
                        {!!_.get(componentState, 'uploadErrors.length', 0) && (
                            <Row>
                                <Col>
                                    <StyledErrorsBox>
                                        <ul>
                                            {componentState.uploadErrors.map(
                                                (err, i) => (
                                                    <li key={i}>
                                                        {err.name}
                                                        &nbsp;-&nbsp;
                                                        {err.error.toLowerCase()}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </StyledErrorsBox>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col md={12} lg={12}>
                                <DragDropContainer
                                    innerRef={this.dragContainer}
                                >
                                    <FileInputLabel>
                                        <FileInput
                                            type="file"
                                            id="file"
                                            name="fileselect[]"
                                            multiple="multiple"
                                            onChange={this.handleFileChange}
                                            accept="image/*,application/msword,application/vnd.oasis.opendocument.text,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        />
                                        <FileUploadText
                                            className="inputText"
                                            id="dd-box"
                                        >
                                            <TextContainer>
                                                <IconDisplay>
                                                    <UploadIconV2 />
                                                    <DragTitle>
                                                        {isMobileDevice()
                                                            ? t`v2_claim_documents_drag_title_mobile`
                                                            : t`v2_claim_documents_drag_title_desktop`}
                                                    </DragTitle>
                                                </IconDisplay>
                                                <DragDescription>
                                                    {t`v2_claim_documents_drag_description`}
                                                </DragDescription>
                                            </TextContainer>
                                        </FileUploadText>
                                    </FileInputLabel>
                                </DragDropContainer>
                            </Col>
                        </Row>
                        {(!!_.get(componentState, 'uploadErrors.length', 0) ||
                            (componentState.canRender && documentsExist)) && (
                            <UploadedDocsWrapper>
                                <Col
                                    className="pdt-0 pdb-0"
                                    md={12}
                                    sm={12}
                                    lg={12}
                                >
                                    {this.renderDocumentsListSeparate(
                                        flightDocs.documents,
                                        componentState.uploadErrors
                                    )}
                                </Col>
                            </UploadedDocsWrapper>
                        )}
                    </StyledStepContent>
                </FileDragAndDrop>
            </StepView>
        )
    }
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        flightDocs: state.v2_user_documents,
        errors: state.v2_step_five_errors.errors,
        V2Claim: state.V2Claim,
        trans: state.translations,
        claim: state.v2_posted_claim,
        claimId: state.v2_posted_claim && state.v2_posted_claim.id,
        claimPreloaded: state.claimPreloaded,
        probAirline: state.v2_posted_claim,
        postedClaim: state.v2_posted_claim,
        primaryColor: state.propsTheme.colors.primaryColor,
        selectedAirports: state.v2_selectedAirports,
        usedAirports: state.v2_UsedAirports,
        usedAirlines: state.v2_UsedAirlines,
        regulation: state.regulation,
        v2_amount: state.v2_amount,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetClaimDocuments,
            uploadDocumentAlternative,
            uploadDocument,
            addUserDocuments,
            validateStepFive,
            setV2Claim,
            setProgress,
            checkUUID,
            getClaimDraftDocuments,
            setPreloadedClaimStep,
            logKibana,
            showModal,
            setSelectedAirports,
            setUsedAirports,
            postCodeFieldCheck,
            setSelectedAirlines,
            getSuggestedFlights,
            getAirlines,
            setUsedAirlines,
            getPayoutPlans,
            removeUserDocument,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(FlightDocuments)
