import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _get from 'lodash/get'
import Loader from 'react-loader-spinner'
import t from '../../../shared/translations'
import {
    loginUser,
    registerUser,
    showModal,
    hideModal,
    setLoginLoading,
    clearAuthErrors,
} from '../../../shared/actions/v2_actions'
import { Row, Col } from '../../../shared/components/V2/Layout'
import { Google, Facebook } from '../../../shared/components/V2/V2SocialLogin'
import InputField from '../../../shared/components/V2/InputField'
import Modal, { ENTER_KEY } from '../../../shared/components/V2/Modal'
import TermsAndConditions from '../components/TermsAndConditions'
import {
    CloseButton,
    NonModalCloseButton,
} from '../../../shared/components/V2/V2Buttons'
import { isValidEmail } from '../../../main'
import {
    desktop,
    mobile,
    tablet,
} from '../../../shared/components/helpers/styled_queries'
import { modalTypes } from '../../../shared/constants'
import { SubscribeContainer } from '../../other/ClaimAgreementLead/styles'
import Subscribe from '../../V2/components/Subscribe'

const StyledRow = styled(Row)`
    margin-bottom: 16px;

    ${desktop`
        margin-bottom: 0px;
    `}

    ${Col} {
        button {
            padding: 0;
            height: 100%;
            width: 100%;
            border-radius: 24px;
            height: 48px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
        }
    }
`

const ButtonsContainer = styled(StyledRow)`
    div {
        padding: 0;
        margin: 0;

        &:first-child {
        }

        &:nth-child(2) {
            margin-top: 16px;
            padding-left: 0px;
        }
    }

    ${desktop`
        div {
            padding: 0;
            margin: 0;

            &:first-child {
                margin-bottom: 0px;
                padding-right: 8px;
            }

            &:nth-child(2) {
                margin-top: 0px;
                padding-left: 8px;
            }
        }
    `}
`

const GoogleCol = styled(Col)`
    button {
        padding: 0;
        height: 100%;
        width: 100%;
        border-radius: 24px;
        height: 48px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;

        &:before {
            top: 8px !important;
            left: -11px !important;
        }
    }
`

const FacebookCol = styled(Col)`
    button {
        padding: 0;
        height: 100%;
        width: 100%;
        border-radius: 24px;
        height: 48px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;

        &:before {
            top: 13px !important;
            left: -11px !important;
        }
    }
`

export const LoginContainer = styled.div`
    overflow: auto;
    position: relative;
    padding: ${({ padding }) => padding || '20px'};
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    background-color: #ffffff;
    z-index: 100;

    ${mobile`
        padding: 24px 8px;
    `}

    ${tablet`
        padding: 24px 8px;
    `}
`

export const DesktopBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    border: 0;
    min-width: 200px;
    height: 48px;
    border-radius: 24px;
    padding: 10px 30px;
    text-align: center;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    ${({ disabled, theme }) =>
        disabled
            ? 'background-color: #f3f5f7;'
            : theme.colors.actionButton
            ? `background-color: ${theme.colors.actionButton};`
            : `background-image: linear-gradient(to top, ${theme.colors.primaryColor}, ${theme.colors.primaryColor}15)};`}
    color: ${({ disabled }) => (disabled ? '#c7cdd4' : '#ffffff')};
    outline: 0 none;
    font-size: 14px;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    div {
        display: flex;
        align-items: center;
    }

    &:hover {
        filter: brightness(105%);
    }

    &:focus {
        outline: 0 none;
    }
`

const DesktopBorderBtn = styled(DesktopBtn)`
    min-width: 150px;
    background: transparent;
    border: solid 1px #98a3bf;
    color: #353d51;
`

const Title = styled.h1`
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #353d51;
    text-align: center;
`

const ToogleContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`

const Text = styled.p`
    object-fit: contain;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    margin: 0;
    color: #7f8fa4;

    span {
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primaryColor};

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
`

const GreenText = styled(Text)`
    width: fit-content;
    margin-left: auto;
    color: ${({ theme }) => theme.colors.primaryColor};
    text-align: right;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`

const LineContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    p {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #32325d;
        margin: 0;
    }
`

const Line = styled.div`
    width: 100%;
    height: 1px;
    border-radius: 6px;
    background-color: #f2f5f9;
`

const SocialEmailContainer = styled.div`
    font-size: 14px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    margin: 16px auto;
    color: #7f8fa4;
`

class LoginModal extends React.Component {
    state = {
        email:
            _get(this, 'props.prefillEmail', '') ||
            _get(this, 'props.modal.props.email', ''),
        password: '',
        passwordRepeat: '',
        formErrors: {},
        rememberMe: false,
        register: _get(this, 'props.register', false),
        passwordError: false,
        termsConditions: false,
        loginCase: _get(this, 'props.loginCase', '0'),
        subscribe: false,
    }

    onChange = e => {
        const { name, value } = e.target
        const { clearAuthErrors, error } = this.props
        const newFormErrors = { ...this.state.formErrors }

        if (newFormErrors[name]) {
            delete newFormErrors[name]
        }
        this.setState(
            {
                [name]: value,
                formErrors: newFormErrors,
            },
            () => {
                const { passwordRepeat, password, register } = this.state
                if (
                    register &&
                    (name === 'passwordRepeat' || name === 'password') &&
                    password &&
                    passwordRepeat
                ) {
                    const validLengthDiff =
                        passwordRepeat.length / password.length >= 1
                    if (validLengthDiff) {
                        this.validateRegisterPasswords()
                    }
                }
            }
        )

        if (error) {
            clearAuthErrors()
        }
    }

    componentDidMount() {
        const { V2Claim } = this.props

        const email =
            _get(V2Claim, 'user.email', false) ||
            _get(this, 'props.modal.props.user.email', false)
        const subscribe =
            _get(V2Claim, 'user.subscribe', false) ||
            _get(this, 'props.modal.props.user.subscribe', false)

        if (email) {
            this.setState({ email })
        }

        this.setState({
            subscribe: subscribe,
            showSubscribeCbx: !subscribe,
        })

        this.handleRegister()
    }

    checkMinLength = (value, minLength) => {
        if (value.length < minLength) {
            return t`common.label.too_short.password`
        }
    }

    componentDidUpdate(prevProps) {
        const { formErrors } = this.state
        const { error } = this.props
        if (prevProps.error !== error || (error && !formErrors['password'])) {
            this.setState({
                formErrors: {
                    ...formErrors,
                    password: error,
                },
            })
        }

        if (prevProps.modal.modalType !== this.props.modal.modalType) {
            this.handleRegister()
        }

        if (prevProps.loginCase !== this.props.loginCase) {
            this.setState({
                email: _get(this, 'props.prefillEmail', ''),
                register: _get(this, 'props.register', false),
                loginCase: _get(this, 'props.loginCase', '0'),
            })
        }

        if (prevProps.prefillEmail !== this.props.prefillEmail) {
            this.setState({
                email: _get(this, 'props.prefillEmail', ''),
            })
        }
    }

    handleRegister = () => {
        const { modal, register } = this.props

        this.setState({
            register: !!register || _get(modal, 'props.register', false),
        })
    }

    componentWillUnmount() {
        const { clearAuthErrors } = this.props

        clearAuthErrors()
    }

    handleSubscribe = () => {
        this.setState({ subscribe: !this.state.subscribe })
    }

    // handleCheckbox = () => {
    //     this.setState({ rememberMe: !this.state.rememberMe });
    // };

    validateEmail = ignoreState => {
        const { email } = this.state
        const stateErrors = { ...this.state.formErrors }
        let formErrors = {}

        if (email) {
            if (email.length < 2) {
                formErrors['email'] = t`common.label.too_short.password`
            } else if (email && !isValidEmail(email)) {
                formErrors['email'] = t`common.label.email_invalid`
            } else {
                delete stateErrors['email']
            }
        }

        if (!ignoreState) {
            this.setState({
                formErrors: { ...stateErrors, ...formErrors },
            })
        } else {
            return { ...stateErrors, ...formErrors }
        }
    }

    validateRegisterPasswords = ignoreState => {
        const { password, passwordRepeat } = this.state
        const stateErrors = { ...this.state.formErrors }
        let formErrors = {}

        if (password && password.length < 8) {
            formErrors['password'] = t`common.label.too_short.password`
        }
        if (passwordRepeat && passwordRepeat.length < 8) {
            formErrors['passwordRepeat'] = t`common.label.too_short.password`
        }

        if (password && passwordRepeat && password !== passwordRepeat) {
            const validLengthDiff = ignoreState
                ? true
                : passwordRepeat.length / password.length >= 1
            if (validLengthDiff) {
                formErrors[
                    'passwordRepeat'
                ] = t`v2_claim.common.passwords_dont_match`
            }
        } else if (
            password === passwordRepeat &&
            formErrors['passwordRepeat'] !== t`common.label.too_short.password`
        ) {
            delete formErrors['passwordRepeat']
            delete formErrors['password']
            delete stateErrors['passwordRepeat']
            delete stateErrors['password']
        }

        if (!ignoreState) {
            this.setState({
                formErrors: { ...formErrors, ...stateErrors },
            })
        } else {
            return { ...stateErrors, ...formErrors }
        }
    }

    validateInputs = () => {
        const { email, password, register } = this.state
        const stateErrors = { ...this.state.formErrors }
        let formErrors = {}

        if (email) {
            formErrors = { ...formErrors, ...this.validateEmail(true) }
        }

        if (register) {
            formErrors = {
                ...formErrors,
                ...this.validateRegisterPasswords(true),
            }
        } else {
            if (password && password.length < 4) {
                formErrors['password'] = t`common.label.too_short.password`
            }
        }

        const resultErrors = { ...stateErrors, ...formErrors }
        Object.keys(resultErrors).forEach(key => {
            if (!resultErrors[key]) {
                delete resultErrors[key]
            }
        })

        this.setState({
            formErrors: resultErrors,
        })

        return Object.keys(resultErrors).length <= 0
    }

    login = () => {
        const {
            loginUser,
            registerUser,
            setLoginLoading,
            lang,
            modal,
            callback = () => null,
        } = this.props
        const {
            email,
            password,
            passwordRepeat,
            register,
            termsConditions,
            subscribe,
        } = this.state
        if (this.validateInputs()) {
            if (register) {
                if (termsConditions && password === passwordRepeat) {
                    let fd = new FormData()

                    fd.append('user[email]', email)
                    fd.append('user[plainPassword][first]', password)
                    fd.append('user[plainPassword][second]', passwordRepeat)
                    fd.append('user[subscribe]', subscribe)

                    setLoginLoading()
                    registerUser(
                        fd,
                        t`login.error.unable_to_register`,
                        t`login.error.email_exists`,
                        lang,
                        callback
                    )
                }
            } else {
                if (email && password) {
                    setLoginLoading()
                    loginUser(
                        email,
                        password,
                        t`login.error.invalid_username_or_pass`,
                        modal.props.backUrl || '',
                        callback
                    )
                }
            }
        }
    }

    handleCancel = () => {
        const {
            setLoginLoading,
            hideModal,
            clearAuthErrors,
            onClose,
        } = this.props

        this.setState({
            formErrors: {},
        })
        setLoginLoading(false)
        clearAuthErrors()
        hideModal()
        if (typeof onClose === 'function') {
            onClose()
        }
    }

    handleTermsChange = () => {
        this.setState({ termsConditions: !this.state.termsConditions })
    }

    toogleMenu = () => {
        const { clearAuthErrors } = this.props

        clearAuthErrors()
        this.setState({ register: !this.state.register, formErrors: {} })
    }

    renderLoginView = () => {
        const { isNotModal, clearAuthErrors } = this.props
        if (isNotModal) {
            return this.loginView(this.login)
        } else {
            return (
                <Modal
                    width="600px"
                    height="auto"
                    modalType={modalTypes.loginModal}
                    onEnterClick={this.login}
                    handleClose={clearAuthErrors}
                >
                    {this.loginView()}
                </Modal>
            )
        }
    }

    loginView = (loginOverride = () => null) => {
        const {
            email,
            password,
            passwordRepeat,
            register,
            subscribe,
            termsConditions,
            formErrors,
            loginCase,
            showSubscribeCbx,
        } = this.state
        const {
            showModal,
            clearAuthErrors,
            loading,
            callback,
            customDescription = '',
            padding,
            isNotModal,
        } = this.props

        return (
            <LoginContainer
                className="enabledTouch"
                padding={padding}
                style={{ overflow: isNotModal ? 'unset' : 'auto' }}
            >
                <form
                    autoComplete="on"
                    onSubmit={e => {
                        e.preventDefault()
                        this.login()
                    }}
                    noValidate
                    onKeyDown={e => {
                        if (e.keyCode === ENTER_KEY) {
                            e.preventDefault()
                            loginOverride()
                        }
                    }}
                >
                    {isNotModal ? (
                        <NonModalCloseButton
                            id="login-modal-close"
                            onClick={this.handleCancel}
                        />
                    ) : (
                        <CloseButton
                            id="login-modal-close"
                            onClick={this.handleCancel}
                        />
                    )}
                    <Row>
                        <Col lg={12}>
                            <Title>
                                {register
                                    ? t`v2_login.register.title`
                                    : t`v2_login.sign_in.title`}
                            </Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Text style={{ color: '#353d51' }}>
                                {register
                                    ? customDescription ||
                                      t`v2_login.register.subtitle`
                                    : customDescription ||
                                      t`v2_login.sign_in.subtitle`}
                            </Text>
                        </Col>
                    </Row>
                    {['2', '4', '6'].includes(loginCase) &&
                        _get(this, 'props.prefillEmail', '') && (
                            <Row>
                                <SocialEmailContainer
                                    style={{ margin: '16px auto' }}
                                >
                                    {email}
                                </SocialEmailContainer>
                            </Row>
                        )}
                    {['0', '4', '5', '6', '7'].includes(loginCase) && (
                        <Row>
                            <GoogleCol lg={12}>
                                <Google
                                    callback={callback}
                                    translationKey={
                                        'login.label.login_with_google'
                                    }
                                />
                            </GoogleCol>
                        </Row>
                    )}
                    {['0', '2', '3', '6', '7'].includes(loginCase) && (
                        <Row>
                            <FacebookCol lg={12}>
                                <Facebook
                                    callback={callback}
                                    translationKey={
                                        'login.label.login_with_facebook'
                                    }
                                />
                            </FacebookCol>
                        </Row>
                    )}
                    {['0', '3', '5', '7'].includes(loginCase) && (
                        <Row>
                            <LineContainer>
                                <Col xs={5} sm={5} md={5} lg={5}>
                                    <Line />
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2}>
                                    <p>{t`login.label.or`}</p>
                                </Col>
                                <Col xs={5} sm={5} md={5} lg={5}>
                                    <Line />
                                </Col>
                            </LineContainer>
                        </Row>
                    )}
                    {['0', '1', '3', '5', '7'].includes(loginCase) && (
                        <React.Fragment>
                            <Row>
                                <InputField
                                    errorIconVisible={false}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    label={t`v2_common.label.email`}
                                    placeholder={t`v2_common.placeholder.email`}
                                    required
                                    data-hj-whitelist=""
                                    name="email"
                                    type="email"
                                    id="login-email"
                                    value={email}
                                    disabled={
                                        ['1', '3', '5', '7'].includes(
                                            loginCase
                                        ) ||
                                        (loginCase === '0' &&
                                            _get(
                                                this,
                                                'props.prefillEmail',
                                                ''
                                            ) &&
                                            register)
                                    }
                                    onChange={this.onChange}
                                    onBlur={() => this.validateEmail()}
                                    errorText={_get(formErrors, 'email', '')}
                                    autoComplete="email"
                                    hasDanger={
                                        !!_get(formErrors, 'password', '') &&
                                        !_get(
                                            formErrors,
                                            'passwordRepeat',
                                            ''
                                        ) &&
                                        _get(formErrors, 'password', '') !==
                                            t`common.label.too_short.password`
                                    }
                                />
                            </Row>
                            <Row>
                                <InputField
                                    required
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    label={t`v2_common.label.password`}
                                    placeholder={t`v2_common.placeholder.password`}
                                    name="password"
                                    type="password"
                                    id="login-password"
                                    value={password}
                                    onChange={this.onChange}
                                    onBlur={() => {
                                        if (register) {
                                            this.validateRegisterPasswords()
                                        }
                                    }}
                                    errorText={_get(formErrors, 'password', '')}
                                    autoComplete={
                                        register
                                            ? 'new-password'
                                            : 'current-password'
                                    }
                                    hasDanger={
                                        !!_get(formErrors, 'passwordRepeat', '')
                                    }
                                />
                            </Row>
                        </React.Fragment>
                    )}
                    {register && (
                        <React.Fragment>
                            <Row>
                                <Row>
                                    <InputField
                                        required
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        label={t`v2_common.label.repeat_password`}
                                        placeholder={t`v2_common.label.repeat_password`}
                                        name="passwordRepeat"
                                        type="password"
                                        value={passwordRepeat}
                                        onChange={this.onChange}
                                        onBlur={() =>
                                            this.validateRegisterPasswords()
                                        }
                                        errorText={_get(
                                            formErrors,
                                            'passwordRepeat',
                                            ''
                                        )}
                                        autoComplete="new-password"
                                    />
                                </Row>
                            </Row>
                            <Row />
                            <Row>
                                <Col>
                                    <TermsAndConditions
                                        text={t`v2_claim.register.terms_and_conditions`}
                                        onChange={this.handleTermsChange}
                                        checked={termsConditions}
                                    />
                                </Col>
                            </Row>
                            {showSubscribeCbx ? (
                                <Row style={{ marginTop: '-25px' }}>
                                    <Col>
                                        <SubscribeContainer>
                                            <Subscribe
                                                text={t`subscription.description.subscribe`}
                                                onChange={this.handleSubscribe}
                                                checked={subscribe}
                                            />
                                        </SubscribeContainer>
                                    </Col>
                                </Row>
                            ) : null}
                        </React.Fragment>
                    )}
                    {!register && (
                        <Row>
                            {/* <Col xs={6} md={6} lg={6}>
                            <V2CheckBox
                                label={() => <Text>Remember me</Text>}
                                type="checkbox"
                                name="rememberMe"
                                onChange={this.handleCheckbox}
                                checked={rememberMe}
                            />
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                            <GreenText>
                                {t`v2_common.label.forgot_password`}
                            </GreenText>
                        </Col> */}
                            {['0', '1', '3', '5', '7'].includes(loginCase) && (
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <GreenText
                                        id="btn-forgot-password"
                                        onClick={() => {
                                            clearAuthErrors()
                                            showModal(
                                                modalTypes.forgotPasswordModal
                                            )
                                        }}
                                    >
                                        {t`v2_common.label.forgot_password`}
                                    </GreenText>
                                </Col>
                            )}
                        </Row>
                    )}
                    {['0', '1', '3', '5', '7'].includes(loginCase) && (
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <ButtonsContainer>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <DesktopBtn
                                            id="login-submit"
                                            onClick={this.login}
                                            disabled={
                                                !password ||
                                                loading ||
                                                !email ||
                                                (register &&
                                                    (!termsConditions ||
                                                        !passwordRepeat ||
                                                        password !==
                                                            passwordRepeat ||
                                                        Object.keys(formErrors)
                                                            .length !== 0))
                                            }
                                        >
                                            {loading ? (
                                                <Loader
                                                    type="ThreeDots"
                                                    color="#fff"
                                                    height="38"
                                                    width="38"
                                                />
                                            ) : register ? (
                                                t`login.button.register_with_email`
                                            ) : (
                                                t`login.button.login_with_email`
                                            )}
                                        </DesktopBtn>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <DesktopBorderBtn
                                            id="btn-login-cancel"
                                            onClick={this.handleCancel}
                                        >
                                            {t`common.label.cancel`}
                                        </DesktopBorderBtn>
                                    </Col>
                                </ButtonsContainer>
                            </Col>
                        </Row>
                    )}
                    {loginCase === '0' && (
                        <Row>
                            <Col>
                                <ToogleContainer>
                                    <Text>
                                        {!register
                                            ? t`v2_claim.login.register_new_account`
                                            : t`v2_claim.login.already_have_account`}
                                        &nbsp;
                                        <span
                                            id="btn-register-toggle"
                                            onClick={this.toogleMenu}
                                        >
                                            {register
                                                ? t`v2_claim.common.sign_in`
                                                : t`v2_claim.common.register`}
                                        </span>
                                    </Text>
                                </ToogleContainer>
                            </Col>
                        </Row>
                    )}
                </form>
            </LoginContainer>
        )
    }

    render() {
        const { trans } = this.props

        if (!trans) return null

        return this.renderLoginView()
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        lang: state.language,
        error: state.error.errorMessage,
        loading: state.v2_loginLoading,
        V2Claim: state.V2Claim,
        modal: state.toggle_modal,
        showSubscribeCbx: true,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showModal,
            hideModal,
            clearAuthErrors,
            loginUser,
            registerUser,
            setLoginLoading,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal)
