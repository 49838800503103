import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _get from 'lodash/get'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import {
    logout,
    getUserData,
    getInfoByDomain,
    resetAgreement,
} from '../../actions/index'
import t from '../../../shared/translations'
import { saveUserClaim, toggleBrandedGdpr } from '../../actions'
import {
    showModal,
    resetV2Claim,
    setReferralModalShareCode,
} from '../../actions/v2_actions'
import styled from 'styled-components'
import Navigation from './components/Navigation'
import LoginNavigation from './components/LoginNavigation'
import { browserHistory } from 'react-router'
import {
    getParameterByName,
    isMobileDevice,
    isFeatureEnabled,
} from '../../../main'
import { paths, featureToggles } from '../../constants'
import store from '../../services/store'
import { V2_ACTIONS } from '../../actions/actionTypes'
import { GdrpMessage } from '../GdrpMessage'

const NavbarOuter = styled.nav`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 91px;
    background-color: white;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 16px;
    transition: all 0.8s;
    margin-top: ${props => (props.showCookies ? '50px' : '0px')};

    @media (max-width: 990px) {
        margin-top: ${props => (props.showCookies ? '58px' : '0px')};
        margin-bottom: 0px;
    }

    @media (max-width: 786px) {
        margin-top: ${props => (props.showCookies ? '102px' : '0px')};
    }

    @media (max-width: 480px) {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    box-shadow: 0px 2px 24px 0px #00000008;

    background: ${({ signatureConfigs, theme }) =>
        signatureConfigs
            ? signatureConfigs.navigationBarColor
            : theme.colors.headerPrimaryColor};
`

const NavbarInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex: 1 0 72px;

    @media (min-width: 990px) {
        max-width: 119rem;
    }
`

class NavBar extends Component {
    state = {
        showDropdown: false,
        skycopLogoType: null,
    }

    UNSAFE_componentWillMount() {
        this.props.getUserData()

        let domain = getParameterByName('domain')
        if (domain) {
            this.props.getInfoByDomain(domain)
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.get('preview') === 'true') {
            this.setState({ skycopLogoType: urlParams.get('skycopLogo') })
        }
    }

    getLogoHyperLink = () => {
        const { hideElements } = this.props
        if (hideElements.hideLogin) {
            return `/?h_log=1${hideElements.hideCookieBar ? '&h_bar=1' : ''}${
                hideElements.hideChat ? '&h_chat=1' : ''
            }`
        }
        return getParameterByName('domain')
            ? window.location + '/' + getParameterByName('domain')
            : t`URL.logo`
    }

    renderName = () => {
        const { user } = this.props
        if (user && user.name && user.surname) {
            const fullName = `${user.name} ${user.surname}`
            return fullName.length > 25
                ? `${fullName.substr(0, 25)}...`
                : fullName
        } else {
            return t`menu.label.user_menu`
        }
    }

    renderNickname = () => {
        const { user } = this.props
        if (user && user.name && user.surname) {
            return `${user.name[0]}${user.surname[0]}`.toUpperCase()
        } else {
            return t`menu.label.user_menu`[0].toUpperCase()
        }
    }

    showMenu = e => {
        e.preventDefault()

        this.setState({ showDropdown: true }, () => {
            document.addEventListener('click', this.closeMenu)
            const dropdown = document.querySelector('#menu-dropdown')

            if (dropdown && isMobileDevice()) {
                disableBodyScroll(dropdown)
            }
        })
    }

    closeMenu = e => {
        if (
            !e ||
            (e.target &&
                e.target.id !== 'mob-lang' &&
                e.target.id !== 'desktop-lang')
        ) {
            this.setState({ showDropdown: false }, () => {
                document.removeEventListener('click', this.closeMenu)
                clearAllBodyScrollLocks()
            })
        }
    }

    handleAddNewClaim = () => {
        const { isSkycopCare, location } = this.props

        if (
            location &&
            location.pathname &&
            location.pathname === paths.COMPLETED_SIGN
        ) {
            return
        }
        resetAgreement()
        saveUserClaim(undefined)
        resetV2Claim()
        store.dispatch({
            type: V2_ACTIONS.STEP_RESET,
            payload: 0,
        })
        browserHistory.push(isSkycopCare ? '/new-claim' : '/')
    }

    handleReferralClick = () => {
        const { lang, setReferralModalShareCode, primaryColor } = this.props
        if (window.SkycopReferral) {
            window.SkycopReferral.ReferralModal.call(
                lang || 'en',
                shareData => {
                    if (shareData.code) {
                        setReferralModalShareCode(shareData.code)
                    }
                },
                null,
                null,
                null,
                null,
                { colors: { primaryColor } }
            )
        }
    }

    render() {
        const {
            trans,
            isAuthenticated,
            logout,
            saveUserClaim,
            resetAgreement,
            showCookies,
            location,
            showBrandedGdpr,
            showModal,
            resetV2Claim,
            isSkycopCare,
            signatureConfigs,
        } = this.props
        const { skycopLogoType } = this.state
        const backUrl = encodeURIComponent(
            `${location.pathname}${location.search}`
        )
        const query = location && location.query
        const infoMessagePosition = query && query.msg_position

        if (trans) {
            const navLinks = [
                {
                    index: false,
                    path: '/claims',
                    name: t`menu.my_claims`,
                },
                {
                    index: false,
                    path: '/subscriptions',
                    name: t`menu.sc_subscriptions`,
                    hidden: !isFeatureEnabled(featureToggles.sc_menu_item),
                },
                {
                    index: false,
                    path: '/planned-trips',
                    name: t`menu.your_planned_trips`,
                },
            ].filter(Boolean)
            const logoLink = this.getLogoHyperLink()

            return (
                <NavbarOuter
                    signatureConfigs={signatureConfigs}
                    showCookies={
                        _get(this, 'props.hideElements.hideCookieBar', false)
                            ? false
                            : showCookies
                    }
                    className="blurrable"
                >
                    <NavbarInner>
                        {isAuthenticated ? (
                            <Navigation
                                active={this.state.showDropdown}
                                logoLink={logoLink}
                                navLinks={navLinks}
                                saveUserClaim={saveUserClaim}
                                logout={logout}
                                nickname={this.renderNickname()}
                                renderName={this.renderName}
                                resetAgreement={resetAgreement}
                                showMenu={this.showMenu}
                                resetV2Claim={resetV2Claim}
                                location={this.props.location}
                                closeMenu={() => this.closeMenu()}
                                addNewClaimText={t`my_claims.button.add_new_claim`}
                                onAddNewClaimClick={this.handleAddNewClaim}
                                getUserData={this.props.getUserData}
                                signatureConfigs={signatureConfigs}
                                handleReferralClick={this.handleReferralClick}
                            />
                        ) : (
                            <LoginNavigation
                                skycopLogoType={skycopLogoType}
                                signatureConfigs={signatureConfigs}
                                isSkycopCare={isSkycopCare}
                                logoLink={logoLink}
                                backUrl={backUrl}
                                showModal={showModal}
                                hideLogin={_get(
                                    this,
                                    'props.hideElements.hideLogin',
                                    false
                                )}
                            />
                        )}
                    </NavbarInner>
                    {showBrandedGdpr &&
                        infoMessagePosition &&
                        infoMessagePosition.toLowerCase() !== 'bottom' && (
                            <GdrpMessage
                                onClose={() => toggleBrandedGdpr(false)}
                                html={t`common.branded.gdpr_info`}
                            />
                        )}
                </NavbarOuter>
            )
        } else {
            return null
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isAuthenticated: state.auth.authenticated,
        isExternal: state.auth.external,
        trans: state.translations,
        domain: state.domain,
        showBrandedGdpr: state.show_branded_gdpr,
        hideElements: state.hideElements,
        lang: state.language,
        signatureConfigs: state.signaturePageConfig,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logout,
            getUserData,
            saveUserClaim,
            getInfoByDomain,
            resetAgreement,
            showModal,
            resetV2Claim,
            setReferralModalShareCode,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
