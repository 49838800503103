import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import _get from 'lodash/get'
import debounce from 'lodash/debounce'
import { Row, Col } from '../../../shared/components/V2/Layout'
import InputField from '../../../shared/components/V2/SkycopPay/InputField'
import t from '../../../shared/translations'
import { emailMagic } from '../../../shared/validators'
import { validateEmailSmtp } from '../../../shared/actions'
import { SPACE_KEY } from '../../../shared/components/V2/Modal'
import TermsAndConditions from '../../../shared/components/V2/SkycopPay/TermsAndConditions'
import { Button } from '../../../shared/components/V2/SkycopPay/Button'
import { InfoBox } from '../../../shared/components/V2/SkycopPay/InfoBox'
import {
    showModal,
    validateSkycopCareView,
    setArrivalAirport,
    setDepartureAirport,
    asyncPreloadAirport,
    setSelectedAirports,
    setUsedAirlines,
} from '../../../shared/actions/v2_actions'
import { desktop } from '../../../shared/components/helpers/styled_queries'

import FlightInput, {
    flightTypes,
} from '../../../shared/components/V2/SkycopPay/FlightInput'
import { isMobileDevice, isValidEmailInput, isValidEmail } from '../../../main'
import Datepicker from '../../../shared/components/V2/SkycopPay/DatePicker'
import {
    UploadIcon,
    EmailIcon,
    FlightNumberIcon,
    NameIcon,
    SurnameIcon,
    CommentIcon,
} from '../../../shared/components/V2/SkycopPay/icons'
import { List } from '../../../shared/components/V2/SkycopPay/List'
import { PageTitle } from '../../../shared/components/V2/SkycopPay/PageTitle'
import SkycopPayHeader from '../../../shared/components/V2/SkycopPay/SkycopPayHeader'
import { FileInput } from '../../../shared/components/V2/SkycopPay/FileInput'
import {
    tabletLG,
    mobile,
} from '../../../shared/components//helpers/styled_queries'
import { getUiid, saveClaimForm, uploadFormDocument } from './utils'
import store from '../../../shared/services/store'
import { modalTypes } from '../../../shared/constants'
import SkycopPayModal from './SkycopPayModal'
import { getAirports } from '../../../shared/actions'

/* global Raven */

const TextareaContainer = styled('div')`
    position: relative;

    .select-icon {
        position: absolute;
        top: 18px;
        left: 18px;
    }
`

const Column = styled(Col)`
    padding: 0;
    background-color: #fff;

    ${tabletLG`
        max-width: 100%;
    `}
`

const FormColumn = styled(Column)`
    padding: 0;

    p {
        font-size: 15px;
    }

    ${tabletLG`
        max-width: 100%;
    `}
`

const Title = styled.h1`
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    color: #043d5d;

    ${mobile`
        font-size: 26px;
    `}

    ${desktop`
        font-size: 46px;
    `}
`

export const Container = styled.div`
    height: 100%;

    .form-group {
        margin-bottom: 0;
    }

    ${desktop`
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        .m-y10-d {
            margin-left: 10px;
            margin-right: 10px;
        }

        .mb-10 {
            margin-bottom: 10px;
        }
    `}

    ${mobile`
        .mb-m-10 {
            margin-bottom: 10px;
        }
    `}

    .mt-10 {
        margin-top: 10px;
    }

    .m-y10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mt-12 {
        margin-top: 12px;
    }

    .mt-2 {
        margin-top: 2px;
    }
`

const ImageContainer = styled(Container)`
    position: relative;
    background-position: center;
    width: 100%;
    height: 100%;
    background-image: url('/images/desktop_skycop_pay_landing.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;

    .image-decription {
        text-align: center;
        font-size: 21px;
    }

    ${desktop`
        padding: 380px 40px 64px;
        justify-content: space-between;
    `}

    @media only screen and (max-width: 960px) {
        padding: 180px 40px 200px;

        .link-container {
            display: flex;
            justify-content: center;
        }
    }
`
const MobileMenuRow = styled(Row)`
    @media only screen and (min-width: 960px) {
        display: none;
    }
`

const DesktopMenuRow = styled(Row)`
    @media only screen and (max-width: 959px) {
        display: none;
    }
`

const Link = styled.a`
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #062d22;

    @media only screen and (max-width: 960px) {
        text-align: center;
    }
`

const FormSide = styled.form`
    padding: 24px;
    height: 100%;

    .decription {
        font-size: 21px;
    }

    ${mobile`
        padding: 0px;
    `}

    ${desktop`
        padding: 64px;
    `}
`
const Textarea = styled.textarea`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    min-height: 105px;
    border: 1px solid #cbd3df;
    padding: 10px 16px 10px 50px;
    outline: 0 none;

    &:hover,
    &:active,
    &:focus {
        border: 1px solid #043d5d;
        box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
    }

    &:disabled,
    &.disabled {
        background: #f2f5f9;
        color: #7f8fa4;
        border: 1px solid #f2f5f9;
        font-weight: 600;
        opacity: 1;
        -webkit-text-fill-color: #7f8fa4;

        &:hover {
            box-shadow: none;
            cursor: not-allowed;
        }
    }

    &::placeholder {
        color: #043d5d;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
    }
`

class SkycopPay extends Component {
    state = {
        name: '',
        surname: '',
        termsCheck: false,
        airportArrival: '',
        airportDeparture: '',
        flightDate: null,
        flightNumber: '',
        email: '',
        uuid: '',
        loading: false,
        comment: '',
        loaloadOptions: [],
    }

    resetState(uuid) {
        this.setState({
            name: '',
            surname: '',
            termsCheck: false,
            airportArrival: '',
            airportDeparture: '',
            flightDate: null,
            flightNumber: '',
            email: '',
            comment: '',
            uuid,
            loading: false,
        })
    }

    async componentDidMount() {
        if (window && window.dataLayer) {
            window.dataLayer.push({
                event: 'FastPayoutButton',
            })
        }
        const {
            getAirports,
            setDepartureAirport,
            setArrivalAirport,
        } = this.props
        const { iata_from, iata_to } = this.props.location.query
        const uuid = await getUiid()

        const getAirportsOptions = { range: 100 }
        getAirports(
            iata_from,
            'airportDeparture',
            null,
            getAirportsOptions
        ).then(e => {
            this.preloadAirport(
                iata_from,
                'airportDeparture',
                setDepartureAirport
            )
            const airline = e.payload.find(item => item.iata === iata_from)

            if (airline) {
                this.setState({ airportDeparture: airline.id })
            }
        })
        getAirports(iata_to, 'airportArrival', null, getAirportsOptions).then(
            e => {
                this.preloadAirport(
                    iata_to,
                    'airportArrival',
                    setArrivalAirport
                )
                const airline = e.payload.find(item => item.iata === iata_to)

                if (airline) {
                    this.setState({ airportArrival: airline.id })
                }
            }
        )

        this.setState({
            uuid,
        })
    }

    onChangeTerms = () => {
        this.setState({ termsCheck: !this.state.termsCheck })
    }

    _handleChangeEvent = event => {
        const e = _get(event, 'target', event)
        this.setState({ [e.name]: e.value })
    }

    handleEmailChange = e => {
        const email = _get(e, 'target.value', '')
        if (!isValidEmailInput(email)) {
            return
        }

        this.setState(
            {
                email: _get(e, 'target.value', ''),
            },
            () => this.emailValidate()
        )
    }

    emailValidate = debounce(() => {
        const { email, emailErrors } = this.state

        if (!isValidEmail(email) && !emailErrors) {
            this.setState({
                emailErrors: t`common.label.email_invalid`,
            })
            return false
        } else if (isValidEmail(email)) {
            if (emailErrors) {
                this.setState({
                    emailErrors: '',
                })
            }
        }

        return true
    }, 500)

    preloadAirport = (query, name, callback) => {
        const { asyncPreloadAirport } = this.props
        const inputRef = this[`${name}Ref`]

        if (inputRef) {
            inputRef.loadOptions(query)
            asyncPreloadAirport(query, airport =>
                this._handleAirportChange({ ...airport, name }, callback)
            )
        }
    }

    _handleAirportChange = (event, callback) => {
        const { selectedAirports } = this.props
        const e = _get(event, 'target', event)

        if (
            !e ||
            !e.name ||
            !e.value ||
            selectedAirports.find(a => a.id === e.value)
        ) {
            return
        }

        this.setState({ [e.name]: e.value })
    }

    clearAirport = name => {
        if (name) {
            this.setState({ [name]: '' })
        }
    }

    handleDocumentChange = e => {
        this.setState({ ticket: e.target.files[0].name })
        this.setState({ files: e.target.files })
    }

    handleSubmit = async e => {
        e.preventDefault()
        this.setState({ loading: true })
        const {
            uuid,
            name,
            surname,
            airportDeparture,
            airportArrival,
            flightNumber,
            flightDate,
            files,
            email,
            comment,
        } = this.state
        const file = files.item(0)
        const fileSize = (file.size / 1024 / 1024).toFixed(4)

        if (
            name &&
            surname &&
            airportArrival &&
            airportDeparture &&
            flightNumber &&
            flightDate &&
            email
        ) {
            const submitData = {
                name,
                surname,
                airportDeparture,
                airportArrival,
                flightNumber,
                flightDate,
                email,
                comment,
            }

            try {
                if (fileSize < 5) {
                    await uploadFormDocument(file, this.state.uuid)
                }
                await saveClaimForm(uuid, submitData)

                if (window && window.dataLayer) {
                    window.dataLayer.push({
                        event: 'FastPayoutSuccess',
                    })
                }

                const newUuid = await getUiid()

                this.resetState(newUuid)
                store.dispatch(showModal(modalTypes.skycopPayModal))
            } catch (error) {
                Raven.captureException(
                    `$FAILED TO POST SKYCOP PAY CLAIM: ${JSON.stringify(error)}`
                )
            }
        }

        this.setState({ loading: false })
    }

    setRef = (name, ref) => {
        const refName = `${name}Ref`
        this[refName] = ref
    }

    render() {
        const { is_valid_smtp, modal, primaryColor } = this.props
        const {
            termsCheck,
            airportDeparture,
            airportArrival,
            name,
            email,
            surname,
            flightNumber,
            flightDate,
            ticket,
            loading,
        } = this.state

        const disabledSubmit =
            !termsCheck ||
            !name ||
            !surname ||
            !airportArrival ||
            !airportDeparture ||
            !flightNumber ||
            !flightDate ||
            !ticket ||
            !email

        const LIST = [
            { key: t`skycop_pay.list_text_1` },
            { key: t`skycop_pay.list_text_2` },
            { key: t`skycop_pay.list_text_3` },
        ]

        return (
            <Container style={{ height: 'auto', minHeight: '100vh' }}>
                <MobileMenuRow>
                    <Col>
                        <SkycopPayHeader />
                    </Col>
                </MobileMenuRow>
                <Row style={{ height: '100%' }}>
                    <Column lg={6}>
                        <ImageContainer>
                            <Row>
                                <Container>
                                    <Row>
                                        <Col sm={12}>
                                            <Title
                                                dangerouslySetInnerHTML={{
                                                    __html: t`skycop_pay.page_image_title`,
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <p className="image-decription">{t`skycop_pay.image_description`}</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Row>

                            <Row>
                                <Col className="link-container">
                                    <Link href="mailto:pay@skycop.com">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t`skycop_pay.page_image_link_text`,
                                            }}
                                        />
                                    </Link>
                                </Col>
                            </Row>
                        </ImageContainer>
                    </Column>
                    <FormColumn lg={6}>
                        <FormSide>
                            <Container>
                                <DesktopMenuRow>
                                    <Col>
                                        <SkycopPayHeader />
                                    </Col>
                                </DesktopMenuRow>
                                <PageTitle
                                    title={t`skycop_pay.page_title`}
                                    metaDesc={t`skycop_pay.meta_description`}
                                />
                                <Row>
                                    <Col sm={12}>
                                        <p className="decription">{t`skycop_pay.description`}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={4} lg={4}>
                                        <InfoBox
                                            text={t`skycop_pay.info_1`}
                                            number={'01'}
                                            bubbleColor="#0CAA56"
                                        />
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                        <InfoBox
                                            text={t`skycop_pay.info_2`}
                                            number={'02'}
                                            bubbleColor="#149CC8"
                                        />
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                        <InfoBox
                                            text={t`skycop_pay.info_3`}
                                            number={'03'}
                                            bubbleColor="#7D74EC"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={8} lg={6}>
                                        <FlightInput
                                            setRef={this.setRef}
                                            autofocus={!isMobileDevice()}
                                            hasIcon
                                            required
                                            clear={() =>
                                                this.clearAirport(
                                                    'airportDeparture'
                                                )
                                            }
                                            autoload={!!airportDeparture}
                                            type={flightTypes.departure}
                                            label={t`skycop_pay.label.departed_from`}
                                            name="airportDeparture"
                                            id="airport-departure"
                                            value={airportDeparture}
                                            defaultValue={airportDeparture}
                                            onChange={this._handleAirportChange}
                                            callback={setDepartureAirport}
                                        />
                                    </Col>
                                    <Col sm={12} md={8} lg={6}>
                                        <FlightInput
                                            setRef={this.setRef}
                                            hasIcon
                                            required
                                            clear={() =>
                                                this.clearAirport(
                                                    'airportArrival'
                                                )
                                            }
                                            autoload={!!airportArrival}
                                            label={t`skycop_pay.label.arrived_to`}
                                            name="airportArrival"
                                            id="airport-arrival"
                                            value={airportArrival}
                                            defaultValue={airportArrival}
                                            onChange={this._handleAirportChange}
                                            callback={setArrivalAirport}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Datepicker
                                            innerRef={ref => {
                                                this.dateTimeWrapperRef = ref
                                            }}
                                            name="flightDate"
                                            label={t`skycop_pay.label.date`}
                                            placeholder={t`skycop_pay.label.date`}
                                            value={this.state.flightDate}
                                            dateFormat="YYYY-MM-DD"
                                            viewMode="years"
                                            viewDate={new Date('1980')}
                                            startDate={2020}
                                            endDate={150}
                                            handleChange={
                                                this._handleChangeEvent
                                            }
                                            primaryColor={primaryColor}
                                        />
                                    </Col>
                                    <InputField
                                        label={t`skycop_pay.label.flight_number`}
                                        name="flightNumber"
                                        type="string"
                                        id="flightNumber"
                                        required
                                        value={this.state.flightNumber}
                                        onChange={this._handleChangeEvent}
                                        Icon={FlightNumberIcon}
                                        onKeyDown={e => {
                                            if (e.keyCode === SPACE_KEY) {
                                                e.preventDefault()
                                                return false
                                            }
                                        }}
                                    />
                                </Row>
                                <Row>
                                    <InputField
                                        label={t`skycop_pay.label.name`}
                                        name="name"
                                        type="string"
                                        id="name"
                                        required
                                        value={this.state.name}
                                        onChange={this._handleChangeEvent}
                                        Icon={NameIcon}
                                        onKeyDown={e => {
                                            if (e.keyCode === SPACE_KEY) {
                                                e.preventDefault()
                                                return false
                                            }
                                        }}
                                    />
                                    <InputField
                                        label={t`skycop_pay.label.surname`}
                                        name="surname"
                                        type="string"
                                        id="surname"
                                        required
                                        value={this.state.surname}
                                        onChange={this._handleChangeEvent}
                                        Icon={SurnameIcon}
                                        onKeyDown={e => {
                                            if (e.keyCode === SPACE_KEY) {
                                                e.preventDefault()
                                                return false
                                            }
                                        }}
                                    />
                                </Row>
                                <Row>
                                    <FileInput
                                        lg={12}
                                        label={
                                            this.state.ticket ||
                                            t`skycop_pay.label.upload_ticket`
                                        }
                                        buttonLabel={t`skycop_pay.label.upload_button`}
                                        name="ticket"
                                        id="ticket"
                                        accept="image/*,application/msword,application/vnd.oasis.opendocument.text,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        required
                                        onChange={this.handleDocumentChange}
                                        Icon={UploadIcon}
                                        onKeyDown={e => {
                                            if (e.keyCode === SPACE_KEY) {
                                                e.preventDefault()
                                                return false
                                            }
                                        }}
                                    />
                                </Row>
                                <Row>
                                    <InputField
                                        lg={12}
                                        label={t`skycop_pay.label.email`}
                                        name="email"
                                        type="email"
                                        id="email"
                                        required
                                        value={this.state.email}
                                        onChange={this.handleEmailChange}
                                        Icon={EmailIcon}
                                        errorText={t`${_get(
                                            this.state,
                                            'emailErrors',
                                            ''
                                        )}`}
                                        suggestedText={{
                                            text: emailMagic(this.state.email),
                                            callback: () => {
                                                this.handleEmailChange({
                                                    target: {
                                                        name: 'email',
                                                        value: emailMagic(
                                                            this.state.email
                                                        ),
                                                    },
                                                })
                                                validateEmailSmtp(
                                                    emailMagic(this.state.email)
                                                )
                                            },
                                        }}
                                        onBlur={() =>
                                            validateEmailSmtp(this.state.email)
                                        }
                                        isValidSmtp={!!is_valid_smtp}
                                        hasSmtpValidation={true}
                                        autoComplete="email"
                                        onKeyDown={e => {
                                            if (e.keyCode === SPACE_KEY) {
                                                e.preventDefault()
                                                return false
                                            }
                                        }}
                                    />
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <TextareaContainer>
                                            <span className="select-icon">
                                                <CommentIcon className="select-icon" />
                                            </span>
                                            <Textarea
                                                data-hj-whitelist=""
                                                placeholder={t`skycop_pay.label.comment`}
                                                value={this.state.comment}
                                                name="comment"
                                                id="comment"
                                                maxLength={1500}
                                                onChange={
                                                    this._handleChangeEvent
                                                }
                                            />
                                        </TextareaContainer>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '12px' }}>
                                    <Col>
                                        <TermsAndConditions
                                            text={t`skycop_pay.checkbox.terms_and_conditions_price_list`}
                                            onChange={this.onChangeTerms}
                                            checked={termsCheck}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            text={
                                                loading
                                                    ? t`skycop_pay.loading`
                                                    : t`skycop_pay.primary_button`
                                            }
                                            onClick={this.handleSubmit}
                                            disabled={disabledSubmit}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <List list={LIST} />
                                    </Col>
                                </Row>
                            </Container>
                        </FormSide>
                    </FormColumn>
                </Row>
                {_get(modal, 'modalType', '') === modalTypes.skycopPayModal && (
                    <SkycopPayModal email={email} />
                )}
            </Container>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showModal,
            getAirports,
            validateSkycopCareView,
            asyncPreloadAirport,
            setDepartureAirport,
            setArrivalAirport,
            setSelectedAirports,
            setUsedAirlines,
        },
        dispatch
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.authenticated,
        trans: state.translations,
        is_valid_smtp: state.valid_email_smtp,
        usedAirports: state.v2_UsedAirports,
        airportArrival: state.v2_airportArrival,
        airportDeparture: state.v2_airportDeparture,
        selectedAirports: state.v2_selectedAirports,
        modal: state.toggle_modal,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkycopPay)
