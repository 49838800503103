import React from 'react'
import styled from 'styled-components'
import { CloseIcon, CircleClose } from './V2Icons'
import { mobile, desktop, tablet } from '../helpers/styled_queries'

export const DesktopBtn = styled.button`
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    border: 0;
    min-width: 200px;
    font-weight: bold;
    width: 100%;
    min-height: 48px;
    border-radius: 24px;
    padding: 10px 30px;
    text-align: center;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    ${({ disabledStyle, theme }) =>
        disabledStyle
            ? 'background-color: #E0E0E0;'
            : theme.colors.actionButton
            ? `background-color: ${theme.colors.actionButton};`
            : theme.colors.isPartnerCustomization ? `background-image: linear-gradient(to top, ${theme.colors.primaryColor}, ${theme.colors.primaryColor}15)};` : `background-color: ${theme.colors.primaryColor};`}
    color: ${({ disabledStyle }) => (disabledStyle ? '#717171' : '#ffffff')};
    outline: 0 none;
    font-size: 14px;
    pointer-events: ${({ preventPointers }) =>
        preventPointers ? 'none' : 'auto'};

    &:hover {
        ${({ disabledStyle }) =>
            !disabledStyle ? 'cursor: pointer' : 'cursor: not-allowed'}
    }

    &:focus {
        outline: 0 none;
    }
`

export const DesktopBtn2 = styled.button`
    font-family: Noto Sans;
    cursor: pointer;
    border: 0;
    width: 100%;
    min-height: 48px;
    border-radius: 16px;
    height: 52px;
    text-align: center;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    ${({ disabledStyle, theme }) =>
        disabledStyle
            ? 'background-color: #E0E0E0;'
            : theme.colors.actionButton
            ? `background-color: ${theme.colors.actionButton};`
            : `background-image: linear-gradient(to top, ${theme.colors.primaryColor},  ${theme.colors.primaryColor})};`}
    color: ${({ disabledStyle }) => (disabledStyle ? '#717171' : '#ffffff')};
    outline: 0 none;
    font-size: 16px;
    font-weight: 500 !important;
    pointer-events: ${({ preventPointers }) =>
        preventPointers ? 'none' : 'auto'};

    &:hover {
        ${({ disabledStyle }) =>
            !disabledStyle ? 'cursor: pointer' : 'cursor: not-allowed'}
    }

    &:focus {
        outline: 0 none;
    }
`

const DesktopBorderBtn = styled(DesktopBtn)`
    width: 100%;
    background: #fff;
    border: solid 1px #98a3bf;
    color: #353d51;
    font-weight: bold;

    &:hover {
        filter: brightness(115%);
        color: rgba(53, 61, 81, 0.8);
    }
`

const DesktopBorderBtn2 = styled(DesktopBtn2)`
    width: 100%;
    background: #fff;
    border: solid 1px ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.primaryColor};
    font-weight: 500;
    height: inherit;

    &:hover {
        filter: brightness(115%);
        color: ${({ theme }) => theme.colors.primaryColor};
    }
`

const CloseButtonContiner = styled.button`
    position: absolute;
    display: flex;
    top: 16px;
    right: 16px;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    background-color: transparent;
    z-index: 99999999;

    &:focus {
        text-decoration: none;
        outline: none;
    }
`

export const CloseButton = ({ text, fill, stroke, width, height, ...rest }) => (
    <CloseButtonContiner {...rest}>
        {text && <p>{text}</p>}
        <CloseIcon fill={fill} stroke={stroke} width={width} height={height} />
    </CloseButtonContiner>
)

export const CloseButton2 = ({
    text,
    fill,
    stroke,
    width,
    height,
    ...rest
}) => (
    <CloseButtonContiner {...rest}>
        {text && <p>{text}</p>}
        <CircleClose
            fill={fill}
            stroke={stroke}
            width={width}
            height={height}
        />
    </CloseButtonContiner>
)

export const NonModalCloseButton = styled(CloseButton)`
    right: -8px;
    top: -8px;

    ${mobile`
        right: 0px;
        top: 0px;
    `}
`

export const V2Button2 = ({
    type,
    children,
    disabled,
    onClick = () => null,
    disabledClick = null,
    ...rest
}) => {
    if (type === 'borderBtn') {
        return (
            <DesktopBorderBtn2
                disabledStyle={disabled}
                preventPointers={disabled && !disabledClick}
                onClick={
                    disabled
                        ? e => {
                              e.preventDefault()
                              if (disabledClick) {
                                  disabledClick(e)
                              }
                          }
                        : e => {
                              e.preventDefault()
                              onClick(e)
                          }
                }
                {...rest}
            >
                {children}
            </DesktopBorderBtn2>
        )
    } else {
        return (
            <DesktopBtn2
                disabledStyle={disabled}
                preventPointers={disabled && !disabledClick}
                onClick={
                    disabled
                        ? e => {
                              e.preventDefault()
                              if (disabledClick) {
                                  disabledClick(e)
                              }
                          }
                        : e => {
                              e.preventDefault()
                              onClick(e)
                          }
                }
                {...rest}
            >
                {children}
            </DesktopBtn2>
        )
    }
}

export const V2Button = ({
    type,
    children,
    disabled,
    onClick = () => null,
    disabledClick = null,
    ...rest
}) => {
    if (type === 'borderBtn') {
        return (
            <DesktopBorderBtn
                disabledStyle={disabled}
                preventPointers={disabled && !disabledClick}
                onClick={
                    disabled
                        ? e => {
                              e.preventDefault()
                              if (disabledClick) {
                                  disabledClick(e)
                              }
                          }
                        : e => {
                              e.preventDefault()
                              onClick(e)
                          }
                }
                {...rest}
            >
                {children}
            </DesktopBorderBtn>
        )
    } else {
        return (
            <DesktopBtn
                disabledStyle={disabled}
                preventPointers={disabled && !disabledClick}
                onClick={
                    disabled
                        ? e => {
                              e.preventDefault()
                              if (disabledClick) {
                                  disabledClick(e)
                              }
                          }
                        : e => {
                              e.preventDefault()
                              onClick(e)
                          }
                }
                {...rest}
            >
                {children}
            </DesktopBtn>
        )
    }
}

export const TransparentBtn = styled.div`
    width: 100%;
    margin: 0;
    border: 0 none;
    color: #353d51;
    font-weight: 600;
    font-size: 14px;
    background: transparent;
    outline: 0 none;
    box-shadow: none;
    line-height: 1.71;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    text-align: center;
    cursor: pointer;

    ${desktop`
        margin: 0;
        padding: 0px 30px;
        display: inline-flex;
        align-items: center;

        &:hover {
            color: #98a3bf;
        }
    `}

    ${tablet`
        padding: 12px 8px;
    `}

    ${mobile`
        padding: 12px 8px;
    `}
`
