import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import t from '../../../../shared/translations'
import TermsAndConditions from '../../components/TermsAndConditions'
import _get from 'lodash/get'
import {
    getApiClient,
    loadScreenOn,
    loadScreenOff,
    getStorage,
    applyTranslationElement,
    isMobileDevice,
    isFeatureEnabled,
} from '../../../../main'
import { Row, Col } from '../../../../shared/components/V2/Layout'
import {
    ScActiveIcon,
    ScCanceledIcon,
    ScExpiredIcon,
    ScPendingIcon,
} from '../../../../shared/components/V2/V2Icons'
import InputField from '../../../../shared/components/V2/InputField'
import { ProfileContainer } from '../MyProfile'
import { getSkycopCareSubscriptions } from '../../../../shared/actions/v2_actions'
import {
    mobile,
    tabletSM,
    desktop,
    tablet,
    tabletLG,
} from '../../../../shared/components/helpers/styled_queries'
import { paths, featureToggles } from '../../../../shared/constants'
import { browserHistory } from 'react-router'
import moment from 'moment'
import { ScButton, DarkButton } from './Button'
import { BenefitsSectionCard } from '../../components/BenefitsSectionCard'
import MobileCarousel from '../../components/MobileCarousel'
import { NoPlansBlock } from './NoPlansBlock'
import { SubscriptionCard } from './SubscriptionCard'
import { StatusSection } from './Card'
import { InfoBlock } from './InfoBlock'

export const PageViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0px;

    ${desktop`
        padding: 16px;
        padding-bottom: ${({ paddingBottom }) => paddingBottom || '84px'};
    `} /* 
    .mobile-focused {
        padding-bottom: 300px;
        animation: none;
        transition: none;
    } */
`

const StyledProfileContainer = styled(ProfileContainer)`
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
    margin-bottom: 30px;

    ${mobile`
        padding: 0px;
        padding-left: 12px;
        padding-right: 12px;

   
    `}

    ${tabletSM`
        padding: 0px;
        padding-left: 24px;
        padding-right: 24px;

    `}

    h1 {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 32px;
    }

    &.prepaid-block,
    &.promo-card {
        & .item-col {
            padding: 12px 0px;
        }
    }
`

const ScContainer = styled.div`
    display: flex;
`

const ScApplyButtonContainer = styled.div`
    ${mobile`
        margin-bottom: 12px
    `}
    ${tabletLG`
    margin-bottom: 12px
    `}
    ${tablet`
    margin-bottom: 12px
    `}
    ${desktop`
    margin-bottom: 8px
    `}
`

const ScCodeTermsContainer = styled.div`
    margin-top: 8px;
`

const ScInputLabel = styled.span`
    color: #493fc2;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 31.92px; /* 159.6% */
    text-wrap: ${({ isMobile }) => (isMobile ? '' : 'nowrap')};
`

const BenefitsContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
`

const BenefitCard = styled.div`
    margin-bottom: 6px;
    ${tablet`
        width: 100%;
    `}

    ${desktop`
    margin-right: 6px;
    `}
`

const PromoCard = styled(Row)`
    & .main-text {
        color: #333;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 35.42px;

        & span {
            color: ${({ theme }) => theme.colors.primaryColor};
        }
    }

    & .description-text {
        color: #000;
        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.92px; /* 160.667% */
    }

    & button {
        margin-top: 38px;
    }
`

class ScSubscriptions extends React.Component {
    state = {
        isOpen: false,
        prepaidCode: this.props.promoCode || '',
        prepaidPlanError: '',
        termsCheck: false,
        shouldValidateTerms: false,
    }
    async componentDidMount() {
        const { user, promoCode } = this.props
        const urlParams = new URLSearchParams(window.location.search)

        const promoCodeParam = urlParams.get('promo')

        if (promoCodeParam) {
            this.setState({ prepaidCode: promoCodeParam })
            setTimeout(() => {
                const element = document.getElementById('prepaid-plan-code')
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }, 2000)
        }

        if (promoCode) {
            const element = document.getElementById('prepaid-plan-code')
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }

        if (user && !user.active) {
            browserHistory.push(paths.PROFILE)
        } else {
            loadScreenOn()
            await getSkycopCareSubscriptions()
            loadScreenOff()
        }
    }

    mapStatus = (orderStatus, paymentStatus) => {
        /*
            cancelled = Order cancelled - Payment refunded
            Active = Order cancelled - Payment paid
            Active = Order open - Payment paid
            Expired = Order completed - Payment paid
            Pending = Order created - Payment pending
        */

        if (paymentStatus === 'expired') {
            return 'expired'
        } else if (orderStatus === 'cancelled') {
            return 'cancelled'
        } else if (orderStatus === 'open' && paymentStatus === 'paid') {
            return 'active'
        } else if (orderStatus === 'completed' && paymentStatus === 'paid') {
            return 'expired'
        } else if (orderStatus === 'created' && paymentStatus === 'pending') {
            return 'pending'
        }

        return 'pending'
    }

    mapStatusTexts = (status, valid, active) => {
        switch (status) {
            case 'active':
                return (
                    <p
                        style={{ color: '#0A833D', fontWeight: 'bold' }}
                    >{t`sc_subscriptions.active`}</p>
                )
            case 'inactive':
                return (
                    <p
                        style={{ color: '#736B6B', fontWeight: 'bold' }}
                    >{t`sc_subscriptions.stopped`}</p>
                )
            case 'stopped':
                return active ? (
                    <p style={{ color: '#0A833D', fontWeight: 'bold' }}>
                        {t`sc_subscriptions.cancelled_status`.replace(
                            '%DATE%',
                            formatDate(valid)
                        )}
                    </p>
                ) : (
                    <p
                        style={{ color: '#736B6B', fontWeight: 'bold' }}
                    >{t`sc_subscriptions.stopped`}</p>
                )
            case 'failed':
                return (
                    <p
                        style={{ color: '#736B6B', fontWeight: 'bold' }}
                    >{t`sc_subscriptions.stopped_payment`}</p>
                )
            case 'prepaid':
                return (
                    <p
                        style={{ color: '#736B6B', fontWeight: 'bold' }}
                    >{t`sc_card_title.subscriptions`}</p>
                )
            case 'capi_promo':
                return (
                    <p
                        style={{ color: '#736B6B', fontWeight: 'bold' }}
                    >{t`sc_card_title.subscriptions`}</p>
                )
            default:
                return ''
        }
    }

    showMenu = (e, id) => {
        e.preventDefault()

        this.setState({ isOpen: id }, () => {
            document.addEventListener('click', this.closeMenu)
            // const dropdown = document.querySelector('#menu-dropdown')
        })
    }

    closeMenu = (e, id) => {
        this.setState(
            prev => ({
                isOpen: prev.isOpen === id ? false : id,
            }),
            () => {
                document.removeEventListener('click', this.closeMenu)
            }
        )
    }

    handlePrepaidPlanCodeChange = e => {
        this.setState({ prepaidCode: e.target.value })
        this.setState({ prepaidPlanError: '' })
    }

    handleEnterKey = e => {
        const { prepaidCode } = this.state
        if (e.charCode === 13 && !!prepaidCode) {
            this.handlePrepaidPlanCall()
        }
    }

    validateDisable = () => {
        this.setState({ shouldValidateTerms: true })
    }

    handlePrepaidPlanClick = () => {
        const { prepaidCode } = this.state
        this.setState({ shouldValidateTerms: false })
        if (!prepaidCode) return
        this.handlePrepaidPlanCall()
    }

    handlePrepaidPlanCall = () => {
        const { prepaidCode } = this.state
        this.setState({ prepaidPlanError: '' })
        const apiClient = getApiClient()
        const authString = `Bearer ${getStorage('token')}`

        const url = '/api/account/skycopcare/activate'
        const { user } = this.props

        loadScreenOn()
        apiClient
            .put(
                url,
                {
                    code: prepaidCode,
                    customer: {
                        email: user.email,
                        name: user.name,
                        lastname: user.surname,
                        locale: user.locale,
                    },
                },
                { headers: { Authorization: authString } }
            )
            .then(() => {
                this.setState({ prepaidCode: '' })
                getSkycopCareSubscriptions()
            })
            .catch(error => {
                if (_get(error, 'response.data.error', null) === 'not_found') {
                    this.setState({ prepaidPlanError: t`sc_not_found.error` })
                }
                if (
                    _get(error, 'response.data.message', null) ===
                    'Registry Care can not be activated!'
                ) {
                    this.setState({
                        prepaidPlanError: t`sc_can_not_be_activated.error`,
                    })
                }
            })
            .finally(() => {
                loadScreenOff()
            })
    }

    onChangeTerms = () => {
        this.setState({ termsCheck: !this.state.termsCheck })
        this.setState({ shouldValidateTerms: false })
    }

    render() {
        const {
            isOpen,
            prepaidPlanError,
            prepaidCode,
            termsCheck,
            shouldValidateTerms,
        } = this.state
        const { trans, subscriptions, primaryColor } = this.props
        const statusIconMap = {
            active: ScActiveIcon({ color: primaryColor }),
            stopped: ScCanceledIcon,
            inactive: ScExpiredIcon,
            failed: ScPendingIcon,
        }
        if (!trans) {
            return null
        }

        const sortedSubcriptions = subscriptions.sort(
            (a, b) =>
                new Date(b.validTill).getTime() -
                new Date(a.validTill).getTime()
        )
        const isExistActiveSubscriptions = subscriptions.some(s => s.active)
        const personalSubscriptions = sortedSubcriptions.filter(s => !s.family)
        const personalSubscriptionsActive = personalSubscriptions.filter(
            s => s.active
        )
        const personalSubscriptionsArchived = personalSubscriptions.filter(
            s => !s.active
        )
        const familySubscriptions = sortedSubcriptions.filter(s => s.family)
        const familySubscriptionsActive = familySubscriptions.filter(
            s => s.active
        )
        const familySubscriptionsArchived = familySubscriptions.filter(
            s => !s.active
        )
        const noSubscriptions = !personalSubscriptions.length
        const noFamilySubscriptions = !familySubscriptions.length
        const familyMembersEditEnabled = isFeatureEnabled(
            featureToggles.sc_family_edit
        )

        return (
            <PageViewContainer>
                <Helmet>
                    <title>{`Skycop - ${t`sc_subscriptions.title`}`}</title>
                </Helmet>
                {isExistActiveSubscriptions ? (
                    <StyledProfileContainer>
                        <InfoBlock
                            title={t`benefit.skycop.care.title`}
                            className="info-block"
                        >
                            {isMobileDevice() ? (
                                <MobileCarousel
                                    childrenArray={[
                                        <BenefitsSectionCard
                                            title={t`benefit.luggage_storage.title`}
                                            subtitle={t`benefit.luggage_storage.subtitle`}
                                            content={applyTranslationElement(
                                                t`benefit.luggage_storage.content`,
                                                '%promoCode%',
                                                <strong>
                                                    {`"${t`benefit.luggage_storage.promo_code`}"`}
                                                </strong>
                                            )}
                                            link={t`benefit.luggage_storage.link`}
                                            disableMaxWidth={true}
                                        />,
                                        <BenefitsSectionCard
                                            title={t`benefit.smart_delay.title`}
                                            subtitle={t`benefit.smart_delay.subtitle`}
                                            content={t`benefit.smart_delay.content`}
                                            link={t`benefit.smart_delay.link`}
                                            disableMaxWidth={true}
                                        />,
                                    ]}
                                />
                            ) : (
                                <BenefitsContainer>
                                    <BenefitCard>
                                        <BenefitsSectionCard
                                            title={t`benefit.luggage_storage.title`}
                                            subtitle={t`benefit.luggage_storage.subtitle`}
                                            content={applyTranslationElement(
                                                t`benefit.luggage_storage.content`,
                                                '%promoCode%',
                                                <strong>
                                                    {`"${t`benefit.luggage_storage.promo_code`}"`}
                                                </strong>
                                            )}
                                            link={t`benefit.luggage_storage.link`}
                                        />
                                    </BenefitCard>
                                    <BenefitCard>
                                        <BenefitsSectionCard
                                            title={t`benefit.smart_delay.title`}
                                            subtitle={t`benefit.smart_delay.subtitle`}
                                            content={
                                                <div
                                                    title={t`benefit.smart_delay.content`}
                                                >{t`benefit.smart_delay.content`}</div>
                                            }
                                            link={t`benefit.smart_delay.link`}
                                        />
                                    </BenefitCard>
                                </BenefitsContainer>
                            )}
                        </InfoBlock>
                    </StyledProfileContainer>
                ) : (
                    <StyledProfileContainer className="promo-card">
                        <PromoCard>
                            <Col xs={12} sm={7}>
                                <Row>
                                    <h1
                                        className="main-text"
                                        dangerouslySetInnerHTML={{
                                            __html: t`sc_subscription.promo_box_title`,
                                        }}
                                    ></h1>
                                </Row>
                                <Row>
                                    <p className="description-text">{t`sc_subscription.promo_box_description`}</p>
                                </Row>
                                <Row>
                                    <DarkButton
                                        onClick={() =>
                                            browserHistory.push('/skycop-care')
                                        }
                                    >
                                        {t`sc_subscription.promo_box_button`}
                                    </DarkButton>
                                </Row>
                            </Col>
                            <Col xs={12} sm={5}>
                                <img
                                    width="100%"
                                    src="./images/sc_care_img.png"
                                    alt="skycop care promo"
                                />
                            </Col>
                        </PromoCard>
                    </StyledProfileContainer>
                )}
                <StyledProfileContainer>
                    <InfoBlock
                        title={t`sc_subscriptions.person_title`}
                        className={`info-block ${
                            noSubscriptions ? 'no-items' : ''
                        }`}
                        handleClick={
                            noSubscriptions
                                ? null
                                : () => browserHistory.push('/skycop-care')
                        }
                        buttonText={t`sc_subscriptions.new_plan_button`}
                    >
                        {noSubscriptions ? (
                            <NoPlansBlock
                                text={t`sc_subscriptions.no_subscriptions_person`}
                                buttonText={t`sc_subscriptions.no_subscriptions_persons_button_text`}
                                handleClick={() =>
                                    browserHistory.push('/skycop-care')
                                }
                            />
                        ) : (
                            <>
                                {!!personalSubscriptionsActive.length && (
                                    <>
                                        <StatusSection
                                            active
                                            text={t`sc_subscription.section_active`}
                                        />
                                        {personalSubscriptionsActive.map(
                                            (sub, i) => {
                                                return (
                                                    <SubscriptionCard
                                                        key={i}
                                                        id={i}
                                                        sub={sub}
                                                        isOpen={isOpen}
                                                        icon={
                                                            statusIconMap[
                                                                sub.status
                                                            ]
                                                        }
                                                        mapStatusTexts={
                                                            this.mapStatusTexts
                                                        }
                                                        showMenu={this.showMenu}
                                                        primaryColor={
                                                            primaryColor
                                                        }
                                                    />
                                                )
                                            }
                                        )}
                                    </>
                                )}
                                {!!personalSubscriptionsArchived.length && (
                                    <>
                                        <StatusSection
                                            active={false}
                                            text={t`sc_subscription.section_archived`}
                                        />
                                        {personalSubscriptionsArchived.map(
                                            (sub, i) => {
                                                return (
                                                    <SubscriptionCard
                                                        key={i}
                                                        id={i}
                                                        sub={sub}
                                                        isOpen={isOpen}
                                                        icon={
                                                            statusIconMap[
                                                                sub.status
                                                            ]
                                                        }
                                                        mapStatusTexts={
                                                            this.mapStatusTexts
                                                        }
                                                        showMenu={this.showMenu}
                                                        primaryColor={
                                                            primaryColor
                                                        }
                                                    />
                                                )
                                            }
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </InfoBlock>
                </StyledProfileContainer>
                <StyledProfileContainer>
                    <InfoBlock
                        title={t`sc_subscriptions.family_title`}
                        className={`info-block ${
                            noFamilySubscriptions ? 'no-items' : ''
                        }`}
                        handleClick={
                            noFamilySubscriptions
                                ? null
                                : () =>
                                      browserHistory.push(
                                          '/skycop-care?family=1'
                                      )
                        }
                        buttonText={t`sc_subscriptions.new_family_plan_button`}
                    >
                        {noFamilySubscriptions ? (
                            <NoPlansBlock
                                text={t`sc_subscriptions.no_subscriptions_family`}
                                buttonText={t`sc_subscriptions.no_subscriptions_family_plan_button`}
                                handleClick={() =>
                                    browserHistory.push('/skycop-care?family=1')
                                }
                            />
                        ) : (
                            <>
                                {!!familySubscriptionsActive.length && (
                                    <>
                                        <StatusSection
                                            active
                                            text={t`sc_subscription.section_active`}
                                        />
                                        {familySubscriptionsActive.map(
                                            (sub, i) => {
                                                return (
                                                    <SubscriptionCard
                                                        enabledEditing={
                                                            familyMembersEditEnabled
                                                        }
                                                        key={i}
                                                        id={i}
                                                        sub={sub}
                                                        isOpen={isOpen}
                                                        icon={
                                                            statusIconMap[
                                                                sub.status
                                                            ]
                                                        }
                                                        mapStatusTexts={
                                                            this.mapStatusTexts
                                                        }
                                                        showMenu={this.showMenu}
                                                        primaryColor={
                                                            primaryColor
                                                        }
                                                    />
                                                )
                                            }
                                        )}
                                    </>
                                )}
                                {!!familySubscriptionsArchived.length && (
                                    <>
                                        <StatusSection
                                            active={false}
                                            text={t`sc_subscription.section_archived`}
                                        />
                                        {familySubscriptionsArchived.map(
                                            (sub, i) => {
                                                return (
                                                    <SubscriptionCard
                                                        enabledEditing={
                                                            familyMembersEditEnabled
                                                        }
                                                        key={i}
                                                        id={i}
                                                        sub={sub}
                                                        isOpen={isOpen}
                                                        icon={
                                                            statusIconMap[
                                                                sub.status
                                                            ]
                                                        }
                                                        mapStatusTexts={
                                                            this.mapStatusTexts
                                                        }
                                                        showMenu={this.showMenu}
                                                        primaryColor={
                                                            primaryColor
                                                        }
                                                    />
                                                )
                                            }
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </InfoBlock>
                </StyledProfileContainer>
                <StyledProfileContainer className="prepaid-block">
                    <Row>
                        <Col>
                            <ScContainer>
                                <InputField
                                    sm={6}
                                    xs={12}
                                    value={prepaidCode}
                                    inlineColStyle={{
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        maxWith: 250,
                                    }}
                                    name="prepaid-plan-code"
                                    id="prepaid-plan-code"
                                    label={
                                        <ScInputLabel
                                            isMobile={isMobileDevice()}
                                        >{t`sc_input_subscriptions.title`}</ScInputLabel>
                                    }
                                    placeholder="XXXXXXXX"
                                    onChange={this.handlePrepaidPlanCodeChange}
                                    onKeyPress={this.handleEnterKey}
                                    errorText={prepaidPlanError}
                                />
                            </ScContainer>
                            <Row>
                                <ScCodeTermsContainer>
                                    <TermsAndConditions
                                        text={t`sc.checkbox.terms_and_conditions_price_list`}
                                        onChange={this.onChangeTerms}
                                        checked={termsCheck}
                                        alert={
                                            !termsCheck && shouldValidateTerms
                                        }
                                    />
                                </ScCodeTermsContainer>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ScApplyButtonContainer>
                                <ScButton
                                    disabled={!termsCheck}
                                    onClick={this.handlePrepaidPlanClick}
                                    disabledClick={this.validateDisable}
                                >
                                    {t`sc_apply_subscriptions.button`}
                                </ScButton>
                            </ScApplyButtonContainer>
                        </Col>
                    </Row>
                </StyledProfileContainer>
            </PageViewContainer>
        )
    }
}

function formatDate(date) {
    return date ? moment(date).format('YYYY-MM-DD') : ''
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        authenticated: state.auth.authenticated,
        user: state.user,
        subscriptions: state.subscriptions,
        promoCode: state.v2_promoCodes.errorModalPromoCode,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(ScSubscriptions)
