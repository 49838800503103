import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import t from '../../../../shared/translations'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import { PageContent, Row, Col } from '../../../../shared/components/V2/Layout'
import { MainContainer } from '../../../other/ClaimAgreementLead/styles'
import styled from 'styled-components'
import InputField from '../../../../shared/components/V2/InputField'
import { bindActionCreators } from 'redux'
import CountryInput from '../CountryInput'
import CurrencyInput from '../CurrencyInput'
import { V2Button } from '../../../../shared/components/V2/V2Buttons'
import { featureToggles, modalTypes } from '../../../../shared/constants'
import { showModal } from '../../../../shared/actions/v2_actions'
import SubmitModal from './SubmitModal'
import { values, isEmpty, includes } from 'lodash'
import {
    getCurrencies,
    getUserData,
    getClaimOffer,
    acceptClaimOffer,
} from '../../../../shared/actions'
import {
    getApiClient,
    loadScreenOn,
    applyTranslationElement,
    isFeatureEnabled,
} from '../../../../main'
import InfoModal from './InfoModal'
import CountryRegionInput from '../CountryRegionInput'

let selectErrors = {}

const InfoBlockContentTitle = styled.div`
    margin-bottom: 16px;
    padding: 8px 12px;
    text-align: left;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    color: #333;
`

const Title = styled.div`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 28px;
    color: #353d51;
    text-align: center;
`

const CountryInputCol = styled(Col)`
    div.form-group {
        margin-bottom: 0;
    }
`

const CurrencyInputCol = styled(Col)`
    div.form-group {
        margin-bottom: 0;
    }
`

class PayoutOfferSendout extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ibanLabel: 'payment_page.iban',
            isAccepted: false,
            bankCountry: '',
            currency: '',
            bankTitle: '',
            bankAddress: '',
            firstName: '',
            lastName: '',
            iban: '',
            swift: '',
            offerPercentage: null,
            payoutOfferId: null,
            isCountrySupportIban: true,
            ibanValid: true,
            receiverAddress: '',
            receiverCity: '',
            receiverCountry: '',
            receiverPostalCode: '',
            receiverRegion: '',
            requiredFieldsBasedOnReceiverCountry: [],
            regionOptions: [],
        }
    }

    componentDidMount() {
        const {
            getCurrencies,
            getUserData,
            getClaimOffer,
            showModal,
        } = this.props
        const urlParams = new URLSearchParams(window.location.search)
        loadScreenOn()
        getCurrencies()
        const claimId = urlParams.get('claim_id')
        if (claimId) {
            getClaimOffer(claimId).then(res => {
                if (!res.error) {
                    this.setState({ offerPercentage: res.percentages })
                    this.setState({ payoutOfferId: res.payoutOfferId })
                } else {
                    showModal(modalTypes.payoutOfferInfoModal, {
                        message: res.message,
                    })
                }
            })
        } else {
            browserHistory.push('/')
        }

        getUserData().then(response => {
            if (response) {
                const mappedUserData = this.mapUser(response)
                this.setState(
                    prevState => ({
                        ...prevState,
                        ...mappedUserData,
                    }),
                    () => {
                        const { receiverCountry } = this.state

                        if (receiverCountry) {
                            this.getRequiredFieldsBasedOnCountry(
                                receiverCountry
                            )
                            this.getRegionsBasedOnCountrySelect(
                                receiverCountry,
                                true
                            )
                        }
                    }
                )
            }
        })
    }

    mapUser(data) {
        return {
            firstName: _get(data, 'user_payment.first_name', ''),
            lastName: _get(data, 'user_payment.last_name', ''),
            bankCountry: _get(data, 'user_payment.bank_country.id', ''),
            currency: _get(data, 'user_payment.currency_country.id', ''),
            bankTitle: _get(data, 'user_payment.bank_name', ''),
            bankAddress: _get(data, 'user_payment.bank_address', ''),
            iban: _get(data, 'user_payment.iban', ''),
            swift: _get(data, 'user_payment.swift', ''),
            receiverAddress: _get(data, 'user_payment.receiver_address', ''),
            receiverCity: _get(data, 'user_payment.receiver_city', ''),
            receiverPostalCode: _get(
                data,
                'user_payment.receiver_postal_code',
                ''
            ),
            receiverRegion: _get(
                data,
                'user_payment.receiver_country_region_id',
                ''
            ),
        }
    }

    checkRequired = (field, translation) => {
        if (!this.state[field] || this.state[field].trim() === '') {
            selectErrors[field] = translation
        }
    }

    validateSubmit = () => {
        const {
            iban,
            bankCountry,
            bankTitle,
            swift,
            currency,
            firstName,
            lastName,
            isCountrySupportIban,
            ibanValid,
        } = this.state
        selectErrors = {}
        if (
            bankCountry ||
            bankTitle ||
            swift ||
            currency ||
            firstName ||
            lastName ||
            iban
        ) {
            if (!iban || (isCountrySupportIban && !ibanValid)) {
                selectErrors['iban'] = t`invalid.iban.number`
            }
            if (firstName && firstName.match('[^a-zA-Z\\s]')) {
                selectErrors[
                    'firstName'
                ] = t`common.label.is_required.latin_only`
            }

            if (lastName && lastName.match('[^a-zA-Z\\s]')) {
                selectErrors[
                    'lastName'
                ] = t`common.label.is_required.latin_only`
            }

            this.validateSwift()
        }

        this.setState({ errors: selectErrors.length > 0 })
    }

    isDisabled = () => {
        const {
            bankCountry,
            currency,
            bankTitle,
            firstName,
            lastName,
            iban,
            swift,
            requiredFieldsBasedOnReceiverCountry,
            receiverAddress,
            receiverCity,
            receiverCountry,
            receiverPostalCode,
            receiverRegion,
        } = this.state

        const isReceiverAddressRequired =
            includes(requiredFieldsBasedOnReceiverCountry, 'receiverAddress') &&
            !receiverAddress
        const isReceiverCityRequired =
            includes(requiredFieldsBasedOnReceiverCountry, 'receiverCity') &&
            !receiverCity
        const isReceiverPostalCodeRequired =
            includes(
                requiredFieldsBasedOnReceiverCountry,
                'receiverPostalCode'
            ) && !receiverPostalCode
        const isReceiverRegionRequired =
            includes(requiredFieldsBasedOnReceiverCountry, 'receiverRegion') &&
            !receiverRegion

        if (
            !bankCountry ||
            !currency ||
            !bankTitle ||
            !firstName ||
            !lastName ||
            !iban ||
            !swift ||
            !values(selectErrors).every(isEmpty) ||
            isReceiverAddressRequired ||
            isReceiverCityRequired ||
            !receiverCountry ||
            isReceiverPostalCodeRequired ||
            isReceiverRegionRequired
        ) {
            return true
        }
        return false
    }

    handleSubmit = () => {
        const { showModal, lang, acceptClaimOffer } = this.props
        const {
            bankCountry,
            currency,
            bankTitle,
            bankAddress,
            firstName,
            lastName,
            iban,
            swift,
            payoutOfferId,
            requiredFieldsBasedOnReceiverCountry,
            receiverAddress,
            receiverCity,
            receiverCountry,
            receiverPostalCode,
            receiverRegion,
        } = this.state

        this.validateSubmit()

        if (!values(selectErrors).every(isEmpty)) return

        this.setState({ isAccepted: true })
        const fd = new FormData()

        fd.append('user_payment[iban]', iban)
        fd.append('user_payment[swift]', swift)
        fd.append('user_payment[firstName]', firstName)
        fd.append('user_payment[lastName]', lastName)
        fd.append('user_payment[bankName]', bankTitle)
        fd.append('user_payment[bankAddress]', bankAddress)
        fd.append('user_payment[bankCountry]', bankCountry)
        fd.append('user_payment[currencyCountry]', currency)

        fd.append('user_payment[receiverCountry]', receiverCountry)
        if (includes(requiredFieldsBasedOnReceiverCountry, 'receiverAddress')) {
            fd.append('user_payment[receiverAddress]', receiverAddress)
        }
        if (includes(requiredFieldsBasedOnReceiverCountry, 'receiverCity')) {
            fd.append('user_payment[receiverCity]', receiverCity)
        }
        if (includes(requiredFieldsBasedOnReceiverCountry, 'receiverRegion')) {
            fd.append('user_payment[receiverRegion]', receiverRegion)
        }
        if (
            includes(requiredFieldsBasedOnReceiverCountry, 'receiverPostalCode')
        ) {
            fd.append('user_payment[receiverPostalCode]', receiverPostalCode)
        }

        acceptClaimOffer(payoutOfferId, fd, lang).then(res => {
            if (res.isSuccess === true) {
                showModal(modalTypes.payoutOfferSubmitModal)
            } else if (!res.isFormErrors) {
                showModal(modalTypes.payoutOfferInfoModal, {
                    message: res.message,
                })
            } else if (res.isFormErrors) {
                selectErrors = res.form
                    ? Object.keys(res.form).reduce((acc, curr) => {
                          return {
                              ...acc,
                              [curr]: res.form[curr][0],
                          }
                      }, {})
                    : {}
                this.setState({ isAccepted: true })
            }
        })
    }

    handleReject = () => {
        const { showModal } = this.props
        this.setState({ isAccepted: false })
        showModal(modalTypes.payoutOfferSubmitModal)
    }

    validateSuggestedIban = value => {
        if (!isFeatureEnabled(featureToggles.featureIsBankPrefillEnabled))
            return

        const apiClient = getApiClient()
        const url = '/api/iban/info'

        apiClient
            .post(url, { iban: value })
            .then(({ data }) => {
                if (data.bic) {
                    this.setState({
                        swift: data.bic,
                    })
                }
                if (data.bankTitle) {
                    this.setState({
                        bankTitle: data.bankTitle,
                    })
                }
                if (data.bankAddress) {
                    this.setState({
                        bankAddress: data.bankAddress,
                    })
                }
            })
            .catch(e => console.error(e))
    }

    validateIban = target => {
        const { isCountrySupportIban } = this.state
        const apiClient = getApiClient()
        const url = '/api/validate/iban'
        const fd = new FormData()

        fd.append('iban', target.value)
        apiClient
            .post(url, fd)
            .then(res => {
                this.setState({ needManualValidation: false, ibanValid: true })
                if (res.data.status === 'valid') {
                    delete selectErrors[target.name]
                }
                if (
                    isCountrySupportIban &&
                    (res.data.status === 'invalid' ||
                        res.data.status === 'invalid_country')
                ) {
                    selectErrors[target.name] = t`invalid.iban.number`
                    this.setState({ ibanValid: false })
                }
                if (
                    !isCountrySupportIban &&
                    res.data.status === 'invalid_country'
                ) {
                    this.setState({
                        needManualValidation: true,
                        ibanValid: false,
                    })
                }

                this.setState({ errors: selectErrors.length > 0 })
            })
            .catch(err => {
                console.warn(err)
            })
    }

    accountLabel = () => {
        return `${t`${this.state.ibanLabel}`}`
    }

    checkBankCountry = countryCode => {
        const url = '/api/validate/iban-country'
        const apiClient = getApiClient()
        const fd = new FormData()
        fd.append('country_code', countryCode)
        apiClient
            .post(url, fd)
            .then(res => {
                this.setState({
                    ibanLabel:
                        res.data.status === 'valid'
                            ? 'payment_page.iban'
                            : 'payment_page.account',
                    isCountrySupportIban: res.data.status === 'valid',
                    needManualValidation: res.data.status !== 'valid',
                })
            })
            .catch(err => {
                console.warn(err)
            })
    }

    validateSwift = () => {
        const { swift } = this.state

        if (!isFeatureEnabled(featureToggles.isSwiftValidationEnabled)) return

        if (!(swift.length > 7 && swift.length < 12)) {
            selectErrors['swift'] = t`commons.label.swift_code_length`
            return
        }

        const onlyLettersAndNumbers = /^\w+$/
        if (!onlyLettersAndNumbers.test(swift)) {
            selectErrors['swift'] = t`commons.label.swift_code_only_latin`
            return
        } else {
            selectErrors['swift'] = null
            return
        }
    }

    handleChangeEvent = e => {
        const { ibanValid, isCountrySupportIban } = this.state
        if (!e) return

        const target = e.target || e

        if ('bankCountry' === target.name) {
            this.checkBankCountry(target.code)
        }

        selectErrors[target.name] = null
        const onlyLetters = /^[a-zA-Z\s]*$/
        const targetValue = target.value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ +(?= )/g, '')
            .replace(/[^a-zA-Z\d\s]/g, '')

        if (
            target.name !== 'iban' &&
            target.name !== 'swift' &&
            target.name !== 'bankCountry' &&
            target.name !== 'currency' &&
            target.name !== 'bankTitle' &&
            target.name !== 'bankAddress' &&
            target.name !== 'receiverCountry' &&
            target.name !== 'receiverAddress' &&
            target.name !== 'receiverCity' &&
            target.name !== 'receiverRegion' &&
            target.name !== 'receiverPostalCode'
        ) {
            if (onlyLetters.test(targetValue)) {
                selectErrors[target.name] = null
                this.setState({
                    [target.name]: targetValue,
                })
            } else {
                selectErrors[
                    target.name
                ] = t`common.label.is_required.latin_only`
            }
        }

        if (
            target.name === 'bankCountry' ||
            target.name === 'currency' ||
            target.name === 'bankTitle' ||
            target.name === 'bankAddress'
        ) {
            this.setState({
                [target.name]: target.value,
            })
            return
        }

        if (
            (target.name === 'swift' || target.name === 'iban') &&
            isFeatureEnabled(featureToggles.isSwiftValidationEnabled)
        ) {
            this.setState({
                [target.name]: targetValue
                    .toUpperCase()
                    .replace(/[^a-zA-Z0-9]/g, ''),
            })
        } else {
            this.setState({
                [target.name]: targetValue,
            })
        }

        if (isCountrySupportIban && !ibanValid) {
            selectErrors['iban'] = t`invalid.iban.number`
        }

        if (
            target.name !== 'receiverCountry' ||
            target.name !== 'receiverAddress' ||
            target.name !== 'receiverCity' ||
            target.name !== 'receiverRegion' ||
            target.name !== 'receiverPostalCode'
        ) {
            this.setState({
                [target.name]: target.value,
            })
            if (target.name === 'receiverCountry') {
                this.getRegionsBasedOnCountrySelect(target.value)
                this.getRequiredFieldsBasedOnCountry(target.value)
            }
            return
        }
    }

    getRegionsBasedOnCountrySelect = (value, init) => {
        const apiClient = getApiClient()
        if (!init) {
            this.setState({
                receiverRegion: '',
                receiverAddress: '',
                receiverCity: '',
                receiverPostalCode: '',
            })
        }
        apiClient
            .get(`/api/country/${value}/regions`)
            .then(({ data }) => {
                const tempRegions = data.reduce((acc, current) => {
                    acc.push({
                        id: current.id,
                        label: current.title,
                        name: 'receiverRegion',
                        value: current.id,
                    })
                    return acc
                }, [])
                this.setState({
                    regionOptions: tempRegions,
                })
            })
            .catch(e => console.error(e))
    }

    getRequiredFieldsBasedOnCountry = value => {
        const apiClient = getApiClient()
        apiClient
            .get(`/api/country/${value}/payment-fields`)
            .then(({ data }) => {
                this.setState({ requiredFieldsBasedOnReceiverCountry: data })
            })
            .catch(e => console.error(e))
    }

    clearBankCountry = () => {
        this.setState({
            bankCountry: '',
            isCountrySupportIban: true,
            needManualValidation: false,
        })
    }

    clearAccountCurrency = () => {
        this.setState({ currency: '' })
    }

    render() {
        const { trans } = this.props
        const {
            isAccepted,
            bankTitle,
            bankAddress,
            bankCountry,
            iban,
            currency,
            firstName,
            lastName,
            swift,
            offerPercentage,
            receiverAddress,
            requiredFieldsBasedOnReceiverCountry,
            receiverCountry,
            regionOptions,
            receiverRegion,
            receiverPostalCode,
            receiverCity,
        } = this.state

        if (!trans) return null

        return (
            <MainContainer>
                <PageContent>
                    <Title>
                        {applyTranslationElement(
                            t`payout.page.title`,
                            '{{ payoutPercentage }}',
                            offerPercentage
                        )}
                    </Title>
                    <Row className="h-mt40">
                        <InfoBlockContentTitle>{t`payment_page.payment_information.bank.details`}</InfoBlockContentTitle>
                    </Row>
                    <Row className="h-mb-8">
                        <CountryInputCol xs={12} md={12} lg={6}>
                            <CountryInput
                                name="bankCountry"
                                label={t`payment_page.bank_country`}
                                placeholder={t`payment_page.placeholder.country`}
                                value={bankCountry}
                                defaultValue={bankCountry || 'lt'}
                                onChange={this.handleChangeEvent}
                                errorText={selectErrors['bankCountry']}
                                clear={this.clearBankCountry}
                                autoComplete="bankCountry"
                                required
                            />
                        </CountryInputCol>
                        <CurrencyInputCol xs={12} md={12} lg={6}>
                            <CurrencyInput
                                value={currency}
                                onChange={e => {
                                    this.handleChangeEvent(e)
                                }}
                                clear={this.clearAccountCurrency}
                                name="currency"
                                placeholder={t`payment_page.placeholder.currency`}
                                label={t`payment_page.currency`.replace(
                                    '*',
                                    ''
                                )}
                                errorText={selectErrors['currency']}
                                id="currency"
                                required
                            />
                        </CurrencyInputCol>
                    </Row>
                    <Row className="h-mb-8">
                        <InputField
                            name="iban"
                            label={this.accountLabel()}
                            placeholder={t`payment_page.placeholder.iban`}
                            value={iban}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['iban']}
                            autoComplete="iban"
                            required
                            onBlur={e =>
                                this.validateSuggestedIban(e.target.value)
                            }
                        />
                        <InputField
                            name="swift"
                            label={t`payment_page.swift`}
                            placeholder={t`payment_page.placeholder.swift`}
                            value={swift}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['swift']}
                            autoComplete="swift"
                            required
                        />
                    </Row>
                    <Row>
                        <InputField
                            name="bankTitle"
                            label={t`payment_page.bank_name`}
                            placeholder={t`payment_page.placeholder.bank_name`}
                            value={bankTitle}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['bankTitle']}
                            autoComplete="bankTitle"
                            required
                        />
                        <InputField
                            xs={12}
                            md={6}
                            sm={6}
                            lg={6}
                            name="bankAddress"
                            label={t`payment_page.bank_address`}
                            placeholder={t`payment_page.placeholder.bank_address`}
                            value={bankAddress}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['bankAddress']}
                        />
                    </Row>

                    <Row className="h-mt20">
                        <InfoBlockContentTitle>{t`payment_page.payment_information.recipient_information`}</InfoBlockContentTitle>
                    </Row>
                    <Row>
                        <InputField
                            name="firstName"
                            label={t`v2_common.label.name`}
                            placeholder={t`v2_common.placeholder.name`}
                            value={firstName}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['firstName']}
                            autoComplete="firstName"
                            required
                        />
                        <InputField
                            name="lastName"
                            label={t`v2_common.label.surname`}
                            placeholder={t`v2_common.placeholder.surname`}
                            value={lastName}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['lastName']}
                            autoComplete="lastName"
                            required
                        />
                    </Row>
                    <Row>
                        {includes(
                            requiredFieldsBasedOnReceiverCountry,
                            'receiverAddress'
                        ) && (
                            <InputField
                                name="receiverAddress"
                                label={t`v2_common.label.address`}
                                placeholder={t`common.label.is_required.address`}
                                value={receiverAddress}
                                onChange={this.handleChangeEvent}
                                required
                                errorText={selectErrors['receiverAddress']}
                                id="receiver-address"
                            />
                        )}
                        {includes(
                            requiredFieldsBasedOnReceiverCountry,
                            'receiverCity'
                        ) && (
                            <InputField
                                name="receiverCity"
                                label={t`v2_common.label.city`}
                                placeholder={t`v2_common.placeholder.city`}
                                value={receiverCity}
                                onChange={this.handleChangeEvent}
                                required
                                errorText={selectErrors['receiverCity']}
                                id="receiver-city"
                            />
                        )}
                    </Row>
                    <Row>
                        <CountryInputCol xs={12} md={6} lg={6}>
                            <CountryInput
                                value={receiverCountry}
                                defaultValue={receiverCountry}
                                onChange={this.handleChangeEvent}
                                name="receiverCountry"
                                required
                                clearable={false}
                                placeholder={t`payment_page.placeholder.country`}
                                label={t`v2_common.label.country`}
                                errorText={selectErrors['receiverCountry']}
                                id="bank-receiver-country-id"
                            />
                        </CountryInputCol>
                        {includes(
                            requiredFieldsBasedOnReceiverCountry,
                            'receiverPostalCode'
                        ) && (
                            <InputField
                                name="receiverPostalCode"
                                label={t`common.label.postcode`}
                                placeholder={t`common.label.is_required.postcode`}
                                value={receiverPostalCode}
                                onChange={this.handleChangeEvent}
                                required
                                errorText={selectErrors['receiverPostalCode']}
                                id="receiver-postal-code"
                            />
                        )}
                    </Row>
                    {includes(
                        requiredFieldsBasedOnReceiverCountry,
                        'receiverRegion'
                    ) && (
                        <Row>
                            <Col xs={12} md={6} lg={6}>
                                <CountryRegionInput
                                    value={receiverRegion}
                                    defaultValue={receiverRegion}
                                    onChange={this.handleChangeEvent}
                                    regionOptions={regionOptions}
                                    name="receiverRegion"
                                    required
                                    clearable={false}
                                    errorText={selectErrors['receiverRegion']}
                                    id="bank-receiver-region"
                                />
                            </Col>
                        </Row>
                    )}
                    <Row
                        className="h-mt40"
                        style={{ justifyContent: 'center' }}
                    >
                        <Col md={4} lg={4}>
                            <V2Button
                                onClick={this.handleSubmit}
                                disabled={this.isDisabled()}
                            >
                                {t`payout.accept.btn`}
                            </V2Button>
                        </Col>
                        <Col md={4} lg={4}>
                            <V2Button
                                type="borderBtn"
                                onClick={this.handleReject}
                            >
                                {t`payout.decline.btn`}
                            </V2Button>
                        </Col>
                    </Row>
                </PageContent>
                <SubmitModal isAccepted={isAccepted} />
                <InfoModal />
            </MainContainer>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showModal,
            getCurrencies,
            getUserData,
            getClaimOffer,
            acceptClaimOffer,
        },
        dispatch
    )
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        currencies: state.currencies,
        countries: state.countries,
        lang: state.language,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutOfferSendout)
