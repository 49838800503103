import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _get from 'lodash/get'
import { isValidEmail, isProduction } from '../../../main'
import {
    hideModal,
    saveClaimDraft,
    sendClaimDraftEmail,
    clearClaimDraftErrors,
    logDraftAction,
} from '../../../shared/actions/v2_actions'
import t from '../../../shared/translations'
import InputField from '../../../shared/components/V2/InputField'
import Modal from '../../../shared/components/V2/Modal'
import TermsAndConditions from '../components/TermsAndConditions'
import { CloseButton, V2Button } from '../../../shared/components/V2/V2Buttons'
import { CopyIcon, V2CheckBubble } from '../../../shared/components/V2/V2Icons'
import { Row, Col } from '../../../shared/components/V2/Layout'
import {
    desktop,
    tablet,
} from '../../../shared/components/helpers/styled_queries'
import {
    modalTypes,
    draftLogActions,
    draftLogActionReasons,
} from '../../../shared/constants'
import { emailMagic } from '../../../shared/validators'
import { validateEmailSmtp, logKibana } from '../../../shared/actions'

const Container = styled.div`
    padding: 4px;

    ${desktop`
        padding: 20px;
    `}

    ${tablet`
        .item-col {
            margin: auto;
        }
    `}
`

const H1 = styled.h1`
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #353d51;
`

const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 20px 0;
    position: relative;

    .bgImage {
        width: 56px;
        height: 56px;
        background: url('/icons/v2-save-icon.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
`

const Text = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #7f8fa4;

    span {
        font-weight: bold;
        color: #353d51;
    }
`

const LinkContainer = styled.div`
    width: 100%;
    height: 112px;
    border-radius: 6px;
    background-color: #f3f5f7;
    position: relative;
`

const LinkText = styled.textarea`
    padding: 16px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #7f8fa4;
    border: none;
    background: none;
    width: 100%;
    height: 112px;
    min-height: unset;
    resize: none;
    box-shadow: none;

    &:focus {
        outline: none;
    }
`

const CopyLink = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 16px;
    bottom: 16px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #353d51;
    cursor: pointer;

    svg {
        margin-right: 10px;
    }
`

const InfoBox = styled.div`
    position: absolute;
    top: -64px;
    margin-top: 12px;
    white-space: nowrap;
    background-color: #353d51;
    border-radius: 8px;
    right: -5px;
    color: #fff;
    padding: 8px 20px;
    font-size: 14px;

    &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        left: 87%;
        top: 100%;
        border-radius: 3px;
        width: 14px;
        background-color: #353d51;
        margin: -8px -7px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        height: 14px;
    }
`

const BtnContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;

    ${desktop`
            button {
                width: unset;
            }
        `}
`

const CheckMark = styled.div`
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: 0;
    right: calc(50% - 35px);
`

const EmailContainer = styled.div`
    max-width: 100%;
    overflow: hidden;
    display: inline-block;
    border-radius: 8px;
    background-color: #f3f5f7;
    padding: 21px 45px;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 24px;
    word-break: break-all;
`

const EmailRow = styled.div`
    display: flex;
    justify-content: center;
`

class ClaimDraftModal extends React.Component {
    state = {
        email: _get(this, 'props.user.email', ''),
        termsConditions: false,
        savedDraft: false,
        showCopiedTooltip: false,
    }

    onChange = e => {
        const { name, value } = e.target

        if (isValidEmail(value)) {
            this.setState({ [name]: value, error: false })
        } else {
            this.setState({
                [name]: value,
                error: t`common.label.email_invalid`,
            })
        }
    }

    handleTermsChange = () => {
        this.setState({ termsConditions: !this.state.termsConditions })
    }

    getDraftLink = () => {
        const { uuid } = this.props

        if (uuid) {
            const prodUrl = 'https://claim.skycop.com'
            const stageUrl = 'https://app.skycop.com'
            return `${
                isProduction()
                    ? prodUrl
                    : _get(window, 'location.origin', stageUrl)
            }/?draftId=${uuid}`
        }
    }

    handleSaveDraft = () => {
        const {
            saveClaimDraft,
            logDraftAction,
            sendClaimDraftEmail,
            uuid,
            claimDraftProps,
            logKibana,
        } = this.props
        const { email } = this.state

        saveClaimDraft(uuid, claimDraftProps)
            .then(() => {
                logDraftAction(
                    false,
                    draftLogActions.SAVE,
                    draftLogActionReasons.MANUAL,
                    _get(window, 'location.pathname', '/')
                )
                sendClaimDraftEmail(uuid, email)
            })
            .catch(err => console.log(err))

        logKibana(
            'draft_saved',
            _get(window, 'location.pathname', '/'),
            'warning'
        )

        this.setState({
            savedDraft: true,
        })
    }

    copyLink = () => {
        const element = document.getElementById('draft-url')
        element.select()
        document.execCommand('copy')
        this.showTooltip()
        window && window.getSelection && window.getSelection().removeAllRanges()
    }

    handleClose = () => {
        const { hideModal, clearClaimDraftErrors } = this.props

        this.setState({
            savedDraft: false,
            email: '',
            termsConditions: false,
        })
        clearClaimDraftErrors()
        hideModal()
    }

    showTooltip = () => {
        this.setState({ showCopiedTooltip: true }, () => {
            setTimeout(() => {
                this.setState({ showCopiedTooltip: false })
            }, 2000)
        })
    }

    renderEmail = () => {
        const { claimDraftErrors } = this.props
        const { email } = this.state

        if (
            !claimDraftErrors.failedEmailSent &&
            claimDraftErrors.claimDraftPosted
        ) {
            return (
                <React.Fragment>
                    <Col>
                        <Text>{t`v2_claim.draft.link_was_sent`}</Text>
                    </Col>
                    <EmailRow>
                        <EmailContainer>{email}</EmailContainer>
                    </EmailRow>
                </React.Fragment>
            )
        }
    }

    render() {
        const { passengerSmtp, primaryColor } = this.props
        const {
            email,
            termsConditions,
            savedDraft,
            error,
            showCopiedTooltip,
        } = this.state

        return (
            <Modal
                width="476px"
                height="auto"
                modalType={modalTypes.claimDraftModal}
                handleClose={this.handleClose}
            >
                <Container>
                    <CloseButton onClick={this.handleClose} />
                    <Row>
                        <ImgContainer>
                            <div className="bgImage" />
                            {savedDraft && (
                                <CheckMark>
                                    <V2CheckBubble width="24" height="24" />
                                </CheckMark>
                            )}
                        </ImgContainer>
                    </Row>
                    {savedDraft ? (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <H1>{t`v2_claim.draft.claim_was_saved`}</H1>
                                </Col>
                            </Row>
                            {this.renderEmail()}
                            <Col>
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: t`v2_claim.draft.saved_draft_text`,
                                    }}
                                />
                            </Col>
                            <Col>
                                <LinkContainer>
                                    <LinkText
                                        id="draft-url"
                                        value={this.getDraftLink()}
                                        readOnly
                                    />
                                    <CopyLink onClick={this.copyLink}>
                                        <div>
                                            <div>
                                                <CopyIcon
                                                    color={primaryColor}
                                                />
                                                {t`v2_claim.common.copy_link`}
                                            </div>
                                            {showCopiedTooltip && (
                                                <InfoBox>
                                                    {t`v2_common.copied_to_clipboard`}
                                                </InfoBox>
                                            )}
                                        </div>
                                    </CopyLink>
                                </LinkContainer>
                            </Col>
                            <Col>
                                <BtnContainer>
                                    <V2Button
                                        onClick={this.handleClose}
                                    >{t`signature_form.button.become_member`}</V2Button>
                                </BtnContainer>
                            </Col>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <H1>{t`v2_claim_draft.save_for_later`}</H1>
                                </Col>
                            </Row>
                            <Col>
                                <Text>
                                    {t`v2_claim.draft.text_part1`}
                                    <br />
                                    <br />
                                </Text>
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: t`v2_claim.draft.text_part2`,
                                    }}
                                />
                            </Col>
                            <InputField
                                sm={12}
                                md={12}
                                lg={12}
                                label={t`v2_common.label.email`}
                                placeholder={t`v2_common.placeholder.email`}
                                name="email"
                                value={email}
                                onChange={this.onChange}
                                errorText={error || ''}
                                suggestedText={{
                                    text: emailMagic(email),
                                    callback: () => {
                                        this.onChange({
                                            target: {
                                                name: 'email',
                                                value: emailMagic(email),
                                            },
                                        })
                                        validateEmailSmtp(emailMagic(email))
                                    },
                                }}
                                onBlur={() =>
                                    validateEmailSmtp(email, 'userDraftEmail')
                                }
                                isValidSmtp={!passengerSmtp['userDraftEmail']}
                                hasSmtpValidation={true}
                            />
                            <Col>
                                <TermsAndConditions
                                    text={t`v2_claim.register.terms_and_conditions`}
                                    onChange={this.handleTermsChange}
                                    checked={termsConditions}
                                />
                            </Col>
                            <Col>
                                <BtnContainer>
                                    <V2Button
                                        onClick={this.handleSaveDraft}
                                        disabled={
                                            !email ||
                                            !termsConditions ||
                                            !!error
                                        }
                                    >
                                        {t`v2_claim.draft.save`}
                                    </V2Button>
                                </BtnContainer>
                            </Col>
                        </React.Fragment>
                    )}
                </Container>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        lang: state.language,
        uuid: state.claim_UUID,
        claim: state.V2Claim,
        claimDraftProps: state.claimDraftProps,
        claimDraftErrors: state.claimDraftErrors,
        passengerSmtp: state.passenger_smtp,
        user: state.user,
        modalProps: _get(state, 'toggle_modal.props', {}),
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            hideModal,
            saveClaimDraft,
            sendClaimDraftEmail,
            clearClaimDraftErrors,
            validateEmailSmtp,
            logKibana,
            logDraftAction,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimDraftModal)
