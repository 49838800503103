import styled from 'styled-components'
import { StepContent, Row, Col } from '../../../../shared/components/V2/Layout'
import {
    mobile,
    tablet,
    desktop,
    tabletLG,
} from '../../../../shared/components/helpers/styled_queries'
import InfoBox from '../../components/InfoBox'
import ErrorsBox from '../../../../views/V2/components/ErrorsBox'
import ToolTip from '../../../../shared/components/V2/V2Tooltip'

export const DocWrapper = styled.div`
    width: 80px;

    ${mobile`
        svg {
           width: 48px;
        }
    `}
`

export const DocsRow = styled(Row)`
    // border-radius: 8px;
    // border: solid 1px #eeeeee;
    padding: 12px 0px;
    margin: 0px 12px 8px;
    width: calc(100% - 24px);

    ${desktop`
        padding: 8px 0px;

        ${Col} {
            padding: 0px 6px;
        }
    `}

    ${tabletLG`
        width: calc(66.666666666667% - 24px);
        margin: auto;
        margin-bottom: 8px;
        align-items: normal;

        ${Col} {
            max-width: 100%;
        }
    `}

    ${Col} {
        padding-top: 0px;
        padding-bottom: 0px;
        display: flex;

        // &:not(:first-child) {
        //     border-left: solid 1px #eeeeee;
        // }
    }

    .item-title {
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 400;
        color: #717171;


        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        margin-left: 8px;
        word-break: break-word;
        display: inline-flex;
        align-items: center;
    }

    img {
        margin-top: 4px;
        margin-bottom: 4px;
        display: inline-flex;
        width: 80px;
    }

    ${mobile`
        ${Col} {
            &:not(:last-child) {
                margin-bottom: 12px;
            }

            border-left: 0px;
            padding: 12px 0px;
        }

        //  background-color: #f3f5f9;
    `}

    ${tablet`
        ${Col} {
            &:not(:last-child) {
                margin-bottom: 12px;
            }

            border-left: 0px;
        }

         background-color: #f3f5f9;
    `}
`

export const StyledErrorsBox = styled(ErrorsBox)`
    display: flex;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #ffffff;

    ul {
        margin: 0;
        list-style: none;
        padding-left: 16px;
    }

    li {
        word-break: break-word;
        margin-bottom: 8px;
    }
`

export const StyledToolTip = styled(ToolTip)`
    &.place-top {
        &:after {
            ${desktop`
                left: 18%;
            `}
        }
    }

    &.__react_component_tooltip.place-bottom:after {
        ${desktop`
            left: 20%!important;
        `}
    }

    &.__react_component_tooltip.show {
        ${desktop`
            margin-left: 100px;
        `}
    }
`

export const DragDropContainer = styled.div`
    height: 160px;
    background-color: #f5f5f5;
    margin: 1px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='rgb(113, 113, 113)' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 16px;

    &.hover,
    &:hover {
        border-color: #00bc61;
        /* 
        .inputText:before {
            background-image: url("/icons/v2_upload-icon-green.svg");
        } */
    }

    ${desktop`
        height: 200px;
    `}
`

export const UploadedDocsWrapper = styled(Row)`
    margin-top: 24px;
`

export const StyledP = styled.p`
    font-size: 16px;
    display: flex;
    font-family: 'Noto sans';
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #717171;

    span {
        display: flex;
        align-items: center;
        margin-left: 8px;
    }

    &.mb-16 {
        margin-bottom: 16px;
    }

    &.align-center {
        text-align: center;
    }

    &.mobile {
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.85;
        letter-spacing: normal;
        text-align: center;
        color: #728096;
        padding: 0px 16px;

        ${desktop`
            display: none;
        `}
    }
`

export const FileInputLabel = styled.label`
    width: 100%;
    height: 100%;

    &:hover {
        cursor: pointer;
    }
`

export const FileInput = styled.input`
    width: 100%;
    height: 100%;
    min-width: 14rem;
    max-width: 100%;
    height: 2.5rem;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
    position: absolute;
    left: -999em;
`

export const FileUploadText = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    ${mobile`
        // display: none;
        padding: 0px 48px;
        text-align: center;
    `}

    // ${tablet`
    //     display: none;
    // `}
`

export const DragFile = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #353d51;

    span {
        color: #00bc61;
    }
`

export const ChooseFile = styled.span`
    max-width: 178px;
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #76869c;
`

export const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
`

export const FileUploadDescription = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #7f8fa4;
    margin-bottom: 8px;
    text-align: center;

    span {
        font-weight: bold;
    }
`

export const MobileUploadContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    ${desktop`
        display: none;
    `}
`

export const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`

export const CameraIcon = styled.div`
    width: 35px;
    height: 29px;
    background-image: url('/icons/v2_photo_camera_icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 13px;
`

export const FilesIcon = styled.div`
    width: 35px;
    height: 29px;
    background-image: url('/icons/v2_files_icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 13px;
`

export const MobileIconText = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #353d51;
`

export const LineBreak = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #d3dae2;
    justify-content: center;
    align-items: center;

    p {
        margin: 8px;
    }

    span {
        height: 40px;
        width: 1px;
        border-left: 1px solid #d3dae2;
    }
`

export const DocumentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    ${desktop`
        margin-top: 0px;
        padding-left: 12px;
    `}
`

export const DocumentOuter = styled.div`
    display: flex;
    margin-bottom: 16px;
    align-items: center;
`

export const DocumentInner = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 8px;
`

export const DocumentProgress = styled.div`
    position: relative;
    height: 6px;
    border-radius: 3px;
    background-color: #dfe3e9;

    &:before {
        content: '';
        position: absolute;
        height: 6px;
        width: ${({ proc }) => (proc ? `${proc}%` : '0%')};
        border-radius: 3px;
        background-color: ${({ theme }) => theme.colors.primaryColor};
        transition: width 2s;
    }
`

export const DocumentName = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #354052;
    margin-bottom: 6px;
`

export const RemoveButtonContainer = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    opacity: ${done => (done ? 1 : 0)};
`

export const DocumentSize = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #7f8fa4;
    margin: 4px 0 0 0;
`

export const StyledStepContent = styled(StepContent)`
    display: flex;
    flex-direction: column;
    width: 100%;

    .__react_component_tooltip {
        padding: 8px;

        &.show {
            opacity: 1 !important;
        }

        &.styled-tooltip {
            width: 290px;
            word-break: break-word;
            white-space: pre-wrap;
            background: #495363 !important;
            font-family: 'Open Sans';
            line-height: 1.33;
            font-size: 12px;
            font-weight: normal;

            ${mobile`
                left: 12px !important;
                width: 90%;
                
                &:after {
                    ${() =>
                        `left: ${
                            (
                                document.getElementById('question-mark') || {
                                    getBoundingClientRect: () => 200,
                                }
                            ).getBoundingClientRect().x
                        }px;`}
                    bottom: -5px;
                    margin-left: -12px;
                    border-top-color: #495363!important;
                }
            `}
        }

        &:after {
            border-top-color: #495363 !important;
        }
    }
`

export const DragTitle = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    margin-left: 6px;
    color: #717171;
`

export const DragDescription = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    color: #717171;
    line-height: 22.4px;
`

export const IconDisplay = styled.div`
    text-align: center;
    max-height: 80px;
    margin-bottom: 16px;
    display: flex;

    svg {
        display: inline-block;
        max-height: 80px;

        &:nth-child(even) {
            margin-left: 8px;
            margin-right: 8px;
        }
    }
`

export const StyledInfoBox = styled(InfoBox)`
    ul {
        margin-top: 8px;
    }

    li {
        font-weight: bold;
        color: #353d51;
    }
`

export const MobileDocList = styled.span`
    .list-text {
        text-align: center;
    }

    ul {
        margin-top: 8px;
        padding-left: 25px;
    }

    li {
        font-weight: bold;
        color: #353d51;
    }
`

export const HideMobile = styled.div`
    ${tablet`
        display: none;
    `}

    ${mobile`
        display: none;
    `}
`

export const DocumentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='rgb(113, 113, 113)' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 16px;
    background-color: #f5f5f5;
    padding: 16px 20px;
    margin: 24px 0px;
`

export const IconWrapper = styled.div`
    margin-right: 12px;
    :hover {
        cursor: pointer;
    }
`

export const DocumentStatusWrapper = styled.div`
    flex-grow: 1;
`

export const ClearIconWrapper = styled.div`
    margin-left: 12px;
    :hover {
        cursor: pointer;
    }
`

export const DocumentTitle = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    color: #333333;
`

export const DocumentStatus = styled.div`
    color: ${({ failed, isLoading, theme }) =>
        failed && !isLoading ? '#FE6050' : theme.colors.primaryColor};
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.72px;
`

export const ReuploadWrapper = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: rows;
    align-items: center;

    color: ${({ theme }) => theme.colors.primaryColor};
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;

    :hover {
        cursor: pointer;
    }
`
export const ReuploadTitle = styled.div`
    margin-right: 6px;
    text-decoration: underline;
`
