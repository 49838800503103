import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { setLanguage } from '../../actions'
import { mobile } from '../helpers/styled_queries'
import { GlobeIcon } from './V2Icons'
import { getLanguagesArray } from '../../../main'

const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 32px;
    ${mobile`
        display: none;
    `}
`
const StyledSelect = styled.select`
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    text-align-last: center;
    z-index: 100;
    color: ${({ theme }) => theme.colors.headerTextColor};
    color: ${({ signatureConfigs }) =>
        signatureConfigs &&
        signatureConfigs.menuItemColor &&
        signatureConfigs.menuItemColor};
    option {
        color: #353d51;
    }
    background: transparent;
    &:disabled {
        cursor: not-allowed;
        background-color: #f2f5f9;
        border: none;
    }
    option {
        &:disabled {
            display: none;
        }
        &[disabled='disabled'] {
            display: none;
        }
        &.hidden {
            display: none;
            visibility: collapse;
            -webkit-appearance: none;
        }
    }
`
const IconContainer = styled.div`
    display: flex;
    z-index: 10;
`
const V2DesktopLangSelect = ({
    webLanguages,
    lang,
    setLanguage,
    callback = () => null,
    signatureConfigs,
    headerTextColor,
    ...props
}) => {
    const handleLanguageChange = e => {
        const { value } = e.target
        setLanguage(value || 'en')
        callback()
    }
    const renderLangOptions = () =>
        (getLanguagesArray(webLanguages) || []).map(lang => (
            <option key={lang.key} value={lang.key}>
                {lang.title}
            </option>
        ))
    return (
        <Container>
            <IconContainer>
                <GlobeIcon fill={headerTextColor} />
            </IconContainer>
            <StyledSelect
                signatureConfigs={signatureConfigs}
                value={lang}
                onChange={handleLanguageChange}
                id="desktop-lang"
                {...props}
            >
                {renderLangOptions()}
            </StyledSelect>
        </Container>
    )
}
function mapStateToProps(state) {
    return {
        lang: state.language,
        trans: state.translations,
        webLanguages: state.web_languages,
        headerTextColor: state.propsTheme.colors.headerTextColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLanguage }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(V2DesktopLangSelect)
