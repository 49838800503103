import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import t from '../../../shared/translations'
import {
    desktop,
    mobile,
    tablet,
} from '../../../shared/components/helpers/styled_queries'
import { V2Button2 } from '../../../shared/components/V2/V2Buttons'
import { getApiClient } from '../../../main'

const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
`

const Wrapper = styled.div`
    display: flex;
    flex-grow: 1;
    background: white;
    margin-top: -14px;
`

const StyledRow = styled(Row)`
    width: 100%;
    margin: unset;
    flex-wrap: wrap-reverse;
`

const StyledCol = styled(Col)`
    padding: unset;
    align-items: center;
    display: flex;
`

const ContentWrapper = styled.div`
    margin: 0 40px 0 108px;
    width: 100%;

    ${mobile`
       margin: 40px 20px 40px 20px;
      `}

    ${tablet`
         margin: 40px 20px 40px 20px;
        `}
`

const Title = styled.div`
    font-family: Noto Sans;
    font-size: 52px;
    font-weight: 600;
    line-height: 62.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin-bottom: 40px;

    ${mobile`
            font-size: 32px;
            line-height: 42.24px;
        `}
`

const Description = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin-bottom: 40px;
`

const BoldDescription = styled(Description)`
    font-weight: 600;
`

const ThankYouTitle = styled.div`
    color: ${({ theme }) => theme.colors.primaryColor};
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 29.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
`

const ThankYouDescription = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #333333;
`

const Button = styled(V2Button2)`
    margin-bottom: 60px;

    ${desktop`
    width: fit-content;
    padding: 0 32px 0 32px;
        `}
`

class ClaimReopen extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isValid: true,
            claimId: null,
        }
    }
    componentDidMount() {
        const apiClient = getApiClient()
        const url = 'api/reopen/status/'
        const urlParams = new URLSearchParams(window.location.search)
        const claimId = urlParams.get('claim_id')
        if (!!claimId) {
            apiClient
                .get(`${url}${claimId}`)
                .then(({ data }) => {
                    if (data.status === 'pending') {
                        this.setState({ claimId: claimId, isValid: true })
                    } else {
                        this.setState({ isValid: false })
                    }
                })
                .catch(e => {
                    console.error(e)
                    this.setState({ isValid: false })
                })
        } else {
            this.setState({ isValid: false })
        }
    }

    handleReopen = () => {
        const { claimId } = this.state
        const apiClient = getApiClient()
        const url = 'api/claim/reopen'

        apiClient
            .post(url, { claimId })
            .then(() => {
                this.setState({ isValid: false })
            })
            .catch(e => {
                console.error(e)
                this.setState({ isValid: false })
            })
    }

    render() {
        const { trans } = this.props
        const { isValid } = this.state

        if (!trans) return null

        return (
            <Wrapper>
                <StyledRow>
                    <StyledCol lg={6} md={6} xs={12}>
                        <ContentWrapper>
                            <Title>{t`claim.reopen.content.title`}</Title>
                            <Description>
                                {t`claim.reopen.content.description`}
                            </Description>
                            <BoldDescription>
                                {t`claim.reopen.content.description2`}
                            </BoldDescription>
                            {isValid ? (
                                <Button
                                    onClick={() => this.handleReopen()}
                                >{t`claim.reopen.submit.button`}</Button>
                            ) : (
                                <div>
                                    <ThankYouTitle>
                                        {t`claim.reopen.thank.you.content.title`}
                                    </ThankYouTitle>
                                    <ThankYouDescription>
                                        {t`claim.reopen.thank.you.content.description`}
                                    </ThankYouDescription>
                                </div>
                            )}
                        </ContentWrapper>
                    </StyledCol>
                    <StyledCol lg={6} md={6} xs={12}>
                        <ImageContainer>
                            <img
                                width="100%"
                                src="/images/reopenAirplane.png"
                                alt="survey"
                            />
                        </ImageContainer>
                    </StyledCol>
                </StyledRow>
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

export default connect(mapStateToProps)(ClaimReopen)
