import React from 'react'
import styled from 'styled-components'
import { Button } from '../../../shared/components/Buttons'
import {
    desktop,
    tablet,
    mobile,
} from '../../../shared/components/helpers/styled_queries'
import t from '../../../shared/translations'
import { Col, Row } from '../../../shared/components/V2/Layout'
import { DownloadIcon } from '../../../shared/components/V2/V2Icons'
import { isFeatureEnabled } from '../../../main'
import { featureToggles } from '../../../shared/constants'
import { V2Button, V2Button2 } from '../../../shared/components/V2/V2Buttons'
import TrustBoxContainer from './TrustPilot'
import { TrustpilotContainer } from '../../../shared/components/V2/Layout'
import { ArrowUp } from '../../../shared/components/V2/V2Icons'

const StyledV2ButtonDesktop = styled(V2Button)`
    margin-right: 10px;
    margin-left: 10px;
    width: fit-content;
    color: ${({ theme }) => theme.colors.primaryColor};
    border-color: ${({ theme }) => theme.colors.primaryColor};
    border-radius: 16px;

    :hover {
        color: ${({ theme }) => theme.colors.primaryColor};
        border-color: ${({ theme }) => theme.colors.primaryColor};
        border-radius: 16px;
    }
`

const StyledBtn = styled(Button)`
    font-size: 16px;
    border-radius: 24px;
    font-size: 14px;
    width: 100%;
    ${({ disabledStyle, theme }) =>
        disabledStyle
            ? 'background: #E0E0E0;'
            : theme.colors.actionButton
            ? `background: ${theme.colors.actionButton};`
            : `background-image: linear-gradient(to top, ${theme.colors.primaryColor}, ${theme.colors.primaryColor}15)};`}
    color: ${({ disabledStyle }) => (disabledStyle ? '#717171' : '#ffffff')};

    ${desktop`
        max-width: 300px;
    `}

    &:focus, &:active, &:hover {
        ${({ disabledStyle }) => disabledStyle && 'color: #717171;'};
        ${({ disabledStyle }) =>
            !disabledStyle && 'box-shadow: 0 10px 20px 0 #e9e9e9;'}
    }

    pointer-events: ${({ preventPointers }) =>
        preventPointers ? 'none' : 'auto'};
`

const BackLink = styled.div`
    width: ${({ width }) => width && width};
    min-width: ${({ minWidth }) => minWidth && minWidth};
    margin: 0;
    margin-bottom: 15px;
    border: ${({ isBorder, theme }) =>
        isBorder ? `1px solid ${theme.colors.primaryColor}` : '0 none;'};
    border-radius: ${({ isBorder }) => isBorder && '16px'};
    color: #353d51;
    font-weight: 600;
    font-size: 16px;
    background: transparent;
    outline: 0 none;
    box-shadow: none;
    line-height: 1.71;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    text-align: center;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primaryColor};

    ${desktop`
        margin: 0;
        padding: 0px 30px;
        align-items: center;
        width: 50%;
        display: flex;
        justify-content: center;

        &:hover {
            color: ${({ theme }) => theme.colors.primaryColor};
        }
    `}

    ${tablet`
        padding: 12px 8px;
        margin-top: 12px;
    `}

    ${mobile`
        padding: 12px 8px;
        margin-top: 12px;
    `}
`

const SaveDraftContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`

const SaveDraftBtn = styled(StyledBtn)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border: 1px solid #98a3bf;
    color: #353d51;
    border-radius: 24px;
    font-weight: 500;
    background: transparent;
    background-image: none;
    background-color: transparent;
    outline: 0 none;
    font-size: 14px;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    ${({ blank }) =>
        blank
            ? `
        border: 0 none;
        outline: 0 none;
        box-shadow: none;
    `
            : ''}

    &:hover {
        color: ${({ theme }) => theme.colors.primaryColor};
    }

    svg {
        margin-right: 14px;
    }
`

const StyledCol = styled(Col)`
    padding: 0;
    margin: 0;
`

const MobileStepNavContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 16px;
    align-items: center;
    flex-shrink: 0;

    ${StyledBtn} {
        margin-bottom: 15px;
        padding: 15px 23px;
    }

    ${desktop`
        display: none;
    `}
`

const MobileDoLaterBtn = styled(V2Button2)`
    margin-top: 12px;
`

const V2Button2Styled = styled(V2Button2)`
    div {
        height: 100%;
        align-items: center;
        display: flex;
        margin-right: 8px;
        text-wrap: wrap;
        :hover {
            transform: translateX(0px);
            svg {
                ${desktop`
                transform: translate(10px, 0px);
                transition: transform 0.4s ease;
                `}
            }
        }
    }

    svg {
        margin-left: 6px;
    }
`

const MobileTopBorder = styled.div`
    height: 2px;
    margin: 10px 0px 40px 0px;

    .top-border {
        // position: absolute;
        top: 0;
        left: 8px;
        width: 98%;
        height: 1px;
        background-image: linear-gradient(
            to right,
            #717171 25%,
            rgba(255, 255, 255, 0) 0%
        );
        // margin-right: 50px;
        background-position: top;
        background-size: 10px 1px;
        background-repeat: repeat-x;
        margin-bottom: 32px;
    }
`

export const MobileStepNav = ({
    prevStepCB,
    nextStepCB,
    nextDisabled,
    saveDraft,
    noBack,
    isFinish,
    buttonText,
    hideButton,
    saveDraftEnabled = true,
    disabledClick,
    hideMobileTrustPilot = false,
    step,
    handlePressLater,
    isClaimFormV1Enabled,
    handleReferralClick,
    primaryColor,
}) => (
    <MobileStepNavContainer id="mobile-step-nav">
        {/* {!isClaimFormV1Enabled && (
            <ReferalLinkDiv>
                <ReferalLink
                    id="mobile_global_footer"
                    text={t`referal.text.share_and_earn`}
                    linkText={t`referal.text.refer_friend`}
                    handleReferralClick={handleReferralClick}
                />
            </ReferalLinkDiv>
        )} */}
        <StyledCol xs={12} sm={12} md={8}>
            {isClaimFormV1Enabled ? (
                <>
                    <div style={{ display: 'flex' }}>
                        {!noBack && step.stepIndex !== 6 && (
                            <BackLink
                                style={{ marginRight: '3px' }}
                                width="100%"
                                isBorder={true}
                                id="btn-step-back"
                                onClick={e => {
                                    prevStepCB(e)
                                    e.target.blur()
                                }}
                            >
                                {t`v2_button.back`}
                            </BackLink>
                        )}
                        {!hideButton && (
                            <StyledBtn
                                style={{ marginLeft: '3px' }}
                                id="btn-step-continue"
                                disabledStyle={nextDisabled}
                                preventPointers={nextDisabled && !disabledClick}
                                onClick={
                                    nextDisabled
                                        ? e => {
                                              e.preventDefault()
                                              if (disabledClick) {
                                                  disabledClick()
                                              }
                                          }
                                        : e => {
                                              e.preventDefault()
                                              nextStepCB(e)
                                              e.target.blur()
                                          }
                                }
                            >
                                {buttonText ||
                                    (isFinish
                                        ? t`v2_button.finish`
                                        : t`v2_button.continue`)}
                            </StyledBtn>
                        )}
                    </div>
                    {!hideButton && step.stepIndex === 6 && (
                        <MobileDoLaterBtn
                            type="borderBtn"
                            id="btn-step-i_will_do_it_later"
                            disabledStyle={nextDisabled}
                            preventPointers={nextDisabled && !disabledClick}
                            onClick={
                                nextDisabled
                                    ? e => {
                                          e.preventDefault()
                                          handlePressLater()
                                          if (disabledClick) {
                                              disabledClick()
                                          }
                                      }
                                    : e => {
                                          e.preventDefault()
                                          handlePressLater()
                                          nextStepCB(e)
                                          e.target.blur()
                                      }
                            }
                        >
                            {t`flight_documents.i_will_do_it_later`}
                        </MobileDoLaterBtn>
                    )}
                </>
            ) : (
                <>
                    <MobileTopBorder>
                        <div className="top-border" />
                    </MobileTopBorder>
                    {!hideButton && (
                        <V2Button2
                            id="btn-step-continue"
                            disabledStyle={nextDisabled}
                            preventPointers={nextDisabled && !disabledClick}
                            onClick={
                                nextDisabled
                                    ? e => {
                                          e.preventDefault()
                                          if (disabledClick) {
                                              disabledClick()
                                          }
                                      }
                                    : e => {
                                          e.preventDefault()
                                          nextStepCB(e)
                                          e.target.blur()
                                      }
                            }
                        >
                            {buttonText ||
                                (isFinish
                                    ? t`v2_button.finish`
                                    : t`v2_button.continue`)}
                        </V2Button2>
                    )}

                    {!hideButton && step.stepIndex === 6 && (
                        <MobileDoLaterBtn
                            type="borderBtn"
                            id="btn-step-i_will_do_it_later"
                            disabledStyle={nextDisabled}
                            preventPointers={nextDisabled && !disabledClick}
                            onClick={
                                nextDisabled
                                    ? e => {
                                          e.preventDefault()
                                          handlePressLater()
                                          if (disabledClick) {
                                              disabledClick()
                                          }
                                      }
                                    : e => {
                                          e.preventDefault()
                                          handlePressLater()
                                          nextStepCB(e)
                                          e.target.blur()
                                      }
                            }
                        >
                            {t`flight_documents.i_will_do_it_later`}
                        </MobileDoLaterBtn>
                    )}
                    {!noBack && step.stepIndex !== 6 && (
                        <BackLink
                            isBorder
                            id="btn-step-back"
                            onClick={e => {
                                prevStepCB(e)
                                e.target.blur()
                            }}
                        >
                            {t`v2_button.back`}
                        </BackLink>
                    )}

                    <ParentReferralWrapper marginTop="40px">
                        <ReferralButtonWrapper>
                            <V2Button2Styled
                                type="borderBtn"
                                id="btn-referral"
                                onClick={() => handleReferralClick()}
                            >
                                <ReferralBtnTitle>
                                    <ReferralLinkText>
                                        {t`referal.text.share_and_earn`}
                                    </ReferralLinkText>
                                    <ArrowUp color={primaryColor} />
                                </ReferralBtnTitle>
                            </V2Button2Styled>
                        </ReferralButtonWrapper>
                    </ParentReferralWrapper>
                </>
            )}

            {isFeatureEnabled(featureToggles.saveDraftFeature) &&
                saveDraftEnabled && (
                    <SaveDraftContainer>
                        <SaveDraftBtn onClick={saveDraft} blank={true}>
                            <DownloadIcon color={primaryColor} />
                            {t`v2_button.save_draft`}
                        </SaveDraftBtn>
                    </SaveDraftContainer>
                )}

            {!hideMobileTrustPilot && (
                <TrustpilotContainer>
                    <TrustBoxContainer />
                </TrustpilotContainer>
            )}
        </StyledCol>
    </MobileStepNavContainer>
)

const DesktopBtn = styled.button`
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    border: 0;
    min-width: 200px;
    min-height: 48px;
    border-radius: 24px;
    padding: 10px 30px;
    text-align: center;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    ${({ disabled, theme }) =>
        disabled
            ? 'background-color: #E0E0E0;'
            : theme.colors.actionButton
            ? `background-color: ${theme.colors.actionButton};`
            : `background-image: linear-gradient(to top, ${theme.colors.primaryColor}, ${theme.colors.primaryColor}15)};`}
    color: ${({ disabled }) => (disabled ? '#717171' : '#ffffff')};
    outline: 0 none;
    font-size: 14px;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    &:hover {
        ${({ disabled }) => !disabled && 'box-shadow: 0 10px 20px 0 #e9e9e9;'}
    }

    &:focus {
        outline: 0 none;
    }
`

export const DesktopBorderBtn = styled(DesktopBtn)`
    min-width: 150px;
    background: transparent;
    border: solid 1px #98a3bf;
    margin-left: 16px;
    color: #353d51;
    font-weight: 500;

    &:hover {
        color: rgba(53, 61, 81, 0.8);
    }
`

// eslint-disable-next-line
const DesktopSaveDraftBtn = styled.div`
    display: inline-flex;
    justify-content: flex-end;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    font-size: 14px;
    height: 100%;
    align-items: center;
    color: #353d51;
    padding-left: 8px;

    svg {
        margin-right: 14px;
    }

    &:hover {
        color: ${({ theme }) => theme.colors.primaryColor};
    }
`

const DesktopStepNavContainer = styled.div`
    display: ${({ hideBorder }) => (hideBorder ? 'none' : 'flex')};
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 32px;
    position: relative;
    border: 1px solid transparent;
    box-sizing: border-box;
    padding: ${({ paddingDefined }) => paddingDefined && '32px 40px'};
    .top-border {
        // position: absolute;
        top: 0;
        left: 8px;
        width: 98%;
        height: 1px;
        background-image: linear-gradient(
            to right,
            #717171 25%,
            rgba(255, 255, 255, 0) 0%
        );
        // margin-right: 50px;
        background-position: top;
        background-size: 10px 1px;
        background-repeat: repeat-x;
        margin-bottom: 32px;
    }

    ${tablet`
        display: none;
    `}

    ${mobile`
        display: none;
    `}
`

const ContinueBackBtnsWrapper = styled.div`
    width: 100%;

    margin-right: ${({ isBack }) => (isBack ? '12px;' : '0px;')} ${tablet`
         margin-right: none;
    `} ${mobile`
         margin-right: none;
    `};
`

const ParentReferralWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;

    margin-top: ${({ marginTop }) => marginTop && marginTop};
`

const ReferralButtonWrapper = styled.div`
    height: 52px;

    width: 70%;

    ${tablet`
        width:100%;
    `}

    ${mobile`
        width:100%;
        height: 54px;
    `}
`

const ReferralBtnTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const ReferralLinkText = styled.div`
    margin-right: 6px;
    text-wrap: nowrap;
`

export const DesktopStepNav = ({
    prevStepCB,
    nextStepCB,
    nextDisabled,
    saveDraft,
    noBack,
    isFinish,
    buttonText,
    hideButton,
    hideBorder,
    saveDraftEnabled = true,
    disabledClick,
    step,
    handlePressLater,
    isClaimFormV1Enabled,
    handleReferralClick,
    paddingDefined,
    noDoLaterBtn,
    primaryColor,
}) => (
    <DesktopStepNavContainer
        hideBorder={hideBorder}
        paddingDefined={paddingDefined}
    >
        {!hideBorder && <div className="top-border" />}
        {isClaimFormV1Enabled ? (
            <div style={{ display: 'inline-flex', width: '100%' }}>
                {!noBack && step.stepIndex !== 6 && (
                    <BackLink
                        id="btn-step-back"
                        onClick={e => {
                            prevStepCB(e)
                            e.target.blur()
                        }}
                    >
                        {t`v2_button.back`}
                    </BackLink>
                )}
                {!hideButton && (
                    <V2Button
                        id="btn-step-continue"
                        disabled={nextDisabled}
                        onClick={e => {
                            if (e && e.preventDefault) {
                                e.preventDefault()
                            }
                            nextStepCB(e)
                        }}
                        disabledClick={disabledClick}
                        style={{ fontWeight: 'bold' }}
                    >
                        {buttonText ||
                            (isFinish
                                ? t`v2_button.finish`
                                : t`v2_button.continue`)}
                    </V2Button>
                )}
                {!hideButton && step.stepIndex === 6 && (
                    <StyledV2ButtonDesktop
                        type="borderBtn"
                        id="btn-step-continue"
                        disabled={nextDisabled}
                        onClick={e => {
                            if (e && e.preventDefault) {
                                e.preventDefault()
                            }
                            handlePressLater()
                            nextStepCB(e)
                        }}
                        disabledClick={disabledClick}
                        style={{ fontWeight: 'bold' }}
                    >
                        {t`flight_documents.i_will_do_it_later`}
                    </StyledV2ButtonDesktop>
                )}
            </div>
        ) : (
            <Row>
                <Col sm={12} md={8} lg={6}>
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                        {!hideButton && (
                            <ContinueBackBtnsWrapper isBack={!noBack}>
                                <V2Button2
                                    id="btn-step-continue"
                                    disabled={nextDisabled}
                                    onClick={e => {
                                        if (e && e.preventDefault) {
                                            e.preventDefault()
                                        }
                                        nextStepCB(e)
                                    }}
                                    disabledClick={disabledClick}
                                    style={{ fontWeight: 'bold' }}
                                >
                                    {buttonText ||
                                        (isFinish
                                            ? t`v2_button.finish`
                                            : t`v2_button.continue`)}
                                </V2Button2>
                            </ContinueBackBtnsWrapper>
                        )}
                        {!hideButton && step.stepIndex === 6 && !noDoLaterBtn && (
                            <StyledV2ButtonDesktop
                                type="borderBtn"
                                id="btn-step-continue"
                                disabled={nextDisabled}
                                onClick={e => {
                                    if (e && e.preventDefault) {
                                        e.preventDefault()
                                    }
                                    handlePressLater()
                                    nextStepCB(e)
                                }}
                                disabledClick={disabledClick}
                                style={{ fontWeight: 'bold' }}
                            >
                                {t`flight_documents.i_will_do_it_later`}
                            </StyledV2ButtonDesktop>
                        )}

                        {!noBack && step.stepIndex !== 6 && (
                            <BackLink
                                isBorder={true}
                                id="btn-step-back"
                                onClick={e => {
                                    prevStepCB(e)
                                    e.target.blur()
                                }}
                            >
                                {t`v2_button.back`}
                            </BackLink>
                        )}
                    </div>
                </Col>
                <Col sm={12} md={8} lg={6}>
                    <ParentReferralWrapper>
                        <ReferralButtonWrapper>
                            <V2Button2Styled
                                type="borderBtn"
                                id="btn-referral"
                                onClick={() => handleReferralClick()}
                            >
                                <ReferralBtnTitle>
                                    <ReferralLinkText>
                                        {t`referal.text.share_and_earn`}
                                    </ReferralLinkText>
                                    <ArrowUp color={primaryColor} />
                                </ReferralBtnTitle>
                            </V2Button2Styled>
                        </ReferralButtonWrapper>
                    </ParentReferralWrapper>
                    {/* <ReferalLinkDiv>
                        <ReferalLink
                            text={t`referal.text.share_and_earn`}
                            linkText={t`referal.text.refer_friend`}
                            handleReferralClick={handleReferralClick}
                        />
                    </ReferalLinkDiv> */}
                </Col>
            </Row>
        )}

        {isFeatureEnabled(featureToggles.saveDraftFeature) && saveDraftEnabled && (
            <SaveDraftContainer>
                <DesktopSaveDraftBtn onClick={saveDraft}>
                    <DownloadIcon color={primaryColor} />
                    {t`v2_button.save_draft`}
                </DesktopSaveDraftBtn>
            </SaveDraftContainer>
        )}
    </DesktopStepNavContainer>
)
