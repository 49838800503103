import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import Sidebar from '../../../shared/components/V2/Sidebar'
import { showModal } from '../../../shared/actions/v2_actions'
import t from '../../../shared/translations'
import {
    SuccessMark,
    SidebarSuccessMark,
} from '../../../shared/components/V2/V2Icons'
import FastPayoutSideBox from './FastPayoutSideBox'
import { isProduction } from '../../../main'
import { paths } from '../../../shared/constants'
import _get from 'lodash/get'

export const AbsoluteCorner = styled.div`
    transform: rotate(45deg);
    background: ${({ bgColor }) => bgColor || '#f5bb28'};
    position: absolute;
    height: 80px;
    width: 80px;
    right: -40px;
    top: -40px;
`

export const StopWatch = styled.div`
    background: url('/images/fast.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 2;
    top: 6px;
    right: 5px;
`

const Container = styled.div`
    padding: 12px 12px;
    box-sizing: border-box;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const TickContainer = styled.div`
    display: inline-flex;
    min-width: 40px;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    justify-content: center;
    ${({ bgColor }) => (bgColor ? `background: ${bgColor};` : '')}
`

export const Bubble = styled(TickContainer)`
    color: #fff;
    background: ${({ theme }) => theme.colors.primaryColor};
    box-shadow: none;
    border-radius: 50%;
    user-select: none;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;

    ${({ active, bgColor }) =>
        !active &&
        `
        color: #717171;
        box-shadow: 0 5px 7px -5px rgba(53, 61, 81, 0.23);
        background: ${bgColor || '#fff'};
    `}
`

const StepRowBox = styled.div`
    padding: ${({ padding }) => padding || '8px 0px'};
    display: flex;

    &:first-child {
        padding-top: 0px;
    }

    &:last-child {
        padding-bottom: 0px;
    }

    span {
        font-family: 'Noto Sans';
        font-size: 13px;
        color: #717171;
        display: inline-flex;
        align-items: center;
        word-break: break-all;
    }

    ${({ active }) =>
        active &&
        `

        span {
            font-weight: bold;
            color: #353d51;
            font-size: 15px;
        }
    `}
`

const CategoryItems = styled.div`
    display: flex;
    flex-direction: column;

    .title {
    }
`

const CategoryTitle = styled.div`
    color: ${({ active }) => (active ? '#333333' : '#717171')};

    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;

    display: inline-flex;
    align-items: center;
    word-break: break-all;
    height: 40px;
    margin-bottom: 10px;
`

const CategorySubtitle = styled.div`
    display: flex;
    &:not(:last-child) {
        margin-bottom: 10px;
    }

    svg {
        min-height: 8px;
        min-width: 11px;
        display: inline-block;
        margin-right: 6px;
    }

    span {
        display: inline-block;
        font-family: 'Noto Sans';
        font-size: 16px;
        line-height: 1.23;
        font-weight: 600;
        color: ${({ activeItem }) => (activeItem ? '#333333' : '#717171')};
    }
`

const StepRow = ({ index, title, active, checked, onClick, color }) => (
    <StepRowBox active={active} onClick={onClick}>
        {checked ? (
            <TickContainer>
                <SuccessMark bgColor="transparent" color="#717171" />
            </TickContainer>
        ) : (
            <Bubble active={active} bgColor={color}>
                {index + 1}{' '}
            </Bubble>
        )}
        <span>{title}</span>
    </StepRowBox>
)

const CategoryRow = ({
    index,
    title,
    active,
    checked,
    currPathIndex,
    children,
    onClick = () => null,
}) => (
    <StepRowBox active={active} onClick={onClick} padding="6px 0px">
        {checked ? (
            <TickContainer bgColor="#eeeeee">
                <SuccessMark bgColor="transparent" color="#717171" />
            </TickContainer>
        ) : (
            <Bubble active={active} bgColor="#E0E0E0" color="#717171">
                {index + 1}
            </Bubble>
        )}
        <CategoryItems>
            <CategoryTitle active={active}>{title}</CategoryTitle>
            {active && (
                <React.Fragment>
                    {children.map((child, index) => (
                        <CategorySubtitle
                            key={`subitem-${index}`}
                            activeItem={
                                window.location.pathname.replace(
                                    '/email',
                                    ''
                                ) === child.path
                            }
                        >
                            {index < currPathIndex ? (
                                <SidebarSuccessMark bgColor="#717171" />
                            ) : (
                                <div
                                    style={{
                                        display: 'inline-block',
                                        width: '22px',
                                        minWidth: '22px',
                                    }}
                                />
                            )}
                            <span>{child.title}</span>
                        </CategorySubtitle>
                    ))}
                </React.Fragment>
            )}
        </CategoryItems>
    </StepRowBox>
)

class SidebarProgress extends React.Component {
    shouldComponentUpdate(nextProps) {
        const { trans, activeIndex } = this.props

        return (
            trans['v2.sidebar_step.flight_itinerary'] !==
                nextProps.trans['v2.sidebar_step.flight_itinerary'] ||
            activeIndex !== nextProps.activeIndex
        )
    }

    _renderFullList = () => {
        const { activeIndex, checkedIndexes, primaryColor } = this.props

        const stepTitles = [
            t`v2.sidebar_step.flight_itinerary`,
            t`v2.sidebar_step.disruption_details`,
            t`v2.sidebar_step.disruption_reason`,
            t`v2.sidebar_step.flight_details`,
            t`v2.sidebar_step.documents`,
            t`v2.sidebar_step.passenger_info`,
            t`v2.sidebar_step.signature`,
            t`v2.sidebar_step.finish`,
        ]

        return stepTitles.map((item, i) => (
            <StepRow
                color={primaryColor}
                key={i}
                index={i}
                title={item}
                active={activeIndex === i}
                checked={checkedIndexes.includes(i)}
                onClick={e => {
                    e.preventDefault()

                    if (!isProduction()) {
                        browserHistory.push(paths.V2[Object.keys(paths.V2)[i]])
                    }
                }}
            />
        ))
    }

    _renderCategoryList = () => {
        const currPath = window.location.pathname.replace('/email', '')
        let detailsSubCastMenu = [
            {
                title: t`v2.sidebar_step.passenger_info`,
                path: paths.V2.STEP_5_PASSENGER_DETAILS,
            },
            {
                title: t`v2.sidebar_step.signature`,
                path: paths.V2.STEP_6_CLAIM_SIGNATURE,
            },
            {
                title: t`v2.sidebar_step.documents`,
                path: paths.V2.STEP_7_FLIGHT_DOCUMENTS,
            },
        ]

        const sideCategories = [
            {
                title: t`v2.sidebar_category.eligibility_check`,
                subCats: [
                    {
                        title: t`v2.sidebar_step.flight_itinerary`,
                        path: paths.V2.STEP_1_FLIGHT_ITINERARY,
                    },
                    {
                        title: t`v2.sidebar_step.disruption_details`,
                        path: paths.V2.STEP_2_DISRUPTION_DETAILS,
                    },
                    {
                        title: t`v2.sidebar_step.disruption_reason`,
                        path: paths.V2.STEP_3_DISRUPTION_REASON,
                    },
                    {
                        title: t`v2.sidebar_step.flight_details`,
                        path: paths.V2.STEP_4_FLIGHT_DETAILS,
                    },
                ],
            },
            {
                title: t`v2.sidebar_category.additional_details`,
                subCats: detailsSubCastMenu,
            },
            {
                title: t`v2.sidebar_category.done`,
                categoryPath: paths.V2.STEP_8_THANK_YOU,
                subCats: [],
            },
        ]

        const allSubCatPaths = sideCategories.reduce((acc, currValue) => {
            const subCatPaths = currValue.subCats.map(subCat => subCat.path)
            return [...acc, ...subCatPaths]
        }, [])

        const allSubCatLengths = sideCategories
            .reduce((acc, currValue) => {
                const subCatPathLength = currValue.subCats.map(
                    subCat => subCat.path
                ).length
                return [...acc, subCatPathLength]
            }, [])
            .reduce((acc, currValue, currIndex) => {
                const prevValue = acc[currIndex - 1] || 0
                return [...acc, currValue + prevValue]
            }, [])

        return sideCategories.map((sideCat, i) => {
            const subCatPaths = (sideCat.subCats || []).map(
                subCat => subCat.path
            )
            const includesSubPath = subCatPaths.includes(currPath)
            const currPathIndex = subCatPaths.indexOf(currPath)
            const activeCategory =
                currPath === sideCat.categoryPath || includesSubPath
            const isChecked =
                allSubCatLengths[i] !== 0 &&
                !includesSubPath &&
                allSubCatPaths.indexOf(currPath) + 1 > allSubCatLengths[i]

            return (
                <CategoryRow
                    key={i}
                    index={i}
                    title={sideCat.title}
                    active={!!activeCategory}
                    checked={!!isChecked}
                    children={sideCat.subCats}
                    currPathIndex={currPathIndex}
                />
            )
        })
    }

    render() {
        const {
            top,
            trans,
            pt,
            hideFast = false,
            showModalOnLoad = false,
        } = this.props

        if (!trans) return null

        return (
            <Sidebar
                top={top || 40}
                pt={pt}
                showLogos={false}
                customStyle="box-shadow: none; border-radius: 24px; border: 1px solid #E0E0E0;"
            >
                <Container>{this._renderCategoryList()}</Container>
                {!hideFast && (
                    <FastPayoutSideBox showModalOnLoad={showModalOnLoad} />
                )}
            </Sidebar>
        )
    }
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        V2Claim: state.V2Claim,
        representativeAgreementRequired: _get(
            state,
            'state.v2_posted_claim.problem_claim_flight.airline.representativeAgreement',
            false
        ),
        trans: state.translations,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showModal }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarProgress)
