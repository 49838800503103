import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getApiClient } from '../../main'
import styled from 'styled-components'
import t from '../../shared/translations'
import { V2Button2 } from '../../shared/components/V2/V2Buttons'
import ReactTooltip from 'react-tooltip'
import { Col, Row } from '../../shared/components/V2/Layout'
import { mobile } from '../../shared/components/helpers/styled_queries'

const CopyIcon = ({ color }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16 8V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H8M8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8H18C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H10C9.46957 20 8.96086 19.7893 8.58579 19.4142C8.21071 19.0391 8 18.5304 8 18V10Z"
            stroke={color}
            stroke-width="1.5"
            stroke-linecap="square"
        />
    </svg>
)

const DraftRow = styled.div`
    background-color: #fafafa;
    @keyframes appearBox {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    transition: all 0.5s ease;
    animation: appearBox;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    display: flex;
    margin: 12px 0px;
    padding: 40px;
    border-radius: 16px;
    border: solid 1px #e0e0e0;

    ${mobile`
        padding: 32px;
        `}

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        ${mobile`
            display: flex;
    flex-direction: column;
            `}

        .tooltip {
            position: relative;
            display: inline-block;
        }

        .tooltip .tooltiptext {
            visibility: hidden;
            width: 140px;
            background-color: #555;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            bottom: 150%;
            left: 50%;
            margin-left: -75px;
            opacity: 0;
            transition: opacity 0.3s;
        }

        .tooltip .tooltiptext::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #555 transparent transparent transparent;
        }

        .tooltip:hover .tooltiptext {
            visibility: visible;
            opacity: 1;
        }

        .name-container {
            .text {
                line-height: 22.4px;
                font-weight: 600;
                font-size: 16px;
                color: #000000;
                margin-bottom: 16px;
            }
            .subName {
                color: #717171;
                font-family: Noto Sans;
                font-size: 16px;
                font-weight: 600;
                line-height: 22.4px;
            }
        }

        .share-container {
            text-decoration: underline;
            color: ${({ theme }) => theme.colors.primaryColor};
            font-family: Noto Sans;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;

            svg {
                margin-left: 4px;
            }
            &:hover {
                cursor: pointer;
            }
        }

        .button-container {
            button {
                width: 100%;
                min-width: 140px !important;
            }
        }

        p {
            margin: 0;
        }
    }
`

const DashedLine = styled.div`
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : '16px')};
    margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
    height: 2px;
    background-image: linear-gradient(
        to right,
        #717171 40%,
        rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;
`

const CopyRow = styled(Row)`
    display: flex;
    justify-content: end;
    flex-flow: nowrap;

    ${mobile`
    justify-content: start;
        `}
`

const SignTitle = styled.div`
    font-family: Noto Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 42.24px;
    color: #000000;
    margin-bottom: 32px;
`

const AgreementTitle = styled(Row)`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    color: #717171;
    margin-bottom: 24px;
    margin-top: ${({ marginTop }) => marginTop && `${marginTop}px;`};
`

class PassengersClaimSignature extends Component {
    constructor(props) {
        super(props)

        this.state = {
            passengersAgreements: null,
            legalGuardianAgreements: null,
        }
    }

    componentDidMount() {
        const { claimId } = this.props
        if (claimId) {
            this.getPassengersAgreementsLinks(claimId)
        } else {
            this.setState({
                passengersAgreements: null,
                legalGuardianAgreements: null,
            })
        }
    }

    getPassengersAgreementsLinks = claimId => {
        const apiClient = getApiClient()
        const url = `api/claim/${claimId}/passengers-agreements-links`

        apiClient
            .get(url)
            .then(({ data }) => {
                const legalGuardianAgreementsList = data.filter(
                    ({ legalGuardianFullName }) => !!legalGuardianFullName
                )
                const passengersAgreementsList = data.filter(
                    ({ legalGuardianFullName }) => !legalGuardianFullName
                )
                this.setState({
                    passengersAgreements: passengersAgreementsList,
                    legalGuardianAgreements: legalGuardianAgreementsList,
                })
            })
            .catch(e =>
                this.setState({
                    passengersAgreements: null,
                    legalGuardianAgreements: null,
                })
            )
    }
    render() {
        const { trans, primaryColor } = this.props
        const { passengersAgreements, legalGuardianAgreements } = this.state

        if (!trans) {
            return null
        }

        return (
            <div>
                {((legalGuardianAgreements &&
                    !!legalGuardianAgreements.length) ||
                    (passengersAgreements &&
                        !!passengersAgreements.length)) && (
                    <>
                        <DashedLine marginBottom={32} />
                        <SignTitle>{t`v2_legal_agreement_sign_title`}</SignTitle>
                    </>
                )}
                {legalGuardianAgreements && !!legalGuardianAgreements.length && (
                    <div>
                        <AgreementTitle>{t`v2_legal_guardian_list_title`}</AgreementTitle>
                        {legalGuardianAgreements.map(
                            ({
                                fullName,
                                link,
                                isMissingSignature,
                                legalGuardianFullName,
                            }) => (
                                <DraftRow key={link}>
                                    <Row className="container">
                                        <Col className="name-container">
                                            <p className="text">{fullName}</p>
                                            <p className="subName">
                                                {t`v2_legal_guardian_signed_by`.replace(
                                                    '%fullName%',
                                                    legalGuardianFullName
                                                )}
                                            </p>
                                        </Col>
                                        <Col className="share-container">
                                            <CopyRow
                                                data-tip
                                                data-event="click"
                                                data-for={`copyToClipboard-${link}`}
                                                onClick={() =>
                                                    isMissingSignature &&
                                                    navigator.clipboard.writeText(
                                                        link
                                                    )
                                                }
                                            >
                                                <p>{t`skycop_lead_list.copy`}</p>
                                                <CopyIcon
                                                    color={primaryColor}
                                                />
                                            </CopyRow>
                                            <ReactTooltip
                                                event="no-event"
                                                place="bottom"
                                                id={`copyToClipboard-${link}`}
                                                globalEventOff={'click'}
                                                afterShow={() =>
                                                    isMissingSignature &&
                                                    navigator.clipboard.writeText(
                                                        link
                                                    )
                                                }
                                            >
                                                <span>
                                                    {t`skycop_lead_list.copied.tooltip`}
                                                </span>
                                            </ReactTooltip>
                                        </Col>
                                        <Col className="button-container">
                                            <V2Button2
                                                disabled={!isMissingSignature}
                                                onClick={() =>
                                                    window.open(link, '_blank')
                                                }
                                            >
                                                {isMissingSignature
                                                    ? t`skycop_lead_list.sign_now`
                                                    : t`skycop_lead_list.signed`}
                                            </V2Button2>
                                        </Col>
                                    </Row>
                                </DraftRow>
                            )
                        )}
                    </div>
                )}
                {passengersAgreements && !!passengersAgreements.length && (
                    <div>
                        <AgreementTitle
                            marginTop={24}
                        >{t`skycop_lead_list.passengers_list`}</AgreementTitle>
                        {passengersAgreements.map(
                            ({ fullName, link, isMissingSignature }) => (
                                <DraftRow key={link}>
                                    <Row className="container">
                                        <Col className="name-container">
                                            <p className="text">{fullName}</p>
                                        </Col>
                                        <Col className="share-container">
                                            <CopyRow
                                                data-tip
                                                data-event="click"
                                                data-for={`copyToClipboard-${link}`}
                                                onClick={() =>
                                                    isMissingSignature &&
                                                    navigator.clipboard.writeText(
                                                        link
                                                    )
                                                }
                                            >
                                                <p>{t`skycop_lead_list.copy`}</p>
                                                <CopyIcon
                                                    color={primaryColor}
                                                />
                                            </CopyRow>
                                            <ReactTooltip
                                                event="no-event"
                                                place="bottom"
                                                id={`copyToClipboard-${link}`}
                                                globalEventOff={'click'}
                                                afterShow={() =>
                                                    isMissingSignature &&
                                                    navigator.clipboard.writeText(
                                                        link
                                                    )
                                                }
                                            >
                                                <span>
                                                    {t`skycop_lead_list.copied.tooltip`}
                                                </span>
                                            </ReactTooltip>
                                        </Col>
                                        <Col className="button-container">
                                            <V2Button2
                                                disabled={!isMissingSignature}
                                                onClick={() =>
                                                    window.open(link, '_blank')
                                                }
                                            >
                                                {isMissingSignature
                                                    ? t`skycop_lead_list.sign_now`
                                                    : t`skycop_lead_list.signed`}
                                            </V2Button2>
                                        </Col>
                                    </Row>
                                </DraftRow>
                            )
                        )}
                    </div>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userAgreement: state.userAgreement,
        trans: state.translations,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

export default connect(mapStateToProps, {
    withRef: true,
})(PassengersClaimSignature)
