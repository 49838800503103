import React from 'react'
import styled from 'styled-components'
import { StopWatchIcon } from '../../../shared/components/V2/V2Icons'
import { desktop } from '../../../shared/components/helpers/styled_queries'
import { getStorage } from '../../../main'

const Container = styled.div`
    @keyframes appearAll {
        0% {
            ${({ minHeight }) =>
                minHeight === 1 ? `padding: 0px 16px;` : 'padding: 16px;'}
            max-height: ${({ minHeight }) => minHeight}px;
            overflow: hidden;
        }

        10% {
            padding: 16px;
        }

        100% {
            padding: 16px;
            max-height: 400px;
            overflow: initial;
        }
    }

    transition: all 0.5s ease;
    animation: appearAll;
    animation-duration: 1.7s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    color: ${({ theme }) => theme.colors.primaryColor};
    font-size: 16px;
    line-height: 22.4px;
    background: ${({ bgColor }) => bgColor};
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    font-weight: 400;
     font-family: 'Noto sans';

    ${desktop`
        align-items: center;
    `}

    b, a, .link {
        color: ${({ theme }) => theme.colors.primaryColor};
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
    }

    a, .link {
        text-decoration: underline;
        cursor: pointer;
    }

    svg {
        margin-right: 16px;
        min-width: 20px;
        min-height: 24px;
    }
`

class AirlineDocsInfoBox extends React.Component {
    render() {
        const {
            Content,
            bgColor = 'rgba(255, 182, 32, 0.08)',
            Icon,
            style,
            className,
            minHeight,
            ignoreLang,
        } = this.props

        if (
            !ignoreLang &&
            !['lt', 'en'].includes((getStorage('lang') || 'en').toLowerCase())
        ) {
            return null
        }

        return (
            <Container
                bgColor={bgColor}
                style={style || {}}
                className={className}
                minHeight={minHeight || 1}
            >
                {Icon ? <Icon /> : <StopWatchIcon fill="#FFB620" />}
                {typeof Content === 'string' ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: Content,
                        }}
                    />
                ) : (
                    <div>{Content}</div>
                )}
            </Container>
        )
    }
}

export default AirlineDocsInfoBox
