import React from 'react'
import styled from 'styled-components'
import t from '../../../shared/translations'
import { RightArrowStroke } from '../../../shared/components/V2/V2Icons'
import {
    desktop,
    mobile,
    tablet,
} from '../../../shared/components/helpers/styled_queries'

const CardContainer = styled.div`
    width: max-content;
    max-width: 255px;
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    position: relative;
    background: #493fc20f;
    height: 100%;

    ${mobile`
      width: 100%;
      max-width: ${props => (props.disableMaxWidth ? '' : 'unset')};
  `}
    ${tablet`
      width: 100%;
      max-width: ${props => (props.disableMaxWidth ? '' : 'unset')};
  `}
    padding: 16px;
    position: relative;
    &:before {
        padding: 2px;
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 1.25rem;
        background: linear-gradient(
            170deg,
            rgb(115, 222, 143, 1),
            rgb(136, 126, 255, 1)
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
`

const Header = styled.div`
    z-index: 1;
    ${desktop`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
`}

    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 24px;
    color: #353d51;
`
const Subtitle = styled.p`
    z-index: 1;
    font-weight: 700;
    margin-top: 12px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primaryColor};
    font-size: 12px;
    font-family: 'Open Sans';
`

const Content = styled.div`
    z-index: 1;
    ${desktop`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    white-space: pre-wrap;
    color: #525F7FB2;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    flex-grow: 1;
`}
`

const DottedLine = styled.div`
    background-image: linear-gradient(
        to right,
        grey 10%,
        rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 8px 1px;
    background-repeat: repeat-x;
    height: 1px;
`

const LearnMoreLink = styled.a`
    z-index: 1;
    display: flex;
    justify-content: start;

    font-weight: bold;
    color: #5b6784;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        color: #7f8fa4;
        text-decoration: none;
    }
`
const LinkLabel = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    fill: #5b6784;
    &:hover,
    &:active,
    &:focus {
        fill: #7f8fa4;
    }
`
const LinkLabelText = styled.div`
    margin-right: 4px;
    color: #525f7f;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Open Sans';
`

export const BenefitsSectionCard = ({
    title,
    subtitle,
    content,
    link,
    disableMaxWidth,
}) => (
    <CardContainer disableMaxWidth={disableMaxWidth}>
        <Header title={title}>{title}</Header>
        <DottedLine />
        <Subtitle>{subtitle}</Subtitle>
        <Content>{content}</Content>
        <LearnMoreLink target="_blank" href={link}>
            <LinkLabel>
                <LinkLabelText>{t`benefit_section_learn_more`}</LinkLabelText>
                <RightArrowStroke />
            </LinkLabel>
        </LearnMoreLink>
    </CardContainer>
)
