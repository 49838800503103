import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import {
    desktop,
    tabletLG,
    mobile,
} from '../../../shared/components/helpers/styled_queries'
import {
    QuestionMarkV2,
    ExclamationMark,
    CloseIcon2,
} from '../../../shared/components/V2/V2Icons'
import { getStorage, getCookie } from '../../../main'
import store from '../../../shared/services/store'
import { logKibana } from '../../../shared/actions'
import { isMobileDevice } from '../../../main'
import { CLAIM_FORM_VERSIONS } from '../../../shared/constants'

const Container = styled.div`
    align-self: center;
    border-radius: 4px;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    color: ${({ isAlert }) => (isAlert ? '#353d51' : '#353d51')};
    font-weight: 600;
    font-size: 16px;
    margin: auto;
    width: 100%;

    ul {
        margin-bottom: 0px;
        padding-left: 18px;
        list-style-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='10' viewBox='-1 -1 2 2'><circle r='0.3' /></svg>");
    }

    .box-title {
        min-height: 30px;
        background: ${({ theme }) => theme.colors.primaryColor}15;
        font-family: Noto Sans;
        font-size: 16px;
        color: ${({ theme }) => theme.colors.primaryColor};
        border-radius: 8px;
        padding: 4px 10px;
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
     
        background-repeat: no-repeat;
        background-position-x: calc(100% - 12px);
        background-position-y: 50%;
        width: fit-content;

        svg {
            min-width: 16px;
            min-height: 16px;
        }
        span {
            margin-right: 6px;
        }
    }

    ${tabletLG`
        ${({ fullWidth }) => (fullWidth ? '100%' : '66.66666666667%')}
    `}

     ${mobile`
        margin-top: 32px;
    `}

    ${desktop`
        width: unset;
        margin: 0;
        margin-left: auto;
        max-width: 300px;
        color: #353d51;
        background: ${({ isBoxWhite }) => isBoxWhite && '#FFFFFF'};


        .box-title {
            background-image: unset;

            &:hover {
                background: ${({ theme }) => theme.colors.primaryColor}15;
            }
        }
    `}
`

const InnerInfo = styled.div`
    line-height: 22.4px;
    ${mobile`
       margin-top: 6px;
    `}
    /* @keyframes appearAll {
        0% {
            max-height: 1px;
            overflow: hidden;
        }

        100% {
            max-height: 600px;
            overflow: initial;
        }
    }

    @keyframes appearAllDesktop {
        0% {
            max-height: 1px;
            opacity: 0;
            overflow: hidden;
        }

        100% {
            max-height: 600px;
            opacity: 1;
            overflow: initial;
        }
    } */

    min-height: fit-content;
    color: ${({ isAlert, innerWidth }) =>
        isAlert && innerWidth < 960 ? '#000000' : '#000000'};
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none !important')};
    border-top: 1px solid
        ${({ isAlert, innerWidth }) =>
            isAlert && innerWidth < 960 ? '#E9F0FB' : '#e6e8ec'};
    transition: all 1.5s ease;
    font-weight: normal;
    animation: appearAll;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    font-family: 'Noto Sans';
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.colors.primaryColor};
    padding: 24px;

    ${({ isAlert }) =>
        isAlert
            ? `
        a {
            color: #373f53;
            font-weight: 600;
        }
    `
            : ''}

    .svg-close {
        // display: none;
    }

    ${desktop`
        ${({ isOpen }) =>
            isOpen &&
            `
            display: flex;
        `}

        animation: appearAllDesktop;
        animation-duration: 0.3s;
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        transition: opacity 211ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 141ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: 0 3px 9px -5px rgba(53, 61, 81, 0.23);
        box-shadow: 0 4px 9px 1px rgba(53,61,81,0.23);
        position: absolute;
        background-color: #fff;
        top: 30px;
        right: 0;
        z-index: 550;
        font-weight: normal;
        line-height: 22.4px;
        min-width: 335px;
        max-width: 350px;
        width: unset;
    `}
`

const ContentDiv = styled.div`
    span {
        color: ${({ theme }) => theme.colors.primaryColor};
    }
`

const CloseIconWrapper = styled.div`
    cursor: pointer;
    height: fit-content;
    margin-top: -5px;
`

class TitleHeaderInfoBox extends React.Component {
    state = {
        innerWidth: window.innerWidth,
    }

    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick, false)
        window.addEventListener('resize', this._onResize)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false)
        window.removeEventListener('resize', this._onResize)
    }

    _onResize = debounce(() => {
        const width = window.innerWidth

        this.setState({ innerWidth: width })
    })

    shouldComponentUpdate(nextProps, nextState) {
        const { trans, isAlert, isOpen } = this.props
        const { innerWidth } = this.state

        return (
            trans['v2.sidebar_step.flight_itinerary'] !==
                nextProps.trans['v2.sidebar_step.flight_itinerary'] ||
            nextProps.isOpen !== isOpen ||
            nextState.innerWidth !== innerWidth ||
            nextProps.isAlert !== isAlert
        )
    }

    handleOutsideClick = e => {
        const container = ReactDOM.findDOMNode(this.container)
        const innerInfo = ReactDOM.findDOMNode(this.innerInfo)
        if (
            this.props.isOpen &&
            (!container || !container.contains(e.target)) &&
            (!innerInfo || !innerInfo.contains(e.target))
        ) {
            this.props.toggleOpen(false)
        }
    }

    toggleOpen = () => {
        const { isOpen, toggleOpen } = this.props
        const step = _get(window, 'location.pathname', '/')

        if (!isOpen) {
            store.dispatch(logKibana('FAQ_click_open', step, 'warning'))
        }

        toggleOpen(!isOpen)
    }

    render() {
        const { innerWidth } = this.state
        const {
            header,
            style = {},
            content,
            isOpen,
            alertHeader,
            alertContent,
            isAlert,
            fullWidth,
            HtmlContent,
            ignoreLang,
            primaryColor,
        } = this.props

        if (!ignoreLang) {
            if (
                (isAlert &&
                    innerWidth > 959 &&
                    !['lt', 'en'].includes(
                        (getStorage('lang') || 'en').toLowerCase()
                    )) ||
                (!isAlert &&
                    !['lt', 'en'].includes(
                        (getStorage('lang') || 'en').toLowerCase()
                    ))
            ) {
                return null
            }
        }

        const isClaimV1 = getCookie('cf') === CLAIM_FORM_VERSIONS.V1

        if (isClaimV1 && isMobileDevice()) {
            return null
        }

        return (
            <Container
                isBoxWhite={isClaimV1}
                style={style}
                isAlert={isAlert}
                isOpen={isOpen}
                ref={ref => (this.container = ref)}
                fullWidth={fullWidth}
            >
                <div className="box-title" onClick={this.toggleOpen}>
                    <span>
                        {isAlert && innerWidth < 960 ? alertHeader : header}
                    </span>
                    {isAlert && innerWidth < 960 ? (
                        <ExclamationMark color="#ffffff" fill="#5296FF" />
                    ) : (
                        <QuestionMarkV2 color={primaryColor} />
                    )}
                </div>
                {HtmlContent ? (
                    <InnerInfo
                        style={{
                            display:
                                isAlert && innerWidth < 960 ? 'block' : 'flex',
                            flexDirection: 'column',
                        }}
                        isAlert={isAlert}
                        innerWidth={innerWidth}
                        isOpen={isOpen}
                        ref={ref => (this.innerInfo = ref)}
                    >
                        <HtmlContent />
                    </InnerInfo>
                ) : (
                    <InnerInfo
                        style={{
                            display:
                                isAlert && innerWidth < 960 ? 'block' : 'flex',
                        }}
                        isAlert={isAlert}
                        innerWidth={innerWidth}
                        isOpen={isOpen}
                        ref={ref => (this.innerInfo = ref)}
                        // dangerouslySetInnerHTML={{
                        //     __html:
                        //         isAlert && innerWidth < 960
                        //             ? alertContent
                        //             : content,
                        // }}
                    >
                        <ContentDiv
                            dangerouslySetInnerHTML={{
                                __html:
                                    isAlert && innerWidth < 960
                                        ? alertContent
                                        : content,
                            }}
                        />
                        <CloseIconWrapper onClick={this.toggleOpen}>
                            <CloseIcon2 />
                        </CloseIconWrapper>
                    </InnerInfo>
                )}
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TitleHeaderInfoBox)
