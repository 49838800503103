import { combineReducers } from 'redux'
import V2_reducers from './V2'
import AuthReducer from './reducer_auth'
import ErrorReducer from './reducer_error'
import FlashMessageReducer from './reducer_flash_message'
import UserReducer from './reducer_user'
import CountriesReducer from './reducer_countries'
import AirportsReducer from './reducer_airports'
import AirlinesReducer from './reducer_airlines'
import StopsReducer from './reducer_stops'
import ClaimReducer from './reducer_claim'
import ClaimReasonsReducer from './reducer_claim_reasons'
import ClaimHistoryReducer from './reducer_claim_history'
import DelayReasonsReducer from './reducer_delay_reasons'
import SignatureReducer from './reducer_signature'
import PaymentPlanReducer from './reducer_payment_plan'
import TranslationsReducer from './reducer_translations'
import DefaultTranslationsReducer from './reducer_default_translations'
import LanguageReducer from './reducer_language'
import UserClaimsReducer from './reducer_user_claims'
import SelectedClaimReducer from './reducer_select_claim'
import ClaimableAmtReducer from './reducer_claimable_amount'
import Reason1 from './reducer_reason_1'
import Reason2 from './reducer_reason_2'
import Reason3 from './reducer_reason_3'
import Reason4 from './reducer_reason_4'
import PayoutPlans from './reducer_payout_plans'
import AboutUs from './reducer_about_us.js'
import PassengerName from './reducer_passenger_name.js'
import RelatedClaims from './reducer_related_claims.js'
import DuplicateReducer from './reducer_duplicate.js'
import DomainInfo from './reducer_domain.js'
import WebLanguagesReducer from './reducer_web_languages.js'
import UserAgreement from './reducer_agreement.js'
import Config from './reducer_config.js'
import ApiClient from './reducer_api_client'
import ThemeReducer from './reducer_theme'
import LoadingTextReducer from './reducer_set_loading_text'
import ClaimErrors from './reducer_claim_errors'
import CpActionsReducer from './reducer_cp_actions'
import SupportedPostcodesReducer from './reducer_supported_postcodes'
import SubmitedClaimData from './reducer_submited_claim_data'
import ValidSmtpReducer from './reducer_valid_smtp'
import PassengerSmtpReducer from './reducer_passenger_smtp'
import ReasonCoefficientsReducer from './reducer_reason_coefficients'
import BrandedGdprReducer from './reducer_branded_gdpr'
import CurrencyReducer from './reducer_currency'
import BufferedClaims from './reducer_buffered_claims'
import IdAssets from './reducer_id_assets'
import LandingParameters from './reducer_landing_parameters'

import { reducer as FormReducer } from 'redux-form'

const rootReducer = combineReducers({
    smartDelay: V2_reducers.reducer_smart_delay,
    isSkycopCare: V2_reducers.reducer_skycopcare,
    sc_v2_products: V2_reducers.reducer_skycopcare_products_v2,
    sc_products: V2_reducers.reducer_skycopcare_products,
    subscriptions: V2_reducers.reducer_skycopcare_subscriptions,
    sc_errors: V2_reducers.reducer_skycopcare_user,
    suggestedFlights: V2_reducers.reducer_suggested_flights,
    draftEmail: V2_reducers.reducer_draft_email,
    regulation: V2_reducers.reducer_regulation,
    hideElements: V2_reducers.reducer_hide_elements,
    notSupportedAirlines: V2_reducers.reducer_not_supported_airlines,
    claimPreloaded: V2_reducers.reducer_claim_draft_preload,
    claimDraftId: V2_reducers.reducer_claim_draft_id,
    claimDraftProps: V2_reducers.reducer_claim_draft_props,
    claimDraft: V2_reducers.reducer_claim_draft,
    claimDraftErrors: V2_reducers.reducer_draft_errors,
    isEnabledPostCode: V2_reducers.reducer_enabled_postcode,
    v2_payout_details: V2_reducers.reducer_payout_details,
    v2_hasFastPayout: V2_reducers.reducer_has_fast_payout,
    unavailableService: V2_reducers.reducer_unavailable_service,
    step_2_state: V2_reducers.reducer_step_2_state,
    selectedPayout: V2_reducers.reducer_selected_plan,
    isFastSelected: V2_reducers.reducer_fast_selected,
    hideCookies: V2_reducers.reducer_hide_mobile_cookies,
    step_3_state: V2_reducers.reducer_step_3_state,
    step_4_state: V2_reducers.reducer_step_4_state,
    step_5_state: V2_reducers.reducer_step_5_state,
    step_6_state: V2_reducers.reducer_step_6_state,
    v2_posted_claim: V2_reducers.reducer_posted_claim,
    v2_skip_signature: V2_reducers.reducer_skip_signature,
    v2_onboarding_slider: V2_reducers.reducer_onboarding_slider,
    v2_total_amount: V2_reducers.reducer_total_amount,
    v2_amount: V2_reducers.reducer_claim_amount,
    v2_progressState: V2_reducers.reducer_progress_bar,
    v2_promoCodes: V2_reducers.reducer_promo_codes,
    v2_distance: V2_reducers.reducer_flight_distance,
    v2_loginLoading: V2_reducers.reducer_login_loading,
    v2_reasonByAirlines: V2_reducers.reducer_airlines_reasons,
    claim_UUID: V2_reducers.reducer_claim_UUID,
    v2_UsedAirports: V2_reducers.reducer_used_airports,
    v2_selectedAirports: V2_reducers.reducer_selected_airports,
    v2_selectedAirlines: V2_reducers.reducer_selected_airlines,
    v2_UsedAirlines: V2_reducers.reducer_used_airlines,
    V2Claim: V2_reducers.reducer_v2_claim,
    v2_user_documents: V2_reducers.reducer_user_documents,
    v2_payoutPlan: V2_reducers.reducer_payout_plan,
    v2_signature: V2_reducers.reducer_signature,
    v2_documents: V2_reducers.reducer_documents,
    v2_translations: V2_reducers.reducer_v2_translations,
    v2_price: V2_reducers.reducer_price,
    v2_partnerId: V2_reducers.reducer_partner_id,
    v2_airportArrival: V2_reducers.reducer_arrival_airport,
    v2_airportDeparture: V2_reducers.reducer_departure_airport,
    v2_timeDeparture: V2_reducers.reducer_reservation_number,
    v2_reservationNumber: V2_reducers.reducer_reservation_number,
    v2_problemClaimFlight: V2_reducers.reducer_stops,
    v2_comment: V2_reducers.reducer_comment,
    v2_claimable: V2_reducers.reducer_claimable,
    v2_passengers: V2_reducers.reducer_passengers,
    v2_flights: V2_reducers.reducer_flights,
    v2_details: V2_reducers.reducer_details,
    v2_user: V2_reducers.reducer_user,
    v2_step_seven: V2_reducers.reducer_step_seven,
    v2_step_six: V2_reducers.reducer_step_six,
    v2_step_five: V2_reducers.reducer_step_five,
    v2_step_four: V2_reducers.reducer_step_four,
    v2_step_three: V2_reducers.reducer_step_three,
    v2_step_two: V2_reducers.reducer_step_two,
    v2_step_one: V2_reducers.reducer_step_one,
    v2_step_one_errors: V2_reducers.reducer_step_one_validation,
    v2_step_two_errors: V2_reducers.reducer_step_two_validation,
    v2_step_three_errors: V2_reducers.reducer_step_three_validation,
    v2_step_four_errors: V2_reducers.reducer_step_four_validation,
    v2_step_five_errors: V2_reducers.reducer_step_five_validation,
    v2_step_six_errors: V2_reducers.reducer_step_six_validation,
    profileViewErrors: V2_reducers.reducer_profile_errors,
    paymentViewErrors: V2_reducers.reducer_payment_errors,
    v2_step: V2_reducers.reducer_steps,
    top_bar_info: V2_reducers.reducer_top_bar_info,
    toggle_modal: V2_reducers.reducer_toggle_modal,
    allDrafts: V2_reducers.reducer_all_drafts,
    leaving_modal: V2_reducers.reducer_leaving_modal,
    form: FormReducer,
    auth: AuthReducer,
    error: ErrorReducer,
    flash_message: FlashMessageReducer,
    user: UserReducer,
    countries: CountriesReducer,
    airports: AirportsReducer,
    airlines: AirlinesReducer,
    stops: StopsReducer,
    claim: ClaimReducer,
    signature: SignatureReducer,
    payment_plan: PaymentPlanReducer,
    claim_reasons: ClaimReasonsReducer,
    claim_history: ClaimHistoryReducer,
    claim_errors: ClaimErrors,
    delay_reasons: DelayReasonsReducer,
    translations: TranslationsReducer,
    default_translations: DefaultTranslationsReducer,
    language: LanguageReducer,
    user_claims: UserClaimsReducer,
    related_claims: RelatedClaims,
    claimable_amt: ClaimableAmtReducer,
    selected_claim: SelectedClaimReducer,
    reason_1: Reason1,
    reason_2: Reason2,
    reason_3: Reason3,
    reason_4: Reason4,
    payouts: PayoutPlans,
    about_us: AboutUs,
    passenger_name: PassengerName,
    duplicate: DuplicateReducer,
    domain: DomainInfo,
    web_languages: WebLanguagesReducer,
    userAgreement: UserAgreement,
    config: Config,
    api_client: ApiClient,
    propsTheme: ThemeReducer,
    loading_text: LoadingTextReducer,
    cp_actions: CpActionsReducer,
    submited_claim_data: SubmitedClaimData,
    valid_email_smtp: ValidSmtpReducer,
    passenger_smtp: PassengerSmtpReducer,
    reason_coefficients: ReasonCoefficientsReducer,
    show_branded_gdpr: BrandedGdprReducer,
    currencies: CurrencyReducer,
    supportedPostcodes: SupportedPostcodesReducer,
    bufferedClaims: BufferedClaims,
    idAssets: IdAssets,
    landing_parameters: LandingParameters,
    signaturePageConfig: V2_reducers.reducer_signature_page_config,
    referralModal: V2_reducers.reducer_referral_modal,
    promoCode: V2_reducers.reducer_promo_code,
})
export default rootReducer
