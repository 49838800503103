import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Helmet from 'react-helmet'
import { browserHistory } from 'react-router'
import _get from 'lodash/get'
import t from '../../../../shared/translations'
import {
    loadScreenOn,
    scrollTo,
    isFeatureEnabled,
    getApiClient,
} from '../../../../main'
// Input fields
import InputField from '../../../../shared/components/V2/InputField'
import CountryInput from '../../components/CountryInput'
import CurrencyInput from '../../components/CurrencyInput'
import V2CheckBox from '../../../../shared/components/V2/V2CheckBox'

//Layout
import { V2Button } from '../../../../shared/components/V2/V2Buttons'
import {
    Col,
    Row,
    InfoBlock,
    CheckMarkBox,
    PageViewContainer,
} from '../../../../shared/components/V2/Layout'
import SuccessBox from '../../components/SuccessBox'
import { PaymentContainer, CheckBoxInner } from './paymentStyles'

//Actions
import {
    getCurrencies,
    uploadPaymentDetails,
    showSuccessMessage,
    clearAuthErrors,
} from '../../../../shared/actions'
import {
    getUserData,
    validatePaymentView,
} from '../../../../shared/actions/v2_actions'

import { paths, featureToggles } from '../../../../shared/constants'
import {
    InvalidCountryRedirect,
    IbanInfoBox,
    InfoBlockContentTitle,
} from './PaymentElements'
import store from '../../../../shared/services/store'
import { CURRENCIES } from '../../../../shared/actions/actionTypes'
import CountryRegionInput from '../../components/CountryRegionInput'
import { includes } from 'lodash'

class Payment extends React.Component {
    nextTimeout = null

    state = {
        swift: '',
        iban: '',
        bankCountry: '',
        bankAddress: '',
        bankName: '',
        bank_details: '',
        iban_length: 24,
        userName: '',
        userSurname: '',
        currency: '',
        checkName: false,
        isInvalidCountry: false,
        success: false,
        disableSubmit: false,
        preventDoubleClick: false,
        alertCheckbox: false,
        receiverAddress: '',
        receiverCity: '',
        receiverCountry: '',
        receiverPostalCode: '',
        receiverRegion: '',
        requiredFieldsBasedOnReceiverCountry: [],
        regionOptions: [],
    }

    static getDerivedStateFromProps(props, state) {
        const { user } = props

        if (user && !user.active) {
            browserHistory.push(paths.V2.MY_PROFILE)
            return null
        } else {
            return state
        }
    }

    componentDidMount() {
        const { countries, getCurrencies } = this.props

        getCurrencies()
        this.setExistingState()

        if (countries) {
            this.ibanSettings()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { countries, trans, user, currencies } = this.props
        const { bankCountry } = this.state

        if (!prevProps.user && user) {
            this.setExistingState()

            if (!user.active) {
                browserHistory.push(paths.V2.MY_PROFILE)
            }
        }

        if ((currencies || []).length > prevProps.currencies.length) {
            this.setExistingState(currencies)
        }

        if (!prevProps.countries && countries) {
            this.ibanSettings(null, countries)
        } else if (countries && !bankCountry) {
            this.ibanSettings()
        }

        if ((!prevProps.trans && trans) || (!prevProps.user && user)) {
            this.alertInvalidCountry()
        }
    }

    recheckErrors = name => {
        const { paymentErrors } = this.props
        const { errors } = paymentErrors
        if (name && errors && errors[name]) {
            const errors = this.validateForm()

            if (!Object.keys(errors).length) {
                this.setState({ disableSubmit: false })
            } else {
                this.setState({ disableSubmit: true })
            }
        }
    }

    handleChange = e => {
        const target = e.target || e
        const { name, value } = target

        if ('userName' === name || 'userSurname' === name) {
            const targetValue = target.value
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/ +(?= )/g, '')
                .replace(/[^a-zA-Z0-9\d\s]/g, '')
            this.setState({ [name]: targetValue }, () => {
                this.recheckErrors(name)
            })
        } else if (
            isFeatureEnabled(featureToggles.isSwiftValidationEnabled) &&
            ('swift' === name || 'iban' === name)
        ) {
            this.setState(
                { [name]: value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '') },
                () => {
                    this.recheckErrors(name)
                }
            )
        } else {
            this.setState({ [name]: value }, () => {
                this.recheckErrors(name)
            })
        }

        const shouldValidateForm = !!this.state.bankCountry
        if (name === 'bankCountry') {
            this.ibanSettings(value)
            this.setState(
                {
                    checkName: false,
                    alertCheckbox: false,
                },
                () => {
                    if (shouldValidateForm) {
                        this.alertInvalidCountry()
                    }
                }
            )
        }
        if (name === 'receiverCountry') {
            this.getRegionsBasedOnCountrySelect(value)
            this.getRequiredFieldsBasedOnCountry(value)
        }
    }

    splitUserName = (receiverName, obj = 'name') => {
        if (!receiverName) {
            return ''
        }

        const splitName = receiverName.split(' ')
        const fullName = {
            name: splitName[0],
            surname: splitName.slice(1, splitName.length).join(' '),
        }

        return fullName[obj]
    }

    handleCurrencyReplace = (userCurrency, defaultCurrency) => {
        const { currencies } = this.props
        if (currencies.length !== 3 || currencies.length !== 2) {
            const currentCurrency = _get(
                currencies.filter(c => c.id === userCurrency),
                '[0]',
                null
            )
            const dollarsCurrency = currencies.filter(c => c.currency === 'USD')
            const eurosCurrency = currencies.filter(
                c => c.id === defaultCurrency
            )

            let payload = [...eurosCurrency, ...dollarsCurrency]
            if (
                currentCurrency &&
                !['EUR', 'USD'].includes(currentCurrency.currency)
            ) {
                currentCurrency.isDisabled = true
                payload.push({
                    ...currentCurrency,
                })
            }

            store.dispatch({
                type: CURRENCIES,
                payload,
            })
        }
    }

    setExistingState = nextCurrencies => {
        const { user, currencies } = this.props
        const defCurrObj = (nextCurrencies || currencies || []).filter(
            c => c.currency === 'EUR'
        )
        const defaultCurrency = _get(defCurrObj, '[0].id', '')

        if (user && user.user_payment) {
            const { user_payment } = user
            const userCurrency = _get(user_payment, 'currency_country.id', '')

            this.setState(
                {
                    iban: user_payment.iban || '',
                    swift: user_payment.swift || '',
                    userName:
                        this.splitUserName(user_payment.receiver_name) || '',
                    userSurname:
                        this.splitUserName(
                            user_payment.receiver_name,
                            'surname'
                        ) || '',
                    bankName: user_payment.bank_name || '',
                    bankAddress: user_payment.bank_address || '',
                    bankCountry: _get(user_payment, 'bank_country.id', ''),
                    currency: userCurrency || defaultCurrency,

                    receiverAddress: _get(user_payment, 'receiver_address', ''),
                    receiverCity: _get(user_payment, 'receiver_city', ''),
                    receiverCountry: _get(
                        user_payment,
                        'receiver_country_id',
                        ''
                    ),
                    receiverPostalCode: _get(
                        user_payment,
                        'receiver_postal_code',
                        ''
                    ),
                    receiverRegion: _get(
                        user_payment,
                        'receiver_country_region_id',
                        ''
                    ),
                },
                () => {
                    const {
                        checkName,
                        isInvalidCountry,
                        receiverCountry,
                    } = this.state
                    this.ibanSettings()
                    this.alertInvalidCountry()
                    const errors = this.validateForm()

                    if (receiverCountry) {
                        this.getRequiredFieldsBasedOnCountry(receiverCountry)
                        this.getRegionsBasedOnCountrySelect(
                            receiverCountry,
                            true
                        )
                    }

                    if (!Object.keys(errors).length && !isInvalidCountry) {
                        if (
                            (this.shouldValidateLocal() ||
                                this.shouldValidateIbanLocal()) &&
                            !checkName
                        ) {
                            this.setState({ disableSubmit: true })
                        } else {
                            this.setState({ disableSubmit: false })
                        }
                    } else {
                        this.setState({ disableSubmit: true })
                    }

                    if (
                        isFeatureEnabled(
                            featureToggles.featureCurrencyRestriction
                        ) &&
                        userCurrency
                    ) {
                        this.handleCurrencyReplace(
                            userCurrency,
                            defaultCurrency
                        )
                    }
                }
            )
        } else if (user) {
            this.setState({
                userName: this.splitUserName(),
                userSurname: this.splitUserName(null, 'surname'),
                currency: isFeatureEnabled(
                    featureToggles.featureCurrencyRestriction
                )
                    ? defaultCurrency
                    : '',
            })

            if (isFeatureEnabled(featureToggles.featureCurrencyRestriction)) {
                this.handleCurrencyReplace(null, defaultCurrency)
            }
        }
    }

    ibanSettings = (val, propCountries) => {
        let countries = propCountries || this.props.countries
        const { bankCountry } = this.state

        if (countries && countries.payload && (val || bankCountry)) {
            const country = countries.payload.filter(
                c => c.id === (val || bankCountry)
            )[0]

            if (country) {
                this.setState(
                    {
                        bank_details: country.bank_details,
                        iban_length: country.iban_length,
                    },
                    () => this.alertInvalidCountry()
                )
            }
        }
    }

    isRequired = isLocal => {
        const { bank_details } = this.state
        let result = false

        switch (bank_details) {
            case 'local_accounts':
                result = true
                break
            case 'iban_user_address':
                isLocal ? (result = true) : (result = false)
                break
            default:
                result = false
        }
        return result
    }

    getIbanLabel = () => {
        return `${t`payment_page.${this.isRequired() ? 'account' : 'iban'}`}`
    }

    shouldValidateLocal = () => {
        const { bank_details } = this.state
        return bank_details && bank_details === 'local_accounts'
    }

    shouldValidateIbanLocal = () => {
        const { bank_details } = this.state
        return bank_details && bank_details === 'iban_user_address'
    }

    alertInvalidCountry = () => {
        const { trans } = this.props
        let isInvalidCountry = false
        if (!trans) {
            return isInvalidCountry
        }
        const { bankCountry } = this.state
        const { user } = this.props
        const userCountryId = _get(user, 'country.id', null)

        if (
            userCountryId &&
            bankCountry &&
            bankCountry !== userCountryId &&
            this.shouldValidateLocal()
        ) {
            isInvalidCountry = true
        }

        this.setState({ isInvalidCountry })

        return isInvalidCountry
    }

    validateForm = (required = true, scrollToField = false) => {
        const { validatePaymentView } = this.props

        let validation

        validation = validatePaymentView(this.state, required, {
            iban: this.isRequired(),
        })

        const errors = _get(validation, 'errors', {})

        if (scrollToField) {
            const missingInput = document.getElementsByName(
                Object.keys(errors)[0]
            )[0]
            if (missingInput && missingInput.getBoundingClientRect()) {
                scrollTo(
                    document.body,
                    missingInput.getBoundingClientRect().top +
                        window.scrollY -
                        110,
                    1000
                )
            } else if (this.alertInvalidCountry()) {
                const errorBox = document.getElementById('error-list-box')
                if (errorBox && errorBox.getBoundingClientRect().top) {
                    scrollTo(
                        document.body,
                        errorBox.getBoundingClientRect().top +
                            window.scrollY -
                            150,
                        1500
                    )
                }
            } else if (!this.state.checkName) {
                this.setState({ alertCheckbox: true })
            }
        }

        return errors
    }

    submitPaymentDetails = () => {
        const {
            uploadPaymentDetails,
            lang,
            getUserData,
            clearAuthErrors,
            errorMessage,
        } = this.props
        const {
            iban,
            swift,
            userName,
            userSurname,
            bankName,
            bankAddress,
            bankCountry,
            currency,
            checkName,
            isInvalidCountry,
            receiverAddress,
            receiverCity,
            receiverCountry,
            receiverPostalCode,
            receiverRegion,
            requiredFieldsBasedOnReceiverCountry,
        } = this.state

        const ibanError = _get(errorMessage, 'iban[0]', '')
        const errors = this.validateForm()

        const fd = new FormData()
        if (!Object.keys(errors).length && !isInvalidCountry) {
            if (
                (this.shouldValidateLocal() ||
                    this.shouldValidateIbanLocal()) &&
                !checkName
            ) {
                return
            }

            fd.append('user_payment[iban]', iban)
            fd.append('user_payment[swift]', swift)
            fd.append('user_payment[firstName]', userName)
            fd.append('user_payment[lastName]', userSurname)
            fd.append('user_payment[bankName]', bankName)
            fd.append('user_payment[bankAddress]', bankAddress)
            fd.append('user_payment[bankCountry]', bankCountry)
            fd.append('user_payment[currencyCountry]', currency)

            fd.append('user_payment[receiverCountry]', receiverCountry)
            if (
                includes(
                    requiredFieldsBasedOnReceiverCountry,
                    'receiverAddress'
                )
            ) {
                fd.append('user_payment[receiverAddress]', receiverAddress)
            }
            if (
                includes(requiredFieldsBasedOnReceiverCountry, 'receiverCity')
            ) {
                fd.append('user_payment[receiverCity]', receiverCity)
            }
            if (
                includes(requiredFieldsBasedOnReceiverCountry, 'receiverRegion')
            ) {
                fd.append('user_payment[receiverRegion]', receiverRegion)
            }
            if (
                includes(
                    requiredFieldsBasedOnReceiverCountry,
                    'receiverPostalCode'
                )
            ) {
                fd.append(
                    'user_payment[receiverPostalCode]',
                    receiverPostalCode
                )
            }

            const { preventDoubleClick } = this.state

            if (preventDoubleClick) {
                return
            } else {
                loadScreenOn()
                uploadPaymentDetails(fd, lang).then(res => {
                    if (res) {
                        if (ibanError) {
                            clearAuthErrors()
                        }
                        scrollTo(document.body, 0, 1000)
                        this.setState({ success: true })
                        getUserData()
                    }
                })
            }
            this.setState({ preventDoubleClick: true }, () => {
                if (this.nextTimeout) {
                    clearTimeout(this.nextTimeout)
                }

                this.nextTimeout = setTimeout(() => {
                    this.setState({ preventDoubleClick: false })
                }, 1500)
            })
        }
    }

    validateSuggestedIban = value => {
        if (!isFeatureEnabled(featureToggles.featureIsBankPrefillEnabled))
            return
        const apiClient = getApiClient()
        const url = '/api/iban/info'

        apiClient
            .post(url, { iban: value })
            .then(({ data }) => {
                if (data.bic) {
                    this.setState({
                        swift: data.bic,
                    })
                }
                if (data.bankAddress) {
                    this.setState({
                        bankAddress: data.bankAddress,
                    })
                }

                if (data.bankTitle) {
                    this.setState({
                        bankName: data.bankTitle,
                    })
                }
            })
            .catch(e => console.error(e))
    }

    getRegionsBasedOnCountrySelect = (value, init) => {
        const apiClient = getApiClient()
        if (!init) {
            this.setState({
                receiverRegion: '',
                receiverAddress: '',
                receiverCity: '',
                receiverPostalCode: '',
            })
        }
        apiClient
            .get(`/api/country/${value}/regions`)
            .then(({ data }) => {
                const tempRegions = data.reduce((acc, current) => {
                    acc.push({
                        id: current.id,
                        label: current.title,
                        name: 'receiverRegion',
                        value: current.id,
                    })
                    return acc
                }, [])
                this.setState({
                    regionOptions: tempRegions,
                })
            })
            .catch(e => console.error(e))
    }

    getRequiredFieldsBasedOnCountry = value => {
        const apiClient = getApiClient()
        apiClient
            .get(`/api/country/${value}/payment-fields`)
            .then(({ data }) => {
                this.setState({ requiredFieldsBasedOnReceiverCountry: data })
            })
            .catch(e => console.error(e))
    }

    render() {
        const {
            trans,
            user,
            paymentErrors,
            errorMessage,
            currencies,
            primaryColor,
        } = this.props
        const {
            bankCountry,
            checkName,
            alertCheckbox,
            currency,
            iban,
            swift,
            bankName,
            bankAddress,
            userName,
            userSurname,
            isInvalidCountry,
            success,
            disableSubmit,
            receiverAddress,
            requiredFieldsBasedOnReceiverCountry,
            receiverCountry,
            regionOptions,
            receiverRegion,
            receiverPostalCode,
            receiverCity,
        } = this.state

        const activeUser = _get(user, 'active', false)

        if (!trans) return null

        const isDisabledSubmit =
            this.shouldValidateLocal() || this.shouldValidateIbanLocal()
                ? !checkName || disableSubmit
                : disableSubmit

        if (!activeUser) {
            return null
        } else {
            return (
                <PageViewContainer>
                    <PaymentContainer>
                        <Helmet>
                            <title>{`Skycop - ${t`payment_page.title.claim`}`}</title>
                        </Helmet>
                        <InfoBlock
                            title={t`payment_page.payment_information.title`}
                        >
                            {bankCountry && (
                                <InvalidCountryRedirect
                                    isInvalidCountry={isInvalidCountry}
                                    user={user}
                                />
                            )}
                            {success && (
                                <Row>
                                    <Col>
                                        <SuccessBox
                                            primaryColor={primaryColor}
                                            onClick={() =>
                                                this.setState({
                                                    success: false,
                                                })
                                            }
                                            title={t`user.edit_profile.updated_success`}
                                        />
                                    </Col>
                                </Row>
                            )}
                            <InfoBlockContentTitle>{t`payment_page.payment_information.bank.details`}</InfoBlockContentTitle>
                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <CountryInput
                                        value={bankCountry}
                                        defaultValue={bankCountry}
                                        onChange={this.handleChange}
                                        name="bankCountry"
                                        required
                                        clearable={false}
                                        placeholder={t`payment_page.placeholder.country`}
                                        label={t`payment_page.bank_country`}
                                        errorText={t`${_get(
                                            paymentErrors,
                                            'errors.country',
                                            ''
                                        )}`}
                                        id="bank-country"
                                    />
                                </Col>
                                <Col xs={12} md={6} lg={6}>
                                    <CurrencyInput
                                        value={currency}
                                        onChange={e => {
                                            if (
                                                !isFeatureEnabled(
                                                    featureToggles.featureCurrencyRestriction
                                                )
                                            ) {
                                                this.handleChange(e)
                                            } else {
                                                if (
                                                    currencies.length === 3 ||
                                                    currencies.length === 2
                                                ) {
                                                    this.handleChange(e)
                                                } else {
                                                    return
                                                }
                                            }
                                        }}
                                        required
                                        name="currency"
                                        clearable={false}
                                        placeholder={t`payment_page.placeholder.currency`}
                                        label={t`payment_page.currency`.replace(
                                            '*',
                                            ''
                                        )}
                                        errorText={t`${_get(
                                            paymentErrors,
                                            'errors.currency',
                                            ''
                                        )}`}
                                        id="payment-currency"
                                    />
                                </Col>
                            </Row>
                            {bankCountry && (
                                <React.Fragment>
                                    <Row>
                                        <InputField
                                            name="iban"
                                            label={this.getIbanLabel()}
                                            placeholder={t`payment_page.placeholder.iban`}
                                            value={iban}
                                            onChange={this.handleChange}
                                            required
                                            errorText={t`${_get(
                                                paymentErrors,
                                                'errors.iban',
                                                ''
                                            ) ||
                                                _get(
                                                    errorMessage,
                                                    'iban[0]',
                                                    ''
                                                )}`}
                                            id="iban-number"
                                            onBlur={e =>
                                                this.validateSuggestedIban(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <IbanInfoBox
                                            isIban={!this.isRequired()}
                                            isMobile={true}
                                        />
                                        <InputField
                                            name="swift"
                                            label={t`payment_page.swift`}
                                            placeholder={t`payment_page.placeholder.swift`}
                                            value={swift}
                                            onChange={this.handleChange}
                                            required
                                            errorText={t`${_get(
                                                paymentErrors,
                                                'errors.swift',
                                                ''
                                            )}`}
                                            id="swift-number"
                                        />
                                    </Row>
                                    <IbanInfoBox isIban={!this.isRequired()} />
                                    <Row>
                                        <InputField
                                            name="bankName"
                                            label={t`payment_page.bank_name`}
                                            placeholder={t`payment_page.placeholder.bank_name`}
                                            value={bankName}
                                            onChange={this.handleChange}
                                            required
                                            errorText={t`${_get(
                                                paymentErrors,
                                                'errors.bankName',
                                                ''
                                            )}`}
                                            id="bank-name"
                                        />
                                        <InputField
                                            name="bankAddress"
                                            label={t`payment_page.bank_address`}
                                            placeholder={t`payment_page.placeholder.bank_address`}
                                            value={bankAddress}
                                            onChange={this.handleChange}
                                            errorText={t`${_get(
                                                paymentErrors,
                                                'errors.bankAddress',
                                                ''
                                            )}`}
                                            id="bank-address"
                                        />
                                    </Row>
                                    <br />
                                    <InfoBlockContentTitle>{t`payment_page.payment_information.recipient_information`}</InfoBlockContentTitle>
                                    <Row>
                                        <InputField
                                            name="userName"
                                            label={t`v2_common.label.name`}
                                            placeholder={t`v2_common.placeholder.name`}
                                            value={userName}
                                            onChange={this.handleChange}
                                            required
                                            errorText={t`${_get(
                                                paymentErrors,
                                                'errors.userName',
                                                ''
                                            )}`}
                                            id="user-name"
                                        />
                                        <InputField
                                            name="userSurname"
                                            label={t`v2_common.label.surname`}
                                            placeholder={t`v2_common.placeholder.surname`}
                                            value={userSurname}
                                            onChange={this.handleChange}
                                            required
                                            errorText={t`${_get(
                                                paymentErrors,
                                                'errors.userSurname',
                                                ''
                                            )}`}
                                            id="user-surname"
                                        />
                                    </Row>
                                    <Row>
                                        {includes(
                                            requiredFieldsBasedOnReceiverCountry,
                                            'receiverAddress'
                                        ) && (
                                            <InputField
                                                name="receiverAddress"
                                                label={t`v2_common.label.address`}
                                                placeholder={t`common.label.is_required.address`}
                                                value={receiverAddress}
                                                onChange={this.handleChange}
                                                required
                                                errorText={t`${_get(
                                                    paymentErrors,
                                                    'errors.receiverAddress',
                                                    ''
                                                )}`}
                                                id="receiver-address"
                                            />
                                        )}
                                        {includes(
                                            requiredFieldsBasedOnReceiverCountry,
                                            'receiverCity'
                                        ) && (
                                            <InputField
                                                name="receiverCity"
                                                label={t`v2_common.label.city`}
                                                placeholder={t`v2_common.placeholder.city`}
                                                value={receiverCity}
                                                onChange={this.handleChange}
                                                required
                                                errorText={t`${_get(
                                                    paymentErrors,
                                                    'errors.receiverCity',
                                                    ''
                                                )}`}
                                                id="receiver-city"
                                            />
                                        )}
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={6}>
                                            <CountryInput
                                                value={receiverCountry}
                                                defaultValue={receiverCountry}
                                                onChange={this.handleChange}
                                                name="receiverCountry"
                                                required
                                                clearable={false}
                                                placeholder={t`payment_page.placeholder.country`}
                                                label={t`v2_common.label.country`}
                                                errorText={t`${_get(
                                                    paymentErrors,
                                                    'errors.receiverCountry',
                                                    ''
                                                )}`}
                                                id="bank-receiver-country-id"
                                            />
                                        </Col>
                                        {includes(
                                            requiredFieldsBasedOnReceiverCountry,
                                            'receiverPostalCode'
                                        ) && (
                                            <InputField
                                                name="receiverPostalCode"
                                                label={t`common.label.postcode`}
                                                placeholder={t`common.label.is_required.postcode`}
                                                value={receiverPostalCode}
                                                onChange={this.handleChange}
                                                required
                                                errorText={t`${_get(
                                                    paymentErrors,
                                                    'errors.receiverPostalCode',
                                                    ''
                                                )}`}
                                                id="receiver-postal-code"
                                            />
                                        )}
                                    </Row>
                                    {includes(
                                        requiredFieldsBasedOnReceiverCountry,
                                        'receiverRegion'
                                    ) && (
                                        <Row>
                                            <Col xs={12} md={6} lg={6}>
                                                <CountryRegionInput
                                                    value={receiverRegion}
                                                    defaultValue={
                                                        receiverRegion
                                                    }
                                                    onChange={this.handleChange}
                                                    regionOptions={
                                                        regionOptions
                                                    }
                                                    name="receiverRegion"
                                                    required
                                                    clearable={false}
                                                    errorText={t`${_get(
                                                        paymentErrors,
                                                        'errors.receiverRegion',
                                                        ''
                                                    )}`}
                                                    id="bank-receiver-region"
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </React.Fragment>
                            )}
                        </InfoBlock>
                        {(this.shouldValidateLocal() ||
                            this.shouldValidateIbanLocal()) && (
                            <React.Fragment>
                                <InfoBlock>
                                    <Row>
                                        <Col>
                                            <CheckMarkBox
                                                label={t`payment_page.check_text.first`}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CheckMarkBox
                                                label={t`payment_page.check_text.second`}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CheckMarkBox
                                                label={t`payment_page.check_text.third`}
                                            />
                                        </Col>
                                    </Row>
                                </InfoBlock>
                                <InfoBlock>
                                    <Row>
                                        <Col>
                                            <V2CheckBox
                                                label={() => (
                                                    <CheckBoxInner>
                                                        <p>
                                                            {t`payment_page.checkbox.valid_data`}
                                                        </p>
                                                    </CheckBoxInner>
                                                )}
                                                type="checkbox"
                                                onChange={() =>
                                                    this.setState({
                                                        checkName: !checkName,
                                                        alertCheckbox: false,
                                                        disableSubmit: false,
                                                    })
                                                }
                                                name="agree-terms"
                                                id="agree-terms"
                                                alert={
                                                    !checkName && alertCheckbox
                                                }
                                                checked={checkName}
                                            />
                                        </Col>
                                    </Row>
                                </InfoBlock>
                            </React.Fragment>
                        )}
                        {bankCountry && (
                            <Row className="btn-row">
                                <Col>
                                    <V2Button
                                        onClick={this.submitPaymentDetails}
                                        disabledClick={() =>
                                            this.validateForm(true, true)
                                        }
                                        disabled={
                                            isDisabledSubmit ||
                                            (bankCountry && isInvalidCountry)
                                        }
                                        id="submit-bank-details"
                                    >
                                        {t`payment_page.button.submit`}
                                    </V2Button>
                                </Col>
                            </Row>
                        )}
                    </PaymentContainer>
                </PageViewContainer>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        countries: state.countries,
        lang: state.language,
        errorMessage: state.error.errorMessage,
        currencies: state.currencies,
        trans: state.translations,
        paymentErrors: state.paymentViewErrors,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCurrencies,
            getUserData,
            uploadPaymentDetails,
            validatePaymentView,
            showSuccessMessage,
            clearAuthErrors,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
