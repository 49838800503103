import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import _ from 'lodash'
import _get from 'lodash/get'
import t from '../../../shared/translations'
import { getClaimReasons, setClaimReasons } from '../../../shared/actions'
import {
    setV2Claim,
    setNavStep,
    getAirportById,
    validateStepTwo,
    setProgress,
    setStepTwo,
    clearStepOne,
    clearStepTwo,
    setStepThree,
    checkIfClaimable,
    showModal,
    nextStep,
    setClaimDraftProps,
    setPreloadedClaimStep,
    saveClaimDraft,
} from '../../../shared/actions/v2_actions'
import StepView from '../components/StepView'
import {
    VerticalRadio,
    FlightRadio,
} from '../../../shared/components/V2/RadioButtons'
import { Row, StepContent } from '../../../shared/components/V2/Layout'
import {
    isEmpty,
    isMobileDevice,
    handleMobileInputFocus,
    scrollComponentToView,
    isProduction,
    getCookie,
} from '../../../main'
import {
    mobile,
    tablet,
} from '../../../shared/components/helpers/styled_queries'
import SorryModal from '../components/SorryModal'
import { mapSteps } from '../../../shared/reducers/V2/reducer_steps'
import { V2_prePath, modalTypes, V2_STEPS } from '../../../shared/constants'
import SidebarProgress from '../components/SidebarProgress'
import { fireGtagEvent } from '../../../shared/services/events'
import { CLAIM_FORM_VERSIONS } from '../../../shared/constants'

const StyledStepContent = styled(StepContent)`
    flex-direction: column;
    // margin: 0 0 40px 0;
    padding: 0;

    ${mobile`
        // margin-bottom: 8px;
    `}
`

const StyledFieldsRow = styled(Row)`
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0px;
    }

    ${tablet`
        margin-bottom: 0px;
        `}

    ${mobile`
        margin-bottom: 0px;
        `}
`

class DisruptionDetails extends React.Component {
    state = {
        details: [],
        claimableErrors: [],
        componentState: {
            minLength: 3,
            variant: '2',
            ...this.props.step_2_state,
        },
        ...this.props.V2Claim,
        claimableResponse: [],
    }

    resetState = () => {
        this.setState({
            details: [],
            claimableErrors: [],
            componentState: {
                minLength: 3,
                ...this.props.step_2_state,
            },
            ...this.props.V2Claim,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { setParentState, isDisabled } = this.props

        if (!this.checkNextStep() && isDisabled) {
            setParentState({
                nextDisabled: false,
            })
        } else if (!isDisabled && this.checkNextStep()) {
            setParentState({
                nextDisabled: true,
            })
        }
    }

    async componentDidMount() {
        try {
            setNavStep(mapSteps.indexOf(this.props.route.path))
        } catch (e) {
            if (!isProduction()) {
                console.log(e)
            }
        }

        const {
            getClaimReasons,
            setNoBack,
            setParentState,
            V2Claim,
            claimDraft,
            claimPreloaded,
        } = this.props
        if (
            (!V2Claim ||
                !V2Claim.airportArrival ||
                !V2Claim.airportDeparture) &&
            isProduction()
        ) {
            setNavStep(0)
            browserHistory.replace(V2_prePath)
        }

        if (claimDraft && !claimPreloaded[V2_STEPS.STEP_2]) {
            this.setState(
                {
                    details: claimDraft.details || [],
                },
                () => {
                    this.props.setPreloadedClaimStep([V2_STEPS.STEP_2])
                    const { details } = this.state

                    if (details[0]) {
                        getClaimReasons(details[0].value, 2)
                            .then(() => {
                                getClaimReasons(details[1].value, 3)
                            })
                            .then(() => {
                                getClaimReasons(details[2].value, 4)
                            })
                            .catch(e => {
                                console.error(e)
                            })
                    }
                }
            )
        }

        scrollComponentToView('pageTopNavbar', false, true)

        setNoBack(false)
        setParentState({
            nextDisabled: true,
        })

        getClaimReasons('reasons', 1)

        // async optimize
        // if (["en", "lt"].includes(getStorage("lang"))) {
        //     if (window.dataLayer) {
        //         await window.dataLayer.push({ event: "optimize.activate" });
        //     }
        //     this.intervalId = setInterval(() => {
        //         if (window.google_optimize !== undefined) {
        //             const variant = window.google_optimize.get("Q1kzs2yxRrKH97TNaUPeFQ");
        //             const { componentState } = this.state;
        //             this.setState({ componentState: { ...componentState, variant } });
        //             clearInterval(this.intervalId);
        //         }
        //     }, 100);
        // }
    }

    setProblemClaimFlight = id => {
        this.setState({ problemClaimFlight: id })
        handleMobileInputFocus('reason_1', false, 500, 0.5)
    }

    renderConnectingFlights = () => {
        const { primaryColor } = this.props
        const { flights, problemClaimFlight } = this.state
        const orderedFlights = flights.sort(
            (a, b) => a.flightOrder - b.flightOrder
        )

        const flightItems = orderedFlights.map((flight, id) => ({
            name: 'problemClaimFlight',
            departure: _get(
                getAirportById(flight.airportDeparture),
                'cityIata',
                ''
            ),
            arrival: _get(
                getAirportById(flight.airportArrival),
                'cityIata',
                ''
            ),
            value: id,
            checked: problemClaimFlight === id,
            onChange: () => this.setProblemClaimFlight(id),
        }))

        return (
            <FlightRadio
                label={t`v2_claim.disruption_details.disrupted_flight`}
                required
                options={flightItems}
                planeIconColor={primaryColor}
            />
        )
    }

    renderReasons = (reasons, name, level, titleLabel) => {
        const reasonsArr = Object.keys(reasons)
        const { details } = this.state
        const reasonState = (details || [])
            .filter(r => r.level === level)
            .map(r => r.value)[0]

        const reasonItems = reasonsArr.map((reason, index) => ({
            name: name,
            value: reason,
            onChange: this.handleChange,
            checked: reasonState === reason,
            label: t`${reason}`,
            id: `reason-${reason}`,
        }))

        return (
            <VerticalRadio
                id={name}
                label={titleLabel}
                options={reasonItems}
                required
                marginLabelLeft={12}
                maxWidth="460"
            />
        )
    }

    handleChange = ({ target }) => {
        const { name, value } = target
        const { getClaimReasons, setStepThree } = this.props
        const { details, componentState } = this.state
        const level_1_details = details.filter(r => r.level === 1)
        const level_2_details = details.filter(r => r.level < 3)

        handleGaEvents(name, value)

        if (name === 'reason_1') {
            getClaimReasons(value, 2)
            const minLength = value === 'del' ? 2 : 3
            this.setState({
                details: [
                    {
                        value,
                        level: 1,
                    },
                ],
                componentState: {
                    ...componentState,
                    minLength,
                },
            })
            setStepThree({
                has_reason: true,
                reason_4: '',
            })
            handleMobileInputFocus('reason_2', false, 500, 0.5)
        }

        if (name === 'reason_2') {
            const firstReason = _.get(details, '[0].value', '')
            if (!firstReason || firstReason !== 'del') {
                getClaimReasons(value, 3)
            }
            this.setState({
                details: [
                    ...level_1_details,
                    {
                        value,
                        level: 2,
                    },
                ],
            })
            setStepThree({
                has_reason: true,
                reason_4: '',
            })
            handleMobileInputFocus('reason_3', false, 500, 0.5)
        }
        if (name === 'reason_3') {
            this.setState({
                details: [
                    ...level_2_details,
                    {
                        value,
                        level: 3,
                    },
                ],
            })
            setStepThree({
                has_reason: true,
                reason_4: '',
            })
            if (
                isMobileDevice() &&
                !getCookie('cf') === CLAIM_FORM_VERSIONS.V1
            ) {
                handleMobileInputFocus('mobile-step-nav', false, 500, 0.5)
            }
        }
    }

    _handleNextStep = () => {
        const {
            V2Claim,
            setV2Claim,
            checkIfClaimable,
            nextStep,
            showModal,
            setProgress,
            setStepTwo,
            lang,
        } = this.props

        const errors = validateStepTwo(this.state)
        const airportArrival = _get(V2Claim, 'airportArrival', '')
        const airportDeparture = _get(V2Claim, 'airportDeparture', '')
        const flights = _get(V2Claim, 'flights', [])
        const details = _get(this.state, 'details', [])

        const claimableDetails = {
            airportArrival,
            airportDeparture,
            flights,
            details,
        }

        checkIfClaimable(claimableDetails, lang, 2).then(res => {
            const { claimable } = res
            this.setState({ claimableResponse: res })
            this._handleSaveDraft()

            if (claimable) {
                setProgress(33)
                if (!!isEmpty(errors)) {
                    setV2Claim(this.state)
                    setStepTwo(this.state.componentState)
                    this.props.triggerAnalytics()
                    nextStep()
                }
            } else {
                const errors = _get(res, 'reason.data')

                this.setState({ claimableErrors: errors })
                showModal(modalTypes.sorryModal)
                // eslint-disable-next-line
                dataLayer.push({
                    event: 'ClaimNotEligible',
                })
                if (window && window.hj && typeof window.hj === 'function') {
                    window.hj('vpv', '/sorry')
                }
            }
        })

        return false
    }

    _handleSaveDraft = () => {
        const { setClaimDraftProps } = this.props
        const stateObj = { ...this.state }

        setClaimDraftProps(stateObj)
        this.props.saveClaimDraft()
    }

    _handlePrevStep = () => {
        const { setV2Claim, setStepTwo } = this.props
        this._handleSaveDraft()
        setV2Claim(this.state)
        setStepTwo(this.state.componentState)
    }

    checkNextStep = () => {
        const { details = [], componentState } = this.state
        return details.length < componentState.minLength
    }

    render() {
        const { details = [], flights, claimableErrors } = this.state
        const { reasons_1, reasons_2, reasons_3, nav, modal } = this.props
        const reasons1 = _.get(reasons_1, 'data.details.items', '')
        const reasons2 = _.get(reasons_2, 'data.details.items', '')
        const reasons3 = _.get(reasons_3, 'data.details.items', '')
        const reason1_title = _.get(reasons_1, 'data.details.title', '')
        const reason2_title = _.get(reasons_2, 'data.details.title', '')
        const reason3_title = _.get(reasons_3, 'data.details.title', '')

        return (
            <StepView
                sideBarPaddingTop={'0px'}
                Sidebar={
                    <SidebarProgress
                        activeIndex={1}
                        checkedIndexes={[0]}
                        paddingTop={0}
                    />
                }
                nav={{ ...nav, nextDisabled: this.checkNextStep() }}
                title={t`v2_claim.disruption_details.title`}
            >
                <StyledStepContent>
                    {_get(modal, 'modalType', '') === modalTypes.sorryModal && (
                        <SorryModal
                            newFlight={() => {
                                this.props.clearStepOne()
                                this.props.clearStepTwo()
                                this.resetState()
                            }}
                            errors={claimableErrors}
                        />
                    )}
                    {(flights || []).length > 1 && (
                        <Row>{this.renderConnectingFlights()}</Row>
                    )}
                    {reasons1 && (
                        <StyledFieldsRow>
                            {this.renderReasons(
                                reasons1,
                                'reason_1',
                                1,
                                t`v2_claim.reasons.${reason1_title}`
                            )}
                        </StyledFieldsRow>
                    )}
                    {details[0] && reasons2 && (
                        <StyledFieldsRow>
                            {this.renderReasons(
                                reasons2,
                                'reason_2',
                                2,
                                t`v2_claim.reasons.${reason2_title}`
                            )}
                        </StyledFieldsRow>
                    )}
                    {details[1] && reasons3 && details[0].value !== 'del' && (
                        <StyledFieldsRow>
                            {this.renderReasons(
                                reasons3,
                                'reason_3',
                                3,
                                t`v2_claim.reasons.${reason3_title}`
                            )}
                        </StyledFieldsRow>
                    )}
                </StyledStepContent>
            </StepView>
        )
    }
}

function handleGaEvents(reasonName, value) {
    if (window && window.dataLayer) {
        const eventList = window.dataLayer
            .map(layer => layer.event)
            .filter(Boolean)

        if (reasonName === 'reason_1') {
            const reasonOneEvents = [
                'step2_tod_flight_delayed',
                'step2_tod_flight_cancelled',
                'step2_tod_denied_boarding',
            ]
            const reasonOneEventsValues = ['del', 'can', 'dbo']
            const found = reasonOneEvents.some(e => eventList.includes(e))

            if (!found) {
                const valueIndex = reasonOneEventsValues.findIndex(
                    v => v === value
                )
                fireGtagEvent({ event: reasonOneEvents[valueIndex] }, true)
            }
        }

        if (reasonName === 'reason_2') {
            const reasonTwoEvents = [
                'step2_tod_flight_delayed_up_to_3h',
                'step2_tod_flight_delayed_more_3h',
                'step2_tod_flight_delayed_missed',
                'step2_tod_flight_cancelled_up_to_2h',
                'step2_tod_flight_cancelled_more_2h',
                'step2_tod_flight_cancelled_never_arr',
                'step2_tod_denied_boarding_up_to_3h',
                'step2_tod_denied_boarding_more_3h',
                'step2_tod_denied_boarding_never_arr',
            ]
            const reasonTwoEventsValues = [
                'lt3',
                '3b5',
                'gt5',
                'cd2',
                'dg3',
                'dne',
                'db1',
                'bg3',
                'bna',
            ]
            const found = reasonTwoEvents.some(e => eventList.includes(e))

            if (!found) {
                const valueIndex = reasonTwoEventsValues.findIndex(
                    v => v === value
                )
                fireGtagEvent({ event: reasonTwoEvents[valueIndex] }, true)
            }
        }

        if (reasonName === 'reason_3') {
            const reasonThreeEvents = [
                'step2_tod_flight_cancelled_less_14d',
                'step2_tod_flight_cancelled_more_14d',
                'step2_tod_denied_boarding_volunteer_yes',
                'step2_tod_denied_boarding_volunteer_no',
            ]
            const reasonThreeEventsValues = ['l14', 'g14', 'yes', 'bno']
            const found = reasonThreeEvents.some(e => eventList.includes(e))

            if (!found) {
                const valueIndex = reasonThreeEventsValues.findIndex(
                    v => v === value
                )

                fireGtagEvent({ event: reasonThreeEvents[valueIndex] }, true)
            }
        }
    }
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        reasons_1: state.reason_1,
        reasons_2: state.reason_2,
        reasons_3: state.reason_3,
        trans: state.translations,
        lang: state.language,
        V2Claim: state.V2Claim,
        step_2_state: state.step_2_state,
        modal: state.toggle_modal,
        claimDraft: state.claimDraft,
        claimPreloaded: state.claimPreloaded,
        authenticated: state.auth.authenticated,
        draftEmail: state.draftEmail,
        primaryColor: state.propsTheme.colors.primaryColor,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getClaimReasons,
            setV2Claim,
            setProgress,
            setStepTwo,
            setStepThree,
            checkIfClaimable,
            nextStep,
            showModal,
            clearStepOne,
            clearStepTwo,
            setClaimDraftProps,
            setPreloadedClaimStep,
            setClaimReasons,
            saveClaimDraft,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(DisruptionDetails)
